import FormSelectListStatic from './FormSelectListStatic';
const ReputationList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      id='reputation'
      header='Reputation'
      options={['Clean', 'Suspicious', 'Malicious']}
    />
  );
};
export default ReputationList;
