import './MainDashboard.css';
import CyberCampaignMainDashboard from '../components/main dashboard/CyberCampaignsMainDahsboard';
import PhishingCampaignsMainDashboard from '../components/main dashboard/PhishingCampaignsMainDashboard';
import TopMalwaresMainDashboard from '../components/main dashboard/TopMalwaresMainDashboard';
import MapMainDashboard from '../components/main dashboard/MapMainDashboard';
import CyberNewsMainDashboard from '../components/main dashboard/CyberNewsMainDashboard';
import RealTimeAlertsMainDashboard from '../components/main dashboard/RealTimeAlertsMainDashboard';
import { useEffect } from 'react';

const MainDashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='app-content-main-dashboard'>
      <div className='main-dashboard-left'>
        <div className='main-dashboard-left-top-cards-container'>
          <TopMalwaresMainDashboard />
          <PhishingCampaignsMainDashboard />
          <CyberCampaignMainDashboard />
        </div>

        <MapMainDashboard />

        <CyberNewsMainDashboard />
      </div>
      <div className='main-dashboard-right'>
        <RealTimeAlertsMainDashboard />
      </div>
    </div>
  );
};
export default MainDashboard;
