import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from '../components/Table';
import FiltersBarAndData from '../components/FiltersBarAndData';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useSelector } from 'react-redux';

const RuleRepository = () => {
  const { getError, getSuccess, getRequestFunc, data } = useGetRequest();
  const { isAdmin } = useSelector((state) => state.appReducer);

  const [filters, setFilters] = useState();
  const [originalData, setOriginalData] = useState();
  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/rule-repository', true);
  }, []);

  useEffect(() => {
    getRules();
  }, [data]);

  const getRules = async () => {
    if (data && !getError && getSuccess) {
      const fetchedRules = data.sort((a, b) => {
        if (a.rule_name < b.rule_name) {
          return -1;
        }
        if (a.rule_name > b.rule_name) {
          return 1;
        }
        return 0;
      });

      setOriginalData(fetchedRules);

      let products = [];
      let severities = [];
      const mitre_tactics = [];
      const mitre_techniques = [];

      data.map((rule) => {
        if (rule.product && rule.product !== 'N/A') {
          if (
            !products.some(
              (item) => item.name.toLowerCase() === rule.product.toLowerCase()
            )
          ) {
            products.push({
              name: rule.product,
              quantity: 1,
            });
          } else {
            const existing = products.find(
              (element) =>
                element.name.toLowerCase() === rule.product.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (rule.severity && rule.severity !== 'N/A') {
          if (
            !severities.some(
              (item) => item.name.toLowerCase() === rule.severity.toLowerCase()
            )
          ) {
            severities.push({
              name: rule.severity,
              quantity: 1,
            });
          } else {
            const existing = severities.find(
              (element) =>
                element.name.toLowerCase() === rule.severity.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (rule.mitre_tactic && rule.mitre_tactic !== 'N/A') {
          if (
            !mitre_tactics.some(
              (item) =>
                item.name.toLowerCase() === rule.mitre_tactic.toLowerCase()
            )
          ) {
            mitre_tactics.push({
              name: rule.mitre_tactic,
              quantity: 1,
            });
          } else {
            const existing = mitre_tactics.find(
              (element) =>
                element.name.toLowerCase() === rule.mitre_tactic.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (rule.mitre_technique && rule.mitre_technique !== 'N/A') {
          if (
            !mitre_techniques.some(
              (item) =>
                item.name.toLowerCase() === rule.mitre_technique.toLowerCase()
            )
          ) {
            mitre_techniques.push({
              name: rule.mitre_technique,
              quantity: 1,
            });
          } else {
            const existing = mitre_techniques.find(
              (element) =>
                element.name.toLowerCase() ===
                rule.mitre_technique.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
      });

      setFilters([
        {
          header: 'Product',
          fieldValue: 'product',
          filters: products
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Severity',
          fieldValue: 'severity',
          filters: severities
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Mitre Tactics',
          fieldValue: 'mitre_tactic',
          filters: mitre_tactics
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Mitre Techniques',
          fieldValue: 'mitre_technique',
          filters: mitre_techniques
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((value, i) => {
            return (
              <tr key={i} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color'
                  style={{ cursor: 'pointer' }}
                >
                  {value.rule_name}
                </td>
                <td className='data-table-td'>{value.product}</td>
                <td className='data-table-td'>{value.description}</td>
                <td className='data-table-td'>{value.severity}</td>
                <td className='data-table-td'>{value.mitre_tactic}</td>
                <td className='data-table-td'>{value.mitre_technique}</td>
                {isAdmin && (
                  <td>
                    <DeleteAndEditButtons
                      id={value._id}
                      path={'rule-repository'}
                      afterDeleteFunc={() => {
                        getRules();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem('edit_rule_repo_id', value._id);
                      }}
                      navigateToEdit='/edit-rule-repository-item'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Rule Name', ''],
            ['Product', ''],
            ['Description', ''],
            ['Severity', ''],
            ['Mitre Tactic', ''],
            ['Mitre Technique', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getRules();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='rule_name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default RuleRepository;
