import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import EditFormMUI from '../components/EditFormMUI';
import CountryList from '../components/CountryList';
import { useNavigate } from 'react-router-dom';

const EditDiscoveryItem = () => {
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const edit_discovery_item_id = sessionStorage.getItem(
    'edit_discovery_item_id'
  );
  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/live-ip-addresses/${edit_discovery_item_id}`,
      true
    );
  }, []);

  const getDiscoveryStr = async () => {
    const tags = document.getElementById('tags').value.split(',');
    const ip_address = document.getElementById('ip_address').value;
    const camera_name = document.getElementById('camera_name').value;
    const city = document.getElementById('city').value;
    const manufacturer = document.getElementById('manufacturer').value;
    const model = document.getElementById('model').value;
    const mac_address = document.getElementById('mac_address').value;
    const user = document.getElementById('user').value;
    const device_time = document.getElementById('device_time').value;
    const added_time = document.getElementById('added_time').value;

    const updatedDiscoveryItem = JSON.stringify({
      tags,
      ip_address,
      camera_name,
      city,
      manufacturer,
      model,
      country: country || data.country,
      mac_address,
      user,
      device_time,
      added_time,
    });

    return updatedDiscoveryItem;
  };

  return (
    data && (
      <EditFormMUI
        header='Discovery Item'
        textInputs={[
          {
            id: 'tags',
            label: 'tags',
            defaultValue: data.tags,
          },
          {
            id: 'ip_address',
            label: 'ip_address',
            defaultValue: data.ip_address,
          },
          {
            id: 'camera_name',
            label: 'camera_name',
            defaultValue: data.camera_name,
          },
          {
            id: 'city',
            label: 'city',
            defaultValue: data.city,
          },
          {
            id: 'manufacturer',
            label: 'manufacturer',
            defaultValue: data.manufacturer,
          },
          {
            id: 'model',
            label: 'model',
            defaultValue: data.model,
          },

          {
            id: 'mac_address',
            label: 'mac_address',
            defaultValue: data.mac_address,
          },
          {
            id: 'user',
            label: 'user',
            defaultValue: data.user,
          },
          {
            id: 'device_time',
            label: 'device_time',
            type: 'date',
            defaultValue: data.device_time
              ? data.device_time.split('T')[0]
              : null,
            additionalData: `time in DB: ${data.device_time}`,
          },
          {
            id: 'added_time',
            label: 'added_time',
            type: 'date',
            defaultValue: data.added_time
              ? data.added_time.split('T')[0]
              : null,
            additionalData: `time in DB: ${data.added_time}`,
          },
        ]}
        selectInputs={
          <>
            <CountryList
              id='country'
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              header='Country'
              additionalData={`Country in DB: ${data.country}`}
            />
          </>
        }
        path={`live-ip-addresses/${data._id}`}
        itemToEdit={getDiscoveryStr}
        afterDeleteFunc={() => {
          navigate('/discovery');
        }}
      />
    )
  );
};
export default EditDiscoveryItem;
