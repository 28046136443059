import usePostRequest from '../../hooks/use-post-request';
import { useEffect, useState } from 'react';
import CreationFormMUI from '../../components/CreationFormMUI';
// import EmergingThreatList from '../../components/EmergingThreatList'; //💥DELETE THIS COMPONENT WHEN NOT NEEDED ANYMORE
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
const CreateEmergingThreatRecommendation = () => {
  const { postSuccess, postLoading, postFeedback, postError, postRequestFunc } =
    usePostRequest();
  const [emergingThreatID, setEmergingThreatID] = useState(null);

  const createNewItem = async () => {
    const recommendation = document.getElementById('recommendation').value;

    const newItemStr = JSON.stringify({
      recommendation,
      emerging_threat_id: emergingThreatID,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/emerging-threat-recommendations',
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Emerging Threat Recommendation'
      textInputs={[{ label: 'Recommendation', id: 'recommendation' }]}
      selectInputs={
        <FormSelectListDynamic
          onChange={(e) => {
            setEmergingThreatID(e.target.value);
          }}
          header='Emerging Threat'
          path='emerging-threats'
          optVal='cveid'
          id='emerging_threat_id'
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateEmergingThreatRecommendation;
