import './CyberNewsMainDashboard.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Card from '../Card';
import { dateFormat } from '../../utils/dateFormat';
const CyberNewsMainDashboard = () => {
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();
  const [rssFeeds, setRssFeeds] = useState(
    <ClipLoader loading={true} size={35} className='top-malwares-cliploader' />
  );
  const displayedRssFeeds = [];

  const createDescriptionDiv = (i, description) => {
    const div = document.createElement('div');
    div.id = i;
    div.innerHTML = description;
    return div.innerText.replace('read more', '').trim();
  };

  const getRssFeeds = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/rss-feeds', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const results = res.data.data;
          for (const linkData of Object.values(results)) {
            for (const value of Object.values(linkData)) {
              for (const [i, data] of Object.entries(value.item)) {
                if (
                  data.description[0].length >= 620 &&
                  displayedRssFeeds.length < 4
                ) {
                  displayedRssFeeds.push(
                    <Card
                      className='col-lg-6 main-dashboard-cyber-news-card-content-wrapper'
                      key={`${i}, ${data.title}`}
                      content={
                        <div className=' main-dashboard-cyber-news-card'>
                          <div className='main-dashboard-cyber-news-card-content'>
                            <div className='main-dashboard-cyber-security-news-title'>
                              <span className='flex-grow-1 '>{data.title}</span>
                            </div>

                            <div className='main-dash-rss-feeds-date'>
                              {dateFormat(data.pubDate)}
                            </div>

                            <div className='row main-dashboard-cyber-security-news-description'>
                              {data.description &&
                                createDescriptionDiv(i, data.description[0])}
                            </div>
                          </div>
                          <button
                            className='main-dash-read-more-btn-cyber-news'
                            onClick={() => {
                              navigate('/threat-feed');
                            }}
                          >
                            Read More
                          </button>
                        </div>
                      }
                    />
                  );
                }
              }
            }
          }
          setRssFeeds(displayedRssFeeds);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRssFeeds();
  }, []);

  return (
    <div className='main-dashboard-cyber-news-wrapper mb-3'>
      <div className='d-flex flex-grow-1 fw-bold small main-dashboard-cyber-security-news-header'>
        Cyber Security News
      </div>
      <div className='main-dashboard-cyber-news-cards-container'>
        {rssFeeds}
      </div>
    </div>
  );
};
export default CyberNewsMainDashboard;
