import { useState, useEffect } from 'react';
import CountryList from './../../components/CountryList';
import useGetRequest from './../../hooks/use-get-request';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditTerrorThreatActorForm = () => {
  const navigate = useNavigate();
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const threat_actor_type = sessionStorage.getItem('threat_actor_type');
  const threat_actor_id = sessionStorage.getItem('threatActorID');
  const [adminOnlyAccess, setAdminOnlyAccess] = useState(false);
  const [chosenOriginCountries, setChosenOriginCountries] = useState([]);
  const [chosenVictimCountries, setChosenVictimCountries] = useState([]);
  const [type, setType] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/${threat_actor_type}s/${threat_actor_id}`,
      true
    );
  }, []);

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const getEditedActor = async () => {
    const name = document.getElementById('name').value;
    const goal = document.getElementById('goal').value;
    const description = document.getElementById('description').value;

    const victims_industries =
      document.getElementById('victims_industries').value;

    const editedItem = JSON.stringify({
      name,
      goal,
      type: type ? type : data.type,
      victims_industries,
      victim_country:
        chosenVictimCountries.length === 0
          ? data.victim_country
          : chosenVictimCountries,
      description,
      country:
        chosenOriginCountries.length === 0
          ? data.country
          : chosenOriginCountries,
      admin_only: adminOnlyAccess,
    });
    return editedItem;
  };

  return (
    data && (
      <EditFormMUI
        header='Terror Threat Actor'
        changeImageInput
        handleImageChange={(e) => {
          handleChange(e);
        }}
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          { label: 'Goal', id: 'goal', defaultValue: data.goal },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
          {
            label: 'Victim Industries',
            id: 'victims_industries',
            defaultValue: data.victims_industries,
          },
        ]}
        selectInputs={
          <>
            <CountryList
              id='country'
              header='Origin Countries'
              onChange={(e) => {
                setChosenOriginCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
            />
            <CountryList
              id='victims'
              header='Victims Countries'
              onChange={(e) => {
                setChosenVictimCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
            />
            <FormSelectListStatic
              onChange={(e) => {
                setType(e.target.value);
              }}
              id='type'
              header='Type'
              options={[
                'Hacktivism',
                'State Sponsored',
                'Cyber-Crime',
                'Gray Hat',
                'Cyber-Vandalism',
              ]}
            />
            <FormSelectListStatic
              onChange={(e) => {
                e.target.value === 'Admin Only'
                  ? setAdminOnlyAccess(true)
                  : setAdminOnlyAccess(false);
              }}
              id='access'
              header='Access'
              options={['Admin Only', 'Everyone']}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`terror-threat-actors/${data._id}`}
        afterDeleteFunc={() => {
          navigate(`/terror-threat-actors/${data._id}`);
        }}
      />
    )
  );
};
export default EditTerrorThreatActorForm;
