import CountryList from '../components/CountryList';
import IOCTypeList from '../components/IOCTypeList';
import IOCCategoryList from '../components/IOCCategoryList';
import ReputationList from '../components/ReputationList';
import IOCActivityList from '../components/IocActivityList';
import usePostRequest from '../hooks/use-post-request';
import { useEffect } from 'react';
import CreationFormMUI from '../components/CreationFormMUI';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import { useState } from 'react';

const NewIOCs = () => {
  const { postSuccess, postError, postFeedback, postLoading, postRequestFunc } =
    usePostRequest();
  const [sourceCountry, setSourceCountry] = useState('N/A');
  const [cyberCampaignID, setCyberCampaignID] = useState(null);
  const [reputation, setReputation] = useState('N/A');
  const [malwareID, setMalwareID] = useState(null);
  const [iocType, setIocType] = useState('N/A');
  const [iocCategory, setIocCategory] = useState('N/A');
  const [cyberThreatActorID, setCyberThreatActorID] = useState(null);
  const [activity, setActivity] = useState('N/A');

  const setNewIOC = async () => {
    const ioc_value = document.getElementById('ioc_value').value;
    const feed = document.getElementById('feed').value;
    const description = document.getElementById('description').value;
    const last_update = document.getElementById('last_update').value;
    const tags =
      document.getElementById('tags').value.trim() === ''
        ? []
        : document
            .getElementById('tags')
            .value.split(',')
            .map((t) => {
              return t.trim();
            });

    const iocStr = JSON.stringify({
      ioc_value,
      cyber_campaign_id: cyberCampaignID,
      feed,
      source_country: sourceCountry,
      activity,
      reputation,
      description,
      tags,
      ioc_type: iocType,
      ioc_category: iocCategory,
      last_update: new Date(last_update),
      malware_id: malwareID,
      cyber_threat_actor_id: cyberThreatActorID,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc('https://api.hackeye.io/api/v1/iocs', iocStr, true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Ioc'
      textInputs={[
        { label: 'Ioc Value', id: 'ioc_value' },
        { label: 'Feed', id: 'feed' },
        {
          label: 'Last Update',
          id: 'last_update',
          type: 'date',
          defaultValue: Date.now(),
        },
        { label: 'Tags', id: 'tags' },
        { label: 'Description', id: 'description' },
      ]}
      selectInputs={
        <>
          <ReputationList
            onChange={(e) => {
              setReputation(e.target.value);
            }}
          />
          <IOCCategoryList
            onChange={(e) => {
              setIocCategory(e.target.value);
            }}
          />
          <IOCTypeList
            onChange={(e) => {
              setIocType(e.target.value);
            }}
          />
          <CountryList
            id='source_country'
            header='Source Country'
            onChange={(e) => {
              setSourceCountry(e.target.value);
            }}
          />
          <IOCActivityList
            onChange={(e) => {
              setActivity(e.target.value);
            }}
          />

          <FormSelectListDynamic
            onChange={(e) => {
              setCyberCampaignID(e.target.value);
            }}
            header='Cyber Campaign'
            path='cyber-campaigns-events'
            optVal='name'
            id='cyber_campaign_id'
          />

          <FormSelectListDynamic
            onChange={(e) => {
              setMalwareID(e.target.value);
            }}
            header='Malware'
            path='malware-threat-actors'
            optVal='malware_name'
            id='malware_id'
          />
          <FormSelectListDynamic
            onChange={(e) => {
              setCyberThreatActorID(e.target.value);
            }}
            header='Cyber Actor'
            path='cyber-threat-actors'
            optVal='name'
            id='cyber_threat_actor_id'
          />
        </>
      }
      onClick={() => {
        setNewIOC();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default NewIOCs;
