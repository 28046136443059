import './IPSearchResults.css';
import axios from 'axios';
import Card from '../../components/Card';
import './SearchResults.css';
import { useState, useEffect } from 'react';
import { countryData } from '../../utils/countryLatLng';
import Flags from '../../components/Flags';
import PieChartSingleValue from '../../components/PieChartSingleValue';
import Table from '../../components/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../utils/dateFormat';
import usePostRequest from '../../hooks/use-post-request';

const IPSearchResults = () => {
  const { postRequestFunc, postData } = usePostRequest();
  const searched_IP = sessionStorage.getItem('searched_IP');
  const token = sessionStorage.getItem('token');
  const { searchedIP } = useSelector((state) => state.appReducer);
  const [ipData, setIPData] = useState(undefined);
  const [iocData, setIOCData] = useState(undefined);
  const [reports, setReports] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const cliploader = (
    <ClipLoader loading={true} size={26} className='navbar-search-cliploader' />
  );

  const getIPDBData = async (ip) => {
    setLoading(true);
    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/abuseIPDB',
          { ip },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setIPData(res.data.data.ipDetails.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIPData(undefined);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getIPDataIocRepo = async (ip) => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/iocs/ip/${ip}`,

          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setIOCData(res.data.data.doc);
          setLoading(false);
        })
        .catch(() => {
          setIOCData(undefined);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getIPDBData(searched_IP);
  }, [searchedIP]);

  useEffect(() => {
    if (ipData) {
      getIPDataIocRepo(searched_IP);
      postRequestFunc(
        'https://api.hackeye.io/api/v1/shodan',
        { ip: searched_IP },
        true
      );
    }
  }, [ipData]);

  const CATEGORIES = {
    1: 'DNS Compromise',
    2: 'DNS Poisoning',
    3: 'Fraud Orders',
    4: 'DDoS Attack',
    5: 'FTP Brute-Force',
    6: 'Ping of Death',
    7: 'Phishing',
    8: 'Fraud VoIP',
    9: 'Open Proxy',
    10: 'Web Spam',
    11: 'Email Spam',
    12: 'Blog Spam',
    13: 'VPN IP',
    14: 'Port Scan',
    15: 'Hacking',
    16: 'SQL Injection',
    17: 'Spoofing',
    18: 'Brute-Force',
    19: 'Bad Web Bot',
    20: 'Exploited Host',
    21: 'Web App Attack',
    22: 'SSH',
    23: 'IoT Targeted',
  };

  useEffect(() => {
    let fetchedReports = [];
    if (ipData && ipData.reports && ipData.reports.length > 0) {
      fetchedReports = ipData.reports;
    }
    if (iocData) {
      fetchedReports.push({
        id: iocData._id,
        source: 'LogintDB',
        category: iocData.ioc_category,
        activity: iocData.activity,
        source_country: iocData.source_country,
        last_update: iocData.last_update,
      });
    }
    setReports(fetchedReports);
  }, [ipData, iocData]);

  const getReputation = (rep) => {
    if (rep <= 20) {
      return ['Clean', 'Low'];
    } else if ((rep >= 20) & (rep <= 50)) {
      return ['Suspicious', 'High'];
    } else {
      return ['Malicious', 'Critical'];
    }
  };

  const displayReports = () => {
    return (
      reports &&
      reports.length > 0 &&
      reports.map((report, i) => {
        return (
          <tr className='data-table-tr' key={i}>
            <td className='data-table-td'>
              {report.categories &&
                report.categories.map((c, i) => {
                  return (
                    <div
                      className='ip-search-results-ioc-table-td-button'
                      key={i}
                    >
                      {CATEGORIES[c]}
                    </div>
                  );
                })}
              {report.category && (
                <div className='ip-search-results-ioc-table-td-button'>
                  {report.category}
                </div>
              )}
            </td>
            <td className='data-table-td'>
              {report.comment ? report.comment : ''}
              {report.activity ? report.activity : ''}
            </td>
            <td className='data-table-td'>{report.source_country}</td>
            <td className='data-table-td'>
              {report.source ? report.source : 'abuseIPDB'}
            </td>
            <td className='data-table-td'>
              {report.reportedAt
                ? dateFormat(report.reportedAt, '-')
                : dateFormat(report.last_update, '-')}
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <div>
      {loading ? (
        cliploader
      ) : ipData ? (
        <div className='col-xl-12 ip-search-results-card'>
          <PieChartSingleValue
            value={getReputation(ipData.abuseConfidenceScore)[0]}
            color={getReputation(ipData.abuseConfidenceScore)[1]}
            header='Reputation'
          />
          <div className='ip-search-results-detail-wrapper'>
            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>IP Address:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {searched_IP}
              </div>
            </div>
            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>Type:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {ipData.usageType ? ipData.usageType : 'N/A'}
              </div>
            </div>
            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>Country:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {countryData[ipData.countryCode].name ? (
                  <Flags
                    countries={[countryData[ipData.countryCode].name]}
                    displayOtherDataByFlag={ipData.countryName}
                  />
                ) : (
                  'N/A'
                )}
              </div>
            </div>
            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>Domain:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {ipData.domain ? ipData.domain : 'N/A'}
              </div>
            </div>

            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>ISP:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {ipData.isp ? ipData.isp : 'N/A'}
              </div>
            </div>

            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>
                Last Reported:
              </div>{' '}
              <div className='ip-search-results-detail-value'>
                {ipData.lastReportedAt
                  ? dateFormat(ipData.lastReportedAt)
                  : 'N/A'}
              </div>
            </div>

            {postData && postData.data && postData.data.org ? (
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  Organization:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {postData.data.org}
                </div>
              </div>
            ) : (
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  Organization:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  <div className='actor-displayed-data-tag-filled-bg'>N/A</div>
                </div>
              </div>
            )}
            {postData &&
            postData.data &&
            postData.data.ports &&
            postData.data.ports.length > 0 ? (
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  Open Ports:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {postData.data.ports.map((port, i) => {
                    return (
                      <div
                        key={i}
                        className='actor-displayed-data-tag-filled-bg'
                      >
                        {port}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  Open Ports:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  <div className='actor-displayed-data-tag-filled-bg'>N/A</div>
                </div>
              </div>
            )}
            <div className='ip-search-results-detail-container'>
              <div className='ip-search-results-detail-header'>Tags:</div>{' '}
              <div className='ip-search-results-detail-value'>
                {ipData.isPublic ? (
                  <div style={{ display: 'flex' }}>
                    <div className='actor-displayed-data-tag-filled-bg'>
                      Public
                    </div>
                    {iocData && iocData.tags && iocData.tags.length > 0 && (
                      <div>
                        {iocData.tags.map((tag, i) => {
                          if (tag.length > 0) {
                            return (
                              <div
                                className='actor-displayed-data-tag-filled-bg'
                                key={i}
                              >
                                {tag}
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='actor-displayed-data-tag-filled-bg'>N/A</div>
                )}
              </div>
            </div>
            {iocData &&
              iocData.description &&
              iocData.description !== 'N/A' && (
                <div className='ip-search-results-detail-container'>
                  <div className='ip-search-results-detail-header'>
                    Description:
                  </div>{' '}
                  <div className='ip-search-results-detail-value'>
                    {iocData.description}
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <Card
          className='col-xl-12'
          content={<div style={{ padding: '10px' }}>No Results Found</div>}
        />
      )}
      {(ipData || iocData) && (
        <div>
          <div className='bloc-tabs'>
            <button className='tabs active-tabs table-single-tab'>
              History
            </button>
          </div>
          <div className='content-tabs mt-10'>
            <div className='content active-content'>
              {reports && (
                <Table
                  receivedData={displayReports}
                  tableHeaders={[
                    ['Category', ''],
                    ['Activity', ''],
                    ['Reported Country', ''],
                    ['Feed Name', ''],
                    ['Reported at', ''],
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default IPSearchResults;
