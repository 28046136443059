import FormSelectListStatic from './FormSelectListStatic';

const EventIdentifierLevelList = (props) => {
  return (
    <FormSelectListStatic
      id='level'
      onChange={props.onChange}
      header='Level'
      options={[
        'Information',
        'Warning',
        'Error',
        'Verbose',
        'Audit Success',
        'Audit Failure',
      ]}
    />
  );
};
export default EventIdentifierLevelList;
