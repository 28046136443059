import { storage } from '../../dbs/initializeFirebase';
import { useState } from 'react';

import './UpdateMyPicture.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const UpdateMyPicture = () => {
  const { user_id } = useSelector((state) => state.appReducer);
  const [loading, setLoading] = useState(false);
  const [isImgUpdated, setIsImgUpdated] = useState(false);

  const [file, setFile] = useState(null);
  const [url, setURL] = useState('');

  function handleChange(e) {
    if (e.target.files[0]) setFile(e.target.files[0]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsImgUpdated(false);
    setLoading(true);
    const path = `/profileImages/${user_id}`;
    const ref = storage.ref(path);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    setURL(url);
    setLoading(false);
    setIsImgUpdated(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Update Picture</h1>
      <div className='update-profile-img-container'>
        <input
          className='update-profile-img-input'
          type='file'
          accept='image/*'
          onChange={(e) => {
            handleChange(e);
          }}
        ></input>
        <button
          className='update-profile-img-btn'
          disabled={!file}
          onClick={(e) => {
            handleUpload(e);
          }}
        >
          upload
        </button>
        <ClipLoader
          loading={loading}
          size={35}
          className='update-profile-img-cliploader'
        />
      </div>
      {isImgUpdated && <p>Image updated successfully!</p>}
    </div>
  );
};
export default UpdateMyPicture;
