import SeverityList from '../components/SeverityList';
import CreationFormMUI from '../components/CreationFormMUI';
import MalwareTypeList from '../components/MalwareTypeList';
import MalwareOperationSystemList from '../components/MalwareOperationSystemList';
import ThreatIndustryList from '../components/ThreatIndustryList';
import usePostRequest from '../hooks/use-post-request';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import { useState } from 'react';

const CreateSuspiciousIdentifier = () => {
  const [types, setTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [threatActors, setThreatActors] = useState([]);
  const [severity, setSeverity] = useState('N/A');
  const [operation_system, setOperationSystem] = useState('N/A');

  const { postFeedback, postRequestFunc, postSuccess, postError, postLoading } =
    usePostRequest();

  const createNewItem = async () => {
    //name, threat_actors, ioc, hash, attack_vectors,detection_name,type, operation_system, industry
    const name = document.getElementById('name').value;
    const ioc = document.getElementById('ioc').value;
    const hash = document.getElementById('hash').value;
    const attack_vectors = document.getElementById('attack_vectors').value;
    const description = document.getElementById('description').value;
    const detection_name = document.getElementById('detection_name').value;
    const detections = document.getElementById('detections').value;
    const confidence_score = document.getElementById('confidence_score').value;
    const affected_platforms =
      document.getElementById('affected_platforms').value;
    const impacted_parties = document.getElementById('impacted_parties').value;
    const impact = document.getElementById('impact').value;
    const file_extention = document.getElementById('file_extention').value;
    const chainware = document.getElementById('chainware').value;
    const victomology = document.getElementById('victomology').value;
    const mitre_attack = document.getElementById('mitre_attack').value;
    const tags = document.getElementById('tags').value;
    const threat_actors = threatActors;
    const type = types;
    const industry = industries;

    const newItemStr = JSON.stringify({
      name,
      threat_actors,
      ioc,
      hash,
      description,
      attack_vectors,
      detection_name,
      type,
      operation_system,
      industry,
      detections,
      severity,
      confidence_score,
      tags,
      affected_platforms,
      impacted_parties,
      impact,
      file_extention,
      chainware,
      victomology,
      mitre_attack,
      createdAt: new Date(Date.now()),
    });
    await postRequestFunc(
      'https://api.hackeye.io/api/v1/suspicious-identifiers',
      newItemStr,
      true
    );
  };

  return (
    <CreationFormMUI
      header='Suspicious Identifier'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Ioc', id: 'ioc' },
        { label: 'Hash', id: 'hash' },
        { label: 'Attack Vectors', id: 'attack_vectors' },
        { label: 'Affected Platforms', id: 'affected_platforms' },
        { label: 'Impacted Parties', id: 'impacted_parties' },
        { label: 'impact', id: 'impact' },
        { label: 'Detection Name', id: 'detection_name' },
        { label: 'File Extention', id: 'file_extention' },
        { label: 'Chainware', id: 'chainware' },
        { label: 'Victomology', id: 'victomology' },
        { label: 'Mitre Attack', id: 'mitre_attack' },
        {
          label: 'Detections',
          id: 'detections',
          type: 'number',
        },
        {
          label: 'Confidence Score',
          id: 'confidence_score',
          type: 'number',
        },
        { label: 'Description', id: 'description' },
        { label: 'Tags', id: 'tags' },
      ]}
      selectInputs={
        <>
          <SeverityList
            onChange={(e) => {
              setSeverity(e.target.value);
            }}
          />
          <MalwareOperationSystemList
            onChange={(e) => {
              setOperationSystem(e.target.value);
            }}
          />
          <MalwareTypeList
            onChange={(e) => {
              setTypes((prev) => {
                return [...prev, e.target.value];
              });
            }}
          />
          <FormSelectListDynamic
            header='Cyber Threat Actor'
            path='cyber-threat-actors'
            optVal='name'
            id='cyber_threat_actor_id'
            onChange={(e) => {
              setThreatActors((prev) => {
                return [...prev, e.target.value];
              });
            }}
          />
          <ThreatIndustryList
            onChange={(e) => {
              setIndustries((prev) => {
                return [...prev, e.target.value];
              });
            }}
          />
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateSuspiciousIdentifier;
