import axios from 'axios';
import { useState } from 'react';

const useDeleteRequest = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteFeedback, setDeleteFeedback] = useState('');

  const token = sessionStorage.getItem('token');

  //INSERT A PARAMETER OF THE REDUX FUNCTION TO MAKE IT NULL after success
  const deleteRequestFunc = async (url, Authorization = false) => {
    setDeleteLoading(true);

    const headers = Authorization
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      : {
          'Content-Type': 'application/json',
        };
    try {
      await axios
        .delete(url, {
          headers,
        })
        .then(() => {
          setDeleteFeedback('operation finished successfully!');
          setDeleteLoading(false);
          setDeleteSuccess(true);
          setDeleteError(false);
          console.log('operation finished successfully!');
        })
        .catch((err) => {
          console.log('fail: ' + err.response.data.message);
          setDeleteFeedback('Something went wrong!');
          setDeleteLoading(false);
          setDeleteSuccess(false);
          setDeleteError(true);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    deleteError,
    deleteSuccess,
    setDeleteSuccess,
    deleteLoading,
    setDeleteLoading,
    deleteRequestFunc,
    deleteFeedback,
  };
};

export default useDeleteRequest;
