import { useState } from 'react';
import { Link } from 'react-router-dom';

const SidebarMainLink = (props) => {
  const [pointer, setPointer] = useState('›');

  const activateMainLink = (e) => {
    Array.from(
      document.querySelectorAll('.sidebar-main-link-value-icon-container')
    ).map((link) => {
      return (link.style.backgroundColor = 'rgb(12.46, 12.46, 19.13)');
    });

    e.target.closest(
      '.sidebar-main-link-value-icon-container'
    ).style.backgroundColor = 'rgba(32, 43, 68, 1)';

    setPointer((prev) => (prev === '›' ? 'v' : '›'));
  };

  return (
    <li
      onClick={(e) => {
        activateMainLink(e);
        props.onClick && props.onClick(e);
      }}
      className='sidebar-main-link-wrapper'
    >
      <div className='sidebar-main-link-container'>
        {props.dropdown ? (
          <div className='sidebar-main-link-value-icon-container-dropdown sidebar-main-link-value-icon-container'>
            <div style={{ display: 'flex' }}>
              <img
                src={props.src}
                alt=''
                className={`sidebar-main-link-icon ${props.className}`}
                id={props.id}
              />
              <div className='sidebar-main-link-value'>{props.value}</div>
            </div>
            <div style={{ width: '10px', paddingTop: '4px' }}>{pointer}</div>
          </div>
        ) : (
          <div className='sidebar-main-link-value-icon-container'>
            <img
              src={props.src}
              alt=''
              className={`sidebar-main-link-icon ${props.className}`}
              id={props.id}
            />
            <Link
              to={props.navigateTo}
              className='sidebar-main-link-value sidebar-main-link-value-link'
            >
              {props.value}
            </Link>
          </div>
        )}
      </div>
    </li>
  );
};
export default SidebarMainLink;
