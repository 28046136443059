import { useEffect, useState } from 'react';
import useGetRequest from '../../hooks/use-get-request';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
import { useNavigate } from 'react-router-dom';

const EditEmergingThreatRecommendation = () => {
  const navigate = useNavigate();
  const { getRequestFunc, data } = useGetRequest();
  const edit_emerging_threat_recommendation = sessionStorage.getItem(
    'edit_emerging_threat_recommendation'
  );
  const [emergingThreat, setEmergingThreat] = useState(null);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/emerging-threat-recommendations/${edit_emerging_threat_recommendation}`,
      true
    );
  }, []);

  const getEditedActor = async () => {
    const recommendation = document.getElementById('recommendation').value;

    const inputs = JSON.stringify({
      recommendation,
      emerging_threat_id: emergingThreat
        ? emergingThreat
        : data.emerging_threat_id,
    });

    return inputs;
  };

  return (
    data && (
      <EditFormMUI
        header='Edit Emerging Threat Recommendation'
        inputs={[
          {
            label: 'Recommendation',
            id: 'recommendation',
            defaultValue: data.recommendation,
          },
        ]}
        selectInputs={
          <>
            <FormSelectListDynamic
              header='Emerging Threat'
              onChange={(e) => {
                setEmergingThreat(e.target.value);
              }}
              path='emerging-threats'
              optVal='name'
              id='emerging_threat_id'
              additionalData={`Emerging Threat in DB: ${data.emerging_threat_id}`}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`emerging-threat-recommendations/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/emerging-threats');
          sessionStorage.removeItem(edit_emerging_threat_recommendation);
        }}
      />
    )
  );
};
export default EditEmergingThreatRecommendation;
