import FormSelectListStatic from './FormSelectListStatic';

const IOCActivityList = (props) => {
  return (
    <FormSelectListStatic
      additionalData={props.additionalData}
      onChange={props.onChange}
      id='activity'
      header='Activity'
      options={[
        'Botnet',
        'Port Scan',
        'Unauthorized Access',
        'Brute Force',
        'Impossible Travel',
        'Data Encryption',
        'Squatting Campaign',
        'Hacking',
        'SSH',
        'DDoS Attack',
        'Web App Attack',
        'Exploited Host',
        'Information theft',
      ]}
    />
  );
};
export default IOCActivityList;
