import { useState, useEffect } from 'react';
import axios from 'axios';
import ThreatActorIocCampaignsMalwaresTable from '../components/ThreatActorIocCampaignsMalwaresTable';
import PieChartSingleValue from '../components/PieChartSingleValue';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../utils/dateFormat';

const SuspiciousIdentifier = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const [item, setItem] = useState(undefined);
  const suspicious_identifier_id = sessionStorage.getItem(
    'suspicious_identifier_id'
  );

  const getData = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/suspicious-identifiers/${suspicious_identifier_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setItem(res.data.data.doc);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {item && (
        <div className='threat-repository-actor-top-data-container'>
          <PieChartSingleValue
            header='Severity'
            value={item.severity || 'N/A'}
            color={item.severity}
          />
          <div className='threat-repository-actor-actor-info-container'>
            <div className='threat-repository-actor-details-container'>
              <div>
                Name: {'  '}
                <span>{item.name || 'N/A'}</span>
              </div>

              <div>
                MD5 Hash:{'  '} <span>{item.md5_hash || 'N/A'}</span>
              </div>
              {item.last_seen && (
                <div>
                  Last Activity:{'  '}
                  <span>{dateFormat(item.last_seen)}</span>
                </div>
              )}
              <div>
                SHA1 Hash: {'  '}
                <span>{item.sha1_hash || 'N/A'}</span>
              </div>
              <div>
                SHA256 Hash:{'  '} <span>{item.sha256_hash || 'N/A'}</span>
              </div>
            </div>

            {item.industry && (
              <div className='emerging-threat-detail'>
                <div>
                  Industries:{'  '}
                  <span>
                    {item.industry &&
                      item.industry.map((ind, i) => {
                        if (ind) {
                          return (
                            <div
                              className='actor-displayed-data-tag-filled-bg'
                              key={i}
                            >
                              {ind}
                            </div>
                          );
                        }
                      })}
                  </span>
                </div>
              </div>
            )}
            {item.tags && (
              <div className='emerging-threat-detail'>
                <div>
                  Tags:{'  '}
                  <span>
                    {item.tags.split(',').map((tag, i) => {
                      return (
                        <div
                          className='actor-displayed-data-tag-filled-bg'
                          key={i}
                        >
                          {tag}
                        </div>
                      );
                    })}
                  </span>
                </div>
              </div>
            )}
            {item.description && (
              <div className='emerging-threat-detail'>
                <div>
                  Description:{'  '} <span>{item.description}</span>
                </div>
              </div>
            )}
          </div>
          <div style={{ height: '30px' }}>
            <DeleteAndEditButtons
              id={item._id}
              path={'suspicious-identifiers'}
              afterDeleteFunc={() => {
                navigate('/suspicious-identifiers');
              }}
              editFunc={() => {
                sessionStorage.setItem(
                  'edit_suspicious_identifier_id',
                  item._id
                );
              }}
              navigateToEdit='/edit-suspicious-identifier'
            />
          </div>
        </div>
      )}
      <ThreatActorIocCampaignsMalwaresTable
        searchbar={true}
        tabClassName='table-single-tab'
        data={item}
        tabs={['Indicator of Compromise']}
      />
    </div>
  );
};
export default SuspiciousIdentifier;
