import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import EditFormMUI from '../components/EditFormMUI';
import EventIdentifierTypeList from '../components/EventIdentifierTypeList';
import EventIdentifierLevelList from '../components/EventIdentifierLevelList';
import { useNavigate } from 'react-router-dom';

const EditEventIdentifier = () => {
  const edit_event_identifier_id = sessionStorage.getItem(
    'edit_event_identifier_id'
  );
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const [type, setType] = useState(null);
  const [level, setLevel] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/event-identifiers/${edit_event_identifier_id}`,
      true
    );
  }, []);

  const editedData = async () => {
    const name = document.getElementById('name').value;
    const event_id = document.getElementById('event_id').value;
    const product = document.getElementById('product').value;
    const description = document.getElementById('description').value;

    return JSON.stringify({
      name,
      type: type || data.type,
      level: level || data.level,
      description,
      event_id,
      product,
    });
  };

  return (
    data && (
      <EditFormMUI
        header='Event Identifier'
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          {
            label: 'Event ID',
            id: 'event_id',
            defaultValue: data.event_id,
          },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
          {
            label: 'Product',
            id: 'product',
            defaultValue: data.product,
          },
        ]}
        selectInputs={
          <>
            <EventIdentifierTypeList
              onChange={(e) => {
                setType(e);
              }}
            />
            <EventIdentifierLevelList
              onChange={(e) => {
                setLevel(e);
              }}
            />
          </>
        }
        itemToEdit={editedData}
        path={`event-identifiers/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/event-identifiers');
        }}
      />
    )
  );
};
export default EditEventIdentifier;
