import './Sidebar.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidebarMainLink from './SidebarMainLink';
import operationCenterIcon from '../../assets/images/sidebar/operation-center.svg';
import cyberIntelligenceIcon from '../../assets/images/sidebar/cyber-intelligence.svg';
import CryptoCurrencyIcon from '../../assets/images/sidebar/crypto-currency.svg';
import fraudIcon from '../../assets/images/sidebar/fraud.svg';
import discoverIcon from '../../assets/images/sidebar/discover.svg';
import threatActorsIcon from '../../assets/images/sidebar/threat-actors.svg';
import globalSearchIcon from '../../assets/images/sidebar/global-search.svg';
import mainDashboardIcon from '../../assets/images/sidebar/main-dashboard.svg';
import generalSidebarIcon from '../../assets/images/sidebar/icon-park-outline-circles-seven@2x.svg';
import threatFeedIcon from '../../assets/images/sidebar/material-symbols-rss-feed-rounded@2x.svg';
import settingsIcon from '../../assets/images/sidebar/material-symbols-settings-rounded@2x.svg';
import crimeIcon from '../../assets/images/sidebar/mdi-eye-off@2x.svg';
import terrorIcon from '../../assets/images/sidebar/skull-svgrepo-com-2@2x.svg';
import alertsIcon from '../../assets/images/sidebar/alerts.svg';
import SidebarSublink from './SidebarSublink';

const Sidebar = () => {
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();

  const toggleSubLinks = (id) => {
    const el = document.getElementById(`${id}`);
    el.style.display !== 'block'
      ? (el.style.display = 'block')
      : (el.style.display = 'none');
  };

  return (
    <div className='side-bar'>
      <div className='brand' />
      <div className='side-bar-scroll'>
        {isAdmin && (
          <ul className='sidebar-ul'>
            <SidebarMainLink
              value='Main Dashboard'
              navigateTo='/main-dashboard'
              src={mainDashboardIcon}
              id='sidebar-main-dashboard-icon'
            />
            <SidebarMainLink
              value='Cyber Intelligence'
              src={cyberIntelligenceIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-cyber-intelligence');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-cyber-intelligence'
              id='menu-submenu-dropdown-cyber-intelligence'
            >
              <SidebarSublink
                value='Threat Repository'
                navigateTo='/threat-repository'
              />
              <SidebarSublink
                value='Emerging Threats'
                navigateTo='/emerging-threats'
              />
              <SidebarSublink
                value='Threat Hunting'
                navigateTo='/threat-hunting'
              />
              <SidebarSublink value='OSINT Report' navigateTo='/osint-report' />

              <SidebarSublink
                value='Software Repository'
                navigateTo='/software-repository'
              />
            </div>
            <SidebarMainLink
              value='Operation Center'
              src={operationCenterIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-operation-center');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-operation-center'
              id='menu-submenu-dropdown-operation-center'
            >
              <SidebarSublink
                value='Rule Repository'
                navigateTo='/rule-repository'
              />
              <SidebarSublink
                value='Suspicious Identifiers'
                navigateTo='/suspicious-identifiers'
              />
              <SidebarSublink
                value='Event Identifiers'
                navigateTo='/event-identifiers'
              />
            </div>

            <SidebarMainLink
              value='Terror'
              src={terrorIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-terror');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-terror'
              id='menu-submenu-dropdown-terror'
            >
              <SidebarSublink
                value='Terror Dashboard'
                navigateTo='/terror-dashboard'
              />
            </div>

            <SidebarMainLink
              value='Crypto Currency'
              src={CryptoCurrencyIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-crypto');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-crypto'
              id='menu-submenu-dropdown-crypto'
            >
              <SidebarSublink
                value='Crypto Dashboard'
                navigateTo='/crypto-dashboard'
              />
            </div>

            <SidebarMainLink
              value='Crime'
              src={crimeIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-crime');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-crime'
              id='menu-submenu-dropdown-crime'
            >
              <SidebarSublink
                value='Crime Dashboard'
                navigateTo='/crime-dashboard'
              />
            </div>

            <SidebarMainLink
              value='Fraud'
              src={fraudIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-fraud');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-fraud'
              id='menu-submenu-dropdown-fraud'
            >
              <SidebarSublink
                value='Fraud Dashboard'
                navigateTo='/fraud-dashboard'
              />
            </div>

            <SidebarMainLink
              value='Bias Crime'
              src={generalSidebarIcon}
              onClick={() => {
                toggleSubLinks('menu-submenu-dropdown-bias-crime');
              }}
              dropdown={true}
            />
            <div
              className='menu-submenu-dropdown-bias-crime'
              id='menu-submenu-dropdown-bias-crime'
            >
              <SidebarSublink
                value='Antisemitism'
                navigateTo='/antisemetic-dashboard'
              />
            </div>
          </ul>
        )}
        <hr className='sidebar-hr' />
        <ul className='sidebar-ul'>
          <SidebarMainLink
            value='Alerts'
            onClick={() => {
              navigate('/alerts');
            }}
            src={alertsIcon}
          />
          <SidebarMainLink
            value='Threats Feed'
            navigateTo='/threat-feed'
            src={threatFeedIcon}
          />

          <SidebarMainLink
            value='Threat Actors'
            navigateTo='/threats-library'
            src={threatActorsIcon}
          />
          <SidebarMainLink
            value='Discover'
            navigateTo='/discovery'
            src={discoverIcon}
          />
          {isAdmin && (
            <SidebarMainLink
              value='Global Search'
              src={globalSearchIcon}
              // navigateTo='/global-search'
            />
          )}
        </ul>

        <hr className='sidebar-hr' />
        <ul className='sidebar-ul'>
          <SidebarMainLink
            value='Settings'
            navigateTo='/settings'
            src={settingsIcon}
          />
          {isAdmin && (
            <div>
              {' '}
              <div className='sidebar-header'>Admin</div>
              <SidebarMainLink
                value='Crawler Management'
                src={generalSidebarIcon}
                // navigateTo='/crawler'
              />
              <SidebarMainLink
                value='Reports & Calculators'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks(
                    'menu-submenu-dropdown-reports-and-calculators-admin'
                  );
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-reports-and-calculators-admin'
                id='menu-submenu-dropdown-reports-and-calculators-admin'
              >
                <SidebarSublink
                  value='SOC Service Calculator'
                  navigateTo='/calculator'
                />
              </div>
              <SidebarMainLink
                value='Create Live IP'
                src={generalSidebarIcon}
                onClick={() => {
                  navigate('/create-live-ip-address');
                }}
              />
              <SidebarMainLink
                value='Cyber Intelligence'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks(
                    'menu-submenu-dropdown-cyber-intelligence-admin'
                  );
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-cyber-intelligence-admin'
                id='menu-submenu-dropdown-cyber-intelligence-admin'
              >
                <SidebarSublink
                  value='New Emerging Threat'
                  navigateTo='/create-emerging-threat'
                />
                <SidebarSublink
                  value='Cyber Campaigns'
                  navigateTo='/cyber-campaigns-management'
                />
                <SidebarSublink
                  value='New Rule Repository Item'
                  navigateTo='/create-rule-repository-item'
                />
              </div>
              <SidebarMainLink
                value='IOC Management'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-ioc');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-ioc'
                id='menu-submenu-dropdown-ioc'
              >
                <SidebarSublink value='IOC List' navigateTo='/ioc-list' />
                <SidebarSublink value='New IOCs' navigateTo='/new-iocs' />
              </div>
              <SidebarMainLink
                value='Customers'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-customers-admin');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-customers-admin'
                id='menu-submenu-dropdown-customers-admin'
              >
                <SidebarSublink
                  value='New Organiztion'
                  navigateTo='/create-organization'
                />
                <SidebarSublink value='New User' navigateTo='/create-user' />
                <SidebarSublink
                  value='Organiztion List'
                  navigateTo='/organization-list'
                />
                <SidebarSublink value='User List' navigateTo='/user-list' />
              </div>
              <SidebarMainLink
                value='Bias Management'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-bias-admin');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-bias-admin'
                id='menu-submenu-dropdown-bias-admin'
              >
                <SidebarSublink
                  value='Antisemetic Management'
                  navigateTo='/antisemetic-websites-blogs-management'
                />
              </div>
              <SidebarMainLink
                value='Alerts Management'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-alerts-admin');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-alerts-admin'
                id='menu-submenu-dropdown-alerts-admin'
              >
                <SidebarSublink value='New Alert' navigateTo='/create-alert' />
                <SidebarSublink
                  value='Alerts List'
                  // onClick={(e) => {
                  //   (e, '/alerts-list');
                  // }}
                  //DOESNT EXIST YET
                />
              </div>
              {/* /******************************************************************************************************************** */}
              <SidebarMainLink
                value='Threats Feed'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-threats-feed-admin');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-threats-feed-admin'
                id='menu-submenu-dropdown-threats-feed-admin'
              >
                <SidebarSublink
                  value='New Post'
                  navigateTo='/create-threat-for-feed'
                />
              </div>
              {/* /******************************************************************************************************************** */}
              <SidebarMainLink
                value='Threat Actors'
                src={generalSidebarIcon}
                onClick={() => {
                  toggleSubLinks('menu-submenu-dropdown-threat-actors-admin');
                }}
                dropdown={true}
              />
              <div
                className='menu-submenu-dropdown-threat-actors-admin'
                id='menu-submenu-dropdown-threat-actors-admin'
              >
                <SidebarSublink
                  value='New Threat Actor'
                  navigateTo='/create-threat-actor'
                />
                <SidebarSublink
                  value='New Threat Actor Campaign'
                  navigateTo='/create-cyber-campaign-for-threat-actor'
                />
                <SidebarSublink
                  value='New Threat Hunting Item'
                  navigateTo='/create-threat-hunting-item'
                />
                <SidebarSublink
                  value='New Dangerous Country'
                  navigateTo='/create-dangerous-country'
                />
                <SidebarSublink
                  value='New Emerging Threat Recommendation'
                  navigateTo='/create-emerging-threat-recommendation'
                />
                <SidebarSublink
                  value='New Suspicious Identifier'
                  navigateTo='/create-suspicious-identifier'
                />
                <SidebarSublink
                  value='New Event Identifier'
                  navigateTo='/create-event-identifier'
                />
              </div>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
