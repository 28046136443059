import Flags from '../Flags';
import CardWithLeftHeader from '../CardWithLeftHeader';

const AntisemeticTopCountriesDash = () => {
  return (
    <CardWithLeftHeader
      header='Top Antisemetic Countries'
      content={
        <div style={{ padding: '10px' }}>
          <div className='antisemitism-dashboard-top-antisemetic-countries-data-container'>
            <div className='antisemitism-dashboard-top-antisemetic-countries-flags-container'>
              <table className='antisemetic-dashboard-top-countries-list-table'>
                <tbody>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      Rank
                    </td>
                    <td>Country</td>
                    <td>null</td>
                    <td>null</td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      1
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      2
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      3
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      4
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      5
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      6
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      7
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      8
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      9
                    </td>
                    <td>
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      10
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      11
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      12
                    </td>
                    <td>
                      {' '}
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      13
                    </td>
                    <td>
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                  <tr className='antisemetic-dashboard-top-countries-tr'>
                    <td className='antisemetic-dashboard-top-countries-td-w-10'>
                      14
                    </td>
                    <td>
                      <Flags countries={['Syria']} withName={true} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='antisemitism-dashboard-most-dangerous-countries-levels-container'>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-10'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-9'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-8'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-7'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-6'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-5'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-4'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-3'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-2'></div>
              </div>
              <div className='antisemitism-dashboard-country-danger-level'>
                <div className='antisemitism-dashboard-country-danger-level-percentage percentage-1'></div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default AntisemeticTopCountriesDash;
