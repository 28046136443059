import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from './pages/Login';
import MainDashboard from './pages/MainDashboard';
import WithNav from './components/WithNav';
import WithoutNav from './components/WithoutNav';
import Analytics from './pages/Analytics';
import CrimeDashboard from './pages/CrimeDashboard';
import FraudDashboard from './pages/FraudDashboard';
import OsintReport from './pages/OsintReport';
import CrawlerMainDashboard from './pages/crawler/CrawlerMainDashboard';
import CrawlerProject from './pages/crawler/CrawlerProject';
import CrawlerUrls from './pages/crawler/CrawlerUrls';
import CrawlerKeywordResults from './pages/crawler/CrawlerKeywordResults';
import UpdateMyPicture from './pages/users/UpdateMyPicture';
import Settings from './pages/users/Settings';
import EditUser from './pages/users/EditUser';
import UserList from './pages/users/UserList';
import UserDetails from './pages/users/UserDetails';
import CreateUser from './pages/users/CreateUser';
import ThreatLibrary from './pages/threat library/ThreatLibrary';
import ThreatActor from './pages/threat library/ThreatActor';
import CreateThreatActor from './pages/threat library/CreateThreatActor';
import EditCyberThreatActorForm from './pages/threat library/EditCyberThreatActorForm';
import EditTerrorThreatActorForm from './pages/threat library/EditTerrorThreatActorForm';
import EditCriminalThreatActorForm from './pages/threat library/EditCriminalThreatActorForm';
import EditOrganizedCrimeThreatActorForm from './pages/threat library/EditOrganizedCrimeThreatActorForm';
import ErrorPage from './pages/ErrorPage';
import CreateIoc from './pages/CreateIoc';
import Iocs from './pages/Iocs';
import EditIOCList from './pages/EditIOCList';
import CreateThreatOnFeed from './pages/threat feed/CreateThreatOnFeed';
import ThreatFeed from './pages/threat feed/ThreatFeed';
import EditThreatFromFeed from './pages/threat feed/EditThreatFromFeed';
import CreateEmergingThreat from './pages/emerging threats/CreateEmergingThreat';
import EmergingThreat from './pages/emerging threats/EmergingThreat';
import Calculator from './pages/Calculator';
import CalculatorFullReport from './pages/CalculatorFullReport';
import CreateAlert from './pages/alerts/CreateAlert';
import Alerts from './pages/alerts/Alerts';
import CyberCampaignManagement from './pages/CyberCampaignManagement';
import CyberCampaignEventsList from './pages/CyberCampaignsEventsList';
import ThreatRepository from './pages/ThreatRepository';
import DarkCrypto from './pages/DarkCrypto';
import DarknetSearch from './pages/DarknetSearch';
import CreateOrg from './pages/CreateOrg';
import OrganizationList from './pages/OrganizationList';
import EditOrganization from './pages/EditOrganization';
import CveIdResults from './pages/search results/CveIdResults';
import EmailSearchResults from './pages/search results/EmailSearchResults';
import URLSearchResults from './pages/search results/URLSearchResults';
import IPSearchResults from './pages/search results/IPSearchResults';
import WalletResults from './pages/search results/WalletResults';
import PhishingResults from './pages/search results/PhishingResults';
import TerrorDashboard from './pages/TerrorDashboard';
import AntisemeticDashboard from './pages/AntisemeticDashboard';
import HashSearchResults from './pages/search results/HashSearchResults';
import DomainSearchResults from './pages/search results/DomainSearchResults';
import ThreatRepositoryActor from './pages/ThreatRepositoryActor';
import CryptoDashboard from './pages/CryptoDashboard';
import ThreatFeedPage from './pages/threat feed/ThreatFeedPage';
import EditThreatRepoActor from './pages/EditThreatRepoActor';
import EditAntisemeticWebsite from './pages/EditAntisemeticWebsite';
import AntisemeticWebsitesBlogsManagement from './components/AntisemeticWebsitesBlogsManagement';
import ThreatRepositorySearchedData from './pages/ThreatRepositorySearchedData';
import CreateCyberCampaignForThreatActor from './pages/CreateCyberCampaignForThreatActor';
import EditThreatActorCyberCampaign from './pages/EditThreatActorCyberCampaign';
import EditEmergingThreat from './pages/emerging threats/EditEmergingThreat';
import TopMalware from './pages/TopMalware';
import { setUser } from './utils/handleLogin';
import axios from 'axios';
import { adminLogin, userLogin } from './actions';
import ThreatActorCyberCampaign from './pages/threat library/ThreatActorCyberCampaign';
import ThreatActorMalwareTool from './pages/threat library/ThreatActorMalwareTool';
import Ioc from './pages/Ioc';
import ThreatActorsSearchResults from './pages/search results/ThreatActorsSearchResults';
import Discovery from './pages/Discovery';
import CreateLiveIPAdress from './pages/CreateLiveIPAddress';
import EmergingThreats from './pages/emerging threats/EmergingThreats';
import ThreatHunting from './pages/threat hunting/ThreatHunting';
import SoftwareRepository from './pages/SoftwareRepository';
import RuleRepository from './pages/RuleRepository';
import CreateThreatHuntingItem from './pages/threat hunting/CreateThreatHuntingItem';
import EditThreatHuntingItem from './pages/threat hunting/EditThreatHuntingItem';
import ThreatHuntingItem from './pages/threat hunting/ThreatHuntingItem';
import EditDiscoveryItem from './pages/EditDiscoveryItem';
import CreateRuleRepositoryItem from './pages/CreateRuleRepositoryItem';
import EditRuleRepoItem from './pages/EditRuleRepoItem';
import CreateMostDangerousCountry from './pages/CreateMostDangerousCountry';
import EditMostDangerousCountries from './pages/EditMostDangerousCountries';
import CreateEmergingThreatRecommendation from './pages/emerging threats/CreateEmergingThreatRecommendation';
import EditEmergingThreatRecommendation from './pages/emerging threats/EditEmergingThreatRecommendation';
import CreateSuspiciousIdentifier from './pages/CreateSuspiciousIdentifier';
import SuspiciousIdentifiers from './pages/SuspiciousIdentifiers';
import SuspiciousIdentifier from './pages/SuspiciousIdentifier';
import EditSuspiciousIdentifier from './pages/EditSuspiciousIdentifier';
import EventIdentifiers from './pages/EventIdentifiers';
import EventIdentifier from './pages/EventIdentifier';
import EditEventIdentifier from './pages/EditEventIdentifier';
import CreateEventIdentifier from './pages/CreateEventIdentifier';
import EditCyberCampaignEventItem from './pages/EditCyberCampaignEventItem';
import { useEffect } from 'react';
import CrawlerAttachedUrlsCreation from './pages/crawler/CrawlerAttachedUrlsCreation';

function App() {
  // const cookieToken = document.cookie.split(';').filter((el) => {
  //   return el.startsWith(' token');
  // });
  const dispatch = useDispatch();
  const token =
    sessionStorage.getItem('token') || localStorage.getItem('token');

  const { isAdmin, isLoggedIn, user_id } = useSelector(
    (state) => state.appReducer
  );

  const getUserData = async () => {
    try {
      await axios
        .get(`https://api.hackeye.io/api/v1/users/get-me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const user = res.data.data.doc;
          if (user.role === 'admin') {
            dispatch(
              adminLogin(
                user.email,
                user.name,
                true,
                user.beforeLastLog,
                user.organization_id,
                user.id
              )
            );
          } else {
            dispatch(
              userLogin(
                user.email,
                user.name,
                user.beforeLastLog,
                user.organization_id,
                user.id
              )
            );
          }
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (token && !isLoggedIn) {
      // set user data in storage, using the saved ID and TOKEN
      setUser(token);

      //set redux data to the fetched user data
      getUserData();
    }
  }, [token, isLoggedIn]);

  return (
    <div className='App'>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route path='/' element={<Login />} />
        </Route>

        {token && isLoggedIn && (
          <Route element={<WithNav />}>
            <Route path='/main-dashboard' element={<MainDashboard />} />
            <Route path='/alerts' element={<Alerts />} />
            <Route path='/terror-dashboard' element={<TerrorDashboard />} />
            <Route
              path='/antisemetic-dashboard'
              element={<AntisemeticDashboard />}
            />
            <Route path='/threat-feed' element={<ThreatFeed />} />
            <Route path='/threat-feed/:id' element={<ThreatFeedPage />} />
            <Route path='/threats-library' element={<ThreatLibrary />} />
            <Route
              path='/threat-actors-cyber-campaigns/:id'
              element={<ThreatActorCyberCampaign />}
            />
            <Route
              path='/threat-actors-malwares-and-tools/:id'
              element={<ThreatActorMalwareTool />}
            />
            <Route path='/settings' element={<Settings />} />
            <Route path='/emerging-threats' element={<EmergingThreats />} />
            <Route
              path='/software-repository'
              element={<SoftwareRepository />}
            />
            <Route path='/rule-repository' element={<RuleRepository />} />
            <Route
              path='/suspicious-identifiers'
              element={<SuspiciousIdentifiers />}
            />
            <Route
              path='/edit-suspicious-identifier'
              element={<EditSuspiciousIdentifier />}
            />
            <Route
              path='/suspicious-identifiers/:id'
              element={<SuspiciousIdentifier />}
            />
            <Route path='/event-identifiers' element={<EventIdentifiers />} />
            <Route
              path='/edit-event-identifier'
              element={<EditEventIdentifier />}
            />
            <Route
              path='/event-identifiers/:id'
              element={<EventIdentifier />}
            />
            <Route path='/threat-hunting' element={<ThreatHunting />} />
            <Route
              path='/threat-hunting-item'
              element={<ThreatHuntingItem />}
            />
            <Route
              path='/cyber-campaigns-events-list'
              element={<CyberCampaignEventsList />}
            />
            <Route path='/update-my-picture' element={<UpdateMyPicture />} />
            <Route path='/phishing-results' element={<PhishingResults />} />
            <Route path='/top-malwares/:name' element={<TopMalware />} />
            <Route path='/wallet-search-results' element={<WalletResults />} />
            <Route path='/cve-results' element={<CveIdResults />} />
            <Route path='/crypto-dashboard' element={<CryptoDashboard />} />
            <Route path='/discovery' element={<Discovery />} />
            <Route path='/error-page' element={<ErrorPage />} />

            <Route path='/threat-actor/:id' element={<ThreatActor />} />

            <Route
              path='/threat-repository/:profile'
              element={<ThreatRepositoryActor />}
            />

            <Route
              path='/threat-repository-search-data/:field/:value'
              element={<ThreatRepositorySearchedData />}
            />

            <Route path='/emerging-threat/:id' element={<EmergingThreat />} />

            <Route path='/ip-search-results' element={<IPSearchResults />} />
            <Route
              path='search-results/threat-actors'
              element={<ThreatActorsSearchResults />}
            />

            <Route path='/url-search-results' element={<URLSearchResults />} />
            <Route path='/iocs/:id' element={<Ioc />} />

            <Route
              path='/hash-search-results'
              element={<HashSearchResults />}
            />

            <Route
              path='/domain-search-results'
              element={<DomainSearchResults />}
            />

            <Route
              path='/account-breach-results'
              element={<EmailSearchResults />}
            />
            {/* <Route path='/global-search' element={<CrawlerMainDashboard />} /> */}
            {/* <Route
              path='/global-search/projects/:project_name'
              element={<CrawlerProject />}
            /> */}
            {/* /** *ADMIN ROUTES*/}
            {isAdmin && (
              <>
                <Route path='/darknet-search' element={<DarknetSearch />} />
                <Route
                  path='/edit-threat-feed/:id'
                  element={<EditThreatFromFeed />}
                />
                <Route
                  path='/edit-threat-hunting-item'
                  element={<EditThreatHuntingItem />}
                />
                <Route path='/analytics' element={<Analytics />} />
                <Route
                  path='/threat-repository'
                  element={<ThreatRepository />}
                />
                <Route path='/dark-crypto' element={<DarkCrypto />} />
                <Route path='/crime-dashboard' element={<CrimeDashboard />} />
                <Route path='/fraud-dashboard' element={<FraudDashboard />} />
                <Route path='/calculator' element={<Calculator />} />
                <Route
                  path='/antisemetic-websites-blogs-management'
                  element={<AntisemeticWebsitesBlogsManagement />}
                />
                <Route
                  path='/create-threat-hunting-item'
                  element={<CreateThreatHuntingItem />}
                />
                <Route
                  path='/create-rule-repository-item'
                  element={<CreateRuleRepositoryItem />}
                />
                <Route
                  path='/calculator-full-report'
                  element={<CalculatorFullReport />}
                />
                <Route
                  path='/edit-cyber-campaign'
                  element={<EditCyberCampaignEventItem />}
                />
                <Route path='/osint-report' element={<OsintReport />} />
                <Route
                  path='/keyword-results'
                  element={<CrawlerKeywordResults />}
                />
                <Route
                  path='/create-threat-for-feed'
                  element={<CreateThreatOnFeed />}
                />
                <Route path='/user-list' element={<UserList />} />
                <Route
                  path='/organization-list'
                  element={<OrganizationList />}
                />
                <Route path='/crawler' element={<CrawlerUrls />} />
                {/* <Route
                  path='/crawler-attached-urls-creation'
                  element={<CrawlerAttachedUrlsCreation />}
                /> */}
                <Route path='/users/:id' element={<UserDetails />} />
                <Route path='/create-user' element={<CreateUser />} />
                <Route
                  path='/create-emerging-threat-recommendation'
                  element={<CreateEmergingThreatRecommendation />}
                />
                <Route
                  path='/edit-emerging-threat-recommendation'
                  element={<EditEmergingThreatRecommendation />}
                />
                <Route path='/create-alert' element={<CreateAlert />} />
                <Route path='/ioc-list' element={<Iocs />} />
                <Route path='/ioc-list-edit' element={<EditIOCList />} />
                <Route
                  path='/edit-rule-repository-item'
                  element={<EditRuleRepoItem />}
                />
                <Route path='/new-iocs' element={<CreateIoc />} />
                <Route
                  path='/cyber-campaigns-management'
                  element={<CyberCampaignManagement />}
                />
                <Route
                  path='/create-emerging-threat'
                  element={<CreateEmergingThreat />}
                />
                <Route
                  path='/create-threat-actor'
                  element={<CreateThreatActor />}
                />
                <Route
                  path='/create-suspicious-identifier'
                  element={<CreateSuspiciousIdentifier />}
                />
                <Route
                  path='/create-event-identifier'
                  element={<CreateEventIdentifier />}
                />
                <Route
                  path='/create-dangerous-country'
                  element={<CreateMostDangerousCountry />}
                />
                <Route
                  path='/edit-antisemetic-website'
                  element={<EditAntisemeticWebsite />}
                />
                <Route
                  path='/edit-most-dangerous-country'
                  element={<EditMostDangerousCountries />}
                />
                <Route
                  path='/edit-threat-actor/cyber-threat-actor'
                  element={<EditCyberThreatActorForm />}
                />
                <Route
                  path='/edit-threat-actor/terror-threat-actor'
                  element={<EditTerrorThreatActorForm />}
                />
                <Route
                  path='/edit-threat-repository-actor'
                  element={<EditThreatRepoActor />}
                />
                <Route
                  path='/edit-threat-actor/criminal-threat-actor'
                  element={<EditCriminalThreatActorForm />}
                />
                <Route
                  path='/edit-threat-actor/organized-crime-threat-actor'
                  element={<EditOrganizedCrimeThreatActorForm />}
                />
                <Route path='/edit-user' element={<EditUser />} />
                <Route
                  path='/edit-organization/:id'
                  element={<EditOrganization />}
                />
                <Route
                  path='/edit-emerging-threats/:id'
                  element={<EditEmergingThreat />}
                />
                <Route path='/create-organization' element={<CreateOrg />} />
                <Route
                  path='/create-cyber-campaign-for-threat-actor'
                  element={<CreateCyberCampaignForThreatActor />}
                />
                <Route
                  path='/threat-actors-edit-cyber-campaign'
                  element={<EditThreatActorCyberCampaign />}
                />
                <Route
                  path='/edit-discovery-item'
                  element={<EditDiscoveryItem />}
                />
                <Route
                  path='/create-live-ip-address'
                  element={<CreateLiveIPAdress />}
                />
              </>
            )}

            <Route
              path='*'
              element={
                token ? (
                  <Navigate to='/error-page' replace />
                ) : (
                  <Navigate to='/' replace />
                )
              }
            />
          </Route>
        )}
      </Routes>
    </div>
  );
}

export default App;
