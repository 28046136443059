import './Calculator.css';
import CompanyTable from '../components/CompanyTable';
import EnvironmentTable from '../components/EnvironmentTable';
import TechnologiesTable from '../components/TechnologiesTable';
import OperationalCosts from '../components/OperationalCosts';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setIsReady } from '../actions';
import { useNavigate } from 'react-router-dom';

const Calculator = () => {
  const navigate = useNavigate();

  const { isReady } = useSelector((state) => state.appReducer);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='front-tables-container'>
        <div className='container'>
          <div className='bloc-tabs'>
            <button
              className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(1)}
            >
              The Company
            </button>
            <button
              className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(2)}
            >
              Environment
            </button>
            <button
              className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(3)}
            >
              Technologies
            </button>
          </div>

          <div className='content-tabs'>
            <div
              className={
                toggleState === 1 ? 'content  active-content' : 'content'
              }
            >
              <CompanyTable />
            </div>

            <div
              className={
                toggleState === 2 ? 'content  active-content' : 'content'
              }
            >
              <EnvironmentTable />
            </div>

            <div
              className={
                toggleState === 3 ? 'content  active-content' : 'content'
              }
            >
              <TechnologiesTable />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='tables'>
        <OperationalCosts />
      </div> */}
      <button
        onClick={() => {
          navigate('/calculator-full-report');
        }}
      >
        Full Report →
      </button>
    </div>
  );
};
export default Calculator;
