import Navbar from './Navbar';
import Sidebar from './sidebar/Sidebar';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const WithNav = () => {
  const token = sessionStorage.getItem('token');
  const isLoggedIn =
    useSelector((state) => state.appReducer.isLoggedIn) || token;

  const headScript1 = document.createElement('script');
  const headScript2 = document.createElement('script');
  const bodyScript1 = document.createElement('script');

  headScript1.src = 'https://cdn.botpress.cloud/webchat/v0/inject.js';
  headScript2.src =
    'https://mediafiles.botpress.cloud/7dac20e0-824d-44ef-af48-28ea1f4d1bd3/webchat/config.js';
  bodyScript1.src = 'https://cdn.botpress.cloud/webchat/v0/inject.js';
  bodyScript1.async = true;
  headScript1.async = true;
  headScript2.async = true;

  bodyScript1.onload = () => {
    window.botpressWebChat.init({
      composerPlaceholder: 'Chat with Cybee',
      botId: '834602f0-a0fa-4428-ba30-9b166ae2f5db',
      hostUrl: 'https://cdn.botpress.cloud/webchat/v0',
      messagingUrl: 'https://messaging.botpress.cloud',
      clientId: '834602f0-a0fa-4428-ba30-9b166ae2f5db',
      botName: 'Cybee',
      enableConversationDeletion: true,
    });
    // window.botpressWebChat.onEvent(() => {
    //   window.botpressWebChat.sendEvent({ type: 'show' });
    // }, ['LIFECYCLE.LOADED']);
  };
  document.body.appendChild(bodyScript1);
  document.head.appendChild(headScript1);
  document.head.appendChild(headScript2);

  useEffect(() => {
    document.body.removeChild(bodyScript1);
  }, []);

  return (
    <div style={{ height: '100%', minHeight: '100vh' }}>
      {isLoggedIn && (
        <div
          className='with-nav-nav-bar'
          style={{
            height: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
          }}
        >
          <div className='with-nav-navbar-wrapper'>
            <Navbar />
          </div>
          <Sidebar />
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default WithNav;
