import { useSelector } from 'react-redux';
import Card from '../components/Card';
import { dateFormat } from '../utils/dateFormat';
// import { countryData } from '../utils/countryLatLng';

const ThreatRepositorySearchedData = () => {
  const threat_repo_actor_search_data = useSelector(
    (state) => state.appReducer.threat_repo_actor_search_data
  );

  return (
    <div>
      {' '}
      <Card
        content={
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr-headers'>
                <td>Ioc Value</td>
                <td>Ioc Type</td>
                <td>Reputation</td>
                <td>Feed</td>
                <td>Activity</td>
                <td>Category</td>
                <td>Country</td>
                <td>Last Update</td>
              </tr>

              {threat_repo_actor_search_data.length > 0 &&
                Object.entries(threat_repo_actor_search_data).map((ioc, i) => {
                  return (
                    <tr className='threat-indicator-of-compromise-tr' key={i}>
                      {ioc[1].ioc_type === 'URL' ? (
                        <td>
                          <a
                            href={ioc[1].ioc_value}
                            target='_blank'
                            rel='noreferrer'
                          >
                            view more
                          </a>
                        </td>
                      ) : (
                        <td>{ioc[1].ioc_value}</td>
                      )}
                      <td>{ioc[1].ioc_type}</td>
                      <td>{ioc[1].reputation}</td>
                      <td>
                        <a href={ioc[1].feed} target='_blank' rel='noreferrer'>
                          Link
                        </a>
                      </td>
                      <td>{ioc[1].activity}</td>
                      <td>{ioc[1].ioc_category}</td>
                      <td
                        style={{
                          display: 'flex',
                          textAlign: 'left',
                        }}
                      >
                        <div>
                          {/* {Object.entries(countryData).map((data, i) => {
                            if (data[1].name === ioc[1].source_country) {
                              return (
                                <img
                                  key={i}
                                  className='threat-actor-country-flag'
                                  crossOrigin='*'
                                  src={`https://countryflagsapi.com/png/${data[0]}`}
                                  alt=''
                                ></img>
                              );
                            }
                          })} */}
                        </div>
                        <div>{ioc[1].source_country} </div>
                      </td>
                      <td> {dateFormat(ioc[1].last_update)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        }
      />
    </div>
  );
};
export default ThreatRepositorySearchedData;
