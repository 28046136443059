import magnifier from '../assets/images/navbar/magnifier.svg';
import './SearchBar.css';

const SearchBar = (props) => {
  return (
    <div>
      <div className='search-bar-wrapper'>
        <img src={magnifier} alt='' className='search-bar-magnifier-svg' />
        <input
          onChange={props.onChange}
          id={props.id}
          className='search-bar-input'
          placeholder='Search'
        />
      </div>
    </div>
  );
};
export default SearchBar;
