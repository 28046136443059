import Card from '../components/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { Grid, Typography, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import InputMUI from './InputMUI';

const CreationFormMUI = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Card
      content={
        <>
          <Typography variant='h6' gutterBottom>
            Create {props.header}
          </Typography>

          <Grid container spacing={3}>
            {/* ADD IMAGE BUTTON */}
            {props.changeImageInput && (
              <Button
                sx={{ width: '100%', height: '50px', margin: '30px' }}
                component='label'
                variant='contained'
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
                <VisuallyHiddenInput
                  type='file'
                  accept='image/*'
                  onChange={props.handleImageChange}
                />
              </Button>
            )}
            {/* TEXT INPUTS, PASSWORD INPUTS, DATE INPUTS */}
            {props.textInputs &&
              props.textInputs.length > 0 &&
              props.textInputs.map((input, i) => {
                return (
                  <InputMUI
                    key={i}
                    min={input.min}
                    max={input.max}
                    defaultValue={props.defaultValue || 'N/A'}
                    type={input.type || 'text'}
                    id={input.id}
                    label={input.label}
                  />
                );
              })}

            {/**SELECT INPUTS */}
            {props.selectInputs && props.selectInputs}

            <div style={{ height: '30px' }}>
              {props.postLoading ? (
                <ClipLoader
                  loading={props.postLoading}
                  size={35}
                  className='cliploader'
                />
              ) : (
                (props.postError || props.postSuccess) && props.postFeedback
              )}
            </div>

            <Button
              variant='contained'
              sx={{ marginTop: '24px', marginLeft: '24px' }}
              onClick={props.onClick}
            >
              Create
            </Button>
          </Grid>
        </>
      }
    />
  );
};
export default CreationFormMUI;
