import ThreatActorIocCampaignsMalwaresTable from '../../components/ThreatActorIocCampaignsMalwaresTable';
import ThreatActorProfileImg from './ThreatActorProfileImg';
import { useState } from 'react';
import Flags from '../../components/Flags';

const CyberThreatActor = (props) => {
  const [viewGraph, setViewGraph] = useState(false);

  return (
    <div className='threat-actor-information-wrapper'>
      <div className='threat-actor-information'>
        <ThreatActorProfileImg id={props.threat.id} />

        <div className='threat-actor-info-description'>
          <div className='threat-actor-profile-threat-name-flag-wrapper'>
            <div className='threat-actor-profile-origin-countries-container'>
              <div>{props.threat.name} </div>
              <Flags
                countries={props.threat.country}
                imgContainerClassName='flags-image-seperator'
              />
            </div>
            <button
              className='threat-actor-graph-view-btn'
              onClick={() => {
                setViewGraph((prev) => !prev);
              }}
            >
              Graph View
            </button>
          </div>
          {viewGraph && (
            <div className='threat-actor-graph-wrapper'>
              <div
                style={{
                  display: 'block',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <Flags
                  countries={props.threat.country}
                  imgClassName='flags-round-flag'
                  displayOtherDataByFlag={props.threat.name}
                  imgContainerClassName='threat-actor-graph-main-actor-origin'
                />
                <div>↓</div>
              </div>
              <div
                style={{
                  display: 'block',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                {' '}
                <Flags
                  countries={props.threat.victims}
                  imgClassName='flags-round-flag'
                  withName={true}
                  imgContainerClassName='threat-actor-graph-victims'
                />
              </div>
            </div>
          )}
          {props.threat.code_name && (
            <h6 className='threat-actor-profile-threat-detail'>
              ({props.threat.code_name})
            </h6>
          )}
          {props.threat.other_names && (
            <h6 className='threat-actor-profile-threat-detail'>
              Other Names: {props.threat.other_names}
            </h6>
          )}
          {props.threat.type && (
            <h6 className='threat-actor-profile-threat-detail'>
              Type: {props.threat.type}
            </h6>
          )}
          {props.threat.victims.length > 0 && (
            <div className='threat-actor-profile-threat-detail'>
              Target Countries:
              <Flags
                countries={props.threat.victims}
                withName={true}
                imgContainerClassName='flags-image-seperator'
              />
            </div>
          )}
          {props.threat.victims_industries && (
            <div className='threat-actor-profile-threat-detail'>
              Target Industries:{' '}
              {props.threat.victims_industries.split(',').map((industry, i) => {
                return (
                  <div className='actor-displayed-data-tag-filled-bg' key={i}>
                    {industry}
                  </div>
                );
              })}
            </div>
          )}
          <div>
            <h6 className='threat-actor-profile-threat-detail'>
              <span
                className='threat-actor-profile-threat-detail-title'
                style={{ display: 'block' }}
              >
                Description:{'  '}
              </span>
              {props.threat.description}
            </h6>
          </div>
        </div>
      </div>
      <ThreatActorIocCampaignsMalwaresTable
        data={props.threat}
        tabs={[
          'Indicator of Compromise',
          'Cyber Campaigns',
          'Malwares & Tools',
        ]}
      />
    </div>
  );
};
export default CyberThreatActor;
