import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import ClipLoader from 'react-spinners/ClipLoader';
import useGetRequest from '../../hooks/use-get-request';
import './EmergingThreats.css';
import FiltersBarAndData from '../../components/FiltersBarAndData';
import Table from '../../components/Table';
import { dateFormat } from '../../utils/dateFormat';

const EmergingThreats = () => {
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [filters, setFilters] = useState();
  const [originalData, setOriginalData] = useState();
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/emerging-threats', true);
  }, []);

  useEffect(() => {
    getThreats();
  }, [data]);

  const getThreats = async () => {
    if (data && !getError && getSuccess) {
      const fetchedData = data.sort((a, b) => {
        if (a.threatname < b.threatname) {
          return -1;
        }
        if (a.threatname > b.threatname) {
          return 1;
        }
        return 0;
      });

      setOriginalData(fetchedData);

      const severities = [];
      const targeted_softwares = [];
      const cvssv3Scores = [];

      data.map((item) => {
        if (
          item.severity &&
          item.severity.trim() !== '' &&
          item.severity.toLowerCase() !== 'choose'
        ) {
          if (
            !severities.some(
              (element) =>
                element.name.toLowerCase() === item.severity.toLowerCase()
            )
          ) {
            severities.push({
              name: item.severity,
              quantity: 1,
            });
          } else {
            const existing = severities.find(
              (element) =>
                element.name.toLowerCase() === item.severity.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          item.targetedSoftware &&
          item.targetedSoftware.trim() !== '' &&
          item.targetedSoftware.toLowerCase() !== 'x'
        ) {
          if (
            !targeted_softwares.some(
              (element) =>
                element.name.toLowerCase() ===
                item.targetedSoftware.toLowerCase()
            )
          ) {
            targeted_softwares.push({
              name: item.targetedSoftware,
              quantity: 1,
            });
          } else {
            const existing = targeted_softwares.find(
              (element) =>
                element.name.toLowerCase() ===
                item.targetedSoftware.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          item.cvssv3score &&
          item.cvssv3score.trim() !== '' &&
          item.cvssv3score.toLowerCase() !== 'x'
        ) {
          if (
            !cvssv3Scores.some(
              (element) =>
                element.name.toLowerCase() === item.cvssv3score.toLowerCase()
            )
          ) {
            cvssv3Scores.push({
              name: item.cvssv3score,
              quantity: 1,
            });
          } else {
            const existing = cvssv3Scores.find(
              (element) =>
                element.name.toLowerCase() === item.cvssv3score.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
      });

      setFilters([
        {
          header: 'Severity',
          fieldValue: 'severity',
          filters: severities
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Targeted Software',
          fieldValue: 'trgetedSoftware',
          className: 'filters-bar-filters-200px-width',
          filters: targeted_softwares
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'CVSSV3 Score',
          fieldValue: 'cvssv3score',
          filters: cvssv3Scores
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((value, i) => {
            return (
              <tr key={value._id} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color emerging-threat-table-threat-name-td'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    sessionStorage.setItem('emerging_threat', value._id);
                    navigate(`/emerging-threat/${value._id}`);
                  }}
                >
                  {value.threatname.replaceAll('&lt;', '')}
                </td>

                <td className='data-table-td'>
                  {value.cveid ? value.cveid : 'N/A'}
                </td>
                <td
                  className={`data-table-td emerging-threat-table-cvssv3score-${Math.round(
                    value.cvssv3score
                  )}`}
                >
                  {value.cvssv3score ? value.cvssv3score : 'N/A'}
                </td>
                <td
                  className={`data-table-td emerging-threat-table-cvssv3score-${Math.round(
                    value.cvssv3score
                  )}`}
                >
                  {value.severity ? value.severity : 'N/A'}
                </td>

                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.targetedSoftware && value.targetedSoftware !== 'x'
                      ? value.targetedSoftware
                      : 'N/A'}
                  </span>
                </td>

                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.affectedproducts && value.affectedproducts !== 'x'
                      ? value.affectedproducts
                      : 'N/A'}
                  </span>
                </td>

                <td className='data-table-td'>
                  {value.date === '' ? 'N/A' : dateFormat(value.date, '-')}
                </td>
                {isAdmin && (
                  <td>
                    <DeleteAndEditButtons
                      id={value._id}
                      path={'emerging-threats'}
                      afterDeleteFunc={() => {
                        getThreats();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem('emerging_threat', value._id);
                      }}
                      navigateToEdit={`/edit-emerging-threats/${value._id}`}
                    />
                  </td>
                )}
              </tr>
            );
          })}
          itemsPerPage={window.innerHeight <= 802 ? 9 : 14}
          tableHeaders={[
            ['Threat Name', 'emerging-threat-table-threat-name-td'],
            ['CVE ID', ''],
            ['CVSSV3 score', ''],
            ['Severity', ''],
            ['Targeted Software', ''],
            ['Affected Products', ''],
            ['Last Activity', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getThreats();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='cveid'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default EmergingThreats;
