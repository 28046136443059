import { toggleDropMenu } from '../utils/toggleDropMenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUser } from '../utils/handleLogin';
import { logout } from './../actions/index';

const NavbarDropMenuProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className='drop-down-menu card'>
      <div
        className='drop-down-menu-item'
        onClick={() => {
          toggleDropMenu('drop-down-menu');
          navigate('/main-dashboard');
        }}
      >
        MAIN DASHBOARD
      </div>
      <div
        className='drop-down-menu-item'
        onClick={() => {
          toggleDropMenu('drop-down-menu');
          navigate('/settings');
        }}
      >
        SETTINGS
      </div>
      <div
        className='drop-down-menu-item'
        onClick={() => {
          toggleDropMenu('drop-down-menu');
          removeUser();
          dispatch(logout());
          navigate('/');
        }}
      >
        LOGOUT
      </div>
    </div>
  );
};
export default NavbarDropMenuProfile;
