import SeverityList from '../components/SeverityList';
import { useEffect, useState } from 'react';
import ThreatIndustryList from '../components/ThreatIndustryList';
import useGetRequest from '../hooks/use-get-request';
import EditFormMUI from '../components/EditFormMUI';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import MalwareTypeList from '../components/MalwareTypeList';
import MalwareOperationSystemList from '../components/MalwareOperationSystemList';
import { useNavigate } from 'react-router-dom';
const EditSuspiciousIdentifier = () => {
  const navigate = useNavigate();
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const edit_suspicious_identifier_id = sessionStorage.getItem(
    'edit_suspicious_identifier_id'
  );

  const [severity, setSeverity] = useState(null);
  const [operation_system, setOperationSystem] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [threatActors, setThreatActors] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/suspicious-identifiers/${edit_suspicious_identifier_id}`,
      true
    );
  }, []);

  const getEditedActor = async () => {
    const name = document.getElementById('name').value;
    const ioc = document.getElementById('ioc').value;
    const detection_name = document.getElementById('detection_name').value;
    const detections = document.getElementById('detections').value;
    const confidence_score = document.getElementById('confidence_score').value;
    const hash = document.getElementById('hash').value;
    const description = document.getElementById('description').value;
    const attack_vectors = document.getElementById('attack_vectors').value;
    const affected_platforms =
      document.getElementById('affected_platforms').value;
    const impacted_parties = document.getElementById('impacted_parties').value;
    const impact = document.getElementById('impact').value;
    const file_extention = document.getElementById('file_extention').value;
    const chainware = document.getElementById('chainware').value;
    const victomology = document.getElementById('victomology').value;
    const mitre_attack = document.getElementById('mitre_attack').value;
    const tags = document.getElementById('tags').value;

    const editedItem = JSON.stringify({
      name,
      ioc,
      industry: industries.length === 0 ? data.industry : industries,
      detection_name,
      hash,
      severity: severity || data.severity,
      type: types.length === 0 ? data.type : types,
      description,
      attack_vectors,
      operation_system: operation_system || data.operation_system,
      threat_actors:
        threatActors.length === 0 ? data.threat_actors : threatActors,
      detections,
      confidence_score,
      affected_platforms,
      impacted_parties,
      impact,
      file_extention,
      chainware,
      victomology,
      mitre_attack,
      tags,
    });
    return editedItem;
  };

  return (
    data && (
      <EditFormMUI
        header='Suspicious Identifier'
        textInputs={[
          { id: 'name', label: 'name', defaultValue: data.name },
          { id: 'ioc', label: 'ioc', defaultValue: data.ioc },
          {
            id: 'detection_name',
            label: 'detection_name',
            defaultValue: data.detection_name,
          },
          { id: 'hash', label: 'hash', defaultValue: data.hash },
          {
            id: 'attack_vectors',
            label: 'attack_vectors',
            defaultValue: data.attack_vectors,
          },
          {
            id: 'detections',
            label: 'detections',
            defaultValue: data.detections,
          },
          {
            id: 'confidence_score',
            label: 'confidence_score',
            defaultValue: data.confidence_score,
          },
          {
            id: 'description',
            label: 'description',
            defaultValue: data.description,
          },
          {
            id: 'affected_platforms',
            label: 'affected_platforms',
            defaultValue: data.affected_platforms,
          },
          {
            id: 'impacted_parties',
            label: 'impacted_parties',
            defaultValue: data.impacted_parties,
          },
          { id: 'impact', label: 'impact', defaultValue: data.impact },
          {
            id: 'file_extention',
            label: 'file_extention',
            defaultValue: data.file_extention,
          },
          { id: 'chainware', label: 'chainware', defaultValue: data.chainware },
          {
            id: 'victomology',
            label: 'victomology',
            defaultValue: data.victomology,
          },
          { id: 'tags', label: 'tags', defaultValue: data.tags },
          {
            id: 'mitre_attack',
            label: 'mitre_attack',
            defaultValue: data.mitre_attack,
          },
        ]}
        selectInputs={
          <>
            <SeverityList
              onChange={(e) => {
                setSeverity(e.target.value);
              }}
              additionalData={`Severity in DB:${data.severity}`}
            />
            <MalwareOperationSystemList
              onChange={(e) => {
                setOperationSystem(e.target.value);
              }}
              additionalData={`Operation System in DB:${data.operation_system}`}
            />
            <MalwareTypeList
              onChange={(e) => {
                setTypes((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`Type in DB:${data.type}`}
            />
            <FormSelectListDynamic
              header='Cyber Threat Actor'
              path='cyber-threat-actors'
              optVal='name'
              id='cyber_threat_actor_id'
              onChange={(e) => {
                setThreatActors((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`Threat Actors in DB:${data.threat_actors}`}
            />
            <ThreatIndustryList
              onChange={(e) => {
                setIndustries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`Industry in DB:${data.industry}`}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`suspicious-identifiers/${edit_suspicious_identifier_id}`}
        afterDeleteFunc={() => {
          navigate('/suspicious-identifiers');
        }}
      />
    )
  );
};
export default EditSuspiciousIdentifier;
