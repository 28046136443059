import Input from './Result';
import Select from './Select';
import { useDispatch } from 'react-redux';
import {
  setCurrency,
  setContractDuration,
  setCompanySize,
  setIsGovernment,
  setIsIndustry,
} from '../actions';

const CompanyTable = () => {
  const dispatch = useDispatch();

  return (
    <div className='the-company-table'>
      <Select
        onChange={(e) => {
          dispatch(setCompanySize(e.target.value));
        }}
        label='Company Size'
        options={['small', 'medium', 'large']}
      />
      <Select
        label='Comapny'
        trueOrFalseSelect={true}
        options={['no', 'yes']}
        onChange={(e) => {
          dispatch(setIsGovernment(e.target.value));
        }}
      />
      <Select
        label='Industry'
        trueOrFalseSelect={true}
        options={['no', 'yes']}
        onChange={(e) => {
          dispatch(setIsIndustry(e.target.value));
        }}
      />
      <Input label='Employees' />
      <Input label='Organizational Assets' />
      <Select
        label='Contract Duration'
        options={['1 year', '2 years', '3 years']}
        onChange={(e) => {
          dispatch(
            setContractDuration(
              e.target.value.replace('years', '').replace('year', '').trim()
            )
          );
        }}
      />
      <Select
        label='Currency'
        options={['USD', 'EUR', 'NIS']}
        onChange={(e) => {
          dispatch(setCurrency(e.target.value));
        }}
      />
    </div>
  );
};
export default CompanyTable;
