import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import Card from './Card';
import './DeleteAndEditButtons.css';
import useDeleteRequest from '../hooks/use-delete-request';
import ClipLoader from 'react-spinners/ClipLoader';

const DeleteAndEditButtons = (props) => {
  const {
    deleteFeedback,
    deleteLoading,
    deleteError,
    deleteRequestFunc,
    deleteSuccess,
  } = useDeleteRequest();

  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state.appReducer);
  return (
    isAdmin && (
      <div className='delete-edit-action-btns-container'>
        <button
          className='delete-edit-action-btn'
          onClick={() => {
            if (props.editFunc) {
              props.editFunc();
            }
            props.navigateToEdit && navigate(props.navigateToEdit);
          }}
        >
          🖌
        </button>

        <Popup
          trigger={<button className='delete-edit-action-btn'>X</button>}
          modal
        >
          <span>
            <Card
              content={
                <div className='delete-popup'>
                  <h3>Are you sure?</h3>
                  <div className='delete-popup-buttons-container'>
                    <button
                      onClick={async () => {
                        await deleteRequestFunc(
                          `https://api.hackeye.io/api/v1/${props.path}/${props.id}`,
                          true
                        );
                        props.afterDeleteFunc();
                        document.querySelector(
                          '[data-popup="modal"]'
                        ).style.display = 'none';
                      }}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => {
                        document.querySelector(
                          '[data-popup="modal"]'
                        ).style.display = 'none';
                      }}
                    >
                      No
                    </button>
                  </div>
                  {deleteLoading ? (
                    <ClipLoader
                      loading={deleteLoading}
                      size={35}
                      className='cliploader'
                    />
                  ) : (
                    (deleteSuccess || deleteError) && deleteFeedback
                  )}{' '}
                </div>
              }
            />
          </span>
        </Popup>
      </div>
    )
  );
};
export default DeleteAndEditButtons;
