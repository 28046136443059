import './CardWithLeftHeader.css';

const CardWithLeftHeader = (props) => {
  return (
    <div className={`card-with-left-header-wrapper ${props.css}`}>
      <h4 className='card-with-left-header-header'>{props.header}</h4>
      <div className='card-with-left-header-content'>{props.content}</div>
    </div>
  );
};
export default CardWithLeftHeader;
