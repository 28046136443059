import './ThreatActor.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TerrorThreatActor from './TerrorThreatActor';
import CriminalThreatActor from './CriminalThreatActor';
import OrganizedCrimeThreatActor from './OrganizedCrimeThreatActor';
import axios from 'axios';
import CyberThreatActor from './CyberThreatActor';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';

const ThreatActor = () => {
  const [actor, setActor] = useState(undefined);
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();
  const threat_actor_type = sessionStorage.getItem('threat_actor_type');
  const threat_actor_id = sessionStorage.getItem('threatActorID');

  const getActor = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/${threat_actor_type}s/${threat_actor_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const threat = res.data.data.doc;

          if (threat_actor_type === 'cyber-threat-actor') {
            setActor(<CyberThreatActor threat={threat} />);
          } else if (threat_actor_type === 'terror-threat-actor') {
            setActor(<TerrorThreatActor threat={threat} />);
          } else if (threat_actor_type === 'criminal-threat-actor') {
            setActor(<CriminalThreatActor threat={threat} />);
          } else if (threat_actor_type === 'organized-crime-threat-actor') {
            setActor(<OrganizedCrimeThreatActor threat={threat} />);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getActor();
  }, []);

  const handleThreatActorDeleted = () => {
    navigate('threats-library');
    sessionStorage.removeItem('threatActorID');
  };

  return (
    <div className='threat-actor-page-wrapper'>
      <div className='col-xl-12 threat-actor-container'>
        <div style={{ height: '100%' }}>
          <div className='threat-actor-action-btns-container'>
            <button
              className='go-back-threat-actor-btn'
              onClick={() => {
                navigate('/threats-library');
              }}
            >
              ←
            </button>
            <div>
              <DeleteAndEditButtons
                id={threat_actor_id}
                path={`${threat_actor_type}s`}
                afterDeleteFunc={handleThreatActorDeleted}
                navigateToEdit={`/edit-threat-actor/${threat_actor_type
                  .toLowerCase()
                  .replaceAll('_', '-')}`}
              />
            </div>
          </div>

          {actor}
        </div>
      </div>
    </div>
  );
};
export default ThreatActor;
