import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Card from '../../components/Card';

import './SearchResults.css';
import { dateFormat } from '../../utils/dateFormat';

const CveIdResults = () => {
  const { cveid_data } = useSelector((state) => state.appReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        <h3 className='search-results-num-of-results-header'>
          {cveid_data ? 1 : 0} Results Found
        </h3>
        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>

      {!cveid_data && (
        <Card
          className='col-xl-12'
          content={<div style={{ padding: '10px' }}>No Results Found</div>}
        />
      )}
      {cveid_data && (
        <div className='col-xl-12'>
          <Card
            className='navbar-search-results-card'
            content={
              <div style={{ padding: '10px' }}>
                {cveid_data.id && (
                  <h6 className='search-results-searched-item'>
                    {cveid_data.id}
                  </h6>
                )}
                {cveid_data.published && (
                  <div>
                    Published:
                    <h6> {dateFormat(cveid_data.published)}</h6>
                  </div>
                )}
                {cveid_data.lastModified && (
                  <div>
                    Last update:
                    <h6> {dateFormat(cveid_data.lastModified)}</h6>
                  </div>
                )}
                {cveid_data.baseSeverity && (
                  <div>
                    Severity:<h6> {cveid_data.baseSeverity}</h6>
                  </div>
                )}
                {cveid_data.baseScore && (
                  <div>
                    Score:<h6> {cveid_data.baseScore}</h6>
                  </div>
                )}
                {cveid_data.attackVector && (
                  <div>
                    Attack Vector:<h6> {cveid_data.attackVector}</h6>
                  </div>
                )}
                {cveid_data.attackComplexity && (
                  <div>
                    Attack Complexity:<h6> {cveid_data.attackComplexity}</h6>
                  </div>
                )}
                {cveid_data.enDescriptions && (
                  <div>
                    Vulnerabilities:
                    <h6>
                      {Object.values(cveid_data.enDescriptions).map((val) => {
                        return val.value;
                      })}
                    </h6>
                  </div>
                )}
                <div className='search-results-result-btns-wrapper'>
                  <div className='search-results-result-btns-container'>
                    <button className='search-results-btn'>
                      Add to reports
                    </button>
                    <button className='search-results-btn'>create alert</button>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};
export default CveIdResults;
