import { useEffect } from 'react';
import useGetRequest from '../../hooks/use-get-request';
import ThreatIndustryList from '../../components/ThreatIndustryList';
import EditFormMUI from '../../components/EditFormMUI';
import { useNavigate } from 'react-router-dom';

const EditThreatFromFeed = () => {
  const { getRequestFunc, data } = useGetRequest();
  const navigate = useNavigate();
  const threat_feed_id = sessionStorage.getItem('threat_feed_id');
  const industry = [];

  const getThreat = async () => {
    await getRequestFunc(
      `https://api.hackeye.io/api/v1/threat-feed/${threat_feed_id}`,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getThreat();
  }, []);

  const updateThreat = async () => {
    const title = document.getElementById('title').value;
    const recommendations = document.getElementById('recommendations').value;
    const tags = document.getElementById('tags').value;
    const reference = document.getElementById('reference').value;
    const content = document.getElementById('content').value;
    const url = document.getElementById('url').value;
    const date =
      document.getElementById('date').value === ''
        ? data.date
        : document.getElementById('date').value;

    const updatedStr = JSON.stringify({
      title,
      recommendations,
      tags,
      reference,
      content,
      url,
      industry: industry.length === 0 ? data.industry : industry,
      date,
    });

    return updatedStr;
  };

  return (
    data && (
      <EditFormMUI
        header='Threat From Feed'
        textInputs={[
          { label: 'Title', id: 'title', defaultValue: data.title },
          {
            label: 'Recommendations',
            id: 'recommendations',
            defaultValue: data.recommendations,
          },
          { label: 'Tags', id: 'tags', defaultValue: data.tags },
          { label: 'Url', id: 'url', defaultValue: data.url },
          {
            label: 'Date',
            id: 'date',
            defaultValue: data.date ? data.date.split('T')[0] : null,
          },
          { label: 'Reference', id: 'reference', defaultValue: data.reference },
          { label: 'Content', id: 'content', defaultValue: data.content },
        ]}
        selectInputs={
          <>
            <ThreatIndustryList
              onChange={(e) => {
                industry.push(e.target.value);
              }}
              additionalData={`Industry in DB: ${data.industry}`}
            />
          </>
        }
        itemToEdit={updateThreat}
        path={`threat-feed/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/threat-feed');
        }}
      />
    )
  );
};
export default EditThreatFromFeed;
