import './ThreatFeed.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Card from '../../components/Card';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../utils/dateFormat';

const ThreatFeed = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const [threatFilter, setThreatFilter] = useState('all');
  const [rssFeeds, setRssFeeds] = useState([]);
  const [localFeeds, setLocalFeeds] = useState([]);
  const [combinedFeeds, setCombinedFeeds] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRssFeeds();
    getLocalFeeds();
  }, []);

  useEffect(() => {
    if (rssFeeds && localFeeds) {
      const allFeeds = [...rssFeeds, ...localFeeds].sort((a, b) => {
        let dateA = new Date(a.pubDate || a.createdAt).getTime();
        let dateB = new Date(b.pubDate || b.createdAt).getTime();

        return dateB - dateA;
      });
      setCombinedFeeds(allFeeds);
    }
  }, [rssFeeds, localFeeds]);

  const createDescriptionDiv = (i, desctiption) => {
    const div = document.createElement('div');
    div.id = i;
    div.innerHTML = desctiption;
    return div.innerText.replace('read more', '').trim();
  };

  const renderRssFeed = (item) => {
    return (
      <div
        className='col-xl-12 threat-feed-rss-feed-wrapper'
        key={Math.random()}
      >
        <Card
          content={
            <div className='threat-feed-rss-feed'>
              <div className='rss-feed-categories-pubdate-container'>
                <div className='rss-feeds-categories-container'>
                  {item.category &&
                    item.category.lnegth &&
                    item.category.map((c, i) => {
                      return (
                        <div
                          key={i}
                          className='actor-displayed-data-tag-filled-bg'
                        >
                          {c}
                        </div>
                      );
                    })}
                </div>

                <div className='rss-feed-pubdate'>
                  {dateFormat(item.pubDate[0])}
                </div>
              </div>

              <h6 className='rss-feed-title'>{item.title}</h6>

              <div className='rss-feed-description-container'>
                {createDescriptionDiv(Math.random(), item.description)}
              </div>
            </div>
          }
        />
      </div>
    );
  };

  const renderLocalThreatFeed = (feed) => {
    return (
      <div
        key={feed._id}
        className='col-xl-12 threat-feed-rss-feed-wrapper'
        onClick={() => {
          sessionStorage.setItem('threat_feed_id', feed._id);
          navigate(`/threat-feed/${feed._id}`);
        }}
      >
        <Card
          content={
            <div className='threat-feed-rss-feed'>
              <div className='rss-feed-categories-pubdate-container'>
                <div className='rss-feeds-categories-container'>
                  {feed.tags &&
                    feed.tags.split(',').map((tag, i) => {
                      if (i < 10) {
                        return (
                          <div
                            key={i}
                            className='actor-displayed-data-tag-filled-bg'
                          >
                            {tag}
                          </div>
                        );
                      }
                    })}
                </div>
                <div className='rss-feed-pubdate'>
                  {dateFormat(feed.date ? feed.date : feed.createdAt)}
                </div>
              </div>

              <h6 className='rss-feed-title'>{feed.title}</h6>

              <div className='rss-feed-description-container'>
                {feed.content}
              </div>
            </div>
          }
        />
      </div>
    );
  };

  const getRssFeeds = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/rss-feeds', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const items = [];
          const results = res.data.data;
          const handledResults = Object.values(results)
            .flat()
            .sort((a, b) => {
              return (
                new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
              );
            });

          for (const handledRes of handledResults) {
            items.push(...handledRes.item);
          }
          const handledItems = items.sort((a, b) => {
            return (
              new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
            );
          });
          // console.log('RSS: ', handledItems);
          setRssFeeds(handledItems);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getLocalFeeds = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/threat-feed', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const results = res.data.data.doc.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
          setLocalFeeds(results);
        })
        .catch((err) => {
          console.log('FAIL: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <select
        style={{ width: '100px', float: 'right' }}
        onChange={(e) => {
          setThreatFilter(e.target.value);
        }}
      >
        <option id='all'>all</option>
        <option id='rss'>rss</option>
        <option id='local'>local</option>
      </select>
      <h3 className='threats-feed-main-header'>Threats feed</h3>
      {threatFilter === 'all' && (
        <div>
          {combinedFeeds?.map((feed) => {
            if (feed.link) {
              return renderRssFeed(feed);
            } else {
              return renderLocalThreatFeed(feed);
            }
          })}
        </div>
      )}
      {threatFilter === 'rss' && (
        <div>
          {rssFeeds?.map((feed) => {
            return renderRssFeed(feed);
          }) ?? []}
        </div>
      )}
      {threatFilter === 'local' && (
        <div>
          {localFeeds?.map((feed) => {
            return renderLocalThreatFeed(feed);
          }) ?? []}
        </div>
      )}
    </div>
  );
};
export default ThreatFeed;
