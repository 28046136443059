import { useEffect, useState } from 'react';
import EditFormMUI from '../../components/EditFormMUI';
import useGetRequest from '../../hooks/use-get-request';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditThreatHuntingItem = () => {
  const threat_hunting_item = sessionStorage.getItem('threat_hunting_item');
  const { data, getRequestFunc } = useGetRequest();
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const [malwareID, setMalwareID] = useState(null);

  const getUpdatedEmergingThreat = async () => {
    const name = document.getElementById('name').value;
    const program = document.getElementById('program').value;
    const description = document.getElementById('description').value;
    const tags = document.getElementById('tags').value;
    const legitimate_path = document.getElementById('legitimate_path').value;
    const date =
      document.getElementById('date').value === ''
        ? data.date
        : document.getElementById('date').value;

    const updatedThreat = JSON.stringify({
      name,
      program,
      description,
      legitimate_path,
      malware_id: malwareID ? malwareID : data.malware_id,
      tags,
      type: type ? type : data.type,
      date: date ? date : data.date,
    });
    return updatedThreat;
  };

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/threat-hunting/${threat_hunting_item}`,
      true
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    data && (
      <EditFormMUI
        header='Threat Hunting Item'
        textInputs={[
          { label: 'name', id: 'name', defaultValue: data.name },
          { label: 'program', id: 'program', defaultValue: data.program },
          {
            label: 'description',
            id: 'description',
            defaultValue: data.description,
          },
          { label: 'tags', id: 'tags', defaultValue: data.tags },
          {
            label: 'legitimate_path',
            id: 'legitimate_path',
            defaultValue: data.legitimate_path,
          },
          {
            label: 'date',
            id: 'date',
            defaultValue: data.date ? data.date.split('T')[0] : null,
            type: 'date',
          },
        ]}
        selectInputs={
          <>
            <FormSelectListStatic
              id='type'
              onChange={(e) => {
                setType(e.target.value);
              }}
              additionalData={`Type in DB: ${data.type}`}
              header='Type'
              options={[
                'Process',
                'Service',
                'Registry Key',
                'Command',
                'DLL',
                'Daemon',
              ]}
            />
            <FormSelectListDynamic
              header='Malware'
              onChange={(e) => {
                setMalwareID(e.target.value);
              }}
              additionalData={`Malware in DB: ${data.malware_id}`}
              path='malware-threat-actors'
              optVal='malware_name'
              id='malware_id'
            />
          </>
        }
        itemToEdit={getUpdatedEmergingThreat}
        path={`threat-hunting/${threat_hunting_item}`}
        afterDeleteFunc={() => {
          navigate('/threat-hunting');
        }}
      />
    )
  );
};
export default EditThreatHuntingItem;
