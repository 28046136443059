import CountryList from '../../components/CountryList';
import { useState, useEffect } from 'react';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import useGetRequest from '../../hooks/use-get-request';

const EditUser = () => {
  const chosen_user_id = sessionStorage.getItem('chosen_user_id');
  const { getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/users/${chosen_user_id}`,
      true
    );
  }, []);

  const [country, setCountry] = useState(null);
  const [role, setRole] = useState(null);
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const editItem = async () => {
    const name = document.getElementById('name').value;
    const company = document.getElementById('company').value;
    const email = document.getElementById('email').value;

    const updatedUser = JSON.stringify({
      name,
      company,
      email,
      country: country ? country : data.country,
      role: role ? role : data.role,
    });
    return updatedUser;

    // try {
    //   await editRequestFunc(
    //     `https://api.hackeye.io/api/v1/users/${chosen_user_id._id}`,
    //     updatedUser,
    //     true
    //   );
    //   if (file) {
    //     await handleUpload(chosen_user_id._id);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    data && (
      <EditFormMUI
        header='User'
        changeImageInput
        handleImageChange={(e) => {
          handleChange(e);
        }}
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          {
            label: 'Company',
            id: 'company',
            defaultValue: data.company,
          },
          { label: 'Email', id: 'email', defaultValue: data.email },
        ]}
        selectInputs={
          <>
            <CountryList
              id='country'
              header='Country'
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              defaultValue={data.country}
            />
            <FormSelectListStatic
              onChange={(e) => {
                setRole(e.target.value);
              }}
              id='role'
              header='Role'
              options={['user', 'admin', 'user-organization']}
            />
          </>
        }
        editItem={editItem}
      />
    )
  );
};
export default EditUser;
