import axios from 'axios';
import { useState, useEffect } from 'react';
import { dateFormat } from '../utils/dateFormat';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import Card from '../components/Card';

const CyberCampaignEventsList = () => {
  const [cyberCampaigns, setCyberCampaigns] = useState();
  const fetchedCyberCampaigns = [];
  const token = sessionStorage.getItem('token');

  const getCampaigns = async () => {
    setCyberCampaigns(undefined);
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/cyber-campaigns-events', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          for (const campaign of res.data.data.doc) {
            // console.log(campaign);
            fetchedCyberCampaigns.push(
              <div
                key={campaign._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>{campaign.name}</div>
                <div style={{ display: 'flex' }}>
                  {dateFormat(campaign.date)}
                  <DeleteAndEditButtons
                    id={campaign._id}
                    path='cyber-campaigns-events'
                    afterDeleteFunc={() => {
                      getCampaigns();
                    }}
                    editFunc={() => {
                      sessionStorage.setItem(
                        'edit_cyber_campaign_id',
                        campaign._id
                      );
                    }}
                    navigateToEdit='/edit-cyber-campaign'
                  />
                </div>
              </div>
            );
          }
          setCyberCampaigns(fetchedCyberCampaigns);
          //   setCyberEvents(fetchedCyberEvents);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCampaigns();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card
      content={
        <>
          <h3 className='ioc-list-header'>Cyber Campaigns and Events</h3>
          <div>{cyberCampaigns}</div>
        </>
      }
    />
  );
};
export default CyberCampaignEventsList;
