import ThreatActorIocCampaignsMalwaresTable from '../../components/ThreatActorIocCampaignsMalwaresTable';
import ThreatActorProfileImg from './ThreatActorProfileImg';
import Flags from '../../components/Flags';

const OrganizedCrimeThreatActor = (props) => {
  return (
    <div>
      <div className='threat-actor-information'>
        <ThreatActorProfileImg id={props.threat.id} />
        <div className='threat-actor-info-description'>
          <div className='threat-actor-profile-threat-name-flag-wrapper'>
            <div>{props.threat.name} </div>{' '}
            <div className='threat-actor-profile-origin-countries-container'>
              <Flags
                countries={props.threat.country}
                imgContainerClassName='flags-image-seperator'
              />
            </div>
          </div>
          {props.threat.type && (
            <h6 className='threat-actor-profile-threat-detail'>
              Type: {props.threat.type}
            </h6>
          )}
          <div>
            <h6 className='threat-actor-profile-threat-detail'>
              {props.threat.description}
            </h6>
          </div>
        </div>
      </div>
      <ThreatActorIocCampaignsMalwaresTable
        data={props.threat}
        tabs={[
          'Indicator of Compromise',
          'Cyber Campaigns',
          'Malwares & Tools',
        ]}
      />
    </div>
  );
};
export default OrganizedCrimeThreatActor;
