import { useSelector } from 'react-redux';
import OfficeFacilities from '../components/OfficeFacilities';
import HumanResources from '../components/HumanResources';
import Siem from '../components/SIEM';
import DataEnrichment from '../components/DataEnrichment';
import OperationalCosts from '../components/OperationalCosts';
import { useEffect } from 'react';

const CalculatorFullReport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-wrapper-scrollable'>
      <h1>Full Report</h1>
      <div className='col-xl-12'>
        <div className='card mb-3 special-bg-card-color'>
          <div className='card-body' style={{ border: '2px solid #172340' }}>
            <div className='malware-list-information'>
              <div className='malware-list-info-description'>
                <OfficeFacilities />
                <HumanResources />
                <Siem />
                <DataEnrichment />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalculatorFullReport;
