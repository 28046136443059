import { useEffect, useState } from 'react';
import CountryList from '../components/CountryList';
import EditFormMUI from '../components/EditFormMUI';
import useGetRequest from '../hooks/use-get-request';
import FormSelectListStatic from '../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditAntisemeticWebsite = () => {
  const edit_antisemetic_website_id = sessionStorage.getItem(
    'edit_antisemetic_website_id'
  );
  const { data, getRequestFunc } = useGetRequest();
  const [status, setStatus] = useState(null);
  const [country, setCountry] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/antisemetic-websites/${edit_antisemetic_website_id}`,
      true
    );
    window.scrollTo(0, 0);
  }, []);

  const getWebsiteStr = async () => {
    const website = document.getElementById('website').value;

    const updatedWebsite = JSON.stringify({
      website: website === '' ? data.website : website,
      status: status ? status : data.status,
      country: country ? country : data.country,
    });

    return updatedWebsite;
  };

  return (
    data && (
      <EditFormMUI
        header='Antisemetic Website'
        textInputs={[
          {
            label: 'Website',
            id: 'website',
            defaultValue: data.website,
          },
        ]}
        selectInputs={
          <>
            <FormSelectListStatic
              id='status'
              additionalData={`Status in DB: ${data.status}`}
              header='Status'
              options={['Active', 'Down']}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
            <CountryList
              id='country'
              header='Country'
              additionalData={`Country in DB: ${data.country}`}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </>
        }
        itemToEdit={getWebsiteStr}
        path={`antisemetic-websites/${data._id}`}
        afterDeleteFunc={() => {
          navigate('antisemetic-websites-blogs-management');
        }}
      />
    )
  );
};
export default EditAntisemeticWebsite;
