import { useEffect } from 'react';
import EditFormMUI from '../components/EditFormMUI';
import useGetRequest from '../hooks/use-get-request';

const EditOrganization = () => {
  const edit_organization_id = sessionStorage.getItem('edit_organization_id');
  const { getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/organizations/${edit_organization_id}`,
      true
    );
  }, []);

  const getEditedItem = async () => {
    const name = document.getElementById('name').value;
    const expiration = document.getElementById('expiration').value;
    const numOfUsers = document.getElementById('numOfUsers').value;
    const upatedUser = JSON.stringify({ name, expiration, numOfUsers });
    return upatedUser;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    data && (
      <EditFormMUI
        header='Organization'
        textInputs={[
          {
            id: 'numOfUsers',
            label: 'Number of Users',
            defaultValue: data.numOfUsers,
          },
          {
            id: 'name',
            label: 'name',
            defaultValue: data.name,
          },
          {
            id: 'expiration',
            label: 'Expiration',
            type: 'date',
            defaultValue: data.expiration.split('T')[0],
          },
        ]}
        itemToEdit={getEditedItem}
        path={`organizations/${edit_organization_id}`}
      />
    )
  );
};
export default EditOrganization;
