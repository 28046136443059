import './CrawlerUrls.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import CountryList from '../../components/CountryList';
import CrawlerLanguageList from './CrawlerLanguageList';
import CrawlerFieldList from './CrawlerFieldList';
import { ClipLoader } from 'react-spinners';
import ListReadOnly from '../../components/ListReadOnly';
import Card from '../../components/Card';
import Input from '../../components/Input';
import useDeleteRequest from '../../hooks/use-delete-request';
import usePostRequest from '../../hooks/use-post-request';
// import useGetRequest from '../../hooks/use-get-request';
// import usePatchRequest from '../../hooks/use-patch-request';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const CrawlerUrlList = () => {
  const { user_id } = useSelector((state) => state.appReducer);
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const { deleteRequestFunc } = useDeleteRequest();
  const { postRequestFunc, postData } = usePostRequest();
  // const { getRequestFunc, data } = useGetRequest();
  // const { editRequestFunc } = usePatchRequest();

  const [displayedUrls, setDisplayedUrls] = useState();
  // const [latestUrlID, setLatestUrlID] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');

  // const getLatestUrlID = async () => {
  //   try {
  //     setLoading(true);
  //     setLoadingMsg('fetching the created url..');
  //     await axios
  //       .get(`https://api.hackeye.io/api/v1/crawler-urls`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       .then((res) => {
  //         setLatestUrlID(res.data.data.doc[0]._id);
  //         setLoading(false);
  //         setLoadingMsg('');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLatestUrlID(undefined);
  //         setLoading(false);
  //         setLoadingMsg('Something went wrong while fetching the latest url');
  //       });
  //   } catch (err) {
  //     console.log(err);
  //     setLatestUrlID(undefined);
  //     setLoading(false);
  //     setLoadingMsg('Something went wrong while fetching the latest url');
  //   }
  // };

  const deleteUrl = async (url_id) => {
    await deleteRequestFunc(
      `https://api.hackeye.io/api/v1/crawler-urls/${url_id}`,
      true
    );
    await getUrlList();
  };

  const addUrl = async () => {
    setLoading(true);
    const url = document
      .querySelector('.crawler-searched-url-input')
      .value.replaceAll('www.', '')
      .replaceAll('http://', '')
      .replaceAll('https://', '')
      .replaceAll('/', '');

    const field = document.getElementById('field-list').value;
    const language = document.getElementById('language-list').value;
    const country = document.getElementById('country-list').value;

    const addedUrlStr = JSON.stringify({
      url,
      field,
      language,
      country,
      user_id,
      createdAt: Date.now(),
      last_update: Date.now(),
    });

    //CREATE MAIN URL
    await postRequestFunc(
      `https://api.hackeye.io/api/v1/crawler-urls`,
      addedUrlStr,
      true
    );

    //GET ATTACHED URLS
    // await getRequestFunc(
    //   `https://api.hackeye.io/api/v1/crawler-urls/attached-urls/${url}`,
    //   true
    // );
  };

  // useEffect(() => {
  //   if (postData) {
  //     getLatestUrlID();
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (latestUrlID) {
  //     createAttachedUrls(data);
  //   }
  // }, [latestUrlID]);
  useEffect(() => {
    if (postData) {
      sessionStorage.setItem('crawler_created_url_id', postData.data.doc.id);
      navigate('/crawler-attached-urls-creation');
    }
  }, [postData]);

  const getUrlList = async () => {
    setLoading(true);
    setDisplayedUrls(undefined);
    const currentUserUrls = [];

    try {
      await axios
        .get('https://api.hackeye.io/api/v1/crawler-urls', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const urls = res.data.data.doc;
          urls.map((url) => {
            // //update attached urls content if needed
            // el.attached_urls.map((attachedUrl) => {
            //   attachedUrl.content.map((attachedUrlContent) => {
            //     if (
            //       new Date(Date.now()).getTime() -
            //         new Date(attachedUrlContent.createdAt).getTime() >
            //       43200000
            //     ) {
            //       //UPDATE CONTENT EVERY 24 HOURS - ADD ANOTHER "CONTENT" OBJ {CONTENT: CONTENT, CREATEDAT: DATE.NOW()}
            //       getMoreUrlAttachedUrlContent(attachedUrl);
            //     }
            //   });
            // });

            //display urls

            currentUserUrls.push({
              value: url.url,
              id: url._id,
              actionBtns: true,
              path: 'urls',
              afterDeleteFunc: () => {
                deleteUrl(url._id);
              },
              // navigateToEdit: {`/edit-threat-actor/${threat_actor_type
              //     //   .toLowerCase()
              //     //   .replaceAll('_', '-')}`}
            });
          });
          setDisplayedUrls(<ListReadOnly data={currentUserUrls} />);
          setLoading(false);
        })
        .catch((err) => {
          console.log('FAIL: ' + err);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUrlList();
  }, []);

  return (
    <div>
      <h4 className='url-list-header'>Url List</h4>
      <p className='url-list-sub-header'>View and update your list of urls.</p>
      <div className='url-list-card'>
        <Card
          content={
            <div
              className='list-group list-group-flush'
              style={{ padding: '10px' }}
            >
              {displayedUrls}
            </div>
          }
        />
        <div className='add-url-form'>
          <label>Add Url</label>
          <Input className='crawler-searched-url-input' />
          <div style={{ textAlign: 'left' }}>
            <CrawlerFieldList />
            <CrawlerLanguageList />
            <CountryList />
          </div>
          <button
            onClick={() => {
              addUrl();
            }}
          >
            Add
          </button>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ClipLoader loading={loading} size={35} className='cliploader' />
            <div style={{ color: '#fff' }}>{loadingMsg}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CrawlerUrlList;
// const fetchDailyContentForAttachedUrls = async () => {
//   try {
//     await axios
//       .get('https://api.hackeye.io/api/v1/crawler-attached-urls', {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         const urls = res.data.data.doc;
//         urls.map(async (url, i) => {
//           if (
//             new Date(Date.now()).getTime() -
//               new Date(url.last_update).getTime() >
//             43200000
//           ) {
//             // await createContentForAttachedUrl(url);
//             await editRequestFunc(
//               `https://api.hackeye.io/api/v1/crawler-attached-urls/${url._id}`,
//               JSON.stringify({ last_update: Date.now() }),
//               true
//             );
//           }
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   } catch (err) {
//     console.log(err);
//   }
// };

// useEffect(() => {
//   fetchDailyContentForAttachedUrls();
// }, []);

// const createContentForAttachedUrl = async (urlData, urlsArray) => {
//   // "get text" for this url
//   setLoading(true);
//   setLoadingMsg(`Searching for content in ${urlData.url}...`);

//   try {
//     await axios
//       .get(
//         `https://api.hackeye.io/api/v1/crawler-attached-urls/get-content/${urlData.url.replaceAll(
//           '/',
//           '~~'
//         )}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then(async (res) => {
//         const content = await res.data.data.doc;
//         const chunkSize = 70000;
//         if (content && content.length > chunkSize) {
//           // Split the content into pieces of 3000 characters each
//           const chunks = [];

//           for (let i = 0; i < content.length; i += chunkSize) {
//             const chunk = content.substring(i, i + chunkSize);
//             chunks.push(chunk);
//           }

//           // Now save data with each chunk in the "chunks" array
//           for (let i = 0; i < chunks.length; i++) {
//             const chunk = chunks[i];
//             await postRequestFunc(
//               'https://api.hackeye.io/api/v1/crawler-url-content',
//               JSON.stringify({
//                 url_id: urlData._id,
//                 createdAt: Date.now(),
//                 content: chunk,
//               }),
//               true
//             );
//           }
//         } else {
//           // If the content is not longer than 3000 characters, save it directly
//           if (content) {
//             await postRequestFunc(
//               'https://api.hackeye.io/api/v1/crawler-url-content',
//               JSON.stringify({
//                 url_id: urlData._id,
//                 createdAt: Date.now(),
//                 content,
//               }),
//               true
//             );
//           }
//         }

//         setLoading(false);
//         setLoadingMsg(
//           `Content for url https://${urlData.url} saved successfully`
//         );
//       })
//       .catch((err) => {
//         console.log(err);
//         setLoadingMsg(
//           `Error in saving content for url https://${urlData.url}`
//         );
//         setLoading(false);
//       });
//   } catch (err) {
//     console.log(err);
//     setLoadingMsg(`Error in saving content for url https://${urlData.url}`);
//     setLoading(false);
//   }

//   if (urlsArray.includes(!false)) {
//     // Do something when all values are false
//     console.log('All values are false.');
//   } else {
//     // Do something else when at least one value is not false
//     console.log('At least one value is not false.');
//   }
// };
