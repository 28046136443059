import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import Card from '../../components/Card';
import './SearchResults.css';

import { dateFormat } from '../../utils/dateFormat';
import useGetRequest from '../../hooks/use-get-request';

const EmailSearchResults = () => {
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();
  const { searchedEmail, emailResults } = useSelector(
    (state) => state.appReducer
  );
  const [loading, setLoading] = useState(false);
  const [displayedResults, setDisplayedResults] = useState([]);
  const initialDisplayedResults = [];

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/iocs/email/${searchedEmail}`,
      true
    );
  }, []);

  const setInnerHtml = (description) => {
    const div = document.createElement('div');
    div.innerHTML = description;
    return div.innerText;
  };

  const displayResultsFrom = (results) => {
    setLoading(true);
    for (const result of Object.values(results)) {
      if (result.hibp) {
        for (const [i, res] of Object.entries(result.hibp)) {
          initialDisplayedResults.push(
            <div key={i + 1}>
              <Card
                className='col-xl-12'
                content={
                  <div style={{ padding: '10px' }}>
                    <div className='search-results-result-tags-container'>
                      {res.Name}
                    </div>
                    <h6 className='search-results-searched-item'>
                      {searchedEmail}
                    </h6>

                    {res.Name && (
                      <div>
                        Source: <h6>{res.Name}</h6>
                      </div>
                    )}
                    {res.AddedDate && (
                      <div>
                        Published: <h6>{dateFormat(res.AddedDate)}</h6>
                      </div>
                    )}
                    {res.Domain && (
                      <div>
                        Breached Domain: <h6>{res.Domain}</h6>
                      </div>
                    )}
                    {res.BreachDate && (
                      <div>
                        Breach Date: <h6>{dateFormat(res.BreachDate)}</h6>
                      </div>
                    )}
                    {res.DataClasses.length > 0 && (
                      <div>
                        Compromised Data: <h6>{res.DataClasses.join(', ')}</h6>
                      </div>
                    )}
                    {res.Description && (
                      <div>
                        Description: <h6>{setInnerHtml(res.Description)}</h6>
                      </div>
                    )}
                    <div className='search-results-result-btns-wrapper'>
                      <div className='search-results-result-btns-container'>
                        <button className='search-results-btn'>
                          Add to reports
                        </button>
                        <button className='search-results-btn'>
                          create alert
                        </button>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          );
        }
      }
      if (result.dehashed) {
        for (const [i, res] of Object.entries(result.dehashed)) {
          initialDisplayedResults.push(
            <div key={i - 1}>
              <Card
                className='col-xl-12'
                content={
                  <div style={{ padding: '10px' }}>
                    <div className='search-results-result-tags-container'>
                      {res.database_name}
                    </div>
                    <h6 className='search-results-searched-item'>
                      {searchedEmail}
                    </h6>
                    {res.ip_address && (
                      <div>
                        IP: <h6>{res.ip_address}</h6>
                      </div>
                    )}
                    {res.username && (
                      <div>
                        Username:<h6> {res.username}</h6>{' '}
                      </div>
                    )}
                    {res.password && (
                      <div>
                        Password: <h6>{res.password}</h6>{' '}
                      </div>
                    )}
                    {res.address && (
                      <div>
                        Address:<h6> {res.address}</h6>{' '}
                      </div>
                    )}
                    {res.phone && (
                      <div>
                        Phone:<h6> {res.phone}</h6>{' '}
                      </div>
                    )}
                    {res.database_name && (
                      <div>
                        Database Name:<h6> {res.database_name}</h6>{' '}
                      </div>
                    )}
                    {res.hashed_password && (
                      <div>
                        {' '}
                        Hashed Password:<h6> {res.hashed_password}</h6>{' '}
                      </div>
                    )}
                    {res.name && (
                      <div>
                        Name:<h6> {res.name}</h6>{' '}
                      </div>
                    )}
                    <div className='search-results-result-btns-wrapper'>
                      <div className='search-results-result-btns-container'>
                        <button className='search-results-btn'>
                          Add to reports
                        </button>
                        <button className='search-results-btn'>
                          create alert
                        </button>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          );
        }
      }
    }
    if (data && data.ioc_value) {
      initialDisplayedResults.push(
        <Card
          className='col-xl-12'
          content={
            <div style={{ padding: '10px' }}>
              <div className='search-results-result-tags-container'>
                {data.tags &&
                  data.tags.length > 0 &&
                  data.tags.map((tag, i) => {
                    return (
                      <div key={i} style={{ marginRight: '5px' }}>
                        {tag}
                      </div>
                    );
                  })}
              </div>
              <h6 className='search-results-searched-item'>{searchedEmail}</h6>

              <div>
                Feed: <h6>{data.feed || 'N/A'}</h6>
              </div>

              <div>
                Description:<h6> {data.description || 'N/A'}</h6>{' '}
              </div>

              <div>
                Activity: <h6>{data.activity || 'N/A'}</h6>{' '}
              </div>

              <div>
                Type:<h6> {data.ioc_type || 'N/A'}</h6>{' '}
              </div>

              <div>
                Category:<h6> {data.category || 'N/A'}</h6>{' '}
              </div>

              <div>
                Reputation:<h6> {data.reputation || 'N/A'}</h6>{' '}
              </div>

              <div>
                {' '}
                Country:<h6> {data.country || 'N/A'}</h6>{' '}
              </div>

              <div className='search-results-result-btns-wrapper'>
                <div className='search-results-result-btns-container'>
                  <button className='search-results-btn'>Add to reports</button>
                  <button className='search-results-btn'>create alert</button>
                </div>
              </div>
            </div>
          }
        />
      );
    }
    setDisplayedResults(initialDisplayedResults);
    setLoading(false);
  };

  const filterResultByTime = (time) => {
    let filteredResults;
    if (time === 'Display All') {
      displayResultsFrom(emailResults);
    } else if (time === '1 Month') {
      filteredResults = Object.values(emailResults).filter((res) => {
        return (
          new Date(Date.now()).getTime() - new Date(res.BreachDate).getTime() <
          2629800000
        );
      });
      displayResultsFrom(filteredResults);
    } else if (time === '3 Months') {
      filteredResults = Object.values(emailResults).filter((res) => {
        return (
          new Date(Date.now()).getTime() - new Date(res.BreachDate).getTime() <
          7889400000
        );
      });
      displayResultsFrom(filteredResults);
    } else if (time === 'Last Year') {
      filteredResults = Object.values(emailResults).filter((res) => {
        return (
          new Date(Date.now()).getTime() - new Date(res.BreachDate).getTime() <
          31556926000
        );
      });
      displayResultsFrom(filteredResults);
    }
  };

  const displayResults = () => {
    if (!emailResults) {
      initialDisplayedResults.push(
        <Card
          className='col-xl-12'
          content={<div style={{ padding: '10px' }}>No Results Found</div>}
        />
      );
      setDisplayedResults(initialDisplayedResults);
    } else {
      displayResultsFrom(emailResults);
    }
  };

  useEffect(() => {
    displayResults();
  }, [emailResults, data]);

  return (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        {
          <h3 className='search-results-num-of-results-header'>
            {displayedResults.length} Results Found
          </h3>
        }
        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={(e) => {
                filterResultByTime(e.target.value);
              }}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <ClipLoader loading={loading} size={35} className='cliploader' />
      ) : (
        <div className='col-xl-12'>{displayedResults}</div>
      )}
    </div>
  );
};
export default EmailSearchResults;
