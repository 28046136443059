import './Settings.css';
import { useNavigate } from 'react-router-dom';
import ListReadOnly from '../../components/ListReadOnly';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions';
import Input from '../../components/Input';
import Card from '../../components/Card';
import { setUserImage } from '../../utils/handleUserProfileImage';
import usePatchRequest from '../../hooks/use-patch-request';

const Settings = () => {
  const token = sessionStorage.getItem('token');

  const { editSuccess, editLoading, editFeedback, editRequestFunc } =
    usePatchRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userOrg, setUserOrg] = useState('');

  const [passwordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState(false);

  const redirectToLogin = () => {
    dispatch(logout());
    navigate('/');
  };

  const updatePassword = async () => {
    const passwordCurrent = document.querySelector('.passwordCurrent').value;
    const password = document.querySelector('.password').value;
    const passwordConfirm = document.querySelector('.passwordConfirm').value;

    const newPassDetails = JSON.stringify({
      passwordCurrent,
      password,
      passwordConfirm,
    });

    await editRequestFunc(
      `https://api.hackeye.io/api/v1/users/updateMyPassword`,
      newPassDetails,
      true
    );

    if (editSuccess) {
      setPasswordChanged(true);
      redirectToLogin();
    }
  };

  const getUserDetails = async () => {
    try {
      await axios
        .get(`https://api.hackeye.io/api/v1/users/get-me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          const user = res.data.data.doc;
          setUser(user);
          user.organization_id
            ? setUserOrg('Organization')
            : setUserOrg('Individual');
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserDetails();
  }, []);

  useEffect(() => {
    if (user) {
      setUserImage('settings-user-profile-image', user._id);
    }
  }, [user]);

  return (
    user && (
      <div className='app-content-settings-wrapper page-wrapper-scrollable'>
        <div className='app-content-settings-container'>
          <div className='app-content-settings-container-content'>
            <div className='settings-card'>
              <h4 className='settings-header'>Profile Info</h4>
              <div style={{ height: '535px' }}>
                <Card
                  adjustCss='settings-card-wrapper-adjust-css'
                  content={
                    <ListReadOnly
                      data={[
                        { label: 'Full Name', value: user.name },
                        { label: 'Email Address', value: user.email },
                        { label: 'Role', value: user.role },
                        { label: 'Country', value: user.country },
                        { label: 'Industry', value: user.industry },
                        { label: 'Company', value: user.company },
                        { label: 'Position', value: user.position },
                      ]}
                    />
                  }
                />
              </div>
            </div>
            <div className='settings-card'>
              <h4 className='settings-header'>Profile Photo</h4>
              <div style={{ height: '170px' }}>
                <Card
                  adjustCss='settings-card-wrapper-adjust-css'
                  className='settings-change-profile-picture-card'
                  content={
                    <div>
                      <img
                        id='settings-user-profile-image'
                        className='settings-user-profile-image'
                        alt=''
                      ></img>
                      <div
                        className='settings-change-profile-photo-header'
                        onClick={() => {
                          navigate('/update-my-picture');
                        }}
                      >
                        Change Profile Photo
                      </div>
                    </div>
                  }
                />
              </div>
              <h4 className='settings-header'>Change Password</h4>
              <div style={{ height: '320px' }}>
                <Card
                  adjustCss='settings-card-wrapper-adjust-css'
                  content={
                    <div className='list-group list-group-flush'>
                      <div className='reset-password-form'>
                        <Input
                          className='passwordCurrent'
                          label='Current password'
                          type='password'
                        />
                        <Input
                          className='password'
                          label='New password'
                          type='password'
                        />
                        <Input
                          className='passwordConfirm'
                          label='Confirm new password'
                          type='password'
                        />
                        <div className='settings-update-password-btns-container'>
                          <button>Discard</button>
                          <button
                            onClick={() => {
                              updatePassword();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      {passwordChanged && !error && (
                        <label className='password-changed-msg'>
                          password changed successfully! Please log in again.
                        </label>
                      )}
                      {error && !passwordChanged && (
                        <label className='password-changed-msg'>
                          password change failed.
                        </label>
                      )}
                    </div>
                  }
                />
              </div>{' '}
            </div>{' '}
          </div>
        </div>

        <div className='app-content-settings-container'>
          <div className='app-content-settings-container-content'>
            <div className='settings-card'>
              <h4 className='settings-header'>License</h4>
              <div style={{ height: '55px' }}>
                <Card
                  adjustCss='settings-card-wrapper-adjust-css'
                  content={
                    <div>
                      <div className='settings-expiration-date-header'>
                        {userOrg}
                      </div>
                      <div className='settings-expiration-date-value'></div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default Settings;
