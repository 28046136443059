import './PhishingCampaignsMainDashboard.css';
import rectangle from '../../assets/images/main-dashboard/rectangle-16@2x.svg';
import { useEffect } from 'react';
import Card from '../Card';
import useGetRequest from '../../hooks/use-get-request';
import CardWithLeftHeader from '../CardWithLeftHeader';

const PhishingCampaignsMainDashboard = () => {
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/phishing-campaigns', true);
  }, []);

  const displayData = (data) => {
    const companies = {};

    data.map((campaign, i) => {
      if (companies[campaign.victim_company]) {
        companies[campaign.victim_company] += 1;
      } else {
        companies[campaign.victim_company] = 1;
      }
    });

    const sorted = Object.entries(companies)
      .sort((a, b) => {
        return b[1] - a[1];
      })
      .splice(0, 10);

    return sorted.map((campaign, i) => {
      return (
        <div
          style={{
            display: 'flex',
            // height: '21px',
            overflow: 'hidden',
          }}
          key={i}
        >
          <div className='main-dashboard-phishing-campaigns-name'>
            {campaign[0]}
          </div>

          <div>
            <img
              src={rectangle}
              alt=''
              style={{ width: `${campaign[1] * 10}%` }}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <CardWithLeftHeader
      css='main-dashboard-top-left-card'
      header='Phishing Campaigns'
      content={
        <div className='main-dashboard-phishing-campaigns-card-content'>
          <div className='main-dashboard-phishing-campaigns-list'>
            {data && typeof data === 'object' && displayData(data)}
          </div>
        </div>
      }
    />
  );
};
export default PhishingCampaignsMainDashboard;
