import usePostRequest from '../hooks/use-post-request';
import { useEffect } from 'react';
import CreationFormMUI from '../components/CreationFormMUI';

const CreateOrg = () => {
  const { postSuccess, postLoading, postFeedback, postError, postRequestFunc } =
    usePostRequest();

  const createNewItem = async () => {
    const name = document.getElementById('name').value;
    const expiration = document.getElementById('expiration').value;
    const numOfUsers = document.getElementById('numOfUsers').value;

    const newItemStr = JSON.stringify({
      name,
      expiration: new Date(expiration),
      numOfUsers,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/organizations',
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Organization'
      textInputs={[
        { label: 'Name', id: 'name' },
        {
          label: 'Expiration',
          id: 'expiration',
          type: 'date',
          defaultValue: Date.now(),
        },
        {
          label: 'Number of Users',
          id: 'numOfUsers',
          type: 'number',
          defaultValue: 0,
        },
      ]}
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateOrg;
