import { Grid, TextField } from '@mui/material';

const InputMUI = (props) => {
  return (
    <Grid item xs={12} sm={6}>
      <TextField
        InputProps={{
          inputProps: { min: props.min, max: props.max },
        }}
        defaultValue={props.defaultValue}
        type={props.type || 'text'}
        sx={{
          input: { color: '#FFFFFF' },
          label: { color: '#FFFFFF' },
          borderBottom: '1px solid #1976d2',
        }}
        fullWidth
        // autoComplete='?'
        required
        id={props.id}
        label={props.label}
      />
      {props.additionalData && (
        <div style={{ textAlign: 'left' }}>{props.additionalData}</div>
      )}
    </Grid>
  );
};
export default InputMUI;
