import FormSelectListStatic from '../../components/FormSelectListStatic';

const CrawlerFieldList = (props) => {
  return (
    <FormSelectListStatic
      id='field'
      onChange={props.onChange}
      header='Field'
      additionalData={props.additionalData}
      options={[
        'Url Field',
        'Food Services',
        'Religious',
        'Finance',
        'Insurance',
        'Politics',
        'Countries',
        'Entertainment',
        'Sport',
        'Education',
        'Mining',
        'Real Estate',
        'Healthcare ',
        'Cyber Security',
        'National Security',
        'Retail Trade',
        'Transportation',
        'Construction',
        'Manufacturing',
      ]}
    />
  );
};
export default CrawlerFieldList;
