import Flags from '../Flags';
import CardWithLeftHeader from '../CardWithLeftHeader';
import axios from 'axios';
import { useState, useEffect } from 'react';

const AntisemeticWebsitesBlogsDash = () => {
  const [websites, setWebsites] = useState();
  const token = sessionStorage.getItem('token');

  const getAllWebsites = async () => {
    const fetchedWebsites = [];

    try {
      await axios
        .get('https://api.hackeye.io/api/v1/antisemetic-websites', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          res.data.data.doc.map((web, i) => {
            if (i < 7) {
              fetchedWebsites.push(
                <tr
                  className='antisemetic-dashboard-websites-blogs-tr'
                  key={web._id}
                >
                  <td style={{ width: '110px' }}>
                    <Flags countries={[`${web.country}`]} withName={true} />
                  </td>
                  <td className='antisemetic-dashboard-websites-blogs-website-td'>
                    {web.website}
                  </td>
                  <td
                    className={`antisemetic-dashboard-websites-blogs-status-td antisemetic-website-status-${web.status.toLowerCase()}`}
                  >
                    {web.status}
                  </td>
                </tr>
              );
            }
          });
          setWebsites(fetchedWebsites);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllWebsites();
  }, []);

  return (
    <CardWithLeftHeader
      header='Websites and blogs'
      content={
        <div className='antisemitism-dashboard-right-top-card-wrapper'>
          <table className='antisemetic-dashboard-websites-blogs-list-table'>
            <tbody>
              <tr className='antisemetic-dashboard-websites-blogs-tr'>
                <td style={{ width: '110px' }}>Country</td>
                <td className='antisemetic-dashboard-websites-blogs-website-td'>
                  Website
                </td>
                <td className='antisemetic-dashboard-websites-blogs-status-td'>
                  Status
                </td>
              </tr>
              {websites}
            </tbody>
          </table>
        </div>
      }
    />
  );
};
export default AntisemeticWebsitesBlogsDash;
