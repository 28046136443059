import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import Card from '../components/Card';
import './Discovery.css';
import SearchBar from '../components/SearchBar';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import camera from '../assets/images/discovery/camera.png';
import location from '../assets/images/discovery/location.png';
import Select from '../components/Select';

const Discovery = () => {
  const [currentIP, setCurrentIP] = useState();
  const [currentCountry, setCurrentCountry] = useState();
  const [currentCountryCities, setCurrentCountryCities] = useState();
  const [activeCountry, setActiveCountry] = useState(0);
  const [groupedData, setGroupedData] = useState();
  const [displayedData, setDisplayedData] = useState();
  const [displayedCities, setDisplayedCities] = useState();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const [countryList, setCountryList] = useState();
  const [displayedCameras, setDisplayedCameras] = useState(false);
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/live-ip-addresses', true);
  }, []);

  useEffect(() => {
    //1
    if (data) {
      setDisplayedData(data);
    }
  }, [data]);

  useEffect(() => {
    //2
    if (displayedData) {
      setGroupedData(
        displayedData.reduce((groupedData, item) => {
          const { country, city } = item;
          if (!groupedData[country]) {
            groupedData[country] = {};
          }
          if (!groupedData[country][city]) {
            groupedData[country][city] = [];
          }

          groupedData[country][city].push(item);

          return groupedData;
        }, {})
      );
    }
  }, [displayedData]);

  useEffect(() => {
    //3
    if (groupedData) {
      setCurrentCountry(Object.keys(groupedData)[0]);
      if (!selectedCountry) {
        setCountryList(Object.keys(groupedData));
        setSelectedCountry(true);
      }
    }
  }, [groupedData]);

  useEffect(() => {
    //4
    if (currentCountry) {
      setCurrentCountryCities(Object.keys(groupedData[currentCountry]));
      setCurrentIP(Object.values(groupedData[currentCountry])[0][0]);
    }
  }, [currentCountry]);

  useEffect(() => {
    //5
    if (currentCountryCities) {
      currentCountryCities.length > 0 &&
        setDisplayedCities(
          currentCountryCities.map((city, i) => {
            return (
              <div
                key={i}
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
              >
                {city}
                {groupedData[currentCountry][city] &&
                  groupedData[currentCountry][city].length > 0 &&
                  groupedData[currentCountry][city].map((cam, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          fontSize: '12px',
                          paddingLeft: '10px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          onClick={() => {
                            setCurrentIP(cam);
                          }}
                        >
                          {cam.camera_name}
                        </div>
                        <div>
                          <DeleteAndEditButtons
                            path='live-ip-addresses'
                            id={cam._id}
                            editFunc={() => {
                              sessionStorage.setItem(
                                'edit_discovery_item_id',
                                cam._id
                              );
                            }}
                            navigateToEdit='/edit-discovery-item'
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })
        );
    }
  }, [currentCountryCities]);

  return (
    groupedData && (
      <div className='discovery-results-list-wrapper'>
        <div className='discovery-results-data-wrapper'>
          <div className='discovery-results-by-country-container'>
            <Card
              className='discovery-page-country-list-card-wrapper'
              adjustCss='discovery-page-country-list-card-wrapper-adjusted'
              content={
                <div style={{ padding: '10px', height: '100%' }}>
                  <div className='discovery-results-found-sort-results-container'>
                    {displayedData ? displayedData.length : 0} results found
                  </div>
                  <div
                    style={{
                      height: '12.5%',
                      minHeight: '60px',
                    }}
                  >
                    <div style={{ width: '200px' }}>
                      <SearchBar
                        onChange={(e) => {
                          const filteredData = data.filter((item, i) => {
                            return item.country
                              .toLowerCase()
                              .startsWith(e.target.value.toLowerCase());
                          });
                          if (filteredData.length > 0) {
                            setDisplayedData(filteredData);
                          } else {
                            setDisplayedData(undefined);
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '5px',
                      }}
                    >
                      {groupedData && (
                        <Select
                          name='discovery-page-country-select'
                          defaultOption='Country'
                          wrapperClassName='discovery-select-bar-wrapper'
                          className='discovery-select-bar'
                          onChange={(e) => {
                            const filteredData = data.filter((item, i) => {
                              return item.country === e.target.value;
                            });
                            if (filteredData.length > 0) {
                              setDisplayedData(filteredData);
                            } else {
                              setDisplayedData(undefined);
                            }
                          }}
                          options={countryList}
                        />
                      )}
                      {/* <Select
                        wrapperClassName='discovery-select-bar-wrapper'
                        className='discovery-select-bar'
                      /> */}
                    </div>
                  </div>
                  <div className='discovery-results-container'>
                    {Object.keys(groupedData).map((country, i) => {
                      return (
                        <div key={i}>
                          <div
                            className={
                              activeCountry === i
                                ? 'mb-3 discovery-list-item-wrapper discovery-list-item-wrapper-active'
                                : 'mb-3 discovery-list-item-wrapper'
                            }
                            key={i}
                            onClick={() => {
                              setCurrentCountry(country);
                              setActiveCountry(i);
                            }}
                          >
                            {i + 1}.
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginLeft: '5px',
                              }}
                            >
                              <div>{country}</div>
                              <div>{activeCountry === i ? '-' : '+'}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
            />
          </div>

          <div className='discovery-available-cameras-by-city-wrapper'>
            <Card
              className='discovery-available-cameras-by-city-card-container'
              adjustCss='discovery-available-cameras-by-city-card-container'
              paddingClassName='discovery-available-cameras-by-city-crad-padding'
              content={
                displayedCities && (
                  <div style={{ padding: '10px' }}>
                    <div>{displayedCities}</div>
                  </div>
                )
              }
            />
          </div>
        </div>

        {currentIP && (
          <div className='col-xl-10 mb-3 discovery-current-ip-wrapper'>
            <div style={{ height: '25%', paddingTop: '10px' }}>
              <div className='discovery-current-ip-camera-name-tags-wrapper'>
                <div>
                  {currentIP.camera_name
                    ? currentIP.camera_name
                    : 'Unknown Camera'}
                </div>
                <div>
                  {currentIP.tags &&
                    currentIP.tags.length > 0 &&
                    currentIP.tags.map((tag, i) => {
                      return (
                        <div
                          className='actor-displayed-data-tag-filled-bg'
                          key={i}
                        >
                          {tag}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='discovery-current-ip-details-wrapper'>
                <div className='discovery-current-ip-details-container'>
                  <div>
                    Manufacturer:{' '}
                    {currentIP.manufacturer ? currentIP.manufacturer : 'N/A'}
                  </div>
                  <div>Model: {currentIP.model ? currentIP.model : 'N/A'}</div>
                  <div>
                    {currentIP.ip_address.includes('www')
                      ? 'Domain: '
                      : 'Camera IP: '}
                    {'  '}
                    {currentIP.ip_address
                      ? currentIP.ip_address.includes('http')
                        ? currentIP.ip_address
                            .replaceAll('http://', '')
                            .replaceAll('https://', '')
                        : currentIP.ip_address
                      : 'N/A'}
                  </div>
                  <div>
                    Mac Address:{' '}
                    {currentIP.mac_address ? currentIP.mac_address : 'N/A'}
                  </div>
                </div>
                <div className='discovery-current-ip-details-container'>
                  <div>User: {currentIP.user ? currentIP.user : 'N/A'}</div>
                  <div>
                    Password:
                    {currentIP.password ? currentIP.password : 'N/A'}{' '}
                  </div>
                  <div>
                    Device Time:
                    {currentIP.device_time ? currentIP.device_time : 'N/A'}{' '}
                  </div>
                  <div>
                    Added Time:{' '}
                    {currentIP.added_time ? currentIP.added_time : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginBottom: '5px',
                paddingRight: '20px',
                height: '5%',
              }}
            >
              <img
                src={camera}
                alt=''
                style={{
                  width: '25px',
                  height: '25px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDisplayedCameras((prev) => {
                    return !prev;
                  });
                }}
              />
              {displayedCameras && (
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    float: 'right',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      float: 'right',
                      width: '100%',
                    }}
                  >
                    <Card
                      className='discovery-available-cameras-by-city-card-container'
                      adjustCss='discovery-available-cameras-by-city-card-container'
                      paddingClassName='discovery-available-cameras-by-city-crad-padding'
                      content={
                        displayedCities && (
                          <div style={{ padding: '10px' }}>
                            <div>{displayedCities}</div>
                          </div>
                        )
                      }
                    />
                  </div>
                </div>
              )}

              <img
                src={location}
                alt=''
                style={{
                  width: '25px',
                  height: '25px',
                  marginRight: '-25px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div style={{ height: '70%' }}>
              {currentIP.ip_address.startsWith('http') && (
                <iframe
                  id='iframe1'
                  title='ip'
                  // src={`https://www.google.com/search?q=%http://${currentIP.ip_address}&embedded=true`}
                  src={currentIP.ip_address}
                  width='100%'
                  height='100%'
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};
export default Discovery;
