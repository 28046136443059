const initialState = {
  isLoggedIn: false,
  isAdmin: false,
  userEmail: '',
  organization_id: '',
  user_id: '',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: false,
        userEmail: action.userEmail,
        organization_id: action.organization_id,
        name: action.name,
        loginTime: action.loginTime,
        user_id: action.user_id,
      };
    case 'ADMIN_LOGIN':
      return {
        ...state,
        isLoggedIn: true,
        userEmail: action.userEmail,
        name: action.name,
        isAdmin: action.isAdmin,
        loginTime: action.loginTime,
        organization_id: action.organization_id,
        user_id: action.user_id,
      };
    case 'LOGOUT':
      return (state = initialState);
    case 'TOP_MALWARE': {
      return {
        ...state,
        top_malware: action.top_malware,
      };
    }
    case 'THREAT_ACTOR_CRIMINAL': {
      return {
        ...state,
        criminal_threat_actor_data: action.criminal_threat_actor_data,
      };
    }
    case 'THREAT_ACTOR_ORGANIZED_CRIME': {
      return {
        ...state,
        organized_crime_threat_actor_data:
          action.organized_crime_threat_actor_data,
      };
    }

    case 'NAVBAR_RESULTS_EMAIL': {
      return {
        ...state,
        searchedEmail: action.searchedEmail,
        emailResults: action.emailResults,
      };
    }
    case 'NAVBAR_SEARCHED_IP': {
      return {
        ...state,
        searchedIP: action.searchedIP,
      };
    }
    case 'NAVBAR_SEARCHED_HASH': {
      return {
        ...state,
        searchedHash: action.searchedHash,
      };
    }
    case 'NAVBAR_SEARCHED_THREAT': {
      return {
        ...state,
        searched_threat: action.searched_threat,
      };
    }

    case 'NAVBAR_RESULTS_URL': {
      return {
        ...state,
        searchedURL: action.searchedURL,
        URLResults: action.URLResults,
      };
    }

    case 'NAVBAR_RESULTS_DOMAIN': {
      return {
        ...state,
        searchedDomain: action.searchedDomain,
        domainResults: action.domainResults,
      };
    }
    case 'NAVBAR_RESULTS_WALLET': {
      return {
        ...state,
        walletResults: action.walletResults,
      };
    }
    case 'EDIT_THREAT_ACTOR_MALWARE_TOOL': {
      return {
        ...state,
        threat_actor_malware_tool: action.threat_actor_malware_tool,
      };
    }
    case 'SET_THREAT_ACTOR_FIELD': {
      return {
        ...state,
        threat_actor_field: action.threat_actor_field,
      };
    }
    case 'SET_IS_READY':
      return {
        ...state,
        isReady: action.isReady,
      };
    case 'SET_CURRENCY':
      return {
        ...state,
        currency: action.currency,
      };
    case 'SET_IS_GOVERNMENT':
      return {
        ...state,
        isGovernment: action.isGovernment,
      };
    case 'SET_COMPANY_SIZE':
      return {
        ...state,
        companySize: action.companySize,
      };
    case 'SET_IS_INDUSTRY':
      return {
        ...state,
        isIndustry: action.isIndustry,
      };
    case 'SET_CONTRACT_DURATION':
      return {
        ...state,
        contractDuration: action.contractDuration,
      };
    case 'SET_IS_PHYSICAL_ENV':
      return {
        ...state,
        isPhysicalEnv: action.isPhysicalEnv,
      };
    case 'SET_IS_VIRTUAL_ENV':
      return {
        ...state,
        isVirtualEnv: action.isVirtualEnv,
      };
    case 'SET_IS_PLAYGROUND_ENV':
      return {
        ...state,
        isPlaygroundEnv: action.isPlaygroundEnv,
      };
    case 'SET_IS_WAR_ROOM':
      return {
        ...state,
        isWarRoom: action.isWarRoom,
      };
    case 'SET_IS_CCTV':
      return {
        ...state,
        isCCTV: action.isCCTV,
      };
    case 'SET_IS_FIREWALL':
      return {
        ...state,
        isFirewall: action.isFirewall,
      };
    case 'SET_ROUTER_SWITCH_NONE':
      return {
        ...state,
        router_switch_none: action.router_switch_none,
      };

    case 'SET_SIEM':
      return {
        ...state,
        siem: action.siem,
      };
    case 'SET_INCIDENT_MANAGEMENT_SYSTEM':
      return {
        ...state,
        incident_management_system: action.incident_management_system,
      };
    case 'SET_SOAR_PLATFORM':
      return {
        ...state,
        soarPlatform: action.soarPlatform,
      };
    case 'SET_THREAT_INTELLIGENCE_PLATFORM':
      return {
        ...state,
        threat_intelligence_platform: action.threat_intelligence_platform,
      };
    case 'SET_THREAT_HUNTING_PLATFORM':
      return {
        ...state,
        threat_hunting_platform: action.threat_hunting_platform,
      };
    case 'SET_DIGITAL_FORENSICS':
      return {
        ...state,
        digitalForensics: action.digitalForensics,
      };
    case 'SET_DATA_ENRICHMENT':
      return {
        ...state,
        dataEnrichment: action.dataEnrichment,
      };
    case 'SET_OFFICE_FACILLITIES_TOTAL':
      return {
        ...state,
        office_facillities_total: action.office_facillities_total,
      };
    case ' SET_HUMAN_RESOURCES_TOTAL':
      return {
        ...state,
        human_resources_total: action.human_resources_total,
      };
    case ' SET_SIEM_TOTAL':
      return {
        ...state,
        siem_total: action.siem_total,
      };
    case 'SET_DATA_ENRICHMENT_TOTAL':
      return {
        ...state,
        data_enrichment_total: action.data_enrichment_total,
      };
    case 'SET_CHOSEN_ORG_ID':
      return {
        ...state,
        chosen_org_id: action.chosen_org_id,
      };
    case 'CHECK_IS_PHISH':
      return {
        ...state,
        phish_data: action.phish_data,
      };
    case 'SET_CVEID_DATA':
      return {
        ...state,
        cveid_data: action.cveid_data,
      };

    default:
      return (state = initialState);
  }
};

export default appReducer;
