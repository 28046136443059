import { Link } from 'react-router-dom';

const SidebarSublink = (props) => {
  return (
    <Link className='sidebar-sublink' to={props.navigateTo}>
      {props.value}
    </Link>
  );
};
export default SidebarSublink;
