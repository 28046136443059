import { useSelector, useDispatch } from 'react-redux';
import { set_siem_total } from '../actions';

const Siem = () => {
  const dispatch = useDispatch();
  const isReady = useSelector((state) => state.appReducer.isReady);
  const currency = useSelector((state) => state.appReducer.currency);
  const siem = useSelector((state) => state.appReducer.siem);
  const companySize = useSelector((state) => state.appReducer.companySize);

  const calc_prices_by_currency = (price_in_usd) => {
    //💥LATER CONNECT TO CURRENCY API
    let cur = 1;

    if (currency === 'USD') {
      cur = 1;
    } else if (currency === 'EUR') {
      cur = 1 / 1.05;
    } else if (currency === 'NIS') {
      cur = 3.41;
    }
    return price_in_usd * cur;
  };

  const calc_siem_cost = (s, m, l) => {
    let num = 0;
    if (companySize === 'small') {
      return (num = s);
    } else if (companySize === 'medium') {
      return (num = m);
    } else if (companySize === 'large') {
      return (num = l);
    }
    return num;
  };

  let qradar_node_lisence = siem === 'Qradar' ? calc_siem_cost(0, 3, 5) : 0; //CHECK THE COST IN THE EXCEL
  let qradar_siem_lisence =
    siem === 'Qradar'
      ? calc_siem_cost(
          1000,
          1000 * qradar_node_lisence,
          1000 * qradar_node_lisence
        )
      : 0;
  let qradar_event_collector =
    siem === 'Qradar'
      ? calc_siem_cost(200, 200 * (qradar_node_lisence - 1), 200)
      : 0;
  let qradar_event_processor = siem === 'Qradar' ? 400 : 0;
  let qradar_console = siem === 'Qradar' ? calc_siem_cost(0, 0, 200) : 0;

  let qroc_lisence = siem === 'Qroc' ? calc_siem_cost(6000, 14000, 21000) : 0;
  let qroc_gateway_server =
    siem === 'Qroc' ? calc_siem_cost(2000, 2000 * 2, 2000 * 3) : 0;

  let sentinel_lisence =
    siem === 'Sentinel' ? calc_siem_cost(24000, 60000, 120000) : 0;
  let sentinel_gateway_server =
    siem === 'Sentinel' ? calc_siem_cost(2000, 2000 * 2, 2000 * 3) : 0;

  const total = calc_prices_by_currency(
    qradar_node_lisence +
      qradar_siem_lisence +
      qradar_event_collector +
      qradar_event_processor +
      qradar_console +
      qroc_lisence +
      qroc_gateway_server +
      sentinel_lisence +
      sentinel_gateway_server
  );

  // dispatch(set_siem_total(total));

  return (
    <div>
      {siem && (
        <>
          <h6>Siem and Lisence</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>platform</td>
                <td>components</td>
                <td>description</td>
              </tr>
              {siem === 'Qradar' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Qradar</td>
                  <td>
                    <div>
                      node lisence:{' '}
                      {calc_prices_by_currency(qradar_node_lisence)}
                    </div>
                    <div>
                      siem lisence:{' '}
                      {calc_prices_by_currency(qradar_siem_lisence)}
                    </div>
                    <div>
                      event collector:
                      {calc_prices_by_currency(qradar_event_collector)}
                    </div>
                    <div>
                      event processor:
                      {calc_prices_by_currency(qradar_event_processor)}
                    </div>
                    <div>
                      console: {calc_prices_by_currency(qradar_console)}
                    </div>
                  </td>
                  <td>description</td>
                </tr>
              )}
              {siem === 'Qroc' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Qroc</td>
                  <td>
                    <div>
                      qroc lisence: {calc_prices_by_currency(qroc_lisence)}
                    </div>
                    <div>
                      qroc gateway server:
                      {calc_prices_by_currency(qroc_gateway_server)}
                    </div>
                  </td>
                  <td>description</td>
                </tr>
              )}
              {siem === 'Sentinel' && (
                <>
                  <tr className='threat-indicator-of-compromise-tr'>
                    <td>Sentinel</td>
                    <td>
                      <div>
                        sentinel lisence:{' '}
                        {calc_prices_by_currency(sentinel_lisence)}
                      </div>
                      <div>
                        sentinel gateway server:
                        {calc_prices_by_currency(sentinel_gateway_server)}
                      </div>
                    </td>
                    <td>description</td>
                  </tr>{' '}
                </>
              )}
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td className='siem-total'>
                  {calc_prices_by_currency(
                    qradar_node_lisence +
                      qradar_siem_lisence +
                      qradar_event_collector +
                      qradar_event_processor +
                      qradar_console +
                      qroc_lisence +
                      qroc_gateway_server +
                      sentinel_lisence +
                      sentinel_gateway_server
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default Siem;
