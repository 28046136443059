import { useState } from 'react';
import Table from './Table';
import { useSelector, useDispatch } from 'react-redux';
import DeleteAndEditButtons from './DeleteAndEditButtons';
import { navbarSearchedHash } from '../actions';
import { useNavigate } from 'react-router-dom';
import useGetRequest from '../hooks/use-get-request';
import { useEffect } from 'react';
import { dateFormat } from '../utils/dateFormat';

const ThreatActorIocCampaignsMalwaresTable = (props) => {
  const { isAdmin } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const { getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(
      'https://api.hackeye.io/api/v1/malware-threat-actors',
      true
    );
  }, []);

  const displayMalwares = () => {
    const filteredData = data.filter((value) => {
      return (
        value.threat_actors && value.threat_actors.includes(props.data._id)
      );
    });
    return (
      <Table
        actionBtns={true}
        receivedData={filteredData.map((value) => {
          return (
            <tr key={value._id} className='data-table-tr'>
              <td
                className='data-table-td data-table-td-special-color'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  sessionStorage.setItem(
                    'threat_repository_malware_id',
                    value._id
                  );
                  navigate(`/threat-repository/${value._id}`);
                }}
              >
                {value.malware_name}
              </td>
              <td className='data-table-td'>
                {value.type.length === 0
                  ? 'N/A'
                  : value.type.map((t) => {
                      if (t !== '') {
                        return t;
                      } else return 'N/A';
                    })}
              </td>
              <td className='data-table-td'>
                {value.operation_system ? value.operation_system : 'N/A'}
              </td>
              <td className='data-table-td'>
                {value.mitre_attack ? value.mitre_attack : 'N/A'}
              </td>
              <td className='data-table-td'>
                {value.industry.length === 0
                  ? 'N/A'
                  : value.industry.map((ind) => {
                      if (ind !== '' && ind.toLowerCase() !== 'x') {
                        return ind;
                      } else return 'N/A';
                    })}
              </td>

              <td className='data-table-td'>
                {value.iocs.length === 0 ? 0 : value.iocs.length}
              </td>
              <td className='data-table-td'>
                {value.createdAt === ''
                  ? 'N/A'
                  : dateFormat(value.createdAt, '-')}
              </td>
              {isAdmin && (
                <td className='data-table-td-action-btns'>
                  {' '}
                  <DeleteAndEditButtons
                    id={value._id}
                    path={'malware-threat-actors'}
                    // afterDeleteFunc={() => {
                    //   getMalwares();
                    // }}

                    editFunc={() => {
                      sessionStorage.setItem('edit_malware_id', value._id);
                    }}
                    navigateToEdit='/edit-threat-repository-actor'
                  />
                </td>
              )}
            </tr>
          );
        })}
        tableHeaders={[
          ['Name / Signature', ''],
          ['Type', ''],
          ['Operation System', ''],
          ['Mitre Tactics', ''],
          ['Industry', ''],
          ['Number of IOCs', ''],
          ['Last Activity', ''],
        ]}
      />
    );
  };

  const displayCyberCampaign = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((item, i) => {
            return (
              <tr className='data-table-tr' key={i} id={item.id}>
                <td
                  style={{ cursor: 'pointer' }}
                  className='data-table-td data-table-td-wider data-table-td-special-color'
                  onClick={() => {
                    sessionStorage.setItem(
                      'threat_actor_cyber_campaign_id',
                      item._id
                    );
                    navigate(`/threat-actors-cyber-campaigns/${item._id}`);
                  }}
                >
                  {item.name}
                </td>
                <td className='data-table-td'>{item.field}</td>
                {item.feed ? (
                  <td className='data-table-td'>
                    <a href={item.feed} target='_blank' rel='noreferrer'>
                      view more
                    </a>
                  </td>
                ) : (
                  <td className='data-table-td'>N/A</td>
                )}
                {isAdmin && (
                  <td className='data-table-td-action-btns'>
                    {' '}
                    <DeleteAndEditButtons
                      id={item._id}
                      path='threat-actor-cyber-campaigns'
                      editFunc={() => {
                        sessionStorage.setItem(
                          'edit_threat_actor_cyber_campaign_id',
                          item._id
                        );
                      }}
                      navigateToEdit='/threat-actors-edit-cyber-campaign'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Name / Signature', 'data-table-td-wider'],
            ['Field', ''],
            ['Feed', ''],
          ]}
        />
      )
    );
  };

  const displayIoc = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((item, i) => {
            return (
              <tr className='data-table-tr' key={i} id={item.id}>
                <td
                  style={{ cursor: 'pointer' }}
                  className='data-table-td data-table-td-wider data-table-td-special-color'
                  onClick={() => {
                    if (item.ioc_type === 'IP') {
                      sessionStorage.setItem('searched_IP', item.ioc_value);
                      navigate('/ip-search-results');
                    } else if (item.ioc_type.toLowerCase().includes('hash')) {
                      dispatch(navbarSearchedHash(item.ioc_value));

                      sessionStorage.setItem('searched_hash', item.ioc_value);
                      navigate('/hash-search-results');
                    } else {
                      sessionStorage.setItem('ioc_id', item._id);
                      navigate(`/iocs/${item._id}`);
                    }
                  }}
                >
                  {item.ioc_value}
                </td>

                <td className='data-table-td'>
                  {item.ioc_type.replace('Hash -', '')}
                </td>
                <td className='data-table-td'>
                  {item.ioc_category ? item.ioc_category : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {item.target_industry ? item.target_industry : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {item.mitre_attack ? item.mitre_attack : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {item.threat_name ? item.threat_name : 'N/A'}
                </td>

                <td className='data-table-td'>
                  {item.last_update ? dateFormat(item.last_update, '-') : 'N/A'}
                </td>
                {isAdmin && (
                  <td className='data-table-td-action-btns'>
                    <DeleteAndEditButtons
                      id={item._id}
                      path='iocs'
                      editFunc={() => {
                        sessionStorage.setItem('edit_ioc_id', item._id);
                      }}
                      navigateToEdit='/ioc-list-edit'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Value', 'data-table-td-wider'],
            ['Type', ''],
            ['Category', ''],
            ['Target Industry', ''],
            ['Mitre Attack', ''],
            ['Threat Name', ''],
            ['Last Activity', ''],
          ]}
        />
      )
    );
  };

  return (
    <div className='data-table-wrapper'>
      <div className='bloc-tabs'>
        {props.tabs.map((tab, i) => {
          return (
            <button
              key={i}
              className={
                toggleState === i + 1
                  ? `tabs active-tabs ${props.tabClassName}`
                  : `tabs ${props.tabClassName}`
              }
              onClick={() => toggleTab(i + 1)}
            >
              {tab}
            </button>
          );
        })}
      </div>

      <div className='content-tabs mt-10'>
        <div
          className={toggleState === 1 ? 'content  active-content' : 'content'}
        >
          {props.data &&
            props.data.iocs &&
            props.data.iocs.length > 0 &&
            displayIoc(props.data.iocs)}
        </div>

        <div
          className={toggleState === 2 ? 'content  active-content' : 'content'}
        >
          {props.data &&
            props.data.threat_actor_cyber_campaigns &&
            displayCyberCampaign(props.data.threat_actor_cyber_campaigns)}
        </div>

        <div
          className={toggleState === 3 ? 'content  active-content' : 'content'}
        >
          {data && data.length > 0 && displayMalwares()}
        </div>
      </div>
    </div>
  );
};
export default ThreatActorIocCampaignsMalwaresTable;
