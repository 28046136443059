import CountryList from '../components/CountryList';
import usePostRequest from '../hooks/use-post-request';
import { useEffect, useState } from 'react';
import CreationFormMUI from '../components/CreationFormMUI';

const CreateLiveIPAdress = () => {
  const { postSuccess, postError, postFeedback, postLoading, postRequestFunc } =
    usePostRequest();
  const [country, setCountry] = useState('N/A');

  const createNewItem = async () => {
    const ip_address = document.getElementById('ip_address').value;
    const camera_name = document.getElementById('camera_name').value;
    const city = document.getElementById('city').value;
    const manufacturer = document.getElementById('manufacturer').value;
    const model = document.getElementById('model').value;
    const mac_address = document.getElementById('mac_address').value;
    const user = document.getElementById('user').value;
    const device_time = document.getElementById('device_time').value;
    const added_time = document.getElementById('added_time').value;
    const tags =
      document.getElementById('tags').value.trim() === ''
        ? []
        : document
            .getElementById('tags')
            .value.split(',')
            .map((t) => {
              return t.trim();
            });

    const newItemStr = JSON.stringify({
      ip_address,
      camera_name,
      city,
      manufacturer,
      model,
      country,
      mac_address,
      user,
      device_time: new Date(device_time),
      added_time: new Date(added_time),
      tags,
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/live-ip-addresses',
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Live IP Address'
      textInputs={[
        { label: 'IP Address', id: 'ip_address' },
        { label: 'Camera Name', id: 'camera_name' },
        { label: 'City', id: 'city' },
        { label: 'Manufacturer', id: 'manufacturer' },
        { label: 'Model', id: 'model' },
        { label: 'Mac Address', id: 'mac_address' },
        { label: 'user', id: 'user' },
        {
          label: 'device Time',
          id: 'device_time',
          type: 'date',
          defaultValue: null,
        },
        {
          label: 'added Time',
          id: 'added_time',
          type: 'date',
          defaultValue: null,
        },
        { label: 'Tags', id: 'tags' },
      ]}
      selectInputs={
        <CountryList
          id='country'
          header='Country'
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateLiveIPAdress;
