import FormSelectListStatic from './FormSelectListStatic';

const MalwareTypeList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      additionalData={props.additionalData}
      id='type'
      header='Type'
      options={[
        'Malware',
        'RAT',
        'Webshell',
        'Ransomware',
        'Coin Miner',
        'Banking Trojan',
        'Credential Stealer',
        'Worm',
        'Spyware',
        'Phishing Kit',
        'Loader',
        'Keylogger',
        'Trojan',
        'Rootkit',
        'Backdoor',
        'Info Stealer',
        'Exfiltration',
        'Downloader',
        'Scanner',
        'Bot',
        'Wiper',
        'Hacking Tool',
      ]}
    />
  );
};
export default MalwareTypeList;
