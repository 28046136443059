import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { useEffect } from 'react';
import useGetRequest from '../hooks/use-get-request';

const FormSelectListDynamic = (props) => {
  const [defaultValue, setDefaultValue] = useState('');
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  const getOptions = async () => {
    await getRequestFunc(`https://api.hackeye.io/api/v1/${props.path}`, true);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const handleChange = (event) => {
    setDefaultValue(event.target.value);
  };

  return (
    data && (
      <Box
        sx={{
          minWidth: 120,
          width: '50%',
          input: { color: '#FFFFFF' },
          label: {
            color: '#FFFFFF',
          },
          borderBottom: '1px solid #1976d2',
          paddingLeft: '24px',
          paddingTop: '24px',
        }}
      >
        <FormControl fullWidth variant='filled'>
          <InputLabel required>{props.header}</InputLabel>
          <Select
            id={props.id}
            value={defaultValue}
            onChange={(e) => {
              props.onChange && props.onChange(e);
              handleChange(e);
            }}
            sx={{
              color: 'white',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: 'white !important',
              },
              textAlign: 'left',
            }}
          >
            {data.map((opt, i) => {
              return (
                <MenuItem key={i} value={opt._id}>
                  {opt[props.optVal]}
                </MenuItem>
              );
            })}
          </Select>
          <div style={{ textAlign: 'left' }}>{props.additionalData}</div>
        </FormControl>
      </Box>
    )
  );
};
export default FormSelectListDynamic;
