import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Flags from '../../components/Flags';
import Card from '../../components/Card';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import { renderCenteredCardLabelValue } from '../../utils/renderCenteredCardLabelValue';

const ThreatLibraryCriminalAndCrime = (props) => {
  const token = sessionStorage.getItem('token');
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [actors, setActors] = useState(
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
  const [length, setLength] = useState(undefined);
  let fetchedActors = [];
  // const [fetchedOrgs, setFetchedOrgs] = useState();
  // const orgs = {};

  // const getThreatOrg = async (criminalID, orgID) => {
  //   try {
  //     await axios
  //       .get(`https://api.hackeye.io/api/v1/terror-threat-actors/${orgID}`, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         orgs[criminalID] = res.data.data.doc.name;
  //       })
  //       .catch(() => {
  //         orgs[criminalID] = 'x';
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setFetchedOrgs(orgs);
  // };

  const handleCurrentActor = (type, threatID) => {
    sessionStorage.setItem('threat_actor_type', type);
    sessionStorage.setItem('threatActorID', threatID);
    navigate(`/threat-actor/${threatID}`);
  };

  const getCriminalActors = async (adminOnly) => {
    fetchedActors = [];
    const url = adminOnly
      ? `https://api.hackeye.io/api/v1/${props.path}/${props.adminPath}`
      : `https://api.hackeye.io/api/v1/${props.path}`;
    try {
      await axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const results = res.data.data.doc;
          setLength(results.length);
          for (const threat of Object.values(results)) {
            fetchedActors.push(
              <div
                key={threat._id}
                style={{ flex: '1 0 21%', margin: '10px' }}
                onClick={() => {
                  handleCurrentActor(props.type, threat._id);
                }}
              >
                <Card
                  className='threat-library-threat-actor-info-card-wrapper'
                  content={
                    <div className='threat-library-threat-actor-info-card-container'>
                      {renderCenteredCardLabelValue('Name', threat.name)}
                      {props.type === 'criminal-threat-actor' &&
                        renderCenteredCardLabelValue(
                          'Organization',
                          threat.organization || 'N/A'
                        )}

                      {renderCenteredCardLabelValue('Status', threat.status)}

                      {renderCenteredCardLabelValue(
                        'Origin Country',
                        threat.country && threat.country.length > 0 ? (
                          <Flags
                            countries={threat.country}
                            withName={true}
                            restrictedLength={true}
                            max={4}
                            imgContainerClassName='flags-image-seperator'
                          />
                        ) : (
                          'N/A'
                        )
                      )}
                      {renderCenteredCardLabelValue('Sector', threat.sector)}
                      {renderCenteredCardLabelValue(
                        'Type',
                        threat.type || 'N/A'
                      )}
                      {renderCenteredCardLabelValue(
                        'Tags',
                        threat.tags && threat.tags.length > 0 ? (
                          threat.tags.map(
                            (tag, i) =>
                              i <= 3 && (
                                <div
                                  className='actor-displayed-data-tag-filled-bg'
                                  key={i}
                                >
                                  {tag}
                                </div>
                              )
                          )
                        ) : (
                          <div className='actor-displayed-data-tag-filled-bg'>
                            N/A
                          </div>
                        )
                      )}
                      {renderCenteredCardLabelValue(
                        'Description',
                        threat.description || 'N/A',
                        props.type === 'criminal-threat-actor' ? '47px' : '92px'
                      )}
                    </div>
                  }
                />
              </div>
            );
          }
          setActors(fetchedActors);
        })
        .catch((err) => {
          console.log('FAIL: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleAdminOnly = (e) => {
    if (e.target.checked) {
      getCriminalActors(true);
    } else {
      getCriminalActors(false);
    }
  };

  useEffect(() => {
    getCriminalActors(false);
  }, []);

  return (
    <>
      <div className='threat-library-admin-only-checkbox-wrapper'>
        {length && <label>{length} Results found</label>}
        {isAdmin && (
          <div style={{ marginLeft: 'auto' }}>
            <input
              type='checkbox'
              onChange={(e) => {
                handleAdminOnly(e);
              }}
            />
            <label>Admin Only</label>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{actors}</div>
    </>
  );
};
export default ThreatLibraryCriminalAndCrime;
