import Card from './Card';
import PaginatedTableWithMappedData from './PaginatedTableWithMappedData';
import { useEffect, useState } from 'react';
import './FiltersBarAndData.css';
import './Table.css';

const Table = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const [receivedData, setReceivedData] = useState();
  const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(
    props.itemsPerPage || 2
  );

  const filtersCard = document.querySelector(
    '.filters-bar-and-data-filters-card-wrapper'
  );
  const SearchResultsTopContent = document.querySelector(
    '.ip-search-results-card'
  );
  const threatActorsTableTopContent = document.querySelector(
    '.threat-actor-information'
  );

  useEffect(() => {
    if (threatActorsTableTopContent) {
      const topContentHeight = threatActorsTableTopContent.offsetHeight;
      const pageContentHeight = window.innerHeight;
      const navbarHeight =
        document.querySelector('.with-nav-navbar-wrapper').offsetHeight + 8;
      const tableHeight =
        pageContentHeight - navbarHeight - topContentHeight - 60; //60 is height of table headers
      // console.log(Math.floor(tableHeight / 36) - 7);
      setNumOfItemsPerPage(Math.floor(tableHeight / 36) - 7);
    }
  }, [threatActorsTableTopContent]);

  useEffect(() => {
    if (SearchResultsTopContent) {
      const topContentHeight = SearchResultsTopContent.offsetHeight;
      const pageContentHeight = window.innerHeight;
      const navbarHeight =
        document.querySelector('.with-nav-navbar-wrapper').offsetHeight + 8;
      const tableHeight =
        pageContentHeight - navbarHeight - topContentHeight - 60; //60 is height of table headers
      // console.log(Math.floor(tableHeight / 36) - 5);
      setNumOfItemsPerPage(Math.floor(tableHeight / 36) - 5);
    }
  }, [SearchResultsTopContent]);

  useEffect(() => {
    if (filtersCard) {
      const filtersCardHeight = filtersCard.offsetHeight;
      const pageContentHeight = window.innerHeight;
      const navbarHeight =
        document.querySelector('.with-nav-navbar-wrapper').offsetHeight + 8;
      const tableHeight = pageContentHeight - navbarHeight - filtersCardHeight;
      setNumOfItemsPerPage(Math.floor(tableHeight / 36) - 4);
    }
  }, [filtersCard]);

  useEffect(() => {
    if (props.receivedData) {
      setReceivedData(props.receivedData);
    }
  }, [props.receivedData]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div>
      {receivedData && receivedData.length > 0 ? (
        <Card
          content={
            <div style={{ padding: '10px' }}>
              <PaginatedTableWithMappedData
                itemsPerPage={numOfItemsPerPage}
                data={receivedData}
                tableHeaders={
                  <tr className='data-table-tr-headers'>
                    {props.tableHeaders.map((header, i) => {
                      return (
                        <td key={i} className={`data-table-td ${header[1]}`}>
                          {header[0]}
                        </td>
                      );
                    })}
                    {props.actionBtns && (
                      <td className='data-table-td-action-btns'></td>
                    )}
                  </tr>
                }
              />
            </div>
          }
        />
      ) : (
        <div>
          {props.tabs && (
            <div className='bloc-tabs' style={{ marginBottom: '10px' }}>
              {props.tabs.map((tab, i) => {
                return (
                  <button
                    key={i}
                    className={
                      toggleState === i + 1
                        ? `tabs active-tabs ${props.tabClassName}`
                        : `tabs ${props.tabClassName}`
                    }
                    onClick={() => toggleTab(i + 1)}
                  >
                    {tab}
                  </button>
                );
              })}
            </div>
          )}

          <Card
            content={
              <table>
                <tbody>
                  <tr>
                    <td style={{ padding: '10px' }}>N/A</td>
                  </tr>
                </tbody>
              </table>
            }
          />
        </div>
      )}
    </div>
  );
};
export default Table;
