import { useSelector, useDispatch } from 'react-redux';
import { set_data_enrichment_total } from '../actions';

const DataEnrichment = () => {
  const dispatch = useDispatch();
  const isReady = useSelector((state) => state.appReducer.isReady);
  const currency = useSelector((state) => state.appReducer.currency);
  const incident_management_system = useSelector(
    (state) => state.appReducer.incident_management_system
  );
  const companySize = useSelector((state) => state.appReducer.companySize);
  const contractDuration = useSelector(
    (state) => state.appReducer.contractDuration
  );
  const threat_intelligence_platform = useSelector(
    (state) => state.appReducer.threat_intelligence_platform
  );
  const threat_hunting_platform = useSelector(
    (state) => state.appReducer.threat_hunting_platform
  );
  const digitalForensics = useSelector(
    (state) => state.appReducer.digitalForensics
  );
  const dataEnrichment = useSelector(
    (state) => state.appReducer.dataEnrichment
  );
  const soarPlatform = useSelector((state) => state.appReducer.soarPlatform); //🍕

  const calc_prices_by_currency = (price_in_usd) => {
    //💥LATER CONNECT TO CURRENCY API
    let cur = 0;

    if (currency === 'USD') {
      cur = 1;
    } else if (currency === 'EUR') {
      cur = 1 / 1.05;
    } else if (currency === 'NIS') {
      cur = 3.41;
    }
    return price_in_usd * cur;
  };

  const calc_num_of_employees = (s, m, l) => {
    let num = 0;
    if (companySize === 'small') {
      num = s;
    } else if (companySize === 'medium') {
      num = m;
    } else if (companySize === 'large') {
      num = l;
    }
    return num;
  };

  const calc_contract_duration_in_months = () => {
    let duration = 0;
    if (contractDuration === '1') {
      duration = 12;
    } else if (contractDuration === '2') {
      duration = 24;
    } else if (contractDuration === '3') {
      duration = 36;
    }
    return duration;
  };

  //incident management system
  let service_now_usd =
    incident_management_system === 'Service Now'
      ? calc_num_of_employees(7, 15, 24) *
        calc_contract_duration_in_months() *
        30
      : 0; // price per USER per MONTH

  let jira_usd =
    incident_management_system === 'Jira'
      ? calc_num_of_employees(7, 15, 24) *
        calc_contract_duration_in_months() *
        15
      : 0; //price per USER per MONTH

  let hive_project_usd =
    incident_management_system === 'The Hive Project'
      ? contractDuration * 1100
      : 0; //price per YEAR

  //threat inteliggence platform
  let insights_usd =
    threat_intelligence_platform === 'Insights'
      ? calc_contract_duration_in_months() * 1100
      : 0; // price per MONTH

  let threat_connect_usd =
    threat_intelligence_platform === 'Threat Connect'
      ? calc_contract_duration_in_months() * 950
      : 0; //price per MONTH

  let threat_stop_usd =
    threat_intelligence_platform === 'Threat Stop'
      ? calc_contract_duration_in_months() *
        calc_num_of_employees(7, 15, 24) *
        80
      : 0; //price per USER per MONTH

  //threat hunting platform
  let elastic_search_usd =
    threat_hunting_platform === 'Elasticsearch'
      ? calc_contract_duration_in_months() * 500
      : 0; // price per MONTH

  let cellebrite_usd =
    digitalForensics === 'Cellebrite UFED'
      ? contractDuration * (10000 + 3000)
      : 0; // price per YEAR

  //data enrichment / external resources
  let virus_total_usd =
    dataEnrichment === 'VirusTotal' ? contractDuration * 15000 : 0; // price per YEAR

  let anyrun_usd =
    dataEnrichment === 'AnyRun' ? calc_contract_duration_in_months() * 250 : 0; //price per MONTH

  const total = calc_prices_by_currency(
    service_now_usd +
      jira_usd +
      hive_project_usd +
      insights_usd +
      threat_connect_usd +
      threat_stop_usd +
      elastic_search_usd +
      cellebrite_usd +
      virus_total_usd +
      anyrun_usd
  );

  // dispatch(set_data_enrichment_total(total));

  return (
    <div>
      {(incident_management_system ||
        threat_intelligence_platform ||
        threat_hunting_platform ||
        digitalForensics ||
        dataEnrichment) && (
        <>
          <h6>Data Enrichment Systems</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>service/platform name</td>
                <td>purpose</td>
                <td>lisence type</td>
                <td>payment method</td>
                <td>number of users (if relevant)</td>
                <td>hardware price</td>
                <td>price</td>
              </tr>
              {incident_management_system === 'Service Now' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Service Now</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per user per month</td>
                  <td>{calc_num_of_employees(7, 15, 24)}</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(service_now_usd)}</td>
                </tr>
              )}
              {incident_management_system === 'Jira' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Jira</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per user per month</td>
                  <td>{calc_num_of_employees(7, 15, 24)}</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(jira_usd)}</td>
                </tr>
              )}

              {incident_management_system === 'The Hive Project' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>The Hive Project</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per year</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(hive_project_usd)}</td>
                  <td>X</td>
                </tr>
              )}

              {threat_intelligence_platform === 'Insights' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Insights</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per month</td>
                  <td>X</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(insights_usd)}</td>
                </tr>
              )}

              {threat_intelligence_platform === 'Threat Connect' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Threat Connect</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per month</td>
                  <td>X</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(threat_connect_usd)}</td>
                </tr>
              )}

              {threat_intelligence_platform === 'Threat Stop' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Threat Stop</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per user per month</td>
                  <td>{calc_num_of_employees(7, 15, 24)}</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(threat_stop_usd)}</td>
                </tr>
              )}

              {threat_hunting_platform === 'Elasticsearch' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Elasticsearch</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per month</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(elastic_search_usd)}</td>
                  <td>X</td>
                </tr>
              )}

              {digitalForensics === 'Cellebrite UFED' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>Cellebrite UFED</td>
                  <td>purpose</td>
                  <td>device+lisence</td>
                  <td>X</td>
                  <td>X</td>
                  <td>device 10000$, lisence 3000$</td>
                  <td>{calc_prices_by_currency(cellebrite_usd)}</td>
                </tr>
              )}

              {dataEnrichment === 'VirusTotal' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>VirusTotal</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per year</td>
                  <td>{calc_num_of_employees(7, 15, 24)}</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(virus_total_usd)}</td>
                </tr>
              )}

              {dataEnrichment === 'AnyRun' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>AnyRun</td>
                  <td>purpose</td>
                  <td>lisence type</td>
                  <td>per month</td>
                  <td>{calc_num_of_employees(7, 15, 24)}</td>
                  <td>X</td>
                  <td>{calc_prices_by_currency(anyrun_usd)}</td>
                </tr>
              )}

              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td className='data-enrichment-total'>
                  {calc_prices_by_currency(
                    service_now_usd +
                      jira_usd +
                      hive_project_usd +
                      insights_usd +
                      threat_connect_usd +
                      threat_stop_usd +
                      elastic_search_usd +
                      cellebrite_usd +
                      virus_total_usd +
                      anyrun_usd
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default DataEnrichment;
