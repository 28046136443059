import { useSelector } from 'react-redux';
import Card from '../../components/Card';

import './SearchResults.css';
import { dateFormat } from '../../utils/dateFormat';

const PhishingResults = () => {
  const phish_data = useSelector((state) => state.appReducer.phish_data);

  return (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        <h3 className='search-results-num-of-results-header'>
          {phish_data ? 1 : 0} Results Found
        </h3>
        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>
      {phish_data && (
        <div className='col-xl-12'>
          <Card
            className='navbar-search-results-card'
            content={
              <div style={{ padding: '10px' }}>
                {phish_data ? (
                  <div className='search-results-result-tags-container'>
                    Verified
                  </div>
                ) : (
                  <div>
                    {' '}
                    <div className='search-results-result-tags-container'>
                      Not In Database
                    </div>
                  </div>
                )}

                <h6 className='search-results-searched-item'>
                  {phish_data.url}
                </h6>
                {phish_data.verified && (
                  <div>
                    Verified at: <h6>{dateFormat(phish_data.verified_at)}</h6>
                  </div>
                )}
                {phish_data.in_database[0] === 'true' &&
                  phish_data.verified[0] === 'true' && (
                    <div>
                      <div>
                        Status: <h6>Malicious</h6>
                      </div>
                      <div>
                        Description: <h6>Reported as phishing</h6>
                      </div>
                    </div>
                  )}
                <div className='search-results-result-btns-wrapper'>
                  <div className='search-results-result-btns-container'>
                    <button className='search-results-btn'>
                      Add to reports
                    </button>
                    <button className='search-results-btn'>create alert</button>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};
export default PhishingResults;
