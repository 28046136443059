import './Flags.css';

const Flags = (props) => {
  const returnedCountries = [];

  if (props.countries) {
    props.countries.length > 0 &&
      props.countries
        .filter((country) => {
          return country.trim() !== '' && country.toLowerCase().trim() !== 'x';
        })
        .map((country, i) => {
          if (country.trim() !== '' && country.toLowerCase().trim() !== 'x') {
            returnedCountries.push(
              <div
                key={i}
                className={`flags-countries-container ${props.imgContainerClassName}`}
              >
                <img
                  className={`flag-svg ${props.imgClassName}`}
                  src={require(`../../public/flags/${country}.svg`)}
                  alt=''
                />
                {props.displayOtherDataByFlag && (
                  <div
                    style={{ margin: 'auto' }}
                    className={props.displayOtherDataByFlagClassName}
                  >
                    {props.displayOtherDataByFlag}
                  </div>
                )}
                {props.withName && (
                  <div style={{ margin: 'auto' }}>{country}</div>
                )}
              </div>
            );
          }
        });

    if (props.restrictedLength) {
      if (props.countries.join('').replaceAll(' ', '').length > 23) {
        return returnedCountries.slice(0, 2);
      } else {
        return props.max
          ? returnedCountries.slice(0, props.max)
          : returnedCountries;
      }
    } else {
      return props.max
        ? returnedCountries.slice(0, props.max)
        : returnedCountries;
    }
  }
};
export default Flags;
