import CardWithLeftHeader from '../CardWithLeftHeader';
import { PieChart, Pie, Tooltip } from 'recharts';
import { useEffect } from 'react';
import { useState } from 'react';

const AntisemeticKeywordsDash = () => {
  const colors = ['#1DBAC5', '#0094AB', '#005F7D', '#003D5B', '#002E4A'];
  const pieData = [];
  const [pie, setPie] = useState();
  const keywordsData = [];
  const [keywords, setKeywords] = useState();

  const months = [
    { duration: '3-6 months', value: 48 },
    { duration: '6-9 months', value: 27 },
    { duration: '12+ months', value: 12 },
    { duration: '1-3 months', value: 12 },
  ];

  const setPieData = () => {
    months.forEach((month, i) => {
      pieData.push({
        name: month.duration,
        value: month.value,
        fill: colors[i],
      });

      keywordsData.push(
        <div
          className='small text-truncate antisemitism-dashboard-keywords-card-text'
          key={i}
        >
          <span
            style={{
              color: colors[i],
              fontWeight: '900',
              fontSize: '20px',
            }}
          >
            •
          </span>{' '}
          {month.duration}
          <br />
        </div>
      );
    });
    setPie(
      <PieChart width={150} height={150}>
        <Pie
          stroke='none'
          dataKey='value'
          isAnimationActive={true}
          data={pieData}
          outerRadius={'100%'}
          innerRadius={35}
          cx='50%'
          cy='50%'
        />
        <Tooltip />
      </PieChart>
    );

    setKeywords(keywordsData);
  };

  useEffect(() => {
    setPieData();
  }, []);

  return (
    <CardWithLeftHeader
      header='Antisemetic Keywords'
      css='antisemitism-dashboard-keywords-card-container'
      content={
        <div
          style={{
            display: 'flex',
          }}
        >
          <div>{pie}</div>
          <div className='antisemitism-dashboard-keywords-data-container'>
            {keywords}
          </div>
        </div>
      }
    />
  );
};
export default AntisemeticKeywordsDash;
