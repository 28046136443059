import Select from './Select';
import { useDispatch } from 'react-redux';
import {
  setSiem,
  set_incident_management_system,
  setSoarPlatform,
  set_threat_intelligence_platform,
  set_threat_hunting_platform,
  setDigitalForensics,
  setDataEnrichment,
} from '../actions';

const TechnologiesTable = () => {
  const dispatch = useDispatch();

  return (
    <div className='technologies-table'>
      <div>
        <Select
          label='SIEM'
          options={['Qroc', 'Qradar', 'Sentinel']}
          onChange={(e) => {
            dispatch(setSiem(e.target.value));
          }}
        />
        <Select
          onChange={(e) => {
            dispatch(set_incident_management_system(e.target.value));
          }}
          label='Incident Management System'
          options={['None', 'Service Now', 'The Hive Project', 'Jira']}
        />
        <Select
          onChange={(e) => {
            dispatch(setSoarPlatform(e.target.value));
          }}
          label='Soar Platform'
          options={['None', 'XSOAR', 'Resilient']}
        />
      </div>
      <div>
        <Select
          onChange={(e) => {
            dispatch(set_threat_intelligence_platform(e.target.value));
          }}
          label='Threat Intelligence Platform'
          options={['None', 'Insights', 'Threat Connect', 'Threat Stop']}
        />
        <Select
          onChange={(e) => {
            dispatch(set_threat_hunting_platform(e.target.value));
          }}
          label='Threat Hunting Platform'
          options={['None', 'Elasticsearch']}
        />
        <Select
          onChange={(e) => {
            dispatch(setDigitalForensics(e.target.value));
          }}
          label='Digital Forensics'
          options={['None', 'Cellebrite UFED']}
        />
        <Select
          onChange={(e) => {
            dispatch(setDataEnrichment(e.target.value));
          }}
          label='Data Enrichment'
          options={['None', 'VirusTotal', 'AnyRun']}
        />
      </div>
    </div>
  );
};
export default TechnologiesTable;
