import usePostRequest from '../../hooks/use-post-request';
import SeverityList from '../../components/SeverityList';
import { useEffect, useState } from 'react';
import CreationFormMUI from '../../components/CreationFormMUI';

const CreateEmergingThreat = () => {
  const { postError, postSuccess, postLoading, postRequestFunc, postFeedback } =
    usePostRequest();
  const [severity, setSeverity] = useState('N/A');

  const createNewItem = async () => {
    const threatname = document.getElementById('threatname').value;
    const date = document.getElementById('date').value;
    const cveid = document.getElementById('cveid').value;
    const impact = document.getElementById('impact').value;
    const cvssv3score = document.getElementById('cvssv3score').value;
    const affectedproducts = document.getElementById('affectedproducts').value;
    const summary = document.getElementById('summary').value;
    const solution = document.getElementById('solution').value;
    const link = document.getElementById('link').value;
    const targetedSoftware = document.getElementById('targetedSoftware').value;

    const newItemStr = JSON.stringify({
      threatname,
      date: new Date(date),
      cveid,
      impact,
      cvssv3score,
      affectedproducts,
      summary,
      solution,
      link,
      targetedSoftware,
      severity,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      `https://api.hackeye.io/api/v1/emerging-threats`,
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Emerging Threat'
      textInputs={[
        { label: 'Threat Name', id: 'threatname', max: '62' },
        { label: 'Date', id: 'date', type: 'date', defaultValue: null },
        { label: 'CVE ID', id: 'cveid' },
        { label: 'Impact', id: 'impact' },
        { label: 'Targeted Software', id: 'targetedSoftware' },
        { label: 'CVSSV3 score', id: 'cvssv3score' },
        { label: 'Affected Products', id: 'affectedproducts' },
        { label: 'Summary', id: 'summary' },
        { label: 'Solution', id: 'solution' },
        { label: 'Link', id: 'link' },
      ]}
      selectInputs={
        <SeverityList
          onChange={(e) => {
            setSeverity(e.target.value);
          }}
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateEmergingThreat;
