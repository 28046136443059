import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import generalUserImg from '../../assets/images/navbar/general-user-image.png';

const ThreatActorProfileImg = (props) => {
  const [threatImg, setThreatImg] = useState(undefined);
  const storage = getStorage();
  const threat_actor_type = sessionStorage.getItem('threat_actor_type');

  const getThreatImg = () => {
    getDownloadURL(
      ref(
        storage,
        `/${threat_actor_type.replaceAll('-', '_')}_images/${props.id}`
      )
    )
      .then((url) => {
        setThreatImg(url);
      })
      .catch(() => {
        setThreatImg(generalUserImg);
      });
  };

  useEffect(() => {
    getThreatImg();
  }, []);

  return (
    <img
      className='threat-actor-info-img'
      id='threat-actor-info-img'
      src={threatImg}
      alt=''
    />
  );
};
export default ThreatActorProfileImg;
