import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import ClipLoader from 'react-spinners/ClipLoader';
import useGetRequest from '../../hooks/use-get-request';
import Table from '../../components/Table';
import FiltersBarAndData from '../../components/FiltersBarAndData';
import { dateFormat } from '../../utils/dateFormat';

const ThreatHunting = () => {
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [filters, setFilters] = useState();
  const [originalData, setOriginalData] = useState();
  const { getError, getSuccess, getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/threat-hunting', true);
  }, []);

  useEffect(() => {
    getThreats();
  }, [data]);

  const getThreats = async () => {
    if (data && !getError && getSuccess) {
      const fetchedMalwares = data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setOriginalData(fetchedMalwares);

      const programs = [];
      const types = [];
      const years = [];

      data.map((item) => {
        if (
          item.program &&
          item.program.trim() !== '' &&
          item.program.toLowerCase() !== 'x'
        ) {
          if (
            !programs.some(
              (element) =>
                element.name.toLowerCase() === item.program.toLowerCase()
            )
          ) {
            programs.push({
              name: item.program,
              quantity: 1,
            });
          } else {
            const existing = programs.find(
              (element) =>
                element.name.toLowerCase() === item.program.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          item.type &&
          item.type.trim() !== '' &&
          item.type.toLowerCase() !== 'x'
        ) {
          if (
            !types.some(
              (element) =>
                element.name.toLowerCase() === item.type.toLowerCase()
            )
          ) {
            types.push({
              name: item.type,
              quantity: 1,
            });
          } else {
            const existing = types.find(
              (element) =>
                element.name.toLowerCase() === item.type.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (item.date) {
          if (item.date) {
            const year = new Date(item.date).getFullYear();
            const existingYear = years.find((item) => item.name === year);

            if (existingYear) {
              existingYear.quantity++;
            } else {
              years.push({ name: year, quantity: 1 });
            }
          }
        }
      });

      setFilters([
        {
          header: 'Programs',
          className: 'filters-bar-filters-250px-width',
          fieldValue: 'program',
          filters: programs
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Types',
          fieldValue: 'type',
          filters: types
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Years',
          fieldValue: 'date',
          filters: years
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((value, i) => {
            return (
              <tr key={value._id} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    sessionStorage.setItem('threat_hunting_item', value._id);
                    navigate('/threat-hunting-item');
                  }}
                >
                  {value.name.replaceAll('&lt;', '')}
                </td>

                <td className='data-table-td'>
                  {value.type ? value.type : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.program ? value.program : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.legitimate_path ? value.legitimate_path : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.description ? value.description : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.malware_id ? value.malware_id : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.tags && value.tags.length > 0
                    ? value.tags.join(',')
                    : 'N/A'}
                </td>
                <td className='data-table-td'>
                  {value.date ? dateFormat(value.date, '-') : 'N/A'}
                </td>

                {isAdmin && (
                  <td>
                    <DeleteAndEditButtons
                      id={value._id}
                      path={'threat-hunting'}
                      afterDeleteFunc={() => {
                        getThreats();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem(
                          'threat_hunting_item',
                          value._id
                        );
                      }}
                      navigateToEdit='/edit-threat-hunting-item'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Name', ''],
            ['Type', ''],
            ['Program', ''],
            ['Legitimate Path', ''],
            ['Description', ''],
            ['Threat Actor', ''],
            ['Tags', ''],
            ['Date', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getThreats();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default ThreatHunting;
