import axios from 'axios';
import { useState, useEffect } from 'react';
import { storage } from '../../dbs/initializeFirebase';
import usePostRequest from '../../hooks/use-post-request';
import CountryList from '../../components/CountryList';
import SeverityList from '../../components/SeverityList';
import CreationFormMUI from '../../components/CreationFormMUI';
import ThreatActorsSelect from '../../components/ThreatActorsSelect';
import MalwareTypeList from '../../components/MalwareTypeList';
import MalwareOperationSystemList from '../../components/MalwareOperationSystemList';
import ThreatIndustryList from '../../components/ThreatIndustryList';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';

const CreateThreatActor = () => {
  const token = sessionStorage.getItem('token');

  const { postFeedback, postRequestFunc, postSuccess, postError, postLoading } =
    usePostRequest();

  const [isTerror, setIsTerror] = useState(false);
  const [isCyber, setIsCyber] = useState(false);
  const [isMalware, setIsMalware] = useState(false);
  const [isCriminal, setIsCriminal] = useState(false);
  const [isOrganizedCrime, setIsOrganizedCrime] = useState(false);
  const [adminOnlyAccess, setAdminOnlyAccess] = useState(false);
  const [chosenOriginCountries, setChosenOriginCountries] = useState([]);
  const [chosenVictimCountries, setChosenVictimCountries] = useState([]);
  const [malwareTypes, setMalwareTypes] = useState([]);
  const [malwareThreatActors, setMalwareThreatActors] = useState([]);
  const [malwareIndustries, setMalwareIndustries] = useState([]);
  const [type, setType] = useState('N/A');
  const [orgID, setOrgID] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isImgUpdated, setIsImgUpdated] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState('');

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const handleUpload = async (threat_type, image) => {
    setIsImgUpdated(false);
    setLoading(true);
    const path = `/${threat_type}_threat_actor_images/${image}`;
    const ref = storage.ref(path);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    setURL(url);
    setLoading(false);
    setIsImgUpdated(true);
    console.log('image added successfully!');
  };

  const initializeFileds = () => {
    setChosenOriginCountries([]);
    setChosenVictimCountries([]);
    setMalwareTypes([]);
    setMalwareThreatActors([]);
    setMalwareIndustries([]);
    setAdminOnlyAccess(false);
  };

  const setThreatType = (e) => {
    initializeFileds();
    if (e.target.value === 'terror') {
      setIsCyber(false);
      setIsMalware(false);
      setIsCriminal(false);
      setIsOrganizedCrime(false);
      setIsTerror(true);
    } else if (e.target.value === 'cyber') {
      setIsTerror(false);
      setIsMalware(false);
      setIsCriminal(false);
      setIsOrganizedCrime(false);
      setIsCyber(true);
    } else if (e.target.value === 'malware') {
      setIsTerror(false);
      setIsCyber(false);
      setIsCriminal(false);
      setIsOrganizedCrime(false);
      setIsMalware(true);
    } else if (e.target.value === 'criminal') {
      setIsTerror(false);
      setIsCyber(false);
      setIsMalware(false);
      setIsOrganizedCrime(false);
      setIsCriminal(true);
    } else if (e.target.value === 'organized crime') {
      setIsTerror(false);
      setIsCyber(false);
      setIsMalware(false);
      setIsCriminal(false);
      setIsOrganizedCrime(true);
    }
  };

  const addImageToThreatActor = async (threat_type, name) => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/${threat_type}-threat-actors/${threat_type}-threat-actor/${name}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          const { id } = res.data.data.doc;
          await handleUpload(threat_type, id);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const createNewItem = async () => {
    if (isTerror) {
      // name, country,goal, imgURL, victims_industries,victim_country
      const name = document.getElementById('name').value;
      const description = document.getElementById('description').value;
      const goal = document.getElementById('goal').value;
      // const type = document.getElementById('type').value;
      const victims_industries =
        document.getElementById('victims_industries').value;

      const terrorActorStr = JSON.stringify({
        name,
        country: chosenOriginCountries,
        goal,
        description,
        victims_industries,
        victim_country: chosenVictimCountries,
        type,
        admin_only: adminOnlyAccess,
        createdAt: new Date(Date.now()),
        last_update: new Date(Date.now()),
      });

      await postRequestFunc(
        'https://api.hackeye.io/api/v1/terror-threat-actors',
        terrorActorStr,
        true
      );
      if (file) {
        await addImageToThreatActor('terror', name);
      }
    } else if (isCyber) {
      //name, country, campaigns, other_names, code_name, mitre_link, imgURL, victims_industries

      const name = document.getElementById('name').value;
      const campaigns = document.getElementById('campaigns').value;
      const other_names = document.getElementById('other_names').value;
      const mitre_link = document.getElementById('mitre_link').value;
      const code_name = document.getElementById('code_name').value;
      const description = document.getElementById('description').value;
      const victims_industries =
        document.getElementById('victims_industries').value;

      const cyberActorStr = JSON.stringify({
        name,
        admin_only: adminOnlyAccess,
        country: chosenOriginCountries,
        type,
        campaigns,
        other_names,
        code_name,
        description,
        mitre_link,
        victims_industries,
        victims: chosenVictimCountries,
        createdAt: new Date(Date.now()),
        last_update: new Date(Date.now()),
      });

      await postRequestFunc(
        'https://api.hackeye.io/api/v1/cyber-threat-actors',
        cyberActorStr,
        true
      );
      if (file) {
        await addImageToThreatActor('cyber', name);
      }
    } else if (isMalware) {
      //malware_name, threat_actors, ioc, hash, attack_vectors,detection_name,type, operation_system, industry
      const malware_name = document.getElementById('malware_name').value;
      const ioc = document.getElementById('ioc').value;
      const hash = document.getElementById('hash').value;
      const attack_vectors = document.getElementById('attack_vectors').value;
      const description = document.getElementById('description').value;
      const detection_name = document.getElementById('detection_name').value;
      const severity = document.getElementById('severity').value;
      const impact = document.getElementById('impact').value;
      const file_extention = document.getElementById('file_extention').value;
      const chainware = document.getElementById('chainware').value;
      const victomology = document.getElementById('victomology').value;
      const mitre_attack = document.getElementById('mitre_attack').value;
      const tags = document.getElementById('tags').value;
      const operation_system =
        document.getElementById('operation_system').value;
      const detections = document.getElementById('detections').value;
      const confidence_score =
        document.getElementById('confidence_score').value;
      const affected_platforms =
        document.getElementById('affected_platforms').value;
      const impacted_parties =
        document.getElementById('impacted_parties').value;

      const malwareStr = JSON.stringify({
        malware_name,
        threat_actors: malwareThreatActors,
        industry: malwareIndustries,
        type: malwareTypes,
        ioc,
        hash,
        description,
        attack_vectors,
        detection_name,
        operation_system,
        detections,
        severity,
        confidence_score,
        tags,
        affected_platforms,
        impacted_parties,
        impact,
        file_extention,
        chainware,
        victomology,
        mitre_attack,
        createdAt: new Date(Date.now()),
      });
      await postRequestFunc(
        'https://api.hackeye.io/api/v1/malware-threat-actors',
        malwareStr,
        true
      );
    } else if (isCriminal) {
      // name, org ,status, img, origin_country, sector, description
      const name = document.getElementById('name').value;
      const description = document.getElementById('description').value;
      const status = document.getElementById('status').value;
      const sector = document.getElementById('sector').value;

      const criminalStr = {
        name,
        org_id: orgID,
        status,
        country: chosenOriginCountries,
        type,
        sector,
        description,
        createdAt: new Date(Date.now()),
      };

      await postRequestFunc(
        'https://api.hackeye.io/api/v1/criminal-threat-actors',
        criminalStr,
        true
      );
      if (file) {
        await addImageToThreatActor('criminal', name);
      }
    } else if (isOrganizedCrime) {
      // name ,status, img, origin_country, sector, description
      const name = document.getElementById('name').value;
      const description = document.getElementById('description').value;
      const status = document.getElementById('status').value;
      const sector = document.getElementById('sector').value;

      const organizedCrimeActor = {
        name,
        status,
        country: chosenOriginCountries,
        type,
        sector,
        description,
        createdAt: new Date(Date.now()),
      };

      await postRequestFunc(
        'https://api.hackeye.io/api/v1/organized-crime-threat-actors',
        organizedCrimeActor,
        true
      );
      if (file) {
        await addImageToThreatActor('organized-crime', name);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <ThreatActorsSelect
          label='Threat type'
          withMalware={true}
          onChange={(e) => {
            setThreatType(e);
          }}
        />
      </div>

      <div className=''>
        {isCyber && (
          <CreationFormMUI
            header='Cyber Threat Actor'
            changeImageInput
            handleImageChange={(e) => {
              handleChange(e);
            }}
            textInputs={[
              { label: 'Name', id: 'name' },
              { label: 'Campaigns', id: 'campaigns' },
              { label: 'Other Names', id: 'other_names' },
              { label: 'Mitre Link', id: 'mitre_link' },
              { label: 'Code Name', id: 'code_name' },
              { label: 'Victim Industires', id: 'victims_industries' },
              { label: 'Description', id: 'description' },
            ]}
            selectInputs={
              <>
                <CountryList
                  id='country'
                  header='Origin Countries'
                  onChange={(e) => {
                    setChosenOriginCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <CountryList
                  id='victims'
                  header='Victims Countries'
                  onChange={(e) => {
                    setChosenVictimCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <FormSelectListStatic
                  id='type'
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  header='Type'
                  options={[
                    'Hacktivism',
                    'State Sponsored',
                    'Cyber-Crime',
                    'Gray Hat',
                    'Cyber-Vandalism',
                  ]}
                />
                <FormSelectListStatic
                  onChange={(e) => {
                    e.target.value === 'Admin Only'
                      ? setAdminOnlyAccess(true)
                      : setAdminOnlyAccess(false);
                  }}
                  id='access'
                  header='Access'
                  options={['Admin Only', 'Everyone']}
                />
              </>
            }
            onClick={() => {
              createNewItem();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}

        {isTerror && (
          <CreationFormMUI
            header='Terror Threat Actor'
            changeImageInput
            handleImageChange={(e) => {
              handleChange(e);
            }}
            textInputs={[
              { label: 'Name', id: 'name' },
              { label: 'Goal', id: 'goal' },
              { label: 'Description', id: 'description' },
              { label: 'Victim Industries', id: 'victims_industries' },
            ]}
            selectInputs={
              <>
                <CountryList
                  id='country'
                  header='Origin Countries'
                  onChange={(e) => {
                    setChosenOriginCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <CountryList
                  id='victims'
                  header='Victims Countries'
                  onChange={(e) => {
                    setChosenVictimCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <FormSelectListStatic
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  id='type'
                  header='Type'
                  options={[
                    'Hacktivism',
                    'State Sponsored',
                    'Cyber-Crime',
                    'Gray Hat',
                    'Cyber-Vandalism',
                  ]}
                />
                <FormSelectListStatic
                  onChange={(e) => {
                    e.target.value === 'Admin Only'
                      ? setAdminOnlyAccess(true)
                      : setAdminOnlyAccess(false);
                  }}
                  id='access'
                  header='Access'
                  options={['Admin Only', 'Everyone']}
                />
              </>
            }
            onClick={() => {
              createNewItem();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}
        {isMalware && (
          <CreationFormMUI
            header='Malware Threat Actor (Threat Repository)'
            textInputs={[
              { label: 'Malware Name', id: 'malware_name' },
              { label: 'Ioc', id: 'ioc' },
              { label: 'Hash', id: 'hash' },
              { label: 'Attack Vectors', id: 'attack_vectors' },
              { label: 'Affected Platforms', id: 'affected_platforms' },
              { label: 'Impacted Parties', id: 'impacted_parties' },
              { label: 'impact', id: 'impact' },
              { label: 'Detection Name', id: 'detection_name' },
              { label: 'File Extention', id: 'file_extention' },
              { label: 'Chainware', id: 'chainware' },
              { label: 'Victomology', id: 'victomology' },
              { label: 'Mitre Attack', id: 'mitre_attack' },
              { label: 'Detections', id: 'detections', type: 'number' },
              {
                label: 'Confidence Score',
                id: 'confidence_score',
                type: 'number',
              },
              { label: 'Description', id: 'description' },
              { label: 'Tags', id: 'tags' },
            ]}
            selectInputs={
              <>
                <SeverityList />
                <MalwareOperationSystemList />
                <MalwareTypeList
                  onChange={(e) => {
                    setMalwareTypes((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <FormSelectListDynamic
                  header='Cyber Actor'
                  path='cyber-threat-actors'
                  optVal='name'
                  id='cyber_threat_actor_id'
                  onChange={(e) => {
                    setMalwareThreatActors((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <ThreatIndustryList
                  onChange={(e) => {
                    setMalwareIndustries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
              </>
            }
            onClick={() => {
              createNewItem();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}
        {isCriminal && (
          <CreationFormMUI
            header='Criminal Threat Actor'
            changeImageInput
            handleImageChange={(e) => {
              handleChange(e);
            }}
            textInputs={[
              { label: 'Name', id: 'name' },
              { label: 'Status', id: 'status' },
              { label: 'Sector', id: 'sector' },
              { label: 'Description', id: 'description' },
            ]}
            selectInputs={
              <>
                <FormSelectListDynamic
                  header='Organization'
                  onChange={(e) => {
                    setOrgID(e.target.value);
                  }}
                  path='terror-threat-actors'
                  optVal='name'
                  id='org_id'
                />
                <CountryList
                  id='country'
                  header='Origin Countries'
                  onChange={(e) => {
                    setChosenOriginCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <FormSelectListStatic
                  id='type'
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  header='Type'
                  options={[
                    'Hacktivism',
                    'State Sponsored',
                    'Cyber-Crime',
                    'Gray Hat',
                    'Cyber-Vandalism',
                  ]}
                />
              </>
            }
            onClick={() => {
              createNewItem();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}

        {isOrganizedCrime && (
          <CreationFormMUI
            header='Organized Crime Threat Actor'
            changeImageInput
            handleImageChange={(e) => {
              handleChange(e);
            }}
            textInputs={[
              { label: 'Name', id: 'name' },
              { label: 'Status', id: 'status' },
              { label: 'Sector', id: 'sector' },
              { label: 'Description', id: 'description' },
            ]}
            selectInputs={
              <>
                <CountryList
                  id='country'
                  header='Origin Countries'
                  onChange={(e) => {
                    setChosenOriginCountries((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                />
                <FormSelectListStatic
                  id='type'
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  header='Type'
                  options={[
                    'Hacktivism',
                    'State Sponsored',
                    'Cyber-Crime',
                    'Gray Hat',
                    'Cyber-Vandalism',
                  ]}
                />
              </>
            }
            onClick={() => {
              createNewItem();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}
      </div>
    </div>
  );
};
export default CreateThreatActor;
