import CardWithLeftHeader from '../CardWithLeftHeader';
import { PieChart, Pie, Tooltip } from 'recharts';
import { useEffect } from 'react';
import { useState } from 'react';

const AntisemeticPlatformDash = () => {
  const colors = ['#1DBAC5', '#0094AB', '#005F7D', '#003D5B', '#002E4A'];
  const topPies = [];
  const bottomPies = [];

  const [readyTopPies, setReadyTopPies] = useState();
  const [readyBottomPies, setReadyBottomPies] = useState();

  const data = [
    { name: 'platform1', value: 75, fill: colors[0] },
    { name: 'platform2', value: 64, fill: colors[1] },
    { name: 'platform3', value: 58, fill: colors[2] },
    { name: 'platform4', value: 45, fill: colors[3] },
    { name: 'platform5', value: 43, fill: colors[4] },
    { name: 'platform6', value: 32, fill: colors[0] },
    { name: 'platform7', value: 21, fill: colors[1] },
    { name: 'platform8', value: 14, fill: colors[2] },
    { name: 'platform9', value: 12, fill: colors[3] },
    { name: 'platform10', value: 8, fill: colors[4] },
    { name: 'platform11', value: 8, fill: colors[0] },
    { name: 'platform12', value: 7, fill: colors[1] },
    { name: 'platform13', value: 7, fill: colors[2] },
    { name: 'platform14', value: 7, fill: colors[3] },
    { name: 'platform15', value: 7, fill: colors[4] },
    { name: 'platform16', value: 5, fill: colors[0] },
    { name: 'platform17', value: 5, fill: colors[1] },
    { name: 'platform18', value: 3, fill: colors[2] },
    { name: 'platform19', value: 3, fill: colors[3] },
    { name: 'platform20', value: 3, fill: colors[4] },
  ];

  const setPies = () => {
    data.forEach((d, i) => {
      if (i < 10) {
        topPies.push(
          <div key={data[i].name}>
            <PieChart width={81} height={81}>
              <Pie
                stroke='none'
                dataKey='value'
                isAnimationActive={true}
                data={[
                  {
                    name: d.name,
                    value: d.value,
                    fill: d.fill,
                  },
                ]}
                outerRadius={'100%'}
                innerRadius={33}
                cx='50%'
                cy='50%'
              />
              <Tooltip />
            </PieChart>
          </div>
        );
      } else {
        bottomPies.push(
          <div key={data[i].name}>
            <PieChart width={81} height={81}>
              <Pie
                stroke='none'
                dataKey='value'
                isAnimationActive={true}
                data={[
                  {
                    name: d.name,
                    value: d.value,
                    fill: d.fill,
                  },
                ]}
                outerRadius={'100%'}
                innerRadius={33}
                cx='50%'
                cy='50%'
              />
              <Tooltip />
            </PieChart>
          </div>
        );
      }
    });
    setReadyTopPies(topPies);
    setReadyBottomPies(bottomPies);
  };

  useEffect(() => {
    setPies();
  }, []);

  return (
    <CardWithLeftHeader
      header='Platform'
      css='antisemetic-dashboard-platform-card'
      content={
        <>
          <div style={{ display: 'flex' }}>{readyTopPies}</div>
          <div style={{ display: 'flex' }}>{readyBottomPies}</div>
        </>
      }
    />
  );
};
export default AntisemeticPlatformDash;
