import Select from './Select';
import { useDispatch } from 'react-redux';

import {
  setIsPhysicalEnv,
  setIsVirtualEnv,
  setIsPlaygroundEnv,
  setIsWarRoom,
  setIsCCTV,
  setIsFirewall,
  set_route_switch_none,
} from '../actions';

const EnvironmentTable = () => {
  const dispatch = useDispatch();

  return (
    <div className='environment-table'>
      <div>
        {' '}
        <Select
          label='Physical Environment'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsPhysicalEnv(e.target.value));
          }}
        />
        <Select
          label='Virtual Environment'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsVirtualEnv(e.target.value));
          }}
        />
        <Select
          label='Playground Environment'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsPlaygroundEnv(e.target.value));
          }}
        />
      </div>
      <div>
        <Select
          label='War Room'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsWarRoom(e.target.value));
          }}
        />
        <Select
          label='CCTV'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsCCTV(e.target.value));
          }}
        />
        <Select
          label='Firewall'
          options={['no', 'yes']}
          trueOrFalseSelect={true}
          onChange={(e) => {
            dispatch(setIsFirewall(e.target.value));
          }}
        />
        <Select
          onChange={(e) => {
            dispatch(set_route_switch_none(e.target.value));
          }}
          label='Router/Switch'
          options={['None', 'Router', 'Switch']}
        />
      </div>
    </div>
  );
};
export default EnvironmentTable;
