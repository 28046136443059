import './ThreatLibrary.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThreatActorField } from '../../actions';
import ThreatLibraryCybersAndTerrors from './ThreatLibraryCybersAndTerrors';
import ThreatLibraryCriminalAndCrime from './ThreatLibraryCriminalAndCrime';

const ThreatLibrary = () => {
  const dispatch = useDispatch();
  const { threat_actor_field } = useSelector((state) => state.appReducer);

  useEffect(() => {
    if (!threat_actor_field) {
      dispatch(setThreatActorField('CYBER_THREAT_ACTOR'));
      window.scrollTo(0, 0);
    }
  }, []);

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let mybutton = document.getElementById('threat-library-back-to-top-button');
    if (mybutton) {
      if (
        document.body.scrollTop > 600 ||
        document.documentElement.scrollTop > 600
      ) {
        mybutton.style.display = 'block';
      } else {
        mybutton.style.display = 'none';
      }
    }
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <div>
      <div className='bloc-tabs'>
        <button
          className={
            threat_actor_field === 'CYBER_THREAT_ACTOR'
              ? 'tabs active-tabs'
              : 'tabs'
          }
          onClick={() => {
            dispatch(setThreatActorField('CYBER_THREAT_ACTOR'));
          }}
        >
          Cyber Threat Actors
        </button>
        <button
          className={
            threat_actor_field === 'TERROR_THREAT_ACTOR'
              ? 'tabs active-tabs'
              : 'tabs'
          }
          onClick={() => {
            dispatch(setThreatActorField('TERROR_THREAT_ACTOR'));
          }}
        >
          Terrorist Organizations
        </button>

        <button
          className={
            threat_actor_field === 'ORGANIZED_CRIME_THREAT_ACTOR'
              ? 'tabs active-tabs'
              : 'tabs'
          }
          onClick={() => {
            dispatch(setThreatActorField('ORGANIZED_CRIME_THREAT_ACTOR'));
          }}
        >
          Organized Crime
        </button>
        <button
          className={
            threat_actor_field === 'CRIMINAL_THREAT_ACTOR'
              ? 'tabs active-tabs'
              : 'tabs'
          }
          onClick={() => {
            dispatch(setThreatActorField('CRIMINAL_THREAT_ACTOR'));
          }}
        >
          Criminals
        </button>

        <button
          id='threat-library-back-to-top-button'
          className='threat-library-back-to-top-button'
          onClick={() => {
            topFunction();
          }}
        >
          ↑
        </button>
      </div>

      <div className='threat-library-container'>
        <div className='content-tabs'>
          <div
            className={
              threat_actor_field === 'CYBER_THREAT_ACTOR'
                ? 'content  active-content'
                : 'content'
            }
          >
            <div className='row'>
              <ThreatLibraryCybersAndTerrors
                path='cyber-threat-actors'
                adminPath='all-cyber-actors'
                type='cyber-threat-actor'
              />
            </div>
          </div>

          <div
            className={
              threat_actor_field === 'TERROR_THREAT_ACTOR'
                ? 'content  active-content'
                : 'content'
            }
          >
            <div className='row'>
              {
                <ThreatLibraryCybersAndTerrors
                  path='terror-threat-actors'
                  adminPath='all-terror-actors'
                  type='terror-threat-actor'
                />
              }
            </div>
          </div>

          <div
            className={
              threat_actor_field === 'ORGANIZED_CRIME_THREAT_ACTOR'
                ? 'content  active-content'
                : 'content'
            }
          >
            <div className='row'>
              {
                <ThreatLibraryCriminalAndCrime
                  path='organized-crime-threat-actors'
                  adminPath='all-organized-crime-actors'
                  type='organized-crime-threat-actor'
                />
              }
            </div>
          </div>

          <div
            className={
              threat_actor_field === 'CRIMINAL_THREAT_ACTOR'
                ? 'content  active-content'
                : 'content'
            }
          >
            <div className='row'>
              {
                <ThreatLibraryCriminalAndCrime
                  path='criminal-threat-actors'
                  adminPath='all-criminal-actors'
                  type='criminal-threat-actor'
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThreatLibrary;
