import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import useGetRequest from '../hooks/use-get-request';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from '../components/Table';
import FiltersBarAndData from '../components/FiltersBarAndData';

const EventIdentifiers = () => {
  const { getError, getSuccess, getRequestFunc, data } = useGetRequest();
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [filters, setFilters] = useState();
  const [originalData, setOriginalData] = useState();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/event-identifiers', true);
  }, []);

  useEffect(() => {
    getEventIdentifiers();
  }, [data]);

  const getEventIdentifiers = async () => {
    if (data && !getError && getSuccess) {
      const fetchedItems = data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setOriginalData(fetchedItems);

      let types = [];
      const levels = [];

      data.map((mal) => {
        if (
          mal.type &&
          mal.type.trim() !== '' &&
          mal.type.toLowerCase() !== 'N/A'
        ) {
          if (
            !types.some(
              (item) => item.name.toLowerCase() === mal.type.toLowerCase()
            )
          ) {
            types.push({
              name: mal.type,
              quantity: 1,
            });
          } else {
            const existing = types.find(
              (element) => element.name.toLowerCase() === mal.type.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          mal.level &&
          mal.level.trim() !== '' &&
          mal.level.toLowerCase() !== 'x'
        ) {
          if (
            !levels.some(
              (item) => item.name.toLowerCase() === mal.level.toLowerCase()
            )
          ) {
            levels.push({
              name: mal.level,
              quantity: 1,
            });
          } else {
            const existing = levels.find(
              (element) =>
                element.name.toLowerCase() === mal.level.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
      });

      setFilters([
        {
          header: 'Type',
          fieldValue: 'type',
          filters: types
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Level',
          fieldValue: 'level',
          filters: levels
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (currentData) => {
    return (
      currentData &&
      currentData.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={currentData.map((value, i) => {
            return (
              <tr key={i} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    sessionStorage.setItem('event_identifier_id', value._id);
                    navigate(`/event-identifiers/${value._id}`);
                  }}
                >
                  {value.name}
                </td>
                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.type && value.type !== 'N/A' ? value.type : 'N/A'}
                  </span>
                </td>
                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.event_id && value.event_id !== 'N/A'
                      ? value.event_id
                      : 'N/A'}
                  </span>
                </td>

                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.description && value.description !== 'N/A'
                      ? value.description
                      : 'N/A'}
                  </span>
                </td>
                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.level && value.level !== 'N/A' ? value.level : 'N/A'}
                  </span>
                </td>
                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.product && value.product !== 'N/A'
                      ? value.product
                      : 'N/A'}
                  </span>
                </td>

                {isAdmin && (
                  <td className='data-table-td-action-btns'>
                    <DeleteAndEditButtons
                      id={value._id}
                      path={'event-identifiers'}
                      afterDeleteFunc={() => {
                        getEventIdentifiers();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem(
                          'edit_event_identifier_id',
                          value._id
                        );
                      }}
                      navigateToEdit='/edit-event-identifier'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Event Name', ''],
            ['Type', ''],
            ['Event ID', ''],
            ['Description', ''],
            ['Level', ''],
            ['Product', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getEventIdentifiers();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default EventIdentifiers;
