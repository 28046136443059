import FormSelectListStatic from '../../components/FormSelectListStatic';

const CrawlerLanguageList = (props) => {
  return (
    <FormSelectListStatic
      id='language'
      onChange={props.onChange}
      header='Lamguage'
      additionalData={props.additionalData}
      options={[
        'Language',
        'English',
        'Hebrew',
        'Spanish',
        'German',
        'Portuguese',
        'Russian',
        'Chinese',
        'Korean',
        'Arabic',
        'Mining',
        'Hindi',
      ]}
    />
  );
};
export default CrawlerLanguageList;
