import { useSelector, useDispatch } from 'react-redux';
import { set_office_facillities_total } from '../actions';

const OfficeFacilities = () => {
  const dispatch = useDispatch();
  const isReady = useSelector((state) => state.appReducer.isReady);
  const companySize = useSelector((state) => state.appReducer.companySize);

  const contractDuration = useSelector(
    (state) => state.appReducer.contractDuration
  );
  const currency = useSelector((state) => state.appReducer.currency);
  const isPhysicalEnv = useSelector((state) => state.appReducer.isPhysicalEnv);
  const isVirtualEnv = useSelector((state) => state.appReducer.isVirtualEnv);
  const isPlaygroundEnv = useSelector(
    (state) => state.appReducer.isPlaygroundEnv
  );
  const isWarRoom = useSelector((state) => state.appReducer.isWarRoom);
  const isCCTV = useSelector((state) => state.appReducer.isCCTV);
  const isFirewall = useSelector((state) => state.appReducer.isFirewall);
  const router_switch_none = useSelector(
    (state) => state.appReducer.router_switch_none
  );

  const calc_prices_by_currency = (price_in_price) => {
    //💥LATER CONNECT TO CURRENCY API
    let cur = 0;

    if (currency === 'USD') {
      cur = 1;
    } else if (currency === 'EUR') {
      cur = 1 / 1.05;
    } else if (currency === 'NIS') {
      cur = 3.41;
    }
    return price_in_price * cur;
  };

  const calc_amount = () => {
    let amount = 0;
    if (companySize === 'small') {
      amount = 7;
    } else if (companySize === 'medium') {
      amount = 15;
    } else if (companySize === 'large') {
      amount = 24;
    }
    return amount;
  };

  const calc_months = () => {
    let num = 0;
    //working days in a year is 260
    if (contractDuration === '1') {
      return (num = 12);
    } else if (contractDuration === '2') {
      return (num = 24);
    } else if (contractDuration === '3') {
      return (num = 36);
    }
    return num;
  };

  const calc_num_of_employees = (s, m, l) => {
    let num = 0;
    if (companySize === 'small') {
      return (num = s);
    } else if (companySize === 'medium') {
      return (num = m);
    } else if (companySize === 'large') {
      return (num = l);
    }
    return num;
  };

  //for equipment that changes by num of analysts
  const analyst_tier_1 = calc_num_of_employees(5, 10, 15);
  const analyst_tier_2 = calc_num_of_employees(1, 3, 5);
  const experts = calc_num_of_employees(1, 2, 4);

  //Mandatory equipment
  let table_price = calc_prices_by_currency(270); //amount = changes by company size
  let chair_price = calc_prices_by_currency(250); //amount = changes by company size
  let keyboard_mouse_price = calc_prices_by_currency(70); //amount = changes by company size
  let headset_price = calc_prices_by_currency(60); //amount = changes by company size

  let monitors_price =
    2 *
    (analyst_tier_1 + analyst_tier_2 + experts) *
    calc_prices_by_currency(200); //amount=2 per analyst
  let soc_wall_tv_price = calc_prices_by_currency(1100); //amount= always 1
  let tv_wall_adapter_price = calc_prices_by_currency(250); //amount= always 1
  let laser_printer_price = calc_prices_by_currency(600); //amount= always 1
  let internet_subscription_price = calc_prices_by_currency(50); //amount= always 1
  let vo_IP_price = calc_prices_by_currency(1000); //amount= always 1

  //Physical environment equipment
  let laptop_price = isPhysicalEnv ? calc_prices_by_currency(1100) : 0; //amount = changes by company size
  let docking_station_price = isPhysicalEnv ? calc_prices_by_currency(250) : 0; //amount = changes by company size

  //virtual environment equipment
  let thin_client_price = isVirtualEnv ? calc_prices_by_currency(600) : 0; //amount = changes by company size
  let azure_vdi_server_price = isVirtualEnv ? calc_prices_by_currency(55) : 0; //amount = changes by company size

  //Playground environment equipment
  let pc_price = isPlaygroundEnv ? calc_prices_by_currency(1150) : 0; //*2
  let cloud_user_price = isPlaygroundEnv ? calc_prices_by_currency(250) : 0; //*3
  //+another internet subscription

  //Security assets
  let firewall_device_price = isFirewall ? calc_prices_by_currency(3000) : 0;
  let firewall_license_price = isFirewall ? calc_prices_by_currency(21000) : 0;
  let switch_price =
    router_switch_none === 'Switch' ? calc_prices_by_currency(5200) : 0;
  let router_price =
    router_switch_none === 'Router' ? calc_prices_by_currency(1000) : 0;
  let CCTV_price = isCCTV ? calc_prices_by_currency(2000) : 0;

  //War room
  let war_table_price = isWarRoom ? calc_prices_by_currency(1500) : 0;
  let war_chair_price = isWarRoom ? calc_prices_by_currency(250) : 0; // *12
  let war_tv_price = isWarRoom ? calc_prices_by_currency(1100) : 0;
  let war_camera_price = isWarRoom ? calc_prices_by_currency(700) : 0;
  let war_speaker = isWarRoom ? calc_prices_by_currency(150) : 0;

  const set_router_switch_none = () => {
    let option = 0;
    if (router_switch_none === 'Router') {
      option = router_price;
    } else if (router_switch_none === 'Switch') {
      option = switch_price;
    } else {
      option = 0;
    }
    return option;
  };

  const router_switch_none_sum = set_router_switch_none();

  const total =
    calc_amount() *
      (table_price + chair_price + keyboard_mouse_price + headset_price) +
    monitors_price +
    soc_wall_tv_price +
    tv_wall_adapter_price +
    laser_printer_price +
    internet_subscription_price +
    vo_IP_price +
    calc_amount() * (docking_station_price + laptop_price) +
    calc_amount() * thin_client_price +
    calc_amount() * calc_months() * azure_vdi_server_price +
    2 * pc_price +
    3 * cloud_user_price +
    internet_subscription_price +
    CCTV_price +
    firewall_device_price +
    firewall_license_price +
    router_switch_none_sum +
    calc_prices_by_currency(
      war_table_price +
        war_chair_price * 12 +
        war_tv_price +
        war_camera_price +
        war_speaker
    );

  // dispatch(set_office_facillities_total(total));

  return (
    <div>
      <h5>Office Facilities: </h5>
      <h6>Mandatory</h6>
      <table className='threat-indicator-of-compromise-table'>
        <tbody>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>item</td>
            <td>price for one</td>
            <td>items (based on company size)</td>
            <td>calculated price</td>
            <td>description</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>tables</td>
            <td>{table_price}</td>
            <td>{calc_amount()}</td>
            <td>{calc_amount() * table_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>chairs</td>
            <td>{chair_price}</td>
            <td>{calc_amount()}</td>
            <td>{calc_amount() * chair_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>keyboard + mouse</td>
            <td>{keyboard_mouse_price}</td>
            <td>{calc_amount()}</td>
            <td>{calc_amount() * keyboard_mouse_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>headset</td>
            <td>{headset_price}</td>
            <td>{calc_amount()}</td>
            <td>{calc_amount() * headset_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>monitors</td>
            <td>{monitors_price}</td>
            <td>{2 * (analyst_tier_1 + analyst_tier_2 + experts)}</td>
            <td>
              {2 * (analyst_tier_1 + analyst_tier_2 + experts) * monitors_price}
            </td>
            <td>2 per Analyst</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>SOC Wall TV</td>
            <td>{soc_wall_tv_price}</td>
            <td>4</td>
            <td>{4 * soc_wall_tv_price}</td>
            <td>Minimum 4 TVs</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>TV Wall Adapter</td>
            <td>{tv_wall_adapter_price}</td>
            <td>1</td>
            <td>{tv_wall_adapter_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>Laser Printer</td>
            <td>{laser_printer_price}</td>
            <td>1</td>
            <td>{laser_printer_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>Internet Subscription</td>
            <td>{internet_subscription_price}</td>
            <td>1</td>
            <td>{internet_subscription_price}</td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>VoIP</td>
            <td>{vo_IP_price}</td>
            <td>1</td>
            <td>{contractDuration * vo_IP_price}</td>
            <td>cost per year</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>total</td>
            <td>
              {calc_amount() *
                (table_price +
                  chair_price +
                  keyboard_mouse_price +
                  headset_price) +
                2 *
                  (analyst_tier_1 + analyst_tier_2 + experts) *
                  monitors_price +
                soc_wall_tv_price +
                tv_wall_adapter_price +
                laser_printer_price +
                internet_subscription_price +
                contractDuration * vo_IP_price}
            </td>
          </tr>
        </tbody>
      </table>
      {isPhysicalEnv && (
        <>
          <h6>Physical Station</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>item</td>
                <td>price for one</td>
                <td>items (based on company size)</td>
                <td>calculated price</td>
                <td>description</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>laptop</td>
                <td>{laptop_price}</td>
                <td>{isPhysicalEnv ? calc_amount() : 0}</td>
                <td>{isPhysicalEnv ? calc_amount() * laptop_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>docking station</td>
                <td>{docking_station_price}</td>
                <td>{isPhysicalEnv ? calc_amount() : 0}</td>
                <td>
                  {isPhysicalEnv ? calc_amount() * docking_station_price : 0}
                </td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td>
                  {calc_amount() * (docking_station_price + laptop_price)}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {isVirtualEnv && (
        <>
          <h6>Virtual Station</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>item</td>
                <td>price for one</td>
                <td>items (based on company size)</td>
                <td>contract duration</td>

                <td>calculated price</td>
                <td>description</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>thin client</td>
                <td>{thin_client_price}</td>
                <td>{isVirtualEnv ? calc_amount() : 0}</td>
                <td>{contractDuration} years</td>
                <td>{isVirtualEnv ? calc_amount() * thin_client_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>azure vdi server</td>
                <td>{azure_vdi_server_price}</td>
                <td>{isVirtualEnv ? calc_amount() : 0}</td>
                <td>{contractDuration} years</td>
                <td>
                  {isVirtualEnv
                    ? calc_months() * calc_amount() * azure_vdi_server_price
                    : 0}
                </td>
                <td>per user per month</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td>
                  {calc_amount() * thin_client_price +
                    calc_amount() * calc_months() * azure_vdi_server_price}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {isPlaygroundEnv && (
        <>
          <h6>Playground Station</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>item</td>
                <td>price for one</td>
                <td>items</td>
                <td>calculated price</td>
                <td>description</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>pc</td>
                <td>{pc_price}</td>
                <td>{isPlaygroundEnv ? 2 : 0}</td>
                <td>{isPlaygroundEnv ? 2 * pc_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>cloud user</td>
                <td>{cloud_user_price}</td>
                <td>{isPlaygroundEnv ? 3 : 0}</td>
                <td>{isPlaygroundEnv ? 3 * cloud_user_price : 0}</td>
                <td>per month per instance</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>internet subscription</td>
                <td>{internet_subscription_price}</td>
                <td>{isPlaygroundEnv ? 1 : 0}</td>
                <td>{isPlaygroundEnv ? internet_subscription_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td>
                  {2 * pc_price +
                    3 * cloud_user_price +
                    internet_subscription_price}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {(isFirewall ||
        router_switch_none === 'Switch' ||
        router_switch_none === 'Router' ||
        isCCTV) && (
        <>
          <h6>SOC security assets</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>item</td>
                <td>price for one</td>
                <td>items</td>
                <td>calculated price</td>
                <td>description</td>
              </tr>
              {isFirewall && (
                <>
                  {' '}
                  <tr className='threat-indicator-of-compromise-tr'>
                    <td>firewall device</td>
                    <td>{firewall_device_price}</td>
                    <td>{isFirewall ? 1 : 0}</td>
                    <td>{isFirewall ? firewall_device_price : 0}</td>
                    <td></td>
                  </tr>
                  <tr className='threat-indicator-of-compromise-tr'>
                    <td>firewall license</td>
                    <td>{firewall_license_price}</td>
                    <td>{isFirewall ? 1 : 0}</td>
                    <td>{isFirewall ? firewall_license_price : 0}</td>
                    <td>for 3 years</td>
                  </tr>
                </>
              )}
              {router_switch_none === 'Switch' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>switch</td>
                  <td>{switch_price}</td>
                  <td>1</td>
                  <td>{switch_price}</td>
                  <td></td>
                </tr>
              )}

              {router_switch_none === 'Router' && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>router</td>
                  <td>{router_price}</td>
                  <td>1</td>
                  <td>{router_price}</td>
                  <td>for 3 years</td>
                </tr>
              )}
              {isCCTV && (
                <tr className='threat-indicator-of-compromise-tr'>
                  <td>cctv</td>
                  <td>{CCTV_price}</td>
                  <td>1</td>
                  <td>{CCTV_price}</td>
                  <td></td>
                </tr>
              )}
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td>
                  {CCTV_price +
                    firewall_device_price +
                    firewall_license_price +
                    router_switch_none_sum}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {isWarRoom && (
        <>
          <h6>War room</h6>
          <table className='threat-indicator-of-compromise-table'>
            <tbody>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>item</td>
                <td>price for one</td>
                <td>items</td>
                <td>calculated price</td>
                <td>description</td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>war room table</td>
                <td>{war_table_price}</td>
                <td>{isWarRoom ? 1 : 0}</td>
                <td>{isWarRoom ? war_table_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>war room chair</td>
                <td>{war_chair_price}</td>
                <td>{isWarRoom ? 12 : 0}</td>
                <td>{isWarRoom ? 12 * war_chair_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>war room tv</td>
                <td>{war_tv_price}</td>
                <td>{isWarRoom ? 1 : 0}</td>
                <td>{isWarRoom ? war_tv_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>war room camera</td>
                <td>{war_camera_price}</td>
                <td>{isWarRoom ? 1 : 0}</td>
                <td>{isWarRoom ? war_camera_price : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>war room speaker</td>
                <td>{war_speaker}</td>
                <td>{isWarRoom ? 1 : 0}</td>
                <td>{isWarRoom ? war_speaker : 0}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>total</td>
                <td className='office-facilities-total'>
                  {calc_prices_by_currency(
                    war_table_price +
                      war_chair_price * 12 +
                      war_tv_price +
                      war_camera_price +
                      war_speaker
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default OfficeFacilities;
