import { useState, useEffect } from 'react';
import usePostRequest from '../../hooks/use-post-request';
import CreationFormMUI from '../../components/CreationFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
import CountryList from '../../components/CountryList';

const CreateUser = () => {
  const { postSuccess, postFeedback, postError, postRequestFunc, postLoading } =
    usePostRequest();

  const [userOrg, setUserOrg] = useState(false);
  const [role, setRole] = useState('user');
  const [country, setCountry] = useState('N/A');
  const [orgID, setOrgID] = useState(null);

  const createNewItem = async () => {
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    const name = document.getElementById('name').value;
    const industry = document.getElementById('industry').value;
    const company = document.getElementById('company').value;
    const position = document.getElementById('position').value;

    const newUserDetails = {
      name,
      email,
      password,
      passwordConfirm,
      role,
      country,
      industry,
      company,
      position,
    };
    if (role === 'user-organization') {
      newUserDetails.organization_id = orgID;
    }
    await postRequestFunc(
      'https://api.hackeye.io/api/v1/users',
      JSON.stringify(newUserDetails),
      true
    );
  };

  useEffect(() => {
    if (role === 'user-organization') {
      setUserOrg(true);
    } else {
      setUserOrg(false);
    }
  }, [role]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='User'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Email', id: 'email' },
        { label: 'Password', id: 'password', type: 'password' },
        { label: 'Password Confirm', id: 'passwordConfirm', type: 'password' },
        { label: 'Industry', id: 'industry' },
        { label: 'Company', id: 'company' },
        { label: 'Position', id: 'position' },
      ]}
      selectInputs={
        <>
          <CountryList
            id='country'
            header='Country'
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          />
          <FormSelectListStatic
            onChange={(e) => {
              setRole(e.target.value);
            }}
            id='role'
            header='Role'
            options={['user', 'admin', 'user-organization']}
          />
          {userOrg && (
            <FormSelectListDynamic
              header='Organization'
              path='organizations'
              optVal='name'
              id='organization_id'
              onChange={(e) => {
                setOrgID(e.target.value);
              }}
            />
          )}
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateUser;
