import { useState, useEffect } from 'react';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table';
import { dateFormat } from '../../utils/dateFormat';

const ThreatHuntingItem = () => {
  const threat_hunting_item = sessionStorage.getItem('threat_hunting_item');
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();
  const [threat, setThreat] = useState();

  const getThreat = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/threat-hunting/${threat_hunting_item}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const fetchedThreat = res.data.data.doc;
          setThreat(fetchedThreat);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getThreat();
    window.scrollTo(0, 0);
  }, []);

  const handleThreatDeleted = () => {
    navigate('/threat-hunting');
    sessionStorage.removeItem('threat_hunting_item');
  };

  return (
    threat && (
      <div className='emerging-threat-wrapper'>
        <div className='emerging-threat-details-wrapper'>
          <div className='emerging-threat-details-container'>
            <div className='emerging-threat-detail'>
              <div>
                Name: {'  '}
                <span>{threat.name}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Type:{'  '} <span>{threat.type}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Program:{'  '} <span>{threat.program}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Legitimate Path:{'  '} <span>{threat.legitimate_path}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Threat Actor:{'  '} <span>{threat.malware_id}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Date:{'  '} <span>{dateFormat(threat.date)}</span>
              </div>
            </div>
            {threat.tags && (
              <div className='emerging-threat-detail'>
                <div>
                  Tags:{'  '}
                  <span>{threat.tags}</span>
                </div>
              </div>
            )}
            <div className='emerging-threat-detail'>
              <div>
                Description:{'  '} <span>{threat.description}</span>
              </div>
            </div>
          </div>
          <div>
            <DeleteAndEditButtons
              id={threat.id}
              path={'threat-hunting'}
              afterDeleteFunc={handleThreatDeleted}
              navigateToEdit='/edit-threat-hunting-item'
            />
          </div>
        </div>

        <div className='bloc-tabs'>
          <button className='tabs active-tabs table-single-tab'>
            Recommendations
          </button>
        </div>
        <Table receivedData={undefined} />
      </div>
    )
  );
};
export default ThreatHuntingItem;
