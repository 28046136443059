import { useEffect, useState } from 'react';
import axios from 'axios';
import CountryList from './CountryList';
import usePostRequest from '../hooks/use-post-request';
import DeleteAndEditButtons from './DeleteAndEditButtons';
import './AntisemeticWebsitesBlogsManagement.css';
import Flags from './Flags';
import CreationFormMUI from './CreationFormMUI';
import FormSelectListStatic from './FormSelectListStatic';
import Card from './Card';

const AntisemeticWebsitesBlogsManagement = () => {
  const token = sessionStorage.getItem('token');
  const [websites, setWebsites] = useState();
  const [country, setCountry] = useState('N/A');
  const [status, setStatus] = useState('N/A');

  const { postLoading, postFeedback, postRequestFunc, postError, postSuccess } =
    usePostRequest();

  const createAntisemeticWebsite = async () => {
    const website = document.getElementById('website');

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/antisemetic-websites',
      JSON.stringify({
        website,
        country,
        status,
      }),
      true
    );

    await getAllWebsites();
  };

  const getAllWebsites = async () => {
    const fetchedWebsites = [];

    try {
      await axios
        .get('https://api.hackeye.io/api/v1/antisemetic-websites', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          for (const web of res.data.data.doc) {
            fetchedWebsites.push(
              <div
                key={web._id}
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '200px', padding: '3px 0' }}>
                  {' '}
                  <Flags countries={[`${web.country}`]} withName={true} />
                </div>
                <div style={{ width: '200px', padding: '3px 0' }}>
                  {web.website}
                </div>
                <div style={{ width: '50px', padding: '3px 0' }}>
                  {web.status}
                </div>

                <DeleteAndEditButtons
                  id={web._id}
                  path='antisemetic-websites'
                  editFunc={() => {
                    sessionStorage.setItem(
                      'edit_antisemetic_website_id',
                      web._id
                    );
                  }}
                  navigateToEdit='/edit-antisemetic-website'
                />
              </div>
            );
          }
          setWebsites(fetchedWebsites);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllWebsites();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div style={{ marginRight: '10px' }}>
        <Card
          content={
            <div style={{ width: '600px', padding: '10px' }}>{websites}</div>
          }
        />
      </div>
      <CreationFormMUI
        header='Threat Hunting Item'
        textInputs={[{ label: 'Website', id: 'website' }]}
        selectInputs={
          <>
            <CountryList
              id='country'
              header='Source Country'
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            <FormSelectListStatic
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              id='status'
              header='Status'
              options={['Active', 'Down']}
            />
          </>
        }
        onClick={() => {
          createAntisemeticWebsite();
        }}
        postSuccess={postSuccess}
        postError={postError}
        postFeedback={postFeedback}
        postLoading={postLoading}
      />
    </div>
  );
};
export default AntisemeticWebsitesBlogsManagement;
