import { useEffect } from 'react';
import useGetRequest from '../../hooks/use-get-request';
import Card from '../../components/Card';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../utils/dateFormat';

const ThreatFeedPage = () => {
  const navigate = useNavigate();
  const { getRequestFunc, data } = useGetRequest();
  const threat_feed_id = sessionStorage.getItem('threat_feed_id');

  const getThreat = async () => {
    await getRequestFunc(
      `https://api.hackeye.io/api/v1/threat-feed/${threat_feed_id}`,
      true
    );
  };

  useEffect(() => {
    getThreat();
  }, []);

  return (
    data && (
      <Card
        content={
          <div className='threat-feed-rss-feed'>
            <div style={{ float: 'right' }}>
              <DeleteAndEditButtons
                id={threat_feed_id}
                path='threat-feed'
                afterDeleteFunc={() => {
                  navigate('/threat-feed');
                }}
                navigateToEdit={`/edit-threat-feed/${threat_feed_id}`}
              />
            </div>
            <div className='rss-feed-categories-pubdate-container'>
              <div className='rss-feeds-categories-container'>
                {data.industry &&
                  data.industry.map((ind, i) => {
                    return <span key={i}>•{ind} </span>;
                  })}
                {data.tags &&
                  data.tags.split(',').map((tag, i) => {
                    return <span key={i}>•{tag} </span>;
                  })}
              </div>
              <div className='rss-feed-pubdate'>{dateFormat(data.date)}</div>
            </div>
            <h6 className='rss-feed-title'>{data.title}</h6>
            <div className='rss-feed-description-container'>{data.content}</div>
          </div>
        }
      />
    )
  );
};
export default ThreatFeedPage;
