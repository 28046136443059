import usePostRequest from '../hooks/use-post-request';
import { useEffect, useState } from 'react';
import CreationFormMUI from '../components/CreationFormMUI';
import FormSelectListStatic from '../components/FormSelectListStatic';

const CyberCampaignManagement = () => {
  const { postSuccess, postFeedback, postRequestFunc, postError, postLoading } =
    usePostRequest();
  const [type, setType] = useState('N/A');

  const createNewItem = async () => {
    const name = document.getElementById('name').value;
    const date =
      document.getElementById('date').value === ''
        ? new Date(Date.now())
        : document.getElementById('date').value;
    const description = document.getElementById('description').value;

    const newCyberCampaigns = JSON.stringify({
      name,
      type,
      date,
      description,
    });

    await postRequestFunc(
      `https://api.hackeye.io/api/v1/cyber-campaigns-events`,
      newCyberCampaigns,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Cyber Campaign / Event'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Description', id: 'description' },
        { label: 'Date', id: 'date', type: 'date' },
      ]}
      selectInputs={
        <>
          <FormSelectListStatic
            onChange={(e) => {
              setType(e.target.value);
            }}
            id='type'
            header='Type'
            options={['Cyber Campaign', 'Cyber Event']}
          />
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CyberCampaignManagement;
