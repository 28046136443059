const FiltersSelect = (props) => {
  return (
    <div className='alerts-filters-select-container'>
      {props.header && (
        <div className='alerts-sort-by-header'>{props.header}</div>
      )}
      <select
        className='alerts-filters-select'
        onChange={(e) => {
          props.onChange(e);
        }}
      >
        {props.options.map((opt, i) => {
          return (
            <option className='alerts-filters-select-option' key={i}>
              {opt}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default FiltersSelect;
