import { useSelector, useDispatch } from 'react-redux';
import { set_human_resources_total } from '../actions';

const HumanResources = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.appReducer.currency);
  const companySize = useSelector((state) => state.appReducer.companySize);
  const isIndustry = useSelector((state) => state.appReducer.isIndustry);
  const soarPlatform = useSelector((state) => state.appReducer.soarPlatform);
  const siem = useSelector((state) => state.appReducer.siem);
  const contractDuration = useSelector(
    (state) => state.appReducer.contractDuration
  );

  //Mandatory human resources (SOC room)
  const calc_num_of_employees = (s, m, l) => {
    let num = 0;
    if (companySize === 'small') {
      return (num = s);
    } else if (companySize === 'medium') {
      return (num = m);
    } else if (companySize === 'large') {
      return (num = l);
    }
    return num;
  };

  const calc_hours = (s, m, l) => {
    let num = 0;
    if (companySize === 'small') {
      return (num = s);
    } else if (companySize === 'medium') {
      return (num = m);
    } else if (companySize === 'large') {
      return (num = l);
    }
    return num;
  };

  const calc_working_days_by_contract_duration = () => {
    let num = 0;
    //working days in a year is 260
    if (contractDuration === '1') {
      return (num += 260);
    } else if (contractDuration === '2') {
      return (num += 520);
    } else if (contractDuration === '3') {
      return (num += 780);
    }
    return num;
  };

  const calc_prices_by_currency = (price_in_usd) => {
    //💥LATER CONNECT TO CURRENCY API
    let cur = 0;

    if (currency === 'USD') {
      cur = 1;
    } else if (currency === 'EUR') {
      cur = 1 / 1.05;
    } else if (currency === 'NIS') {
      cur = 3.41;
    }
    return price_in_usd * cur;
  };

  const analyst_tier_1 = calc_num_of_employees(5, 10, 15);
  const analyst_tier_2 = calc_num_of_employees(1, 3, 5);
  const experts = calc_num_of_employees(1, 2, 4);
  const costumer_analysis = calc_hours(16, 40, 100);
  const siem_deployment = siem ? calc_hours(8, 16, 80) : 0;
  const siem_configuration = siem ? calc_hours(21, 40, 60) : 0;
  const soar_deployment = soarPlatform ? calc_hours(8, 24, 40) : 0;
  const soar_configuration = soarPlatform ? calc_hours(16, 45, 60) : 0;
  const elk_deployment = isIndustry ? calc_hours(16, 36, 80) : 0;
  //const support_system_config = ????

  const calc_rate = () => {
    let rate = 0;
    if (companySize === 'small') {
      return (rate = 250);
    } else if (companySize === 'medium' || companySize === 'large') {
      return (rate = 150);
    }
    return rate;
  };

  const total =
    calc_prices_by_currency(calc_rate()) *
      (experts + analyst_tier_1 + analyst_tier_2) +
    calc_prices_by_currency(250) *
      (costumer_analysis +
        siem_deployment +
        siem_configuration +
        soar_deployment +
        soar_configuration +
        elk_deployment);

  // dispatch(set_human_resources_total(total));

  return (
    <div>
      <h6>Human Resources for ongoing SOC service (in people)</h6>
      <table className='threat-indicator-of-compromise-table'>
        <tbody>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>position</td>
            <td>employees(based on company size)</td>
            <td>rate</td>
            <td>calculated price</td>
            <td>description</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>Analyst Tier 1</td>
            <td>{analyst_tier_1}</td>
            <td style={{ paddingLeft: '63px' }}>
              {calc_prices_by_currency(calc_rate())}
            </td>
            <td style={{ paddingLeft: '70px' }}>
              {analyst_tier_1 * calc_prices_by_currency(calc_rate())}
            </td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>Analyst Tier 2</td>
            <td>{analyst_tier_2}</td>
            <td style={{ paddingLeft: '63px' }}>
              {calc_prices_by_currency(calc_rate())}
            </td>
            <td style={{ paddingLeft: '70px' }}>
              {analyst_tier_2 * calc_prices_by_currency(calc_rate())}
            </td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>Experts</td>
            <td>{experts}</td>
            <td style={{ paddingLeft: '63px' }}>
              {calc_prices_by_currency(calc_rate())}
            </td>
            <td style={{ paddingLeft: '70px' }}>
              {experts * calc_prices_by_currency(calc_rate())}
            </td>
            <td></td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>total</td>
            <td className='human-resources-people-total'>
              {calc_prices_by_currency(calc_rate()) *
                (experts + analyst_tier_1 + analyst_tier_2)}
            </td>
          </tr>
        </tbody>
      </table>
      <h6>Human Resources for Build solution (Working Hours)</h6>
      <table className='threat-indicator-of-compromise-table'>
        <tbody>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>position</td>
            <td>working hours</td>
            <td>rate</td>
            <td>calculated price</td>
            <td>description</td>
          </tr>
          <tr className='threat-indicator-of-compromise-tr'>
            <td>costumer analysis</td>
            <td>{costumer_analysis}</td>
            <td>{calc_prices_by_currency(250)}</td>
            <td>{costumer_analysis * calc_prices_by_currency(250)}</td>
            <td></td>
          </tr>
          {siem && (
            <>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>siem deployment</td>
                <td>{siem_deployment}</td>
                <td>{calc_prices_by_currency(250)}</td>
                <td>{siem_deployment * calc_prices_by_currency(250)}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>siem configuration</td>
                <td>{siem_configuration}</td>
                <td>{calc_prices_by_currency(250)}</td>
                <td>{siem_configuration * calc_prices_by_currency(250)}</td>
                <td></td>
              </tr>
            </>
          )}
          {soarPlatform && (
            <>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>soar deployment</td>
                <td>{soar_deployment}</td>
                <td>{calc_prices_by_currency(250)}</td>
                <td>{soar_deployment * calc_prices_by_currency(250)}</td>
                <td></td>
              </tr>
              <tr className='threat-indicator-of-compromise-tr'>
                <td>soar configuration</td>
                <td>{soar_configuration}</td>
                <td>{calc_prices_by_currency(250)}</td>
                <td>{soar_configuration * calc_prices_by_currency(250)}</td>
                <td></td>
              </tr>
            </>
          )}
          {isIndustry && (
            <tr className='threat-indicator-of-compromise-tr'>
              <td>elk deployment</td>
              <td>{elk_deployment}</td>
              <td>{calc_prices_by_currency(250)}</td>
              <td>{elk_deployment * calc_prices_by_currency(250)}</td>
              <td></td>
            </tr>
          )}
          <tr className='threat-indicator-of-compromise-tr'>
            <td>total</td>
            <td className='human-resources-hours-total'>
              {calc_prices_by_currency(250) *
                (costumer_analysis +
                  siem_deployment +
                  siem_configuration +
                  soar_deployment +
                  soar_configuration +
                  elk_deployment)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default HumanResources;

// Suport system configuration 💥
