import useGetRequest from '../../hooks/use-get-request';
import { useEffect } from 'react';
import Card from '../../components/Card';
import ClipLoader from 'react-spinners/ClipLoader';

const ThreatActorMalwareTool = () => {
  const threat_actor_malware_tool_id = sessionStorage.getItem(
    'threat_actor_malware_tool_id'
  );
  const { getSuccess, getLoading, getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/threat-actor-malwares-tools/${threat_actor_malware_tool_id}`,
      true
    );
  }, []);

  const displayData = (data) => {
    return (
      <div style={{ height: 'auto', textAlign: 'left' }}>
        <Card
          content={
            <div style={{ padding: '10px' }}>
              {getSuccess ? (
                <div>
                  {data.name && (
                    <div>
                      <span>Name:</span>
                      <h6> {data.name}</h6>
                    </div>
                  )}
                  {data.feed && (
                    <div>
                      <span>Feed:</span>
                      <h6> {data.feed}</h6>
                    </div>
                  )}
                  {data.field && (
                    <div>
                      <span>Field:</span>
                      <h6> {data.field}</h6>
                    </div>
                  )}
                </div>
              ) : (
                <div>Error</div>
              )}
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div>
      {getSuccess ? (
        displayData(data)
      ) : (
        <ClipLoader loading={getLoading} size={35} className='cliploader' />
      )}
    </div>
  );
};
export default ThreatActorMalwareTool;
