import axios from 'axios';
import { useState } from 'react';

const usePatchRequest = () => {
  const token = sessionStorage.getItem('token');

  const [editLoading, setEditLoading] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editFeedback, setEditFeedback] = useState('');

  //INSERT A PARAMETER OF THE REDUX FUNCTION TO MAKE IT NULL after success
  const editRequestFunc = async (url, itemToUpdate, Authorization = false) => {
    setEditLoading(true);
    const headers = Authorization
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      : {
          'Content-Type': 'application/json',
        };
    try {
      await axios
        .patch(url, itemToUpdate, {
          headers,
        })
        .then(() => {
          setEditLoading(false);
          setEditSuccess(true);
          setEditError(false);
          setEditFeedback('operation finished successfully!');
          console.log('operation finished successfully!');
        })
        .catch((err) => {
          console.log('fail: ' + err.response.data.message);
          setEditLoading(false);
          setEditSuccess(false);
          setEditError(true);
          setEditFeedback('Something went wrong!');
        });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    editError,
    editSuccess,
    setEditSuccess,
    editLoading,
    setEditLoading,
    editRequestFunc,
    editFeedback,
  };
};

export default usePatchRequest;
