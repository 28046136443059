import FormSelectListStatic from './FormSelectListStatic';
const IOCTypeList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      id='ioc_type'
      header='Type'
      options={[
        'IP',
        'Domain',
        'Hash',
        'Email',
        'URL',
        'Hash - MD5',
        'Hash - SHA1',
        'Hash - SHA256',
      ]}
    />
  );
};
export default IOCTypeList;
