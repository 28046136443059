import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import Card from '../../components/Card';
import threatIcon from '../../assets/images/bomb-icon-threat-library.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import Flags from '../../components/Flags';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThreatActorProfileImg from '../../pages/threat library/ThreatActorProfileImg';
import { dateFormat } from '../../utils/dateFormat';

const ThreatActorsSearchResults = () => {
  const token = sessionStorage.getItem('token');
  const { searched_threat } = useSelector((state) => state.appReducer);

  const navigate = useNavigate();
  const searched_threat_name = sessionStorage.getItem('searched_threat_name');
  const threat_actor_type = sessionStorage
    .getItem('threat_actor_type')
    .replace('-threat-actor', '');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const fetchedActors = [];

  useEffect(() => {
    getThreatActors(threat_actor_type, searched_threat_name);
  }, [searched_threat]);

  const handleCurrentActor = (type, threatID) => {
    sessionStorage.setItem('threat_actor_type', `${type}-threat-actor`);
    sessionStorage.setItem('threatActorID', threatID);
    navigate(`/threat-actor/${threatID}`);
  };

  const getThreatActors = async (type, name) => {
    setLoading(<ClipLoader loading={true} size={35} className='cliploader' />);
    try {
      await axios
        .get(`https://api.hackeye.io/api/v1/${type}-threat-actors`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          const actors = res.data.data.doc.filter((actor) => {
            return (
              actor.name
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(name.toLowerCase().replaceAll(' ', '')) ||
              (actor.code_name &&
                actor.code_name
                  .toLowerCase()
                  .replaceAll(' ', '')
                  .includes(name.toLowerCase().replaceAll(' ', ''))) ||
              (actor.other_names &&
                actor.other_names
                  .toLowerCase()
                  .replaceAll(' ', '')
                  .includes(name.toLowerCase().replaceAll(' ', '')))
            );
          });
          for (const threat of actors) {
            fetchedActors.push(
              <div
                key={threat._id}
                className='col-xl-3 col-lg-6 d-flex'
                style={{
                  marginBottom: '20px',
                  width: 'auto',
                }}
                onClick={() => {
                  handleCurrentActor(type, threat._id);
                }}
              >
                <Card
                  className=' threat-library-threat-actor-info-card-wrapper'
                  content={
                    <div style={{ display: 'flex' }}>
                      <ThreatActorProfileImg id={threat._id} />
                      <div className='threat-library-threat-actor-info-card-container'>
                        <div className='threat-library-threat-icon-name-container'>
                          <img
                            src={threatIcon}
                            alt=''
                            className='threat-library-threat-icon'
                          />
                          <span className='flex-grow-1 threat-library-threat-name'>
                            {threat.name} {threat.admin_only && '(ADMIN ONLY)'}
                          </span>
                        </div>
                        <div>
                          <div>Origin Country</div>
                          <div className='threat-library-cyber-terror-actor-info-detail-flags-wrapper'>
                            <Flags
                              countries={threat.country}
                              withName={true}
                              restrictedLength={true}
                              max={4}
                              imgContainerClassName='flags-image-seperator'
                            />
                          </div>
                          <div>Top Target Countries</div>
                          <div className='threat-library-cyber-terror-actor-info-detail-flags-wrapper'>
                            {type === 'cyber' && (
                              <Flags
                                countries={threat.victims}
                                withName={true}
                                restrictedLength={true}
                                max={4}
                                imgContainerClassName='flags-image-seperator'
                              />
                            )}

                            {type === 'terror' && (
                              <Flags
                                countries={threat.victim_country}
                                withName={true}
                                restrictedLength={true}
                                max={4}
                                imgContainerClassName='flags-image-seperator'
                              />
                            )}
                          </div>
                          <div>Top Target Sectors</div>
                          <div className='threat-library-threat-actor-info-detail'>
                            {threat.victims_industries.split(',').map(
                              (industry, i) =>
                                i <= 3 && (
                                  <div
                                    className='actor-displayed-data-tag-filled-bg'
                                    key={i}
                                  >
                                    {industry}
                                  </div>
                                )
                            )}
                          </div>
                          <div>Type</div>
                          <div className='threat-library-threat-actor-info-detail'>
                            {threat.type || 'N/A'}
                          </div>
                          <div>Tags</div>
                          <div className='threat-library-threat-actor-info-detail'>
                            {threat.tags && threat.tags.length > 0 ? (
                              threat.tags.map(
                                (tag, i) =>
                                  i <= 3 && (
                                    <div
                                      className='actor-displayed-data-tag-filled-bg'
                                      key={i}
                                    >
                                      {tag}
                                    </div>
                                  )
                              )
                            ) : (
                              <div className='actor-displayed-data-tag-filled-bg'>
                                N/A
                              </div>
                            )}
                          </div>
                          <div>Last Activity</div>
                          <div className='threat-library-threat-actor-info-detail'>
                            {dateFormat(threat.last_update)}
                          </div>
                          <div>Description</div>
                          <div className='threat-library-threat-actor-description'>
                            {threat.description ||
                              'Lorem ipsum dolor sit amet consectetur. Sit commodo vestibulum eleifend ullamcorper condimentum id. Eu volutpat facilisis dolor  diam massa at. Iaculis rhoncus et consectetur sagittis sociis sed at ante. Vel hac neque ultrices amet accumsan nec mattis risus facilisis.'}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            );
          }
          if (fetchedActors.length > 0) {
            setData(fetchedActors);
          } else
            setData(
              <Card
                className='col-xl-12'
                content={
                  <div style={{ padding: '10px' }}>No Results Found</div>
                }
              />
            );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };
  return <div>{loading ? loading : data}</div>;
};
export default ThreatActorsSearchResults;
