import '../../pages/EditThreatActor.css';
import { useState, useEffect } from 'react';
import useGetRequest from './../../hooks/use-get-request';
import CountryList from './../../components/CountryList';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditCyberThreatActorForm = () => {
  const navigate = useNavigate();
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const threat_actor_type = sessionStorage.getItem('threat_actor_type');
  const threat_actor_id = sessionStorage.getItem('threatActorID');

  const [adminOnlyAccess, setAdminOnlyAccess] = useState(false);
  const [chosenOriginCountries, setChosenOriginCountries] = useState([]);
  const [chosenVictimCountries, setChosenVictimCountries] = useState([]);
  const [type, setType] = useState('N/A');
  const [file, setFile] = useState(null);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/${threat_actor_type}s/${threat_actor_id}`,
      true
    );
  }, []);

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const getEditedActor = async () => {
    const name = document.getElementById('name').value;
    const campaigns = document.getElementById('campaigns').value;
    const description = document.getElementById('description').value;
    const other_names = document.getElementById('other_names').value;
    const code_name = document.getElementById('code_name').value;
    const mitre_link = document.getElementById('mitre_link').value;
    const last_activity = document.getElementById('last_activity').value
      ? document.getElementById('last_activity').value
      : data.last_update;
    const victims_industries =
      document.getElementById('victims_industries').value;

    const editedActor = JSON.stringify({
      name,
      campaigns,
      other_names,
      code_name,
      mitre_link,
      type,
      victims_industries,
      description,
      admin_only: adminOnlyAccess,
      country:
        chosenOriginCountries.length > 0 ? chosenOriginCountries : data.country,
      victims:
        chosenVictimCountries.length > 0 ? chosenVictimCountries : data.victims,
      last_update: last_activity,
    });

    return editedActor;
  };

  return (
    data && (
      <EditFormMUI
        changeImageInput
        handleImageChange={(e) => {
          handleChange(e);
        }}
        header='Cyber Threat Actor'
        textInputs={[
          { id: 'name', label: 'name', defaultValue: data.name },
          { id: 'campaigns', label: 'campaigns', defaultValue: data.campaigns },
          {
            id: 'other_names',
            label: 'other_names',
            defaultValue: data.other_names,
          },
          {
            id: 'victims_industries',
            label: 'victims_industries',
            defaultValue: data.victims_industries,
          },
          { id: 'code_name', label: 'code_name', defaultValue: data.code_name },
          {
            id: 'mitre_link',
            label: 'mitre_link',
            defaultValue: data.mitre_link,
          },
          {
            id: 'last_activity',
            label: 'last_activity',
            defaultValue: data.last_activity
              ? data.last_activity.split('T')[0]
              : null,
            type: data,
          },
          {
            id: 'description',
            label: 'description',
            defaultValue: data.description,
          },
        ]}
        selectInputs={
          <>
            <CountryList
              id='country'
              header='Origin Countries'
              onChange={(e) => {
                setChosenOriginCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
            />
            <CountryList
              id='victims'
              header='Victims Countries'
              onChange={(e) => {
                setChosenVictimCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
            />
            <FormSelectListStatic
              id='type'
              onChange={(e) => {
                setType(e.target.value);
              }}
              header='Type'
              options={[
                'Hacktivism',
                'State Sponsored',
                'Cyber-Crime',
                'Gray Hat',
                'Cyber-Vandalism',
              ]}
            />
            <FormSelectListStatic
              onChange={(e) => {
                e.target.value === 'Admin Only'
                  ? setAdminOnlyAccess(true)
                  : setAdminOnlyAccess(false);
              }}
              id='access'
              header='Access'
              options={['Admin Only', 'Everyone']}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`threat-library/${data._id}`}
        afterDeleteFunc={() => {
          navigate(`/threat-library/${data._id}`);
        }}
      />
    )
  );
};
export default EditCyberThreatActorForm;
