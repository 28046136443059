const ThreatActorsSelect = (props) => {
  return (
    <div>
      <label>{props.label}</label>
      <div className='input-control'>
        <select
          name={props.name}
          className={props.className}
          onChange={props.onChange}
        >
          <option value='choose'>Choose</option>
          <option value='cyber'>Cyber</option>
          <option value='terror'>Terror</option>
          <option value='criminal'>Criminal</option>
          <option value='organized crime'>Organized crime</option>
          {props.withMalware && <option value='malware'>Malware</option>}
        </select>
      </div>
    </div>
  );
};
export default ThreatActorsSelect;
