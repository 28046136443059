import { useEffect, useState } from 'react';
import './ThreatRepository.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import useGetRequest from '../hooks/use-get-request';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from '../components/Table';
import FiltersBarAndData from '../components/FiltersBarAndData';
import { dateFormat } from '../utils/dateFormat';

const ThreatRepository = () => {
  const { getError, getSuccess, getRequestFunc, data } = useGetRequest();
  const { isAdmin } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [filters, setFilters] = useState();
  const [originalData, setOriginalMalwares] = useState();

  useEffect(() => {
    getRequestFunc(
      'https://api.hackeye.io/api/v1/malware-threat-actors',
      true
    );
  }, []);

  useEffect(() => {
    getMalwares();
  }, [data]);

  const getMalwares = async () => {
    if (data && !getError && getSuccess) {
      const fetchedMalwares = data.sort((a, b) => {
        if (a.malware_name < b.malware_name) {
          return -1;
        }
        if (a.malware_name > b.malware_name) {
          return 1;
        }
        return 0;
      });

      setOriginalMalwares(fetchedMalwares);

      let types = [];
      let industries = [];
      const operationSystems = [];
      const mitreAttacks = [];

      data.map((mal) => {
        mal.type &&
          mal.type.map((t) => {
            if (t.trim() !== '' && t.toLowerCase() !== 'x') {
              if (
                !types.some(
                  (item) => item.name.toLowerCase() === t.toLowerCase()
                )
              ) {
                types.push({ name: t, quantity: 1 });
              } else {
                const existing = types.find(
                  (element) => element.name.toLowerCase() === t.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        mal.industry &&
          mal.industry.map((ind) => {
            if (ind.trim() !== '' && ind.toLowerCase() !== 'x') {
              if (
                !industries.some(
                  (item) => item.name.toLowerCase() === ind.toLowerCase()
                )
              ) {
                industries.push({ name: ind, quantity: 1 });
              } else {
                const existing = industries.find(
                  (element) => element.name.toLowerCase() === ind.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        if (
          mal.operation_system &&
          mal.operation_system.trim() !== '' &&
          mal.operation_system.toLowerCase() !== 'x'
        ) {
          if (
            !operationSystems.some(
              (item) =>
                item.name.toLowerCase() === mal.operation_system.toLowerCase()
            )
          ) {
            operationSystems.push({
              name: mal.operation_system,
              quantity: 1,
            });
          } else {
            const existing = operationSystems.find(
              (element) =>
                element.name.toLowerCase() ===
                mal.operation_system.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        mal.mitre_attack &&
          mal.mitre_attack.map((m) => {
            if (m.trim() !== '' && m.toLowerCase() !== 'x') {
              if (
                !mitreAttacks.some(
                  (item) => item.name.toLowerCase() === m.toLowerCase()
                )
              ) {
                mitreAttacks.push({ name: m, quantity: 1 });
              } else {
                const existing = mitreAttacks.find(
                  (element) => element.name.toLowerCase() === m.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });
      });

      setFilters([
        {
          header: 'Type',
          fieldValue: 'type',
          filters: types
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Industry',
          fieldValue: 'industry',
          filters: industries
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Operation Systems',
          fieldValue: 'operation_system',
          filters: operationSystems
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Mitre Attacks',
          fieldValue: 'mitre_attack',
          filters: mitreAttacks
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (currentData) => {
    return (
      currentData &&
      currentData.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={currentData.map((value, i) => {
            return (
              <tr key={i} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    sessionStorage.setItem(
                      'threat_repository_malware_id',
                      value._id
                    );
                    navigate(`/threat-repository/${value._id}`);
                  }}
                >
                  {value.malware_name}
                </td>
                <td className='data-table-td'>
                  {value.type && value.type.length > 0 ? (
                    value.type.map((t, i) => {
                      if (t !== '' && t.toLowerCase() !== 'x') {
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            {t}
                          </span>
                        );
                      } else
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            N/A
                          </span>
                        );
                    })
                  ) : (
                    <span className='actor-displayed-data-tag-filled-bg'>
                      N/A
                    </span>
                  )}
                </td>

                <td className='data-table-td'>
                  <span className='actor-displayed-data-tag-filled-bg'>
                    {value.operation_system && value.operation_system !== 'x'
                      ? value.operation_system
                      : 'N/A'}
                  </span>
                </td>
                <td className='data-table-td'>
                  {value.mitre_attack && value.mitre_attack.length > 0 ? (
                    value.mitre_attack.map((m, i) => {
                      if (m !== '' && m.toLowerCase() !== 'x') {
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            {m}
                          </span>
                        );
                      } else
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            N/A
                          </span>
                        );
                    })
                  ) : (
                    <span className='actor-displayed-data-tag-filled-bg'>
                      N/A
                    </span>
                  )}
                </td>
                <td className='data-table-td'>
                  {value.industry && value.industry.length > 0 ? (
                    value.industry.map((ind, i) => {
                      if (ind !== '' && ind.toLowerCase() !== 'x') {
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            {ind}
                          </span>
                        );
                      } else
                        return (
                          <span
                            className='actor-displayed-data-tag-filled-bg'
                            key={i}
                          >
                            N/A
                          </span>
                        );
                    })
                  ) : (
                    <span className='actor-displayed-data-tag-filled-bg'>
                      N/A
                    </span>
                  )}
                </td>

                <td className='data-table-td'>
                  {value.iocs && value.iocs.length === 0
                    ? 0
                    : value.iocs.length}
                </td>
                <td className='data-table-td'>
                  {value.createdAt === ''
                    ? 'N/A'
                    : dateFormat(value.createdAt, '-')}
                </td>
                {isAdmin && (
                  <td className='data-table-td-action-btns'>
                    <DeleteAndEditButtons
                      id={value._id}
                      path='malware-threat-actors'
                      afterDeleteFunc={() => {
                        getMalwares();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem('edit_malware_id', value._id);
                      }}
                      navigateToEdit='/edit-threat-repository-actor'
                    />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Name / Signature', ''],
            ['Type', ''],
            ['Operation System', ''],
            ['Mitre Tactics', ''],
            ['Industry', ''],
            ['Number of IOCs', ''],
            ['Last Activity', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMalwares();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='malware_name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default ThreatRepository;
