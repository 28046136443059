import axios from 'axios';
import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';

const OrganizationList = () => {
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();

  const [orgs, setOrgs] = useState([]);
  const displayedOrgs = [];

  const getAllOrgs = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/organizations', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          for (const org of res.data.data.doc) {
            displayedOrgs.push(
              <div
                stye={{ padding: '10px' }}
                key={org._id}
                id={org._id}
                onClick={() => {
                  sessionStorage.setItem('edit_organization_id', org._id);
                  navigate(`/edit-organization/${org._id}`);
                }}
              >
                {org.name}
              </div>
            );
          }
          setOrgs(displayedOrgs);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllOrgs();
  }, []);

  return (
    <div>
      <Card content={orgs} />
    </div>
  );
};

export default OrganizationList;
