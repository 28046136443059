import { useEffect } from 'react';
import EditFormMUI from '../components/EditFormMUI';
import useGetRequest from '../hooks/use-get-request';

const EditThreatActorCyberCampaign = () => {
  const edit_threat_actor_cyber_campaign_id = sessionStorage.getItem(
    'edit_threat_actor_cyber_campaign_id'
  );
  const { getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/threat-actor-cyber-campaigns/${edit_threat_actor_cyber_campaign_id}`,
      true
    );
    window.scrollTo(0, 0);
  }, []);

  const getUpdatedCyberCampaign = async () => {
    const name = document.getElementById('name').value;
    const field = document.getElementById('field').value;
    const feed = document.getElementById('feed').value;

    const updatedCyberCampaignStr = JSON.stringify({
      name,
      field,
      feed,
    });
    return updatedCyberCampaignStr;
  };

  return (
    data && (
      <EditFormMUI
        header='Edit Threat Actor Cyber Campaign'
        inputs={[
          {
            label: 'Name',
            id: 'name',
            defaultValue: data.name,
          },
          {
            label: 'Feed',
            id: 'feed',
            defaultValue: data.feed,
          },
          {
            label: 'Field',
            id: 'field',
            defaultValue: data.field,
          },
        ]}
        itemToEdit={getUpdatedCyberCampaign}
        path={`threat-actor-cyber-campaigns/${edit_threat_actor_cyber_campaign_id}`}
      />
    )
  );
};
export default EditThreatActorCyberCampaign;
