import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from '../components/Table';
import FiltersBarAndData from '../components/FiltersBarAndData';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useSelector } from 'react-redux';

const SoftwareRepository = () => {
  const { getError, getSuccess, getRequestFunc, data } = useGetRequest();
  const { isAdmin } = useSelector((state) => state.appReducer);

  const [filters, setFilters] = useState();
  const [originalData, setOriginalMalwares] = useState();
  useEffect(() => {
    getRequestFunc(
      'https://api.hackeye.io/api/v1/malware-threat-actors',
      true
    );
  }, []);

  useEffect(() => {
    getMalwares();
  }, [data]);

  const getMalwares = async () => {
    if (data && !getError && getSuccess) {
      const fetchedMalwares = data.sort((a, b) => {
        if (a.malware_name < b.malware_name) {
          return -1;
        }
        if (a.malware_name > b.malware_name) {
          return 1;
        }
        return 0;
      });

      setOriginalMalwares(fetchedMalwares);

      let types = [];
      let industries = [];
      const operationSystems = [];
      const mitreAttacks = [];

      data.map((mal) => {
        mal.type &&
          mal.type.map((t) => {
            if (t.trim() !== '' && t.toLowerCase() !== 'x') {
              if (
                !types.some(
                  (item) => item.name.toLowerCase() === t.toLowerCase()
                )
              ) {
                types.push({ name: t, quantity: 1 });
              } else {
                const existing = types.find(
                  (element) => element.name.toLowerCase() === t.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        mal.industry &&
          mal.industry.map((ind) => {
            if (ind.trim() !== '' && ind.toLowerCase() !== 'x') {
              if (
                !industries.some(
                  (item) => item.name.toLowerCase() === ind.toLowerCase()
                )
              ) {
                industries.push({ name: ind, quantity: 1 });
              } else {
                const existing = industries.find(
                  (element) => element.name.toLowerCase() === ind.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        if (
          mal.operation_system &&
          mal.operation_system.trim() !== '' &&
          mal.operation_system.toLowerCase() !== 'x'
        ) {
          if (
            !operationSystems.some(
              (item) =>
                item.name.toLowerCase() === mal.operation_system.toLowerCase()
            )
          ) {
            operationSystems.push({
              name: mal.operation_system,
              quantity: 1,
            });
          } else {
            const existing = operationSystems.find(
              (element) =>
                element.name.toLowerCase() ===
                mal.operation_system.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          mal.mitre_attack &&
          !mal.mitre_attack.startsWith('https:') &&
          !mitreAttacks[mal.mitre_attack] &&
          mal.mitre_attack.toLowerCase() !== 'x' &&
          mal.mitre_attack.toLowerCase() !== 'n/a' &&
          mal.mitre_attack.trim() !== ''
        ) {
          if (
            !mitreAttacks.some(
              (item) =>
                item.name.toLowerCase() === mal.mitre_attack.toLowerCase()
            )
          ) {
            mitreAttacks.push({
              name: mal.mitre_attack,
              quantity: 1,
            });
          } else {
            const existing = mitreAttacks.find(
              (element) =>
                element.name.toLowerCase() === mal.mitre_attack.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
      });

      setFilters([
        {
          header: 'Type',
          fieldValue: 'type',
          filters: types
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Industry',
          fieldValue: 'industry',
          filters: industries
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Operation Systems',
          fieldValue: 'operation_system',
          filters: operationSystems
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Mitre Attacks',
          fieldValue: 'mitre_attack',
          filters: mitreAttacks
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const displayData = (data) => {
    return (
      data &&
      data.length > 0 && (
        <Table
          actionBtns={true}
          receivedData={data.map((value, i) => {
            return (
              <tr key={i} className='data-table-tr'>
                <td
                  className='data-table-td data-table-td-special-color'
                  style={{ cursor: 'pointer' }}
                >
                  N/A
                </td>
                <td className='data-table-td'>N/A</td>
                <td className='data-table-td'>N/A</td>
                <td className='data-table-td'>N/A</td>
                <td className='data-table-td'>N/A</td>
                <td className='data-table-td'>N/A</td>
                <td className='data-table-td'>N/A</td>
                {isAdmin && (
                  <td>
                    <DeleteAndEditButtons />
                  </td>
                )}
              </tr>
            );
          })}
          tableHeaders={[
            ['Name / Signature', ''],
            ['Type', ''],
            ['Operation System', ''],
            ['Mitre Tactics', ''],
            ['Industry', ''],
            ['Number of IOCs', ''],
            ['Last Activity', ''],
          ]}
        />
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMalwares();
  }, []);

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='malware_name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default SoftwareRepository;
