import CreationFormMUI from '../components/CreationFormMUI';
import usePostRequest from '../hooks/use-post-request';
import EventIdentifierTypeList from '../components/EventIdentifierTypeList';
import EventIdentifierLevelList from '../components/EventIdentifierLevelList';

const CreateEventIdentifier = () => {
  const { postFeedback, postRequestFunc, postSuccess, postError, postLoading } =
    usePostRequest();

  const createNewItem = async () => {
    const name = document.getElementById('name').value;
    const description = document.getElementById('description').value;
    const product = document.getElementById('product').value;
    const type = document.getElementById('type').value;
    const level = document.getElementById('level').value;
    const event_id = document.getElementById('event_id').value;

    const newItemStr = JSON.stringify({
      name,
      description,
      type,
      level,
      product,
      event_id,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/event-identifiers',
      newItemStr,
      true
    );
  };

  return (
    <CreationFormMUI
      header='Event Identifier'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Event ID', id: 'event_id' },
        { label: 'Description', id: 'description' },
        { label: 'Product', id: 'product' },
      ]}
      selectInputs={
        <>
          <EventIdentifierTypeList />
          <EventIdentifierLevelList />
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateEventIdentifier;
