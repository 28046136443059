export const filterData = (originalData, filteringField) => {
  const searchedValue = document
    .querySelector('.search-bar-input')
    .value.trim();

  if (searchedValue === '') {
    return originalData;
  } else if (searchedValue !== '') {
    const filteredData = originalData.filter((item, i) => {
      return item[filteringField]
        .toLowerCase()
        .startsWith(searchedValue.toLowerCase());
    });
    if (filteredData.length > 0) {
      return filteredData;
    } else {
      return null;
    }
  }
};
