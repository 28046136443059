import CreationFormMUI from '../components/CreationFormMUI';
// import ThreatActorsSelect from '../components/ThreatActorsSelect';
// import ThreatActorList from '../components/ThreatActorList';
import { useState } from 'react';
import usePostRequest from '../hooks/use-post-request';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import FormSelectListStatic from '../components/FormSelectListStatic';

const CreateCyberCampaignForThreatActor = () => {
  const { postSuccess, postLoading, postFeedback, postError, postRequestFunc } =
    usePostRequest();
  const [isCyber, setIsCyber] = useState(false);
  const [isTerror, setIsTerror] = useState(false);
  const [isCriminal, setIsCriminal] = useState(false);
  const [isOrganizedCrime, setIsOrganizedCrime] = useState(false);
  const [cyberActorID, setCyberActorID] = useState(null);
  const [terrorActorID, setTerrorActorID] = useState(null);
  const [criminalActorID, setCriminalActorID] = useState(null);
  const [organizedCrimeActorID, setOrganizedCrimeActorID] = useState(null);
  const [field, setField] = useState('');

  const setThreatType = (e) => {
    if (e.target.value === 'terror') {
      setIsCyber(false);
      setIsCriminal(false);
      setIsOrganizedCrime(false);
      setIsTerror(true);
    } else if (e.target.value === 'cyber') {
      setIsTerror(false);
      setIsCriminal(false);
      setIsOrganizedCrime(false);
      setIsCyber(true);
    } else if (e.target.value === 'criminal') {
      setIsTerror(false);
      setIsCyber(false);
      setIsOrganizedCrime(false);
      setIsCriminal(true);
    } else if (e.target.value === 'organized crime') {
      setIsTerror(false);
      setIsCyber(false);
      setIsCriminal(false);
      setIsOrganizedCrime(true);
    }
    setField(e.target.value);
  };

  const createNewItem = async () => {
    const name = document.getElementById('name').value;
    const feed = document.getElementById('feed').value;

    const cyberCampaignStr = {
      name,
      field,
      feed,
      createdAt: new Date(Date.now()),
    };

    if (isCyber) {
      cyberCampaignStr.cyber_threat_actor_id = cyberActorID;
    } else if (isTerror) {
      cyberCampaignStr.terror_threat_actor_id = terrorActorID;
    } else if (isCriminal) {
      cyberCampaignStr.criminal_threat_actor_id = criminalActorID;
    } else if (isOrganizedCrime) {
      cyberCampaignStr.organized_crime_threat_actor_id = organizedCrimeActorID;
    }
    await postRequestFunc(
      'https://api.hackeye.io/api/v1/threat-actor-cyber-campaigns',
      JSON.stringify(cyberCampaignStr),
      true
    );
  };

  return (
    <CreationFormMUI
      header='Threat Actor Cyber Campaign'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Feed', id: 'feed' },
      ]}
      selectInputs={
        <>
          <FormSelectListStatic
            onChange={(e) => {
              setThreatType(e);
            }}
            id='field'
            header='Field'
            options={['cyber', 'terror', 'criminal', 'organized crime']}
          />
          {isCyber && (
            <FormSelectListDynamic
              onChange={(e) => {
                setCyberActorID(e.target.value);
              }}
              header='Cyber Threat Actor ID'
              path='cyber-threat-actors'
              optVal='name'
              id='cyber_threat_actor_id'
            />
          )}
          {isTerror && (
            <FormSelectListDynamic
              onChange={(e) => {
                setTerrorActorID(e.target.value);
              }}
              header='Terror Threat Actor ID'
              path='terror-threat-actors'
              optVal='name'
              id='terror_threat_actor_id'
            />
          )}
          {isCriminal && (
            <FormSelectListDynamic
              onChange={(e) => {
                setCriminalActorID(e.target.value);
              }}
              header='Criminal Threat Actor ID'
              path='criminal-threat-actors'
              optVal='name'
              id='criminal_threat_actor_id'
            />
          )}
          {isOrganizedCrime && (
            <FormSelectListDynamic
              onChange={(e) => {
                setOrganizedCrimeActorID(e.target.value);
              }}
              header='Organized Crime Threat Actor ID'
              path='organized-crime-threat-actors'
              optVal='name'
              id='organized_crime_threat_actor_id'
            />
          )}
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateCyberCampaignForThreatActor;
