import FormSelectListStatic from './FormSelectListStatic';
const IOCCategoryList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      id='ioc_category'
      header='Category'
      options={[
        'Malicious File',
        'Malware',
        'Spam',
        'DDOS',
        'Hacking',
        'Exploit',
        'Ransomeware',
        'Phishing / Fraud',
        'Malicious URL',
        'Malicious Domain',
        'Potential Unwanted Program',
      ]}
    />
  );
};
export default IOCCategoryList;
