import { useEffect } from 'react';

const ErrorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Error</h1>
      <p>Something went wrong</p>
    </div>
  );
};
export default ErrorPage;
