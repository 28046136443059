import axios from 'axios';
import { useState, useEffect } from 'react';
import CountryList from '../../components/CountryList';
import useGetRequest from '../../hooks/use-get-request';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditCriminalThreatActorForm = () => {
  const threat_actor_type = sessionStorage.getItem('threat_actor_type');
  const threat_actor_id = sessionStorage.getItem('threatActorID');
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();
  const [chosenOriginCountries, setChosenOriginCountries] = useState([]);
  const [type, setType] = useState(null);
  const [orgID, setOrgID] = useState(null);
  const [file, setFile] = useState(null);
  const [orgInDb, setOrgInDb] = useState('');

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/${threat_actor_type}s/${threat_actor_id}`,
      true
    );
  }, []);

  useEffect(() => {
    if (data) {
      getOrgInDb();
    }
  }, [data]);

  const getOrgInDb = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/terror-threat-actors/${data.org_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrgInDb(res.data.data.doc.name);
        })
        .catch((err) => {
          setOrgInDb('Error');
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const getEditedActor = async () => {
    const name = document.getElementById('name').value;
    const org_id = orgID ? orgID : data.org_id;
    const status = document.getElementById('status').value;
    const sector = document.getElementById('sector').value;
    const description = document.getElementById('description').value;

    const inputs = JSON.stringify({
      name,
      org_id,
      status,
      type: type ? type : data.type,
      sector,
      description,
      country: chosenOriginCountries,
    });
    return inputs;
  };

  return (
    data && (
      <EditFormMUI
        header='Criminal Threat Actor'
        changeImageInput
        handleImageChange={(e) => {
          handleChange(e);
        }}
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          { label: 'Status', id: 'status', defaultValue: data.status },
          { label: 'Sector', id: 'sector', defaultValue: data.sector },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
        ]}
        selectInputs={
          <>
            <FormSelectListDynamic
              header='Organization'
              onChange={(e) => {
                setOrgID(e.target.value);
              }}
              path='terror-threat-actors'
              optVal='name'
              id='org_id'
              additionalData={`Organization in DB: ${orgInDb}`}
            />
            <CountryList
              id='country'
              header='Origin Countries'
              onChange={(e) => {
                setChosenOriginCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`Country in DB: ${data.country}`}
            />
            <FormSelectListStatic
              id='type'
              onChange={(e) => {
                setType(e.target.value);
              }}
              header='Type'
              options={[
                'Hacktivism',
                'State Sponsored',
                'Cyber-Crime',
                'Gray Hat',
                'Cyber-Vandalism',
              ]}
              additionalData={`Type in DB: ${data.type}`}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`criminal-threat-actors/${data._id}`}
        afterDeleteFunc={() => {
          navigate(`/criminal-threat-actors/${data._id}`);
        }}
      />
    )
  );
};
export default EditCriminalThreatActorForm;
