import { useEffect } from 'react';

const DarkCrypto = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Dark Crypto</h1>
    </div>
  );
};
export default DarkCrypto;
