import { useState, useEffect } from 'react';
import CountryList from '../../components/CountryList';
import useGetRequest from '../../hooks/use-get-request';
import EditFormMUI from '../../components/EditFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import { useNavigate } from 'react-router-dom';

const EditOrganizedCrimeThreatActorForm = () => {
  const threat_actor_id = sessionStorage.getItem('threatActorID');
  const { getRequestFunc, data } = useGetRequest();
  const [chosenOriginCountries, setChosenOriginCountries] = useState([]);
  const [type, setType] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/organized-crime-threat-actors/${threat_actor_id}`,
      true
    );
  }, []);

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  const getEditedActor = async () => {
    const name = document.getElementById('name').value;
    const status = document.getElementById('status').value;
    const sector = document.getElementById('sector').value;
    const description = document.getElementById('description').value;

    const inputs = JSON.stringify({
      name,
      status,
      sector,
      type: type ? type : data.type,
      description,
      country:
        chosenOriginCountries.length > 0 ? chosenOriginCountries : data.country,
    });

    return inputs;
  };

  return (
    data && (
      <EditFormMUI
        header='Organized Crime Threat Actor'
        changeImageInput
        handleImageChange={(e) => {
          handleChange(e);
        }}
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          { label: 'Status', id: 'status', defaultValue: data.status },
          { label: 'Sector', id: 'sector', defaultValue: data.sector },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
        ]}
        selectInputs={
          <>
            <CountryList
              id='country'
              header='Origin Countries'
              onChange={(e) => {
                setChosenOriginCountries((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`in DB: ${data.country}`}
            />
            <FormSelectListStatic
              id='type'
              onChange={(e) => {
                setType(e.target.value);
              }}
              header='Type'
              options={[
                'Hacktivism',
                'State Sponsored',
                'Cyber-Crime',
                'Gray Hat',
                'Cyber-Vandalism',
              ]}
              additionalData={`in DB: ${data.type}`}
            />
          </>
        }
        itemToEdit={getEditedActor}
        path={`https://api.hackeye.io/api/v1/organized-crime-threat-actors/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/threat-library');
          sessionStorage.removeItem(threat_actor_id);
        }}
      />
    )
  );
};
export default EditOrganizedCrimeThreatActorForm;
