import CardWithLeftHeader from '../CardWithLeftHeader';
import youtubeBg from '../../assets/images/antisemetic-dashboard-youtube.png';

const AntisemeticYoutubeVideosDash = () => {
  return (
    <CardWithLeftHeader
      header='Youtube videos'
      content={
        <div style={{ height: '415px', padding: '10px' }}>
          <div className='antisemetic-dashboard-youtube-video-container'>
            <img src={youtubeBg} alt=''></img>
            <div className='antisemetic-dashboard-youtube-vid-details-container'>
              <div className='antisemetic-dashboard-youtube-video-title'>
                Youtube video title
              </div>
              <div className='antisemetic-dashboard-youtube-video-date'>
                Upload date: 23/03/2023
              </div>
            </div>
          </div>
          <div className='antisemetic-dashboard-youtube-video-container'>
            <img src={youtubeBg} alt=''></img>
            <div className='antisemetic-dashboard-youtube-vid-details-container'>
              <div className='antisemetic-dashboard-youtube-video-title'>
                Youtube video title
              </div>
              <div className='antisemetic-dashboard-youtube-video-date'>
                Upload date: 23/03/2023
              </div>
            </div>
          </div>
          <div className='antisemetic-dashboard-youtube-video-container'>
            <img src={youtubeBg} alt=''></img>
            <div className='antisemetic-dashboard-youtube-vid-details-container'>
              <div className='antisemetic-dashboard-youtube-video-title'>
                Youtube video title
              </div>
              <div className='antisemetic-dashboard-youtube-video-date'>
                Upload date: 23/03/2023
              </div>
            </div>
          </div>
          <div className='antisemetic-dashboard-youtube-video-container'>
            <img src={youtubeBg} alt=''></img>
            <div className='antisemetic-dashboard-youtube-vid-details-container'>
              <div className='antisemetic-dashboard-youtube-video-title'>
                Youtube video title
              </div>
              <div className='antisemetic-dashboard-youtube-video-date'>
                Upload date: 23/03/2023
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default AntisemeticYoutubeVideosDash;
