import { useEffect } from 'react';
const CrimeDashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Crime Dashboard</h1>
    </div>
  );
};
export default CrimeDashboard;
