import Card from './Card';
import FilteringCheckbox from './FilteringCheckbox';
import './FiltersBarAndData.css';
import { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import { filterData } from '../utils/filterData';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';

const FiltersBarAndData = (props) => {
  const [originalData, setOriginalData] = useState();
  const [currentData, setCurrentData] = useState();

  const [checkedBoxes, setCheckedBoxes] = useState(
    Array.from(document.querySelectorAll('input[type="checkbox"]:checked'))
  );
  const [searchedValue, setSearchedValue] = useState('');

  const removeDuplicates = (array) => {
    const uniqueObjects = [];
    const seen = {};

    array.map((obj) => {
      if (!seen[obj._id]) {
        seen[obj._id] = true;
        uniqueObjects.push(obj);
      }
    });

    return uniqueObjects;
  };

  const handleCheckboxes = () => {
    const checkedBoxesFieldsAndIDs = [];

    for (const check of checkedBoxes) {
      checkedBoxesFieldsAndIDs.push([check.dataset.field, check.id]);
    }

    const checkedDataArraysGroupedByArrays = checkedBoxesFieldsAndIDs.map(
      (box) => {
        return originalData.filter((item) => {
          if (
            typeof item[box[0]] === 'string' &&
            isNaN(new Date(item[box[0]]).getFullYear())
          ) {
            return item[box[0]] === box[1];
          } else if (typeof item[box[0]] === 'object') {
            return item[box[0]].includes(box[1]);
          } else if (
            typeof new Date(item[box[0]]).getFullYear() === 'number' &&
            !isNaN(new Date(item[box[0]]).getFullYear())
          ) {
            return +new Date(item[box[0]]).getFullYear() === +box[1];
          }
        });
      }
    );
    const finalCheckedData = [];
    for (const arr of checkedDataArraysGroupedByArrays) {
      finalCheckedData.push(...arr);
    }

    if (finalCheckedData.length > 0) {
      return removeDuplicates(finalCheckedData);
    } else {
      return null;
    }
  };

  const handleDataFiltering = (originalData, searchField) => {
    //ONLY SEARCH
    if (searchedValue !== '' && checkedBoxes.length === 0) {
      setCurrentData(filterData(originalData, searchField));
    }
    //ONLY CHECKBOXES
    else if (searchedValue === '' && checkedBoxes.length > 0) {
      setCurrentData(handleCheckboxes());
    }
    //SEARCH + CHECKBOXES
    else if (searchedValue !== '' && checkedBoxes.length > 0) {
      setCurrentData(filterData(handleCheckboxes(), searchField));
    }
    //NOTHING
    else {
      setCurrentData(props.originalData);
    }
  };

  useEffect(() => {
    if (props.originalData) {
      setOriginalData(props.originalData);
      setCurrentData(props.originalData);
    }
  }, [props.originalData]);

  useEffect(() => {
    handleDataFiltering(originalData, props.searchField);
  }, [checkedBoxes, searchedValue]);

  return (
    <>
      <div className='col-xl-10 mb-3 filters-bar-wrapper'>
        <Card
          className='filters-bar-and-data-filters-card-wrapper'
          adjustCss='filters-bar-container'
          content={
            <div>
              <div className='filters-bar-filters-content'>
                {props.filters &&
                  props.filters.map((filter, i) => {
                    return (
                      <div className='filters-bar-filter-container' key={i}>
                        <div className='filters-bar-filter-header'>
                          {filter.header}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {filter.pieChart && (
                            <div className='alerts-pie-chart-container'>
                              <ResponsiveContainer>
                                <PieChart width={100} height={100}>
                                  <Pie
                                    dataKey='value'
                                    isAnimationActive={true}
                                    data={filter.filters.map((f, i) => {
                                      return {
                                        value: f.quantity,
                                        name: f.name,
                                        fill: f.pieColor,
                                      };
                                    })}
                                    outerRadius={'100%'}
                                    innerRadius={47}
                                    cx='50%'
                                    cy='50%'
                                    stroke='none'
                                  />
                                  <Tooltip />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                          )}
                          <div
                            style={{
                              margin: 'auto',
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            {filter.filters.map((f, i) => {
                              if (i <= 4) {
                                return (
                                  <FilteringCheckbox
                                    id={f.name}
                                    key={i}
                                    className={filter.className}
                                    source={f.quantity}
                                    field={filter.fieldValue}
                                    onClick={() => {
                                      setCheckedBoxes(
                                        Array.from(
                                          document.querySelectorAll(
                                            'input[type="checkbox"]:checked'
                                          )
                                        )
                                      );
                                    }}
                                  />
                                );
                              }
                            })}
                          </div>
                          <div>
                            {filter.filters.map((f, i) => {
                              if (i > 4) {
                                return (
                                  <FilteringCheckbox
                                    id={f.name}
                                    key={i}
                                    className={filter.className}
                                    source={f.quantity}
                                    field={filter.fieldValue}
                                    onClick={() => {
                                      setCheckedBoxes(
                                        Array.from(
                                          document.querySelectorAll(
                                            'input[type="checkbox"]:checked'
                                          )
                                        )
                                      );
                                    }}
                                  />
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div>
                {props.searchbar && (
                  <div
                    style={{
                      width: '200px',
                    }}
                  >
                    <SearchBar
                      onChange={(e) => {
                        setSearchedValue(e.target.value.trim());
                      }}
                      filters={props.filters}
                      originalData={props.originalData}
                    />
                  </div>
                )}
              </div>
            </div>
          }
        />
      </div>
      {currentData && props.displayData(currentData)}
    </>
  );
};
export default FiltersBarAndData;
