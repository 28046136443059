import SeverityList from '../components/SeverityList';
import { useEffect, useState } from 'react';
import useGetRequest from '../hooks/use-get-request';
import EditFormMUI from '../components/EditFormMUI';
import { useNavigate } from 'react-router-dom';

const EditRuleRepoItem = () => {
  const navigate = useNavigate();

  const { getRequestFunc, data } = useGetRequest();
  const edit_rule_repo_id = sessionStorage.getItem('edit_rule_repo_id');

  const [severity, setSeverity] = useState(null);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/rule-repository/${edit_rule_repo_id}`,
      true
    );
  }, []);

  const editdata = async () => {
    const product = document.getElementById('product').value;
    const description = document.getElementById('description').value;
    const mitre_tactic = document.getElementById('mitre_tactic').value;
    const mitre_technique = document.getElementById('mitre_technique').value;

    const inputs = JSON.stringify({
      product,
      description,
      severity: severity || data.severity,
      mitre_tactic,
      mitre_technique,
    });

    return inputs;
  };

  return (
    data && (
      <EditFormMUI
        header='Rule Repository Item'
        textInputs={[
          { label: 'Product', id: 'product', defaultValue: data.product },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },

          {
            label: 'Mitre Tactic',
            id: 'mitre_tactic',
            defaultValue: data.mitre_tactic,
          },
          {
            label: 'Mitre Technique',
            id: 'mitre_technique',
            defaultValue: data.mitre_technique,
          },
        ]}
        selectInputs={
          <>
            <SeverityList
              onChange={(e) => {
                setSeverity(e.target.value);
              }}
              additionalData={`Severity in DB: ${data.severity}`}
            />
          </>
        }
        itemToEdit={editdata}
        path={`rule-repository/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/rule-repository');
        }}
      />
    )
  );
};
export default EditRuleRepoItem;
