import { useEffect } from 'react';
import Card from '../../components/Card';
import { useState } from 'react';
import SeverityList from '../../components/SeverityList';
import useGetRequest from '../../hooks/use-get-request';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import CreationFormMUI from '../../components/CreationFormMUI';
import usePostRequest from '../../hooks/use-post-request';
import info from '../../assets/images/info-icon.png';
import EditFormMUI from '../../components/EditFormMUI';
import { useNavigate } from 'react-router-dom';

const EditEmergingThreat = () => {
  const emerging_threat_id = sessionStorage.getItem('emerging_threat');
  const navigate = useNavigate();
  const [severity, setSeverity] = useState(null);
  const { postSuccess, postLoading, postFeedback, postError, postRequestFunc } =
    usePostRequest();
  const { getRequestFunc, data, setData } = useGetRequest();

  const updateEmergingThreat = async () => {
    const threatname = document.getElementById('threatname').value;
    const affectedproducts = document.getElementById('affectedproducts').value;
    const cveid = document.getElementById('cveid').value;
    const cvssv3score = document.getElementById('cvssv3score').value;
    const date = document.getElementById('date').value
      ? document.getElementById('date').value
      : data.date;
    const impact = document.getElementById('impact').value;
    const link = document.getElementById('link').value;
    const solution = document.getElementById('solution').value;
    const summary = document.getElementById('summary').value;
    const targetedSoftware = document.getElementById('targetedSoftware').value;

    const updateEmergingThreatStr = JSON.stringify({
      threatname,
      affectedproducts,
      cveid,
      cvssv3score,
      date,
      impact,
      link,
      solution,
      summary,
      targetedSoftware,
      severity: severity || data.severity,
    });
    return updateEmergingThreatStr;
  };

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/emerging-threats/${emerging_threat_id}`,
      true
    );
    window.scrollTo(0, 0);
  }, []);

  const createNewRecommendation = async () => {
    const recommendation = document.getElementById('recommendation').value;

    const itemStr = JSON.stringify({
      recommendation,
      emerging_threat_id,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/emerging-threat-recommendations',
      itemStr,
      true
    );
  };

  return (
    data && (
      <>
        <EditFormMUI
          header='Emerging Threat'
          textInputs={[
            {
              label: 'Threat Name',
              id: 'threatname',
              defaultValue: data.threatname,
            },
            {
              label: 'Affected Products',
              id: 'affectedproducts',
              defaultValue: data.affectedproducts,
            },
            { label: 'CVE ID', id: 'cveid', defaultValue: data.cveid },
            {
              label: 'CVSSV3 Score',
              id: 'cvssv3score',
              defaultValue: data.cvssv3score,
            },
            {
              label: 'Date',
              id: 'date',
              defaultValue: data.date ? data.date.split('T')[0] : null,
              type: 'date',
            },
            { label: 'Impact', id: 'impact', defaultValue: data.impact },
            { label: 'Link', id: 'link', defaultValue: data.link },
            { label: 'Solution', id: 'solution', defaultValue: data.solution },
            { label: 'Summary', id: 'summary', defaultValue: data.summary },
            {
              label: 'Targeted Software',
              id: 'targetedSoftware',
              defaultValue: data.targetedSoftware,
            },
          ]}
          selectInputs={
            <>
              <SeverityList
                onChange={(e) => {
                  setSeverity(e.target.value);
                }}
                additionalData={`Severity in DB: ${data.severity}`}
              />
            </>
          }
          itemToEdit={updateEmergingThreat}
          path={`emerging-threats/${emerging_threat_id}`}
          afterDeleteFunc={() => {
            navigate('/emerging-threats');
          }}
        />
        {data.recommendations && data.recommendations.length > 0 ? (
          <Card
            content={
              <div style={{ padding: '10px' }}>
                Edit Recommendations
                {data.recommendations.map((rec, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        textAlign: 'left',
                        margin: '7px 0',
                        padding: '10px',
                        color: '#fff',
                        background: 'rgb(37,40,71)',
                        borderRadius: '10px',
                        height: '42px',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <img
                          style={{ height: '18px', width: '18px' }}
                          src={info}
                          alt=''
                        />{' '}
                        {rec.recommendation}
                      </div>
                      <DeleteAndEditButtons
                        id={rec._id}
                        path='emerging-threat-recommendations'
                        afterDeleteFunc={() => {
                          setData(undefined);
                          getRequestFunc(
                            `https://api.hackeye.io/api/v1/emerging-threats/${emerging_threat_id}`,
                            true
                          );
                        }}
                        editFunc={() => {
                          sessionStorage.setItem(
                            'edit_emerging_threat_recommendation',
                            rec._id
                          );
                        }}
                        navigateToEdit='/edit-emerging-threat-recommendation'
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        ) : (
          <CreationFormMUI
            header='Emerging Threat Recommendation'
            textInputs={[{ label: 'Recommendation', id: 'recommendation' }]}
            onClick={() => {
              createNewRecommendation();
            }}
            postSuccess={postSuccess}
            postError={postError}
            postFeedback={postFeedback}
            postLoading={postLoading}
          />
        )}
      </>
    )
  );
};
export default EditEmergingThreat;
