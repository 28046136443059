import { useEffect } from 'react';
const DarknetSearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Darknet Search</h1>
    </div>
  );
};
export default DarknetSearch;
