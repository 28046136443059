import axios from 'axios';
import { useState } from 'react';

const usePostRequest = () => {
  const token = sessionStorage.getItem('token');
  const [postLoading, setPostLoading] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);
  const [postFeedback, setPostFeedback] = useState('');

  const [postData, setPostData] = useState();

  const postRequestFunc = async (
    url,
    itemToCreate = null,
    Authorization = false
  ) => {
    setPostLoading(true);
    const headers = Authorization
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      : {
          'Content-Type': 'application/json',
        };

    try {
      await axios
        .post(url, itemToCreate, {
          headers,
        })
        .then((res) => {
          setPostData(res.data);

          setPostLoading(false);
          setPostSuccess(true);
          setPostError(false);
          setPostFeedback('item created successfully!');
        })
        .catch((err) => {
          setPostLoading(false);
          setPostSuccess(false);
          setPostError(true);
          setPostFeedback('Something went wrong!');
          console.log('fail: ' + err.response.data.message);
          setPostData(undefined);
        });
      setTimeout(() => {
        setPostFeedback('');
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    postError,
    postSuccess,
    setPostSuccess,
    postLoading,
    setPostLoading,
    postRequestFunc,
    postFeedback,
    postData,
  };
};

export default usePostRequest;
