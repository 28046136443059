import { useEffect } from 'react';
import useGetRequest from '../hooks/use-get-request';
import EditFormMUI from '../components/EditFormMUI';
import { useNavigate } from 'react-router-dom';

const EditCyberCampaignEventItem = () => {
  const navigate = useNavigate();

  const { getRequestFunc, data } = useGetRequest();
  const edit_cyber_campaign_id = sessionStorage.getItem(
    'edit_cyber_campaign_id'
  );

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/cyber-campaigns-events/${edit_cyber_campaign_id}`,
      true
    );
  }, []);

  const editActor = async () => {
    const name = document.getElementById('name').value;
    const type = document.getElementById('type').value;
    const description = document.getElementById('description').value;

    const inputs = JSON.stringify({
      name,
      description,
      type,
    });

    return inputs;
  };

  return (
    data && (
      <EditFormMUI
        header='Cyber Campaign / Event'
        textInputs={[
          { label: 'Name', id: 'name', defaultValue: data.name },
          { label: 'Type', id: 'type', defaultValue: data.type },
          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
        ]}
        itemToEdit={editActor}
        path={`cyber-campaigns-events/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/cyber-campaigns-events-list');
        }}
      />
    )
  );
};
export default EditCyberCampaignEventItem;
