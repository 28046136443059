import useGetRequest from '../hooks/use-get-request';
import { useEffect } from 'react';
import Card from '../components/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { dateFormat } from '../utils/dateFormat';

const Ioc = () => {
  const ioc_id = sessionStorage.getItem('ioc_id');
  const { getSuccess, getLoading, getRequestFunc, data } = useGetRequest();

  useEffect(() => {
    getRequestFunc(`https://api.hackeye.io/api/v1/iocs/${ioc_id}`, true);
  }, []);

  const displayData = (data) => {
    return (
      <div style={{ height: 'auto', textAlign: 'left' }}>
        <Card
          content={
            <div style={{ padding: '10px' }}>
              {getSuccess ? (
                <div>
                  {data.ioc_value && (
                    <div>
                      <span>Name:</span>
                      <h6> {data.ioc_value}</h6>
                    </div>
                  )}
                  {/* add: malware_id, cyber_threat_actor_id */}

                  {data.ioc_type && (
                    <div>
                      <span>Type:</span>
                      <h6> {data.ioc_type}</h6>
                    </div>
                  )}
                  {data.feed && (
                    <div>
                      <span>Feed:</span>
                      <h6> {data.feed}</h6>
                    </div>
                  )}
                  {data.ioc_category && (
                    <div>
                      <span>Category:</span>
                      <h6> {data.ioc_category}</h6>
                    </div>
                  )}
                  {data.reputation && (
                    <div>
                      <span>Reputation:</span>
                      <h6> {data.reputation}</h6>
                    </div>
                  )}
                  {data.source_country && (
                    <div>
                      <span>Source Country:</span>
                      <h6> {data.source_country}</h6>
                    </div>
                  )}
                  {data.activity && (
                    <div>
                      <span>Activity:</span>
                      <h6> {data.activity}</h6>
                    </div>
                  )}
                  {data.activity && (
                    <div>
                      <span>Last Update:</span>
                      <h6>{dateFormat(data.last_update)}</h6>
                    </div>
                  )}
                </div>
              ) : (
                <div>Error</div>
              )}
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div>
      {getSuccess ? (
        displayData(data)
      ) : (
        <ClipLoader loading={getLoading} size={35} className='cliploader' />
      )}
    </div>
  );
};
export default Ioc;
