import ReactPaginate from 'react-paginate';
import { useState } from 'react';

/**
 receives:
 1) props.data = array of objects
 2) props.tableHeaders - jsx of table headers (meaning: first <tr> element)
 3) props.mapData = function of how to display each of the received objects
 4)props.itemsPerPage = number of items in page
 */
const PaginatedTableWithMappedData = (props) => {
  function Items({ currentItems }) {
    return (
      <table className='data-table'>
        <tbody>
          {props.tableHeaders}
          {currentItems && currentItems}
        </tbody>
      </table>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.

    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = props.data.slice(itemOffset, endOffset);

    const pageCount = Math.ceil(props.data.length / itemsPerPage);

    // const currentPage = (itemOffset + itemsPerPage) / itemsPerPage;

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % props.data.length;
      setItemOffset(newOffset);
    };

    return (
      <div>
        <ReactPaginate
          className={
            props.searchbar
              ? 'pagination-page-numbers-container pagination-page-numbers-container-with-searchbar'
              : 'pagination-page-numbers-container'
          }
          breakLabel='...'
          nextLabel='→'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel='←'
          renderOnZeroPageCount={null}
          previousLinkClassName={`${
            (itemOffset + itemsPerPage) / itemsPerPage === 1
              ? 'pagination__link--disabled'
              : 'pagination__link'
          }`}
          nextLinkClassName={`${
            itemOffset + currentItems.length === props.data.length
              ? 'pagination__link--disabled'
              : 'pagination__link'
          }`}
          activeClassName={'pagination__link--active'}
        />
        <Items currentItems={currentItems} />
      </div>
    );
  }
  return <PaginatedItems itemsPerPage={props.itemsPerPage} />;
};
export default PaginatedTableWithMappedData;
