import './Navbar.css';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  navbarResultsEmail,
  check_is_phish,
  set_cveid_data,
  navbarResultsWallet,
  navbarResultsURL,
  navbarResultsDomain,
  navbarSearchedIP,
  navbarSearchedHash,
  navbarSearchedThreat,
} from './../actions/index';
import alerts from '../assets/images/navbar/alerts.svg';
import downArrow from '../assets/images/navbar/dropdown@2x.svg';
import magnifier from '../assets/images/navbar/magnifier.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState } from 'react';
import { toggleDropMenu } from '../utils/toggleDropMenu';
import NavbarDropMenuProfile from './NavbarDropMenuProfile';
import usePatchRequest from '../hooks/use-patch-request';
import { setUserImage } from '../utils/handleUserProfileImage';
import Card from './Card';
import { dateFormat } from '../utils/dateFormat';

const Navbar = () => {
  const { name, organization_id, isAdmin, user_id } = useSelector(
    (state) => state.appReducer
  );
  const token = sessionStorage.getItem('token');
  const { editRequestFunc } = usePatchRequest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [numOfNewAlerts, setNumOfNewAlerts] = useState(0);
  const [newAlerts, setNewAlerts] = useState();

  const allEmailSearchResults = [];

  const getAlerts = async () => {
    const fetchedAlerts = [];
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/alerts', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const filteredAlerts = res.data.data.doc.filter((alert) => {
            return (
              (alert.group === 'Specific Organization' &&
                alert.to === organization_id) ||
              (alert.group !== 'Specific Organization' &&
                !alert.specific_user) ||
              alert.specific_user === user_id
            );
          });

          let unSeenAlerts = 0;
          for (const alert of filteredAlerts) {
            if (
              (alert.type === 'Specific Person' && !alert.readBy[user_id]) ||
              !alert.readBy[user_id]
            ) {
              unSeenAlerts += 1;
            }

            fetchedAlerts.push(
              <div
                className='navbar-alerts-drop-down-menu-item'
                key={`alert-${alert._id}`}
                id={`alert-${alert._id}`}
                onClick={() => {
                  getAlerts();
                  navigate('/alerts');
                  if (
                    alert.specific_user === user_id ||
                    (alert.specific_user !== user_id &&
                      !alert.readBy[alert._id])
                  ) {
                    markAlertAsSeen(alert._id, alert.readBy);
                  }
                }}
              >
                <div className='navbar-alerts-drop-down-menu-item-alert-subject'>
                  {alert.subject}
                </div>
                <div className='navbar-alerts-drop-down-menu-item-alert-createdAt-dot-container'>
                  <div className='navbar-alerts-drop-down-menu-item-alert-createdAt'>
                    {dateFormat(alert.createdAt)}
                  </div>
                  <span
                    className={`
                    ${
                      (alert.specific_user === user_id &&
                        !alert.readBy[user_id]) ||
                      !alert.readBy[user_id]
                        ? 'navbar-alerts-unseen-personal-alert'
                        : 'navbar-alerts-seen-personal-alert'
                    }`}
                  >
                    •
                  </span>
                </div>
              </div>
            );
          }

          setNumOfNewAlerts(unSeenAlerts);
          setNewAlerts(fetchedAlerts);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user_id) {
      setUserImage('user-profile-image', user_id);
      getAlerts();
    }
  }, [user_id]);

  const markAlertAsSeen = async (alert_id, readByField) => {
    const readBy = readByField;
    readBy[user_id] = true;
    await editRequestFunc(
      `https://api.hackeye.io/api/v1/alerts/${alert_id}`,
      {
        readBy: readBy,
      },
      true
    );
  };

  function checkIfValidIP(str) {
    const regexExp =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

    return regexExp.test(str);
  }

  function checkIfEmail(str) {
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
  }

  const getDehashedData = async (param) => {
    setLoading(true);
    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/dehashed',
          { param },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.data.data.entries) {
            allEmailSearchResults.push({
              dehashed: res.data.data.data.entries,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getHIBPData = async (email) => {
    setLoading(true);
    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/hibp',
          { account: email },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const { breachDetails } = res.data.data;
          if (breachDetails && breachDetails.statusCode !== 429) {
            allEmailSearchResults.push({ hibp: breachDetails });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getUrlDataIocRepo = async (url) => {
    setLoading(true);
    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/iocs/url',
          { url },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.data.doc) {
            dispatch(navbarResultsURL(url, res.data.data.doc));
          } else {
            dispatch(navbarResultsURL(url, undefined));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getDomainDataIocRepo = async (domain) => {
    setLoading(true);
    try {
      await axios
        .get(`https://api.hackeye.io/api/v1/iocs/domain/${domain}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.doc) {
            dispatch(navbarResultsDomain(domain, res.data.data.doc));
          } else {
            dispatch(navbarResultsDomain(domain, undefined));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          dispatch(navbarResultsDomain(domain, undefined));
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const searchNavbar = async (e) => {
    e.preventDefault();
    const indexOfSplit =
      document.querySelector('.navbar-searchbar-input').value.indexOf(':') + 2;

    const searched_field = document
      .querySelector('.navbar-searchbar-input')
      .value.slice(0, indexOfSplit - 1);

    const searched_value = document
      .querySelector('.navbar-searchbar-input')
      .value.slice(indexOfSplit);

    //EMAIL ADDRESS - dehashed, hibp
    if (searched_field === 'Email:' && checkIfEmail(searched_value)) {
      await getHIBPData(searched_value);
      await getDehashedData(searched_value);
      dispatch(navbarResultsEmail(searched_value, allEmailSearchResults));
      navigate('/account-breach-results');
    }
    //IP ADDRESS - abuseIPDB , shodan
    else if (searched_field === 'IP:' && checkIfValidIP(searched_value)) {
      if (sessionStorage.getItem('searched_IP')) {
        sessionStorage.removeItem('searched_IP');
      }
      dispatch(navbarSearchedIP(searched_value));
      sessionStorage.setItem('searched_IP', searched_value);
      navigate('/ip-search-results');
    }
    //cve
    else if (searched_field === 'CVE:') {
      await checkCVEID(searched_value);
      navigate('/cve-results');
    }
    //phishtank
    else if (searched_field === 'Phishing:') {
      await checkIsPhish(searched_value);
      navigate('/phishing-results');
    }
    //bitcoinabuse
    else if (searched_field === 'Wallet:') {
      await getWalletData(searched_value);
      navigate('/wallet-search-results');
    }
    //url - our repo
    else if (searched_field === 'URL:') {
      await getUrlDataIocRepo(searched_value);
      navigate('/url-search-results');
    } else if (searched_field === 'Hash:') {
      if (sessionStorage.getItem('searched_hash')) {
        sessionStorage.removeItem('searched_hash');
      }
      dispatch(navbarSearchedHash(searched_value));
      sessionStorage.setItem('searched_hash', searched_value);
      navigate('/hash-search-results');
    } else if (searched_field === 'Domain:') {
      await getDomainDataIocRepo(searched_value);
      navigate('/domain-search-results');
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    else if (searched_field === 'Terror Threat Actor:') {
      sessionStorage.setItem('searched_threat_name', searched_value);
      sessionStorage.setItem('threat_actor_type', 'terror-threat-actor');
      dispatch(navbarSearchedThreat(searched_value));
      navigate('/search-results/threat-actors');
    } else if (searched_field === 'Cyber Threat Actor:') {
      sessionStorage.setItem('searched_threat_name', searched_value);
      sessionStorage.setItem('threat_actor_type', 'cyber-threat-actor');
      dispatch(navbarSearchedThreat(searched_value));
      navigate('/search-results/threat-actors');
    } else if (searched_field === 'Malware Threat Actor:') {
      // sessionStorage.setItem('searched_threat_name', searched_value);
      // sessionStorage.setItem('threat_actor_type', 'malware');
      // navigate('/search-results/threat-actors');
      // dispatch(navbarSearchedThreat(searched_value))
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  };

  const getWalletData = async (address) => {
    setLoading(true);

    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/bitcoinabuse',
          { address },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.data) {
            dispatch(navbarResultsWallet(res.data.data));
          } else {
            dispatch(navbarResultsWallet(undefined));
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(navbarResultsWallet(undefined));
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const checkCVEID = async (cveId) => {
    dispatch(set_cveid_data(null));
    setLoading(true);
    const urlStr = JSON.stringify({ cveId });
    try {
      await axios
        .post('https://api.hackeye.io/api/v1/cve', urlStr, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data) {
            dispatch(set_cveid_data(res.data));
          } else {
            dispatch(set_cveid_data(undefined));
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(set_cveid_data(undefined));
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const checkIsPhish = async (url) => {
    dispatch(check_is_phish(null));
    setLoading(true);
    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/phishtank',
          { url },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.results[0].url0[0].in_database[0] === 'true') {
            dispatch(check_is_phish(res.data.results[0].url0[0]));
          } else {
            dispatch(check_is_phish(null));
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(check_is_phish(null));
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const chooseSearchField = (e) => {
    const input = document.getElementById('navbar-searchbar-input');
    const select = document.getElementById('navbar-searchbar-select');

    input.classList.remove('input-not-valid');
    select.classList.remove('input-not-valid');
    input.value = e.target.value;
  };

  const searchValidationHandler = (e) => {
    const input = document.getElementById('navbar-searchbar-input');
    const select = document.getElementById('navbar-searchbar-select');

    if (
      !(
        e.target.value.startsWith('Email: ') ||
        e.target.value.startsWith('IP: ') ||
        e.target.value.startsWith('CVE: ') ||
        e.target.value.startsWith('Phishing: ') ||
        e.target.value.startsWith('Cyber Threat Actor: ') ||
        e.target.value.startsWith('Terror Threat Actor: ') ||
        e.target.value.startsWith('Malware Threat Actor: ') ||
        e.target.value.startsWith('Wallet: ') ||
        e.target.value.startsWith('URL: ') ||
        e.target.value.startsWith('Hash: ') ||
        e.target.value.startsWith('Domain: ')
      )
    ) {
      input.classList.add('input-not-valid');
      select.classList.add('input-not-valid');
    } else {
      input.classList.remove('input-not-valid');
      select.classList.remove('input-not-valid');
    }
  };

  const emptySearchHandler = () => {
    const input = document.getElementById('navbar-searchbar-input');
    const select = document.getElementById('navbar-searchbar-select');
    input.classList.remove('input-not-valid');
    select.classList.remove('input-not-valid');
  };

  return (
    <div className='col-xl-10 nav-bar-card-wrapper'>
      <div>
        <div className='navbar-card-content'>
          <div className='navbar-user-details-searchbar-container'>
            <div className='navbar-user-details-container'>
              <div className='navbar-user-details-image-name-role-wrapper'>
                <div className='navbar-user-details-image-name-role-container'>
                  <div className='navbar-user-details-profile-image'>
                    <img
                      className='user-profile-image'
                      id='user-profile-image'
                      alt=''
                    />
                  </div>
                  <div className='navbar-user-details-name-role'>
                    <div className='navbar-user-name'>{name}</div>
                    <div className='navbar-user-role'>
                      {isAdmin ? 'Admin' : 'User'}
                    </div>
                  </div>
                </div>
                <div className='navbar-arrow-dropdown-menu-toggler-container'>
                  <img
                    onClick={() => {
                      toggleDropMenu('drop-down-menu');
                    }}
                    src={downArrow}
                    alt=''
                    className='navbar-arrow-dropdown-menu-toggler'
                  ></img>
                </div>
              </div>
            </div>

            <div className='navbar-searchbar-container'>
              <form
                className='navbar-searchbar'
                onSubmit={(e) => {
                  searchNavbar(e);
                }}
              >
                <div className='navbar-select-input-container'>
                  {loading ? (
                    <ClipLoader
                      loading={loading}
                      size={26}
                      className='navbar-search-cliploader'
                    />
                  ) : (
                    <img
                      src={magnifier}
                      alt=''
                      className='navbar-magnifier-svg'
                    />
                  )}

                  <select
                    id='navbar-searchbar-select'
                    name='select-search'
                    className='navbar-select-search-input'
                    onChange={(e) => {
                      return chooseSearchField(e);
                    }}
                  >
                    <option value='' style={{ display: 'none' }}></option>
                    <option value='Email: '>Email</option>
                    <option value='IP: '>IP</option>
                    <option value='CVE: '>CVE</option>
                    <option value='Phishing: '>Phishing</option>
                    <option value='Wallet: '>Wallet</option>
                    {isAdmin && <option value='URL: '>URL</option>}
                    {isAdmin && <option value='Hash: '>Hash</option>}
                    {isAdmin && (
                      <option value='Terror Threat Actor: '>
                        Terror Threat Actor
                      </option>
                    )}
                    {isAdmin && (
                      <option value='Cyber Threat Actor: '>
                        Cyber Threat Actor
                      </option>
                    )}
                    {isAdmin && (
                      <option value='Malware Threat Actor:'>Malware</option>
                    )}
                    {isAdmin && <option value='Phone'>Phone</option>}
                    {isAdmin && <option value='Domain'>Domain</option>}
                    {isAdmin && <option value='Username'>Username</option>}
                    {isAdmin && <option value='Technology'>Technology</option>}
                    {isAdmin && <option value='Vendor'>Vendor</option>}
                    {isAdmin && <option value='Onion'>Onion</option>}
                    {isAdmin && <option value='Apps'>Apps</option>}
                    {isAdmin && (
                      <option value='Browser-extention'>
                        Browser Extention
                      </option>
                    )}
                    {isAdmin && (
                      <option value='Exploit-Kit'>Exploit Kit</option>
                    )}
                    {isAdmin && <option value='Crime'>Crime</option>}
                    {isAdmin && (
                      <option value='Cyber-campaign'>Cyber Campaign</option>
                    )}
                    {isAdmin && <option value='Channels'>Channels</option>}
                    {isAdmin && <option value='Groups'>Groups</option>}
                    {isAdmin && (
                      <option value='Subdomain'>Subdomain</option>
                    )}{' '}
                  </select>
                  <input
                    onChange={(e) => {
                      searchValidationHandler(e);
                    }}
                    onBlur={() => {
                      emptySearchHandler();
                    }}
                    className='navbar-searchbar-input'
                    id='navbar-searchbar-input'
                    type='text'
                    placeholder='Search here...'
                    // defaultValue={navbar_search_field}
                  />
                </div>
              </form>
            </div>
          </div>

          {/**ENVELOPE */}
          <div className='navbar-notification-mail-btns'>
            <div
              className='menu-link'
              onClick={() => {
                toggleDropMenu('navbar-alerts-drop-down-menu');
              }}
            >
              <div className='menu-icon-bg'>
                <div className='navbar-new-alerts-number'>
                  {numOfNewAlerts !== 0 && numOfNewAlerts}
                </div>
                <img
                  className='menu-icon-content-notifications'
                  src={alerts}
                  alt=''
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className='navbar-drop-menues-container'>
          <NavbarDropMenuProfile />

          <div className='navbar-alerts-drop-down-menu'>
            {' '}
            <Card
              className='navbar-alerts-drop-down-menu-card-wrapper'
              adjustCss='navbar-alerts-drop-down-menu-card-content'
              content={<div>{newAlerts}</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
