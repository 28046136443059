import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBVWysR0ZoIFZNlY616_-7eeBeGoLUITJc',
  authDomain: 'gymsecdb.firebaseapp.com',
  projectId: 'gymsecdb',
  storageBucket: 'gymsecdb.appspot.com',
  messagingSenderId: '1054635040512',
  appId: '1:1054635040512:web:7c5c80c0d3c2f44f541de5',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const storage = app.storage();
const db = app.firestore();

export { auth, app, storage, db };
