import { useSelector } from 'react-redux';
import Card from '../../components/Card';

import './SearchResults.css';
import { dateFormat } from '../../utils/dateFormat';

const WalletResults = () => {
  const { walletResults } = useSelector((state) => state.appReducer);

  const abuseTypeIdData = {
    1: 'ransomware',
    2: 'darknet market',
    3: 'bitcoin tumbler',
    4: 'blackmail scam',
    5: 'sextortion',
  };

  const recentData = [];
  const descriptions = [];

  if (walletResults && walletResults.recent) {
    for (const report of Object.values(walletResults.recent)) {
      if (
        report.abuse_type_id !== 99 &&
        !recentData.includes(abuseTypeIdData[report.abuse_type_id])
      ) {
        recentData.push(abuseTypeIdData[report.abuse_type_id]);
        descriptions.push(report.description);
      } else if (
        !recentData.includes(report.abuse_type_other) &&
        report.abuse_type_id === 99
      ) {
        recentData.push(report.abuse_type_other);
        descriptions.push(report.description);
      }
    }
  }

  return walletResults ? (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        <h3 className='search-results-num-of-results-header'>
          {walletResults.first_seen ? 1 : 0} Results Found
        </h3>
        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>
      {walletResults.count ? (
        <Card
          className='col-xl-12'
          content={
            <div style={{ padding: '10px' }}>
              {/* <div className='search-results-result-tags-container'></div> */}
              <h6 className='search-results-searched-item'>
                {walletResults.address}{' '}
              </h6>
              <div>
                First Seen: <h6>{dateFormat(walletResults.first_seen)}</h6>
              </div>
              <div>
                Last Seen: <h6>{dateFormat(walletResults.last_seen)}</h6>
              </div>
              <div>
                Status:
                {walletResults.count <= 2 && <h6>clean</h6>}
                {walletResults.count >= 5 && walletResults.count <= 10 && (
                  <h6> suspicious</h6>
                )}
                {walletResults.count > 10 && <h6>malicious</h6>}
              </div>
              <div>
                Types:
                {recentData.length > 0 &&
                  recentData.map((data) => {
                    return <h6 key={data}>{data}</h6>;
                  })}
              </div>

              {descriptions.length > 0 && (
                <div>
                  Description:
                  {descriptions.map((data) => {
                    return (
                      <h6
                        key={data}
                        className='wallet-search-results-description'
                      >
                        {data}
                      </h6>
                    );
                  })}
                </div>
              )}

              <div className='search-results-result-btns-wrapper'>
                <div className='search-results-result-btns-container'>
                  <button className='search-results-btn'>Add to reports</button>
                  <button className='search-results-btn'>create alert</button>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        ''
      )}
    </div>
  ) : (
    <div>
      {' '}
      <Card content={<div style={{ padding: '10px' }}>No results found</div>} />
    </div>
  );
};
export default WalletResults;
