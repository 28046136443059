import EmergingThreatsMainDashboard from '../../components/main dashboard/EmergingThreatsMainDashboard';
import AlertsMainDashboard from '../../components/main dashboard/AlertsMainDashboard';
import PotentialThreatsMainDash from '../../components/main dashboard/PotentialThreatsMainDash';
import './RealTimeAlertsMainDashboard.css';
import CardWithLeftHeader from '../CardWithLeftHeader';
const RealTimeAlertsMainDashboard = () => {
  return (
    <CardWithLeftHeader
      css='main-dashboard-right-card'
      header='Real Time Alerts'
      content={
        <div className='real-time-alerts-main-dash-wrapper'>
          <AlertsMainDashboard />
          <PotentialThreatsMainDash />
          <EmergingThreatsMainDashboard withHeader={true} />
        </div>
      }
    />
  );
};
export default RealTimeAlertsMainDashboard;
