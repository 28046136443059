import { useState, useEffect } from 'react';
import axios from 'axios';
import ThreatActorIocCampaignsMalwaresTable from '../components/ThreatActorIocCampaignsMalwaresTable';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';

const EventIdentifier = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const [item, setItem] = useState(undefined);
  const event_identifier_id = sessionStorage.getItem('event_identifier_id');

  const getData = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/event-identifiers/${event_identifier_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setItem(res.data.data.doc);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {item && (
        <div className='threat-repository-actor-top-data-container'>
          <div className='threat-repository-actor-actor-info-container'>
            <div className='threat-repository-actor-details-container'>
              <div>
                Name: {'  '}
                <span>{item.name || 'N/A'}</span>
              </div>
              <div>
                ID: {'  '}
                <span>{item.event_id || 'N/A'}</span>
              </div>
              <div>
                Type:{'  '} <span>{item.type || 'N/A'}</span>
              </div>
              <div>
                Level:{'  '} <span>{item.level || 'N/A'}</span>
              </div>
              <div>
                Product:{'  '} <span>{item.product || 'N/A'}</span>
              </div>
            </div>

            {item.description && (
              <div className='emerging-threat-detail'>
                <div>
                  Description:{'  '} <span>{item.description}</span>
                </div>
              </div>
            )}
          </div>
          <div style={{ height: '30px' }}>
            <DeleteAndEditButtons
              id={item._id}
              path={'event-identifiers'}
              afterDeleteFunc={() => {
                navigate('/event-identifiers');
              }}
              editFunc={() => {
                sessionStorage.setItem('edit_event_identifier_id', item._id);
              }}
              navigateToEdit='/edit-event-identifier'
            />
          </div>
        </div>
      )}
      <ThreatActorIocCampaignsMalwaresTable
        searchbar={true}
        tabClassName='table-single-tab'
        data={item}
        tabs={['Indicator of Compromise']}
      />
    </div>
  );
};
export default EventIdentifier;
