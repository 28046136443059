import { useSelector } from 'react-redux';
import Card from '../../components/Card';

import './SearchResults.css';
import Flags from '../../components/Flags';
import { dateFormat } from '../../utils/dateFormat';

const DomainSearchResults = () => {
  const { searchedDomain, domainResults } = useSelector(
    (state) => state.appReducer
  );
  return domainResults ? (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        <h3 className='search-results-num-of-results-header'>
          {domainResults ? 1 : 0} Results Found
        </h3>
        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>

      <div className='col-xl-12 ip-search-results-card'>
        <Card
          content={
            <div style={{ padding: '10px' }}>
              <div className='search-results-result-tags-container'>
                {domainResults.activity && <div>{domainResults.activity}</div>}{' '}
                {domainResults.ioc_category && (
                  <>
                    <div className='search-results-tag-seperator'>•</div>
                    <div>{domainResults.ioc_category}</div>
                  </>
                )}
              </div>
              <h6 className='search-results-searched-item'>{searchedDomain}</h6>

              {domainResults.last_update && (
                <div>
                  Last Reported:{' '}
                  <h6>{dateFormat(domainResults.last_update)}</h6>
                </div>
              )}
              {domainResults.ioc_type && (
                <div>
                  Type: <h6>{domainResults.ioc_type}</h6>
                </div>
              )}

              {domainResults.feed && (
                <div>
                  Feed: <h6>{domainResults.feed}</h6>
                </div>
              )}

              {domainResults.reputation && (
                <div>
                  Reputation: <h6>IPv{domainResults.reputation}</h6>
                </div>
              )}
              {domainResults.source_country && (
                <div>
                  Location:
                  <div style={{ display: 'flex', margin: '2px 0' }}>
                    {' '}
                    <Flags
                      countries={[`${domainResults.source_country}`]}
                      withName={true}
                      imgContainerClassName='flags-image-seperator'
                    />
                  </div>
                </div>
              )}

              {domainResults.totalReports > 0 && (
                <div>
                  Reported this as malicious:{' '}
                  <h6>{domainResults.totalReports}</h6>
                </div>
              )}

              {domainResults.domain && (
                <div>
                  Domain: <h6>{domainResults.domain}</h6>
                </div>
              )}

              {domainResults.isp && (
                <div>
                  ISP: <h6>{domainResults.isp}</h6>
                </div>
              )}

              <div className='search-results-result-btns-wrapper'>
                <div className='search-results-result-btns-container'>
                  <button className='search-results-btn'>Add to reports</button>
                  <button className='search-results-btn'>create alert</button>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  ) : (
    <div>
      <Card content={<div style={{ padding: '10px' }}>No results found</div>} />
    </div>
  );
};
export default DomainSearchResults;
