import './Alerts.css';
import { useState, useEffect } from 'react';
import criticalSvg from '../../assets/images/alerts/Alert-Critical.svg';
import highSvg from '../../assets/images/alerts/Alert-High.svg';
import mediumSvg from '../../assets/images/alerts/Alert-Medium.svg';
import lowSvg from '../../assets/images/alerts/Alert-Low.svg';
import usePatchRequest from '../../hooks/use-patch-request';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import Card from '../../components/Card';
import FiltersSelect from '../../components/FiltersSelect';
import { dateFormat } from '../../utils/dateFormat';
import ClipLoader from 'react-spinners/ClipLoader';
import FiltersBarAndData from '../../components/FiltersBarAndData';
import useGetRequest from '../../hooks/use-get-request';
import { useSelector } from 'react-redux';

const Alerts = () => {
  const { user_id } = useSelector((state) => state.appReducer);
  const { getError, getSuccess, getLoading, getRequestFunc, data, setData } =
    useGetRequest();
  const { editRequestFunc } = usePatchRequest();
  const [originalData, setOriginalData] = useState();
  const [filters, setFilters] = useState();

  const [currentAlert, setCurrentAlert] = useState();

  const getAlerts = async () => {
    setData(undefined);
    await getRequestFunc('https://api.hackeye.io/api/v1/alerts', true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAlerts();
  }, []);

  useEffect(() => {
    if (data && user_id) {
      getData();
      currentDisplayedAlert(data[0]);
    }
  }, [data, user_id]);

  const getData = () => {
    if (data && !getError && getSuccess) {
      data.map((alert, i) => {
        if (!alert.openStatus[user_id] || alert.openStatus[user_id] === 'New') {
          alert.status = 'New';
        } else {
          alert.status = 'Closed';
        }
        alert.orderID = i < 10 ? `TR-000-000${i}` : `TR-000-00${i}`;
      });
      // console.log(data);
      const fetchedAlerts = data
        .filter((alert) => {
          return alert.specific_user === user_id || !alert.specific_user;
        })
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

      setOriginalData(fetchedAlerts);

      const tags = [];
      const types = [];
      const tlps = [];
      const severities = [];
      const statuses = [];

      data.map((alert) => {
        alert.tags &&
          alert.tags.map((tag) => {
            if (tag.trim() !== '' && tag.toLowerCase() !== 'x') {
              if (
                !tags.some(
                  (item) => item.name.toLowerCase() === tag.toLowerCase()
                )
              ) {
                tags.push({ name: tag, quantity: 1 });
              } else {
                const existing = tags.find(
                  (element) => element.name.toLowerCase() === tag.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        if (
          alert.type &&
          alert.type.trim() !== '' &&
          alert.type.toLowerCase() !== 'x'
        ) {
          if (
            !types.some(
              (item) => item.name.toLowerCase() === alert.type.toLowerCase()
            )
          ) {
            types.push({
              name: alert.type,
              quantity: 1,
            });
          } else {
            const existing = types.find(
              (element) =>
                element.name.toLowerCase() === alert.type.toLowerCase()
            );
            existing.quantity += 1;
          }
        }

        if (
          alert.tlp &&
          alert.tlp.trim() !== '' &&
          alert.tlp.toLowerCase() !== 'x'
        ) {
          if (
            !tlps.some(
              (item) => item.name.toLowerCase() === alert.tlp.toLowerCase()
            )
          ) {
            tlps.push({
              name: alert.tlp,
              quantity: 1,
              pieColor:
                alert.tlp === 'Red'
                  ? '#c51d31'
                  : alert.tlp === 'Amber'
                  ? '#d99517'
                  : alert.tlp === 'Green'
                  ? '#2f6451'
                  : '#E0E0E0',
            });
          } else {
            const existing = tlps.find(
              (element) =>
                element.name.toLowerCase() === alert.tlp.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          alert.severity &&
          alert.severity.trim() !== '' &&
          alert.severity.toLowerCase() !== 'x'
        ) {
          if (
            !severities.some(
              (item) => item.name.toLowerCase() === alert.severity.toLowerCase()
            )
          ) {
            severities.push({
              name: alert.severity,
              quantity: 1,
              pieColor:
                alert.severity === 'Critical'
                  ? '#d14d14'
                  : alert.severity === 'High'
                  ? '#c51d31'
                  : alert.severity === 'Medium'
                  ? '#d99517'
                  : '#2f6451',
            });
          } else {
            const existing = severities.find(
              (element) =>
                element.name.toLowerCase() === alert.severity.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (
          alert.status &&
          alert.status.trim() !== '' &&
          alert.status.toLowerCase() !== 'x'
        ) {
          if (
            !statuses.some(
              (item) => item.name.toLowerCase() === alert.status.toLowerCase()
            )
          ) {
            statuses.push({
              name: alert.status,
              quantity: 1,
              pieColor: alert.status === 'New' ? '#1DBAC5' : '#E0E0E0',
            });
          } else {
            const existing = statuses.find(
              (element) =>
                element.name.toLowerCase() === alert.status.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
      });

      setFilters([
        {
          header: 'Threat Level',
          fieldValue: 'severity',
          pieChart: true,
          filters: severities
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'TLP',
          fieldValue: 'tlp',
          pieChart: true,
          filters: tlps
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Status',
          fieldValue: 'status',
          pieChart: true,
          filters: statuses
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Category',
          fieldValue: 'type',
          filters: types
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
        {
          header: 'Tags',
          fieldValue: 'tags',

          filters: tags
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  };

  const changeAlertStatus = async (alert) => {
    const updatedOpenStatus = alert.openStatus;
    if (!updatedOpenStatus[user_id] || updatedOpenStatus[user_id] === 'New') {
      updatedOpenStatus[user_id] = 'Closed';
    } else {
      updatedOpenStatus[user_id] = 'New';
    }

    await editRequestFunc(
      `https://api.hackeye.io/api/v1/alerts/${alert._id}`,
      { openStatus: updatedOpenStatus },
      true
    );
    await getAlerts();
  };

  const currentDisplayedAlert = (alert) => {
    if (alert === undefined) {
      setCurrentAlert(undefined);
    } else {
      setCurrentAlert(
        <Card
          content={
            <div
              className='current-alert-container current-alert-id'
              id={alert._id}
            >
              <div className='alerts-current-alert-date-action-btns-container'>
                <div className='small text-truncate alerts-current-alert-date'>
                  {dateFormat(alert.createdAt)}
                </div>

                <div className='alerts-action-btns-container'>
                  <DeleteAndEditButtons
                    id={alert._id}
                    path={'alerts'}
                    afterDeleteFunc={getAlerts}
                    navigateToEdit={`/edit-alerts/${alert._id}`}
                  />
                  <button
                    className='alerts-open-close-action-btn'
                    onClick={() => {
                      changeAlertStatus(alert);
                    }}
                  >
                    {alert.openStatus[user_id] === 'New' ? 'Close' : 'Open'}
                  </button>
                </div>
              </div>

              <div className='small text-truncate alerts-current-alert-subject-severity-container'>
                <div className='small text-truncate alerts-current-alert-severity-level'>
                  {alert.severity === 'Critical' && (
                    <img
                      className='alerts-severity-level-svg'
                      src={criticalSvg}
                      alt=''
                    ></img>
                  )}
                  {alert.severity === 'High' && (
                    <img
                      className='alerts-severity-level-svg'
                      src={highSvg}
                      alt=''
                    ></img>
                  )}
                  {alert.severity === 'Medium' && (
                    <img
                      className='alerts-severity-level-svg'
                      src={mediumSvg}
                      alt=''
                    ></img>
                  )}
                  {alert.severity === 'Low' && (
                    <img
                      className='alerts-severity-level-svg'
                      src={lowSvg}
                      alt=''
                    ></img>
                  )}
                </div>
                <div className='alerts-current-alert-subject-tags-container'>
                  <div className='alerts-current-alert-subject'>
                    {alert.subject}
                  </div>
                  <div className='alerts-current-alert-tags'>
                    {alert.tags.map((tag) => {
                      return (
                        <div
                          key={`${tag}${alert.content}`}
                          className='alerts-current-alert-tag'
                        >
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className='alerts-current-alert-content'>
                <span className='alerts-current-alert-description-header'>
                  Description:
                </span>
                {alert.content}
              </div>
            </div>
          }
        />
      );
    }
  };

  const sortResultsBy = (e) => {
    if (e.target.value === 'Creation Date') {
      setOriginalData(
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
    } else if (e.target.value === 'Threat Level') {
      setOriginalData(
        data.sort((a, b) => {
          return compareSeverity(b.severity, a.severity);
        })
      );
    } else if (e.target.value === 'Status') {
      setOriginalData(
        data.sort((a, b) => {
          return compareStatus(b.status, a.status);
        })
      );
    }
  };

  const compareSeverity = (a, b) => {
    if (
      (a === 'Critical' && b !== 'Critical') ||
      (a === 'High' && b !== 'Critical' && b !== 'High') ||
      (a === 'Medium' && b !== 'Critical' && b !== 'High' && b !== 'Medium')
    ) {
      return 1;
    }
    if (
      (b === 'Critical' && a !== 'Critical') ||
      (b === 'High' && a !== 'Critical' && a !== 'High') ||
      (b === 'Medium' && a !== 'Critical' && a !== 'High' && a !== 'Medium')
    ) {
      return -1;
    }
    return 0;
  };
  const compareStatus = (a, b) => {
    if (a === 'New' && b === 'Closed') {
      return 1;
    }
    if (a === 'Closed' && b === 'New') {
      return -1;
    }
    return 0;
  };

  const displayData = (currentData) => {
    return (
      currentData &&
      currentData.length > 0 && (
        <div className='alerts-all-results-wrapper'>
          <div className='alerts-results-found-wrapper'>
            <Card
              content={
                <div className='alerts-results-found-container'>
                  <div className='alerts-results-found-sort-results-container'>
                    <div>{currentData.length} results found</div>
                    <FiltersSelect
                      options={['Creation Date', 'Status', 'Threat Level']}
                      onChange={(e) => {
                        sortResultsBy(e);
                      }}
                    />
                  </div>
                  <div className='alerts-results-container'>
                    {currentData.map((alert, i) => {
                      return (
                        <div
                          className='card mb-3 alert-alerts-list-wrapper'
                          key={i}
                          id={i}
                          onClick={() => {
                            currentDisplayedAlert(alert);
                          }}
                        >
                          <div className='card-body alert-alerts-list-container'>
                            <div
                              className={`alert-alerts-list-severity-level small text-truncate ${alert.severity.toLowerCase()}`}
                            >
                              {alert.severity === 'Critical' && (
                                <img
                                  className='alerts-severity-level-svg'
                                  src={criticalSvg}
                                  alt=''
                                />
                              )}
                              {alert.severity === 'High' && (
                                <img
                                  className='alerts-severity-level-svg'
                                  src={highSvg}
                                  alt=''
                                />
                              )}
                              {alert.severity === 'Medium' && (
                                <img
                                  className='alerts-severity-level-svg'
                                  src={mediumSvg}
                                  alt=''
                                />
                              )}
                              {alert.severity === 'Low' && (
                                <img
                                  className='alerts-severity-level-svg'
                                  src={lowSvg}
                                  alt=''
                                />
                              )}
                            </div>
                            <div className='alert-alerts-list-data-type-subject-container'>
                              <div className='small text-truncate alert-alerts-list-date-order-id-container'>
                                <div className='alert-alerts-list-date'>
                                  {dateFormat(alert.createdAt)}
                                </div>
                                {alert.orderID}
                              </div>
                              <div className='alert-alerts-list-type'>
                                {alert.type}
                              </div>
                              <div className='small text-truncate alert-alerts-list-subject'>
                                {alert.subject}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
            />
          </div>

          <div className='col-xl-10 mb-3 alerts-current-alert-container'>
            {currentAlert}
          </div>
        </div>
      )
    );
  };

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='subject'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default Alerts;
