import './Card.css';

const Card = (props) => {
  return (
    <div className={`card card-container-border ${props.className}`}>
      <div className={`card-container-padding ${props.paddingClassName}`}>
        <div className={`card-inner-content ${props.adjustCss}`}>
          {props.content}
        </div>
      </div>
    </div>
  );
};
export default Card;
