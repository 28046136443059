import axios from 'axios';
import { useState } from 'react';

const useGetRequest = () => {
  const token = sessionStorage.getItem('token');
  const [getLoading, setGetLoading] = useState(false);
  const [getSuccess, setGetSuccess] = useState(false);
  const [getError, setGetError] = useState(false);
  const [data, setData] = useState(undefined);

  const getRequestFunc = async (url, Authorization = false) => {
    setGetLoading(true);
    const headers = Authorization
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      : {
          'Content-Type': 'application/json',
        };

    try {
      await axios
        .get(url, {
          headers,
        })
        .then((res) => {
          setGetLoading(false);
          setGetSuccess(true);
          setGetError(false);
          setData(res.data.data.doc);
          // console.log('fetched data successfully');
        })
        .catch((err) => {
          setGetLoading(false);
          setGetSuccess(false);
          setGetError(true);
          setData('Something went wrong!');
          console.log('fail: ' + err.response.data.message);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getError,
    getSuccess,
    setGetSuccess,
    getLoading,
    setGetLoading,
    getRequestFunc,
    data,
    setData,
  };
};

export default useGetRequest;
