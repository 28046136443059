import FormSelectListStatic from './FormSelectListStatic';

const ThreatIndustryList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      additionalData={props.additionalData}
      id='industry'
      header='Industry'
      options={[
        'Any',
        'Financial',
        'Government',
        'Telecommunications',
        'Construction',
        'Education',
        'Energy',
        'Healthcare',
        'Hospitality',
        'IT',
        'Manufacturing',
        'Media',
        'Non-profit organizations',
        'Oil and gas',
        'Retail',
        'Shipping and Logistics',
        'Technology',
        'Maritime',
        'Biotechnology',
        'Defense',
        'Embassies',
        'Ngos',
        'Pharmaceutical',
        'Research',
        'Software Development',
        'Cryptocurrency',
        'Military',
        'Infrastructure',
        'Transportation',
        'Real Estate',
        'Transportation and Logistics',
        'Engineering',
        'Automotive',
        'Aerospace',
        'Cash Apps',
        'High Tech Industries',
        'Chinese Nationals within different industries',
        'Government agencies in Southern Asia',
        'Professional and Legal services',
      ]}
    />
  );
};
export default ThreatIndustryList;
