import FormSelectListStatic from './FormSelectListStatic';

const SeverityList = (props) => {
  return (
    <FormSelectListStatic
      id='severity'
      header='Severity'
      additionalData={props.additionalData}
      onChange={props.onChange}
      options={['Critical', 'High', 'Medium', 'Low']}
    />
  );
};
export default SeverityList;
