import Card from '../Card';
import { LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts';
import CardWithLeftHeader from '../CardWithLeftHeader';

const AntisemeticTrendByMonthDash = () => {
  const data = [
    {
      xAxisVal: '10/01',
      yAxisVal: 2,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '10/02',
      yAxisVal: 4,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '10/03',
      yAxisVal: 6,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '10/04',
      yAxisVal: 8,
      pct: Math.random() * 10,
    },
  ];
  return (
    <CardWithLeftHeader
      header='Antisemetic trend by month'
      content={
        <div>
          <LineChart
            width={250}
            height={280}
            margin={{ left: -42 }}
            fontSize={12}
            data={data}
          >
            <XAxis dataKey='xAxisVal' />
            <YAxis dataKey='yAxisVal' />
            <CartesianGrid stroke='#eee' strokeDasharray='10' />
            <Line type='monotone' dataKey='pct' stroke='#1DBAC5' dot={false} />
          </LineChart>
        </div>
      }
    />
  );
};
export default AntisemeticTrendByMonthDash;
