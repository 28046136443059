import { useState, useEffect } from 'react';

import './CyberCampaignsMainDashboard.css';
import Card from '../Card';
import { useNavigate } from 'react-router-dom';
import useGetRequest from '../../hooks/use-get-request';
import { dateFormat } from '../../utils/dateFormat';
import CardWithLeftHeader from '../CardWithLeftHeader';

const CyberCampaignMainDashboard = () => {
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  const navigate = useNavigate();

  const [typeOfEvent, setTypeOfEvent] = useState('Cyber Campaign');

  const [currentCampaignName, setCurrentCampaignName] = useState(undefined);
  const [currentCampaignDate, setCurrentCampaignDate] = useState(undefined);
  const [currentEventName, setCurrentEventName] = useState(undefined);
  const [currentEventDate, setCurrentEventDate] = useState(undefined);

  const handleData = (cyberData) => {
    const campaigns = [];
    const events = [];
    cyberData
      .sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      })
      .map((item) => {
        if (item.type === 'Cyber Campaign') campaigns.push(item);
        else events.push(item);
      });

    return displayData(cyberData, campaigns, events);
  };

  const displayData = (sortedData, campaigns, events) => {
    if (typeOfEvent === 'Cyber Campaign') {
      return (
        <div>
          <div className='main-dash-cyber-campaign-current-campaign-event'>
            <div className='mb-0 main-dash-current-date'>
              {currentCampaignDate || dateFormat(campaigns[0].date)}
            </div>
            <div className='mb-0 main-dash-current-name'>
              {currentCampaignName || campaigns[0].name}
            </div>
          </div>
          <div className='main-dashboard-cyber-campaigns-events-list-container'>
            {campaigns.map((camp, i) => {
              if (i < 4) {
                return (
                  <div
                    className='small text-truncate main-dashboard-cyber-campaign-event-date-name'
                    key={i}
                    onClick={() => {
                      setCurrentCampaignName(camp.name);
                      setCurrentCampaignDate(dateFormat(camp.date));
                    }}
                  >
                    <div className='main-dash-cyber-campaign-event-name'>
                      {camp.name}
                    </div>

                    <div className='main-dash-cyber-campaign-event-date'>
                      {dateFormat(camp.date)}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='main-dash-cyber-campaign-current-campaign-event'>
            <div className='mb-0 main-dash-current-date'>
              {currentEventDate || dateFormat(events[0].date)}
            </div>
            <div className='mb-0 main-dash-current-name'>
              {currentEventName || events[0].name}
            </div>
          </div>
          <div className='main-dashboard-cyber-campaigns-events-list-container'>
            {events.map((event, i) => {
              if (i < 4) {
                return (
                  <div
                    className='small text-truncate main-dashboard-cyber-campaign-event-date-name'
                    key={i}
                    onClick={() => {
                      setCurrentEventName(event.name);
                      setCurrentEventDate(dateFormat(event.date));
                    }}
                  >
                    <div className='main-dash-cyber-campaign-event-name'>
                      {event.name}
                    </div>

                    <div className='main-dash-cyber-campaign-event-date'>
                      {dateFormat(event.date)}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    getRequestFunc(
      'https://api.hackeye.io/api/v1/cyber-campaigns-events',
      true
    );
  }, []);

  return (
    <CardWithLeftHeader
      css='main-dashboard-top-left-card'
      header='Cyber Events & Campaigns'
      content={
        <div className='main-dashboard-cyber-campaign-event-type-wrapper'>
          <div className='main-dashboard-cyber-campaign-event-type-btns-container'>
            <button
              className='main-dash-cyber-campaign-event-type-btn'
              onClick={() => {
                setTypeOfEvent('Cyber Campaign');
              }}
            >
              Campaigns
            </button>
            <button
              className='main-dash-cyber-campaign-event-type-btn'
              onClick={() => {
                setTypeOfEvent('Cyber Event');
              }}
            >
              Events
            </button>
          </div>
          <div className='main-dash-cyber-campaign-content'>
            {data && typeof data === 'object' && (
              <div>
                {handleData(data)}

                <button
                  className='main-dash-cyber-campaigns-view-more'
                  onClick={() => {
                    navigate('/cyber-campaigns-events-list');
                  }}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};
export default CyberCampaignMainDashboard;
