import './TopMalwaresMainDashboard.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { topMalware } from '../../actions';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import Card from '../Card';
import CardWithLeftHeader from '../CardWithLeftHeader';

const TopMalwaresMainDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayedMalwares, setDisplayedMalwares] = useState(
    <ClipLoader loading={true} size={35} className='top-malwares-cliploader' />
  );
  const token = sessionStorage.getItem('token');
  const fetchedMalwares = [];
  const pieData = [];
  const [pieChart, setPieChart] = useState('');
  const colors = ['#1DBAC5', '#0094AB', '#005F7D', '#003D5B', '#002E4A'];
  const seenMalwares = {};

  const getTopMalwares = async () => {
    try {
      await axios
        .post('https://api.hackeye.io/api/v1/malware-bazaar', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { data } = res.data.data.data;
          for (const malware of data) {
            if (malware.signature) {
              if (!seenMalwares[malware.signature]) {
                seenMalwares[malware.signature] = {
                  signature: malware.signature,
                  sha256_hash: malware.sha256_hash,
                  file_type: malware.file_type,
                  first_seen: malware.first_seen,
                  last_seen: malware.last_seen,
                  sha1_hash: malware.sha1_hash,
                  md5_hash: malware.md5_hash,
                  pct: 1,
                };
              } else {
                seenMalwares[malware.signature].pct += 1;
              }
            }
          }

          const top10MalwaresByPct = Object.values(seenMalwares)
            .sort((a, b) => {
              return b.pct - a.pct;
            })
            .splice(0, 5);

          top10MalwaresByPct.map((malware, i) => {
            if (i < 5) {
              pieData.push({
                name: malware.signature,
                value: malware.pct,
                fill: colors[i],
              });

              fetchedMalwares.push(
                <div
                  className='small text-truncate main-dashboard-top-malwares-card-text'
                  key={malware.signature}
                  onClick={() => {
                    dispatch(topMalware(malware));
                    navigate(`/top-malwares/${malware.signature}`);
                  }}
                >
                  <span
                    style={{
                      color: colors[i],
                      fontWeight: '900',
                      fontSize: '20px',
                    }}
                  >
                    •
                  </span>{' '}
                  {malware.signature}
                  <br />
                </div>
              );
            }
          });
          setDisplayedMalwares(fetchedMalwares);
          setPieChart(
            <ResponsiveContainer>
              <PieChart width={100} height={100}>
                <Pie
                  stroke='none'
                  dataKey='value'
                  isAnimationActive={true}
                  data={pieData}
                  outerRadius={'100%'}
                  cx='50%'
                  cy='50%'
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          );
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTopMalwares();
  }, []);

  return (
    <CardWithLeftHeader
      css='main-dashboard-top-left-card'
      header='Top Malwares'
      content={
        <div className='main-dashboard-top-malwares-card-content'>
          <div className='main-dash-top-malwares-pie-chart'>{pieChart}</div>
          <div className='main-dashboard-top-malware-list'>
            {displayedMalwares}
          </div>
        </div>
      }
    />
  );
};
export default TopMalwaresMainDashboard;
