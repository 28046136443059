import './CrawlerKeywordResults.css';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Card from '../../components/Card';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../utils/dateFormat';

const CrawlerKeywordResults = () => {
  const { keywords } = useSelector((state) => state.appReducer);
  const [displayedResults, setDisplayedResults] = useState('');
  const navigate = useNavigate();

  const displayKeywords = () => {
    const results = [];
    for (const value of Object.values(keywords)) {
      // console.log(value.link);
      results.push(
        <div key={value.link} className='col-xl-12'>
          <Card
            content={
              <div style={{ padding: '10px' }}>
                <div className='search-results-result-tags-container'>
                  <div>Tag 1</div>
                  <div className='search-results-tag-seperator'>•</div>
                  <div>Tag 2</div>
                  <div className='search-results-tag-seperator'>•</div>
                  <div>Tag 3</div>
                  <div className='search-results-tag-seperator'>•</div>
                  <div>Tag 4</div>
                </div>
                <div className='keyword-results-result-detail'>
                  Keyword:{' '}
                  <h6 style={{ marginLeft: '5px' }}>{value.keyWord}</h6>
                </div>
                <div className='keyword-results-result-detail'>
                  Search date:
                  <h6 style={{ marginLeft: '5px' }}>
                    {dateFormat(Date.now())}
                  </h6>
                </div>
                <div className='keyword-results-result-detail'>
                  Source:
                  <a
                    style={{
                      color: '#fff',
                      marginLeft: '5px',
                      textDecoration: 'none',
                    }}
                    target='_blank'
                    rel='noreferrer'
                    href={value.link}
                  >
                    {value.link}
                  </a>
                </div>
                <div className='keyword-results-article-quote-container'>
                  {value.quote}
                </div>

                <div className='keyword-results-btns-container'>
                  <button className='keyword-results-btn'>
                    Add to reports
                  </button>
                  <button className='keyword-results-btn'>Create alert</button>
                </div>
              </div>
            }
          />
        </div>
      );
    }
    setDisplayedResults(results);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    displayKeywords();
  }, []);

  return (
    <div>
      <div className='search-results-num-of-results-filters-wrapper'>
        <div style={{ display: 'flex' }}>
          <h3
            className='keyword-results-go-back-btn'
            onClick={() => {
              navigate('/key-word-search');
            }}
          >
            ⨞
          </h3>
          <h3 className='search-results-num-of-results-header'>
            {Object.values(keywords).length} Results Found
          </h3>
        </div>

        <div className='search-results-filters-selects-wrapper'>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Relevance
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 1
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 2
              </option>
              <option className='search-results-filters-select-option'>
                Relevance 3
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={(e) => {
                // filterResultByTime(e.target.value);
              }}
            >
              <option className='search-results-filters-select-option'>
                Display All
              </option>
              <option className='search-results-filters-select-option'>
                1 Month
              </option>
              <option className='search-results-filters-select-option'>
                3 Months
              </option>
              <option className='search-results-filters-select-option'>
                Last Year
              </option>
            </select>
          </div>
          <div className='search-results-filters-select-container'>
            <select
              className='search-results-filters-select'
              onChange={() => {}}
            >
              <option className='search-results-filters-select-option'>
                Filter by
              </option>
              <option className='search-results-filters-select-option'>
                Filter 1
              </option>
              <option className='search-results-filters-select-option'>
                Filter 2
              </option>
              <option className='search-results-filters-select-option'>
                Filter 3
              </option>
            </select>
          </div>
        </div>
      </div>
      <div> {displayedResults}</div>
    </div>
  );
};
export default CrawlerKeywordResults;
