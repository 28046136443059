import './FilteringCheckbox.css';
// import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
//CHANGE TO MUI COMPONENT FOR PRACTICE

const FilteringCheckbox = (props) => {
  return (
    <div className={`filtering-checkbox-wrapper  ${props.className}`}>
      <div className='filtering-checkbox-container'>
        <input
          className='filtering-checkbox-input'
          type='checkbox'
          id={props.id}
          onClick={props.onClick}
          defaultChecked={props.defaultChecked}
          data-field={props.field}
        />
        <label className='filtering-checkbox-data'>
          {props.id}{' '}
          <span className='filtering-checkbox-data-source'>{props.source}</span>
        </label>
      </div>
    </div>
  );
};
export default FilteringCheckbox;
