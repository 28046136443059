export const dateFormat = (date, replacement = false) => {
  if (replacement) {
    return new Date(date)
      .toLocaleString('en-GB')
      .split(',')[0]
      .replaceAll('/', replacement);
  } else {
    return new Date(date).toLocaleString('en-GB').split(',')[0];
  }
};
