import FormSelectListStatic from './FormSelectListStatic';

const MalwareTypeList = (props) => {
  return (
    <FormSelectListStatic
      onChange={props.onChange}
      id='operation_system'
      header='Operation System'
      options={['Windows', 'Android', 'MacOs', 'Linux']}
      additionalData={props.additionalData}
    />
  );
};
export default MalwareTypeList;
