import Card from '../../components/Card';
import PieChartSingleValue from '../../components/PieChartSingleValue';
import './SearchResults.css';
// import Flags from '../../components/Flags';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from './../../components/Table';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { dateFormat } from '../../utils/dateFormat';

const HashSearchResults = () => {
  const cliploader = (
    <ClipLoader loading={true} size={26} className='navbar-search-cliploader' />
  );
  const token = sessionStorage.getItem('token');
  const searched_hash = sessionStorage.getItem('searched_hash');
  const [data, setData] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const fetchedTableData = [];
  const { searchedHash } = useSelector((state) => state.appReducer);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHashData();
  }, [searchedHash]);

  const getHashDataVirusTotal = async (hash) => {
    // setLoading(true);

    try {
      await axios
        .post(
          'https://api.hackeye.io/api/v1/virus-total',
          JSON.stringify({ hash }),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.res.data.attributes); //HASH - VIRUS TOTAL DATA
          Object.entries(
            res.data.res.data.attributes.last_analysis_results
          ).map((item) => {
            fetchedTableData.push({
              filename: item[0],
              engine_name: item[1].engine_name,
              category: item[1].category,
              engine_update: item[1].engine_update,
              engine_version: item[1].engine_version,
              method: item[1].method,
              result: item[1].result,
            });
          });

          setTableData(fetchedTableData);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getHashData = async () => {
    try {
      setLoading(cliploader);
      await axios
        .get(`https://api.hackeye.io/api/v1/iocs/hash/${searched_hash}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          const doc = res.data.data.doc;

          await getHashDataVirusTotal(doc.ioc_value);
          if (doc) {
            setData(doc);
          } else {
            setData(undefined);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setData(undefined);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getReputation = (rep) => {
    if (rep <= 20) {
      return ['Clean', 'Low'];
    } else if ((rep >= 20) & (rep <= 50)) {
      return ['Suspicious', 'High'];
    } else {
      return ['Malicious', 'Critical'];
    }
  };

  const displayVirusTotalData = () => {
    return (
      tableData &&
      tableData.length > 0 &&
      tableData.map((value, i) => {
        return (
          <tr key={i} className='data-table-tr'>
            <td className='data-table-td data-table-td-special-color'>
              {value.filename}
            </td>

            <td className='data-table-td'>
              {value.category === '' ? 'N/A' : value.category}
            </td>
            <td className='data-table-td'>
              {value.engine_update === '' ? 'N/A' : value.engine_update}
            </td>
            <td className='data-table-td'>
              {value.engine_version === '' ? 'N/A' : value.engine_version}
            </td>
            <td className='data-table-td'>
              {value.method === '' ? 'N/A' : value.method}
            </td>
            <td className='data-table-td'>
              {value.result === '' ? 'N/A' : value.result}
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <div>
      {loading ? (
        loading
      ) : data ? (
        <div>
          <div className='col-xl-12 ip-search-results-card'>
            <PieChartSingleValue
              value={getReputation(data.reputation)[0]}
              color={getReputation(data.reputation)[1]}
              header='Reputation'
            />
            <div className='ip-search-results-detail-wrapper'>
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>Hash:</div>{' '}
                <div className='ip-search-results-detail-value'>
                  {data.ioc_value}
                </div>
              </div>

              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>Type:</div>{' '}
                <div className='ip-search-results-detail-value'>Hash</div>
              </div>

              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  File Name:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {data.filename ? data.filename : 'N/A'}
                </div>
              </div>

              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  Last Update:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {data.last_update ? dateFormat(data.last_update) : 'N/A'}
                </div>
              </div>

              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  File Extension:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {data.file_extension ? data.file_extension : 'N/A'}
                </div>
              </div>
              <div className='ip-search-results-detail-container'>
                <div className='ip-search-results-detail-header'>
                  File Type:
                </div>{' '}
                <div className='ip-search-results-detail-value'>
                  {data.ioc_type ? data.ioc_type.replace('Hash - ', '') : 'N/A'}
                </div>
              </div>
            </div>
          </div>
          {tableData && (
            <div>
              <div className='bloc-tabs'>
                <button className='tabs active-tabs table-single-tab'>
                  History
                </button>
              </div>
              <div className='content-tabs mt-10'>
                <div className='content active-content'>
                  <Table
                    receivedData={displayVirusTotalData}
                    tableHeaders={[
                      ['File Name', ''],
                      ['Category', ''],
                      ['Engine Update', ''],
                      ['Engine Version', ''],
                      ['Method', ''],
                      ['Result', ''],
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Card
          className='col-xl-12'
          content={<div style={{ padding: '10px' }}>No Results Found</div>}
        />
      )}
    </div>
  );
};
export default HashSearchResults;
