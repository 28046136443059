import { useState, useEffect } from 'react';
import './ThreatRepositoryActor.css';
import axios from 'axios';
import ThreatActorIocCampaignsMalwaresTable from '../components/ThreatActorIocCampaignsMalwaresTable';
import PieChartSingleValue from '../components/PieChartSingleValue';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../utils/dateFormat';

const ThreatRepositoryActor = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const [malware, setMalware] = useState(undefined);
  const threat_repository_malware_id = sessionStorage.getItem(
    'threat_repository_malware_id'
  );

  const getMalware = async () => {
    try {
      await axios
        .get(
          `https://api.hackeye.io/api/v1/malware-threat-actors/${threat_repository_malware_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMalware(res.data.data.doc);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMalware();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {malware && (
        <div className='threat-repository-actor-top-data-container'>
          <PieChartSingleValue
            header='Severity'
            value={malware.severity || 'N/A'}
            color={malware.severity}
          />
          <div className='threat-repository-actor-actor-info-container'>
            <div className='threat-repository-actor-details-container'>
              <div>
                Threat name: {'  '}
                <span>{malware.malware_name || 'N/A'}</span>
              </div>

              <div>
                MD5 Hash:{'  '} <span>{malware.md5_hash || 'N/A'}</span>
              </div>
              {malware.last_seen && (
                <div>
                  Last Activity:{'  '}
                  <span>{dateFormat(malware.last_seen)}</span>
                </div>
              )}
              <div>
                SHA1 Hash: {'  '}
                <span>{malware.sha1_hash || 'N/A'}</span>
              </div>
              <div>
                SHA256 Hash:{'  '} <span>{malware.sha256_hash || 'N/A'}</span>
              </div>
            </div>

            {malware.industry && (
              <div className='emerging-threat-detail'>
                <div>
                  Industries:{'  '}
                  <span>
                    {malware.industry &&
                      malware.industry.map((ind, i) => {
                        if (ind) {
                          return (
                            <div
                              className='actor-displayed-data-tag-filled-bg'
                              key={i}
                            >
                              {ind}
                            </div>
                          );
                        }
                      })}
                  </span>
                </div>
              </div>
            )}
            {malware.tags && (
              <div className='emerging-threat-detail'>
                <div>
                  Tags:{'  '}
                  <span>
                    {malware.tags.split(',').map((tag, i) => {
                      return (
                        <div
                          className='actor-displayed-data-tag-filled-bg'
                          key={i}
                        >
                          {tag}
                        </div>
                      );
                    })}
                  </span>
                </div>
              </div>
            )}
            {malware.description && (
              <div className='emerging-threat-detail'>
                <div>
                  Description:{'  '} <span>{malware.description}</span>
                </div>
              </div>
            )}
          </div>
          <div style={{ height: '30px' }}>
            <DeleteAndEditButtons
              id={malware._id}
              path={'malware-threat-actors'}
              afterDeleteFunc={() => {
                navigate('/threat-repository');
              }}
              editFunc={() => {
                sessionStorage.setItem('edit_malware_id', malware._id);
              }}
              navigateToEdit='/edit-threat-repository-actor'
            />
          </div>
        </div>
      )}
      <ThreatActorIocCampaignsMalwaresTable
        searchbar={true}
        tabClassName='table-single-tab'
        data={malware}
        tabs={['Indicator of Compromise']}
      />
    </div>
  );
};
export default ThreatRepositoryActor;
