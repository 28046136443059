import './Iocs.css';
import { useEffect, useState } from 'react';
import Card from '../components/Card';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../utils/dateFormat';
import useGetRequest from '../hooks/use-get-request';
import ClipLoader from 'react-spinners/ClipLoader';

const Iocs = () => {
  const navigate = useNavigate();
  const { data, getRequestFunc, getLoading } = useGetRequest();

  const [fetchedIOCs, setFetchedIOCs] = useState();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/iocs', true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      getIOCs();
    }
  }, [data]);

  const getIOCs = async () => {
    const iocsTable = [];
    setFetchedIOCs(undefined);
    data.map((value) => {
      iocsTable.push(
        <tr className='ioc-list-tr' key={value._id}>
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem('ioc_id', value._id);
              navigate(`/iocs/${value._id}`);
            }}
          >
            {value.ioc_value}
          </td>
          <td>{value.ioc_type}</td>
          <td>{value.reputation}</td>
          <td>
            <a href={value.feed}>link</a>
          </td>
          <td>{value.activity}</td>
          <td>{value.ioc_category}</td>
          <td>{value.source_country}</td>
          <td>{dateFormat(value.last_update)}</td>
          <td>
            <DeleteAndEditButtons
              id={value._id}
              path='iocs'
              editFunc={() => {
                sessionStorage.setItem('edit_ioc_id', value._id);
              }}
              navigateToEdit='/ioc-list-edit'
              afterDeleteFunc={() => {
                getIOCs();
              }}
            />
          </td>
        </tr>
      );
    });

    setFetchedIOCs(iocsTable);
  };

  return fetchedIOCs ? (
    <div>
      <h3 className='ioc-list-header'>Indicator of Compromise List</h3>
      <Card
        content={
          <div className='ioc-list-information'>
            <div className='ioc-list-info-description'>
              <table className='ioc-list-table'>
                <tbody>
                  <tr className='ioc-list-tr categories'>
                    <td>IOC Value</td>
                    <td>IOC Type</td>
                    <td>Reputation</td>
                    <td>Feed</td>
                    <td>Activity</td>
                    <td>Category</td>
                    <td>Country</td>
                    <td>Last Update</td>
                    <td></td>
                  </tr>
                  {fetchedIOCs}
                </tbody>
              </table>
            </div>
          </div>
        }
      />
    </div>
  ) : (
    <ClipLoader loading={getLoading} size={35} className='cliploader' />
  );
};
export default Iocs;
