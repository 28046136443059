import Card from '../components/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { useEffect } from 'react';
import useDeleteRequest from '../hooks/use-delete-request';
import usePatchRequest from '../hooks/use-patch-request';
import { Grid, Typography, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const EditFormMUI = (props) => {
  const { editSuccess, editError, editLoading, editRequestFunc, editFeedback } =
    usePatchRequest();
  const {
    deleteSuccess,
    deleteLoading,
    deleteFeedback,
    deleteError,
    deleteRequestFunc,
  } = useDeleteRequest();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deleteItem = async () => {
    await deleteRequestFunc(
      `https://api.hackeye.io/api/v1/${props.path}`,
      true
    );
    if (props.afterDeleteFunc) {
      props.afterDeleteFunc();
    }
  };
  const editItem = async () => {
    const item = await props.itemToEdit();
    await editRequestFunc(
      `https://api.hackeye.io/api/v1/${props.path}`,
      item,
      true
    );
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Card
      content={
        <>
          <Typography variant='h6' gutterBottom>
            Edit {props.header}
          </Typography>

          <Grid container spacing={3}>
            {/* ADD IMAGE BUTTON */}
            {props.changeImageInput && (
              <Button
                sx={{ width: '100%', height: '50px', margin: '30px' }}
                component='label'
                variant='contained'
                startIcon={<CloudUploadIcon />}
              >
                Edit Image
                <VisuallyHiddenInput
                  type='file'
                  accept='image/*'
                  onChange={props.handleImageChange}
                />
              </Button>
            )}
            {/* TEXT INPUTS, PASSWORD INPUTS, DATE INPUTS */}
            {props.textInputs &&
              props.textInputs.length > 0 &&
              props.textInputs.map((input, i) => {
                return (
                  <Grid item xs={12} sm={6} key={i}>
                    <label
                      style={{
                        display: 'block',
                        textAlign: 'left',
                        marginBottom: '10px',
                      }}
                    >
                      {input.label}
                    </label>
                    <input
                      style={{
                        display: 'block',
                        textAlign: 'left',
                        color: '#fff',
                        background: 'none',
                        border: 'none',
                        borderBottom: '1px solid #1976d2',
                        width: '100%',
                      }}
                      onChange={input.onChange}
                      type={input.type || 'text'}
                      min={input.min}
                      max={input.max}
                      id={input.id}
                      defaultValue={input.defaultValue}
                    />
                    {input.additionalData && (
                      <div style={{ textAlign: 'left' }}>
                        {input.additionalData}
                      </div>
                    )}
                  </Grid>
                );
              })}

            {/**SELECT INPUTS */}
            {props.selectInputs && props.selectInputs}

            <div style={{ height: '30px' }}>
              {props.postLoading ? (
                <ClipLoader
                  loading={props.postLoading}
                  size={35}
                  className='cliploader'
                />
              ) : (
                (props.postError || props.postSuccess) && props.postFeedback
              )}
            </div>

            <Button
              variant='contained'
              sx={{ marginTop: '24px', marginLeft: '24px' }}
              onClick={() => {
                editItem();
              }}
            >
              Update
            </Button>
            <Button
              variant='contained'
              sx={{ marginTop: '24px', marginLeft: '24px' }}
              onClick={() => {
                deleteItem();
              }}
            >
              Delete
            </Button>
          </Grid>
        </>
      }
    />
  );
};
export default EditFormMUI;
