import { useEffect } from 'react';
import Card from '../components/Card';
import { LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts';
import blocks from '../assets/images/crypto dashboard/clarity-blocks-group-solid.svg';
import transactions from '../assets/images/crypto dashboard/mingcute-transfer-2-line.svg';
import outputs from '../assets/images/crypto dashboard/material-symbols-output.svg';
import bitcoinLogo from '../assets/images/crypto dashboard/logos-bitcoin.svg';
import etheriumLogo from '../assets/images/crypto dashboard/ethereum-eth-logo-1-1.svg';
import bitcoinCashLogo from '../assets/images/crypto dashboard/bitcoin-cash-bch-logo-1.svg';
import eosCashLogo from '../assets/images/crypto dashboard/eos-eos-logo-1.svg';
import litecoinLogo from '../assets/images/crypto dashboard/litecoin-ltc-logo-1.svg';
import polygonLogo from '../assets/images/crypto dashboard/polygon-matic-logo-1.svg';
import nemLogo from '../assets/images/crypto dashboard/nem-xem-logo-1.svg';
import solanaLogo from '../assets/images/crypto dashboard/solana-sol-logo-1.svg';
import nanoLogo from '../assets/images/crypto dashboard/nano-xno-logo-1.svg';
import bitcoinGoldLogo from '../assets/images/crypto dashboard/bitcoin-gold-btg-logo-1.svg';
import elrondLogo from '../assets/images/crypto dashboard/elrond-egld-egld-logo--1--1.svg';
import AvalancheLogo from '../assets/images/crypto dashboard/avalanche-avax-logo-1.svg';
import PundiXLogo from '../assets/images/crypto dashboard/pundi-x-npxs-logo-1.svg';
import DentLogo from '../assets/images/crypto dashboard/dent-dent-logo-1.svg';
import USDCoinLogo from '../assets/images/crypto dashboard/usd-coin-usdc-logo-1-1.svg';
import tetherLogo from '../assets/images/crypto dashboard/tether-usdt-logo-1-1.svg';
import FantomLogo from '../assets/images/crypto dashboard/fantom-ftm-logo-1.svg';
import OptimismLogo from '../assets/images/crypto dashboard/optimism-ethereum-op-logo-1.svg';
import BancorLogo from '../assets/images/crypto dashboard/bancor-bnt-logo-1.svg';
import DaiLogo from '../assets/images/crypto dashboard/multi-collateral-dai-dai-logo-1.svg';
import binanceLogo from '../assets/images/crypto dashboard/binance-usd-busd-logo-1.svg';
import cardanoLogo from '../assets/images/crypto dashboard/cardano-ada-logo-1.svg';
import bnbLogo from '../assets/images/crypto dashboard/cryptocurrency-color-bnb.svg';
import xrpLogo from '../assets/images/crypto dashboard/cryptocurrency-color-xrp.svg';
import dogeLogo from '../assets/images/crypto dashboard/dogecoin-doge-logo-1.svg';
import wallet from '../assets/images/crypto dashboard/ph-wallet-fill-1.svg';
import './CryptoDashboard.css';
import axios from 'axios';
import { useState } from 'react';
import { dateFormat } from '../utils/dateFormat';
import CardWithLeftHeader from '../components/CardWithLeftHeader';

const CryptoDashboard = () => {
  const token = sessionStorage.getItem('token');
  const displayedRssFeeds = [];
  const [cryptoNews, setCryptoNews] = useState();

  const getCryptoNews = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/crypto-currency-news', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const results = res.data.data;

          Object.values(results).map((linkData) => {
            linkData.map((link) => {
              // console.log(link.item);
              link.item.map((item, i) => {
                if (displayedRssFeeds.length < 5) {
                  displayedRssFeeds.push(
                    <div
                      key={Math.random()}
                      style={{
                        marginRight: '25px',
                      }}
                    >
                      <Card
                        adjustCss='threat-hunter-crypto-currency-news-card'
                        content={
                          <div
                            style={{
                              height: '260px',
                              overflow: 'hidden',
                              textAlign: 'left',
                              fontSize: '14px',
                            }}
                          >
                            <div style={{ fontSize: '12px' }}>
                              {dateFormat(item.pubDate[0])}
                            </div>
                            <h6
                              style={{
                                padding: '5px 0',
                                borderBottom: '1px solid #202b44',
                                fontSize: '15.5px',
                              }}
                            >
                              {item.title}
                            </h6>
                            <div>
                              {item.description[0]
                                .split('>')[4]
                                .replaceAll('</p', '')}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  );
                }
              });
            });
          });

          setCryptoNews(displayedRssFeeds);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    getCryptoNews();
  }, []);

  const data = [
    {
      xAxisVal: '2018',
      yAxisVal: 2,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '2019',
      yAxisVal: 4,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '2020',
      yAxisVal: 6,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '2021',
      yAxisVal: 8,
      pct: Math.random() * 10,
    },
    {
      xAxisVal: '2022',
      yAxisVal: 10,
      pct: Math.random() * 10,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          height: '160px',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            width: '25%',
            marginRight: '20px',
          }}
        >
          <Card
            content={
              <div style={{ height: '110px', paddingTop: '30px' }}>
                <div
                  style={{
                    display: 'inline-flex',
                  }}
                >
                  <img style={{ margin: 0 }} src={blocks} alt=''></img>
                  <div
                    style={{
                      margin: 0,
                      paddingTop: '12px',
                      paddingLeft: '12px',
                    }}
                  >
                    <div>Blocks</div>
                    <h6>778,991</h6>
                  </div>
                </div>
              </div>
            }
          />{' '}
        </div>
        <div
          style={{
            width: '25%',
            marginRight: '20px',
          }}
        >
          <Card
            content={
              <div style={{ height: '110px', paddingTop: '30px' }}>
                <div
                  style={{
                    display: 'inline-flex',
                  }}
                >
                  <img style={{ margin: 0 }} src={transactions} alt=''></img>
                  <div
                    style={{
                      margin: 0,
                      paddingTop: '12px',
                      paddingLeft: '12px',
                    }}
                  >
                    <div>Transactions</div>
                    <h6>365,250,604</h6>
                  </div>
                </div>
              </div>
            }
          />{' '}
        </div>{' '}
        <div
          style={{
            width: '25%',
            marginRight: '20px',
          }}
        >
          <Card
            content={
              <div style={{ height: '110px', paddingTop: '30px' }}>
                <div
                  style={{
                    display: 'inline-flex',
                  }}
                >
                  <img style={{ margin: 0 }} src={outputs} alt=''></img>
                  <div
                    style={{
                      margin: 0,
                      paddingTop: '12px',
                      paddingLeft: '12px',
                    }}
                  >
                    <div>Outputs</div>
                    <h6>984,234,002</h6>
                  </div>
                </div>
              </div>
            }
          />{' '}
        </div>
        <div
          style={{
            width: '12.5%',
            marginRight: '20px',
          }}
        >
          <Card
            content={
              <div style={{ height: '110px', paddingTop: '35px' }}>
                <div>
                  <img src={bitcoinLogo} alt=''></img>
                </div>
              </div>
            }
          />{' '}
        </div>
        <div
          style={{
            width: '12.5%',
          }}
        >
          <Card
            content={
              <div style={{ height: '110px', paddingTop: '30px' }}>
                <img src={wallet} alt=''></img>
                <div>wallets</div>
              </div>
            }
          />{' '}
        </div>
      </div>
      <div
        style={{
          display: 'flex',

          height: '820px',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: '40%',
              paddingRight: '20px',
              height: '100%',
            }}
          >
            <CardWithLeftHeader
              css='threat-hunter-fraud-by-coin-card-content'
              header=' Fraud activity by coin'
              content={
                <div
                  style={{
                    height: '100%',
                  }}
                >
                  <div
                    className='threat-hunter-fraud-by-coin-data-container'
                    style={{
                      height: '100%',
                    }}
                  >
                    <div
                      className='threat-hunter-fraud-by-coin-coins-container'
                      style={{
                        height: '100%',
                      }}
                    >
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={bitcoinLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Bitcoin
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={etheriumLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Etherium
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={eosCashLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        EOS
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={bitcoinCashLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Bitcoin cash
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={litecoinLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Litecoin
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={polygonLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Polygon
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={nemLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        NEM
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={solanaLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Solana
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={nanoLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        NANO
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={bitcoinGoldLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Bitcoin gold
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={elrondLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Elrond
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={AvalancheLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Avalanche
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={PundiXLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        PundiX
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={DentLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Dent
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={USDCoinLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        USD Coin
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={tetherLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Tether
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={FantomLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Fantom
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={OptimismLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Optimism
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={BancorLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Bancor
                      </div>
                      <div className='threat-hunter-fraud-by-coin-coin-type'>
                        <img
                          src={DaiLogo}
                          alt=''
                          className='threat-hunter-fraud-by-coin-logo'
                        ></img>{' '}
                        Dai
                      </div>
                    </div>
                    <div
                      style={{
                        height: '100%',
                      }}
                      className='threat-hunter-fraud-by-coin-levels-container'
                    >
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-10'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-10'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-9'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-9'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-8'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-8'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-7'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-7'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-6'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-6'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-5'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-5'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-4'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-4'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-3'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-3'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-2'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-2'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-1'></div>
                      </div>
                      <div className='threat-hunter-fraud-by-coin-level'>
                        <div className='threat-hunter-fraud-by-coin-level-percentage percentage-1'></div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
          <div
            style={{
              width: '60%',
              height: '100%',
            }}
          >
            <div>
              <CardWithLeftHeader
                header='Crypto market'
                css='crypto-dashboasrd-crypto-market-card'
                content={
                  <table className='threat-hunter-crypto-market-list-table'>
                    <tbody>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          #
                        </td>
                        <td>Name</td>
                        <td>Price</td>
                        <td>24h %</td>
                        <td>Market cap</td>
                        <td>Circulating supply</td>
                        <td>Last 7 days</td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          1
                        </td>
                        <td>
                          {' '}
                          <img
                            src={bitcoinLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Bitcoin
                        </td>
                        <td>$23,172.00</td>
                        <td>0.64%</td>
                        <td>$446,865,907,652</td>
                        <td>19,284,737 BTC</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          2
                        </td>
                        <td>
                          {' '}
                          <img
                            src={etheriumLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Etherium
                        </td>
                        <td>$1,669.75</td>
                        <td>1.59%</td>
                        <td>$204,334,020,867</td>
                        <td>122,373,866 ETH</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          3
                        </td>
                        <td>
                          {' '}
                          <img
                            src={tetherLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Tether
                        </td>
                        <td>$1.00</td>
                        <td>0.23%</td>
                        <td>$68,189,630,808</td>
                        <td>68,182,382,219 USDT</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          4
                        </td>
                        <td>
                          <img
                            src={bnbLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          BNB
                        </td>
                        <td>$325.61</td>
                        <td>1.44%</td>
                        <td>$51,413,158,627</td>
                        <td>157,899,823 BNB</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          5
                        </td>
                        <td>
                          <img
                            src={USDCoinLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          USD Coin
                        </td>
                        <td>$0.9999</td>
                        <td>0.01%</td>
                        <td>$41,506,049,749</td>
                        <td>$41,508,586,804 USDC</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          6
                        </td>
                        <td>
                          <img
                            src={xrpLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          XRP
                        </td>
                        <td>$0.3957</td>
                        <td>1.04%</td>
                        <td>$20,103,643,120</td>
                        <td>50,799,084,881 XRP</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          7
                        </td>
                        <td>
                          <img
                            src={binanceLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Binance
                        </td>
                        <td>$1.00</td>
                        <td>0.04%</td>
                        <td>$16,183,758,162</td>
                        <td>16,176,805,370 BUSD</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          8
                        </td>
                        <td>
                          <img
                            src={cardanoLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Cardano
                        </td>
                        <td>$0.3911</td>
                        <td>0.00%</td>
                        <td>$13,537,432,701</td>
                        <td>34,609,653,532 ADA</td>
                        <td></td>
                      </tr>
                      <tr className='threat-hunter-crypto-market-tr'>
                        <td className='threat-hunter-crypto-market-tr-number-td'>
                          9
                        </td>
                        <td>
                          <img
                            src={dogeLogo}
                            alt=''
                            className='threat-hunter-fraud-by-coin-logo'
                          ></img>{' '}
                          Dogecoin
                        </td>
                        <td>$0.08957</td>
                        <td>2.48%</td>
                        <td>$11,882,699,600</td>
                        <td>132,670,764,300 DOGE</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              />
            </div>
            <div
              style={{
                height: '300px',
                overflow: 'hidden',
              }}
            >
              {' '}
              <CardWithLeftHeader
                header='Fraud amount'
                css='crypto-dashboard-farud-amount-card'
                content={
                  <div>
                    <div>
                      <LineChart
                        width={675}
                        height={172}
                        margin={{ left: -42 }}
                        fontSize={10}
                        data={data}
                      >
                        <XAxis dataKey='xAxisVal' />
                        <YAxis dataKey='yAxisVal' />
                        <CartesianGrid stroke='#eee' strokeDasharray='10' />
                        <Line
                          type='monotone'
                          dataKey='pct'
                          stroke='#1DBAC5'
                          dot={false}
                        />
                      </LineChart>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: '350px',
          overflow: 'hidden',
        }}
      >
        <h4 style={{ textAlign: 'left', marginTop: '10px' }}>
          Crypto Fraud News
        </h4>
        <div style={{ display: 'flex' }}>{cryptoNews}</div>
      </div>
    </div>
  );
};
export default CryptoDashboard;
