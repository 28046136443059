import CountryList from '../components/CountryList';
import IocActivityList from '../components/IocActivityList';
import { useEffect, useState } from 'react';
import EditFormMUI from '../components/EditFormMUI';
import useGetRequest from '../hooks/use-get-request';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import IOCTypeList from '../components/IOCTypeList';
import IOCCategoryList from '../components/IOCCategoryList';
import ReputationList from '../components/ReputationList';

const EditIOCList = () => {
  const edit_ioc_id = sessionStorage.getItem('edit_ioc_id');
  const { data, getRequestFunc } = useGetRequest();
  const [country, setCountry] = useState(null);
  const [activity, setActivity] = useState(null);
  const [cyberCampaignID, setCyberCampaignID] = useState(null);
  const [reputation, setReputation] = useState(null);
  const [iocType, setIocType] = useState(null);
  const [iocCategory, setIocCategory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRequestFunc(`https://api.hackeye.io/api/v1/iocs/${edit_ioc_id}`, true);
  }, []);

  useEffect(() => {
    if (data) {
      setCountry(data.country);
      setActivity(data.activity);
      setCyberCampaignID(data.cyber_campaign_id);
    }
  }, [data]);

  const itemToEdit = async () => {
    const ioc_value = document.getElementById('ioc_value').value;
    const feed = document.getElementById('feed').value;
    const description = document.getElementById('description').value;
    const tags = document.getElementById('tags').value.split(',');
    const last_update = document.getElementById('last_update').value;

    const updatedIOCStr = JSON.stringify({
      ioc_value,
      cyber_campaign_id: cyberCampaignID || data.cyber_campaign_id,
      source_country: country || data.source_country,
      activity: activity || data.activity,
      reputation: reputation || data.reputation,
      ioc_type: iocType || data.ioc_type,
      ioc_category: iocCategory || data.ioc_category,
      last_update: last_update || data.last_update,
      feed,
      tags,
      description,
    });
    return updatedIOCStr;
  };

  return (
    data && (
      <EditFormMUI
        header='IOC'
        textInputs={[
          {
            label: 'Value',
            id: 'ioc_value',
            defaultValue: data.ioc_value,
          },
          { label: 'Feed', id: 'feed', defaultValue: data.feed },

          {
            label: 'Description',
            id: 'description',
            defaultValue: data.description,
          },
          {
            label: 'Tags',
            id: 'tags',
            defaultValue: data.tags.join(','),
          },

          {
            label: 'Last Update',
            id: 'last_update',
            type: 'date',
            defaultValue: data.last_update
              ? data.last_update.split('T')[0]
              : null,
          },
        ]}
        selectInputs={
          <>
            <ReputationList
              onChange={(e) => {
                setReputation(e.target.value);
              }}
            />
            <IOCCategoryList
              onChange={(e) => {
                setIocCategory(e.target.value);
              }}
            />
            <IOCTypeList
              onChange={(e) => {
                setIocType(e.target.value);
              }}
            />
            <CountryList
              id='country'
              header='Country'
              additionalData={`country in DB: ${data.source_country}`}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            <IocActivityList
              additionalData={`activity in DB: ${data.activity}`}
              onChange={(e) => {
                setActivity(e.target.value);
              }}
            />
            <FormSelectListDynamic
              header='Cyber Campaigns'
              path='cyber-campaigns-events'
              optVal='name'
              id='cyber_campaign_id'
              onChange={(e) => {
                setCyberCampaignID(e.target.value);
              }}
              additionalData={`cyber campaign in DB: ${data.cyber_campaign_id}`}
            />
          </>
        }
        itemToEdit={itemToEdit}
        path={`iocs/${edit_ioc_id}`}
      />
    )
  );
};
export default EditIOCList;
