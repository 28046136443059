import './UserList.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserList = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const displayedUsers = [];

  const getAllUsers = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/users', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          for (const user of res.data.data.doc) {
            displayedUsers.push(
              <div
                className={user.active ? 'user' : 'disabled-user'}
                key={user._id}
                id={user._id}
                onClick={() => {
                  sessionStorage.setItem('chosen_user_id', user._id);
                  navigate(`/users/${user._id}`);
                }}
              >
                {user.name}
              </div>
            );
          }
          setUsers(displayedUsers);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllUsers();
  }, []);

  return <div>{users}</div>;
};

export default UserList;
