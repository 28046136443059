import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import './MapMainDashboard.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { countryData } from '../../utils/countryLatLng';
import usePatchRequest from '../../hooks/use-patch-request';
import CardWithLeftHeader from '../CardWithLeftHeader';

const MapMainDashboard = () => {
  const token = sessionStorage.getItem('token');

  const { editRequestFunc } = usePatchRequest();
  let fetchedMapId = '';
  const [finalMap, setFinalMap] = useState();
  const [mostReportedData, setMostReportedData] = useState('');
  const coloredCountries = {};

  const map = {
    markers: [],
    series: {},
    regionStyle: {
      initial: {
        fill: '#959da2',
        fillOpacity: 0.35,
        stroke: 'none',
        strokeWidth: 0.4,
        strokeOpacity: 1,
      },
      hover: {
        fill: '#1dbac5',
        fillOpacity: 0.5,
      },
    },
    markerStyle: {
      initial: {
        fill: '#1dbac5',
        stroke: '#383f47',
      },
    },
    focusOn: {
      x: 0.5,
      y: 0.5,
      scale: 1,
    },
  };

  const updateCountryData = async (data, id) => {
    try {
      const newData = { information: data.top5, date: Date.now() };
      await editRequestFunc(
        `https://api.hackeye.io/api/v1/map-data/${id}`,
        newData,
        true
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getCountries = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/map-data', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          const data = res.data.data.doc[0];
          fetchedMapId = data.id;
          if (
            new Date(Date.now()).getTime() - new Date(data.date).getTime() <
            43200000
          ) {
            const { information } = data;
            showDataInMap(information);
          } else {
            try {
              await axios
                .get('https://api.hackeye.io/api/v1/abuseIPDB', {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(async (res) => {
                  const { top5 } = res.data;
                  await updateCountryData(res.data, fetchedMapId);
                  showDataInMap(top5);
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addCountriesToMapCb = (countries) => {
    for (const country of countries) {
      addCountryToMap(country);
    }
  };

  const addCountryToMap = (country) => {
    if (countryData[country]) {
      map.markers.push({
        latLng: countryData[country].latLng,
        name: countryData[country].name,
      });
    }
    coloredCountries[country] = '#1dbac5';

    setFinalMap(
      <VectorMap
        map={worldMill}
        backgroundColor='transparent'
        markers={map.markers}
        regionStyle={map.regionStyle}
        markerStyle={map.markerStyle}
        focusOn={map.focusOn}
        series={{
          regions: [
            {
              normalizeFunction: 'polynomial',
              attribute: 'fill',
              values: coloredCountries,
            },
          ],
        }}
        zoomOnScroll={false}
      />
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  const showDataInMap = (infoSource) => {
    const countryCodes = [];
    infoSource.forEach((code) => {
      countryCodes.push(code[0]);
    });

    addCountriesToMapCb(countryCodes);

    setMostReportedData(
      <div>
        <div className='main-dash-attack-by-source-country-list-item'>
          <div>{countryData[infoSource[0][0]].name}</div>
          <div>{infoSource[0][1]}</div>
        </div>
        <div className='main-dash-attack-by-source-country-list-item'>
          <div>{countryData[infoSource[1][0]].name}</div>
          <div>{infoSource[1][1]}</div>
        </div>
        <div className='main-dash-attack-by-source-country-list-item'>
          <div>{countryData[infoSource[2][0]].name}</div>
          <div>{infoSource[2][1]}</div>
        </div>
        <div className='main-dash-attack-by-source-country-list-item'>
          <div>{countryData[infoSource[3][0]].name}</div>
          <div>{infoSource[3][1]}</div>
        </div>
        <div className='main-dash-attack-by-source-country-list-item'>
          <div>{countryData[infoSource[4][0]].name}</div>
          <div>{infoSource[4][1]}</div>
        </div>
      </div>
    );
  };

  return (
    <CardWithLeftHeader
      css='main-dashboard-map-card'
      header='Attack By Source Country'
      content={
        <div>
          <div className='map-display-phone-size'>
            <div className='mb-3 main-dash-map-container-phone'>{finalMap}</div>
            <div className='row gx-4 main-dashboard-attack-by-source-country-data-phone'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>COUNTRY</div>
                <div>REPORTS</div>
              </div>

              {mostReportedData}
            </div>
          </div>

          <div className='map-display-desktop-size'>
            <div className='mb-3 main-dash-map-container-desktop'>
              {finalMap}
            </div>
            <div className='map-display-desktop-size-country-data-container'>
              <div className='row gx-4 main-dashboard-attack-by-source-country-data-desktop'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                    padding: '5px 0',
                  }}
                >
                  <div>COUNTRY</div>
                  <div>REPORTS</div>
                </div>

                {mostReportedData}
              </div>
            </div>
          </div>
        </div>
      }
    />
    // <div className='col-xl-6 main-dashboard-attack-by-source-country-container'>
    //   <Card
    //     content={
    //       <div>
    //         <div className='d-flex fw-bold small main-dashboard-attack-by-source-country-header-bg'>
    //           <span className='flex-grow-1 card-header'>
    //             Attack By Source Country
    //           </span>
    //         </div>

    //         <div className='map-display-phone-size'>
    //           <div className='mb-3 main-dash-map-container-phone'>
    //             {finalMap}
    //           </div>
    //           <div className='row gx-4 main-dashboard-attack-by-source-country-data-phone'>
    //             <div
    //               style={{ display: 'flex', justifyContent: 'space-between' }}
    //             >
    //               <div>COUNTRY</div>
    //               <div>REPORTS</div>
    //             </div>

    //             {mostReportedData}
    //           </div>
    //         </div>

    //         <div className='map-display-desktop-size'>
    //           <div className='mb-3 main-dash-map-container-desktop'>
    //             {finalMap}
    //           </div>
    //           <div className='map-display-desktop-size-country-data-container'>
    //             <div className='row gx-4 main-dashboard-attack-by-source-country-data-desktop'>
    //               <div
    //                 style={{
    //                   display: 'flex',
    //                   justifyContent: 'space-between',
    //                   fontSize: '14px',
    //                   paddingTop: '5px',
    //                 }}
    //               >
    //                 <div>COUNTRY</div>
    //                 <div>REPORTS</div>
    //               </div>

    //               {mostReportedData}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     }
    //   />
    // </div>
  );
};
export default MapMainDashboard;
