import './AntisemeticDashboard.css';
import AntisemeticTrendByMonthDash from '../components/antisemetic dashboard/AntisemeticTrendByMonthDash';
import AntisemeticKeywordsDash from '../components/antisemetic dashboard/AntisemeticKeywordsDash';
import AntisemeticTopCountriesDash from '../components/antisemetic dashboard/AntisemeticTopCountriesDash';
import AntisemeticPlatformDash from '../components/antisemetic dashboard/AntisemeticPlatformDash';
import AntisemeticWebsitesBlogsDash from '../components/antisemetic dashboard/AntisemeticWebsitesBlogsDash';
import AntisemeticYoutubeVideosDash from '../components/antisemetic dashboard/AntisemeticYoutubeVideosDash';

const AntisemeticDashboard = () => {
  return (
    <div className='antisemitism-dashboard-wrapper page-wrapper-scrollable'>
      <div className='antisemitism-dashboard-left-container'>
        <div className='antisemitism-dashboard-top-left-container'>
          <div className='antisemitism-dashboard-top-left-container-left'>
            <div className='antisemitism-dashboard-top-left-container-trend-card-wrapper'>
              <AntisemeticTrendByMonthDash />
            </div>
            <div className='antisemitism-dashboard-top-left-container-keywords-card-wrapper'>
              <AntisemeticKeywordsDash />
            </div>
          </div>
          <div className='antisemitism-dashboard-top-left-container-right-card'>
            <AntisemeticTopCountriesDash />
          </div>
        </div>
        <AntisemeticPlatformDash />
      </div>

      <div className='antisemitism-dashboard-right-container'>
        <div className='antisemitism-dashboard-right-top-card'>
          {' '}
          <div>
            <AntisemeticWebsitesBlogsDash />
          </div>
        </div>
        <div className='antisemitism-dashboard-right-bottom-card'>
          {' '}
          <div>
            <AntisemeticYoutubeVideosDash />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AntisemeticDashboard;
