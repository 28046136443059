import CountryList from '../components/CountryList';
import { useEffect, useState } from 'react';
import EditFormMUI from '../components/EditFormMUI';
import useGetRequest from '../hooks/use-get-request';
import { useNavigate } from 'react-router-dom';

const EditMostDangerousCountries = () => {
  const edit_most_dangerous_country = sessionStorage.getItem(
    'edit_most_dangerous_country'
  );
  const navigate = useNavigate();
  const { data, getRequestFunc } = useGetRequest();
  const [country, setCountry] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRequestFunc(
      `https://api.hackeye.io/api/v1/most-dangerous-countries/${edit_most_dangerous_country}`,
      true
    );
  }, []);

  const getItem = () => {
    const pct = document.getElementById('pct').value;

    const updatedIOCStr = JSON.stringify({
      pct,
      country: country || data.country,
    });
    return updatedIOCStr;
  };

  return (
    data && (
      <EditFormMUI
        header='Rule Repository Item'
        textInputs={[
          {
            label: 'Danger Level (1-10)',
            id: 'pct',
            defaultValue: data.pct,
            type: 'number',
            min: 1,
            max: 10,
          },
        ]}
        selectInputs={
          <CountryList
            id='country'
            header='country'
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            additionalData={`Country in DB: ${data.country}`}
          />
        }
        itemToEdit={getItem}
        path={`most-dangerous-countries/${data._id}`}
        afterDeleteFunc={() => {
          navigate('/terror-dashboard');
        }}
      />
    )
  );
};
export default EditMostDangerousCountries;
