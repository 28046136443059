import { PieChart, Pie } from 'recharts';
import './PieChartSingleValue.css';

const PieChartSingleValue = (props) => {
  const pieColors = {
    Critical: '#c51d31',
    High: '#d14d14',
    Medium: '#d99517',
    Low: '#2f6451',
  };

  return (
    <div className='piechart-single-value-wrapper'>
      <div className='piechart-single-value-container'>
        <PieChart width={210} height={210}>
          <Pie
            stroke='none'
            dataKey='value'
            isAnimationActive={true}
            data={[
              {
                value: 1,
                name: props.value,
                fill: pieColors[props.color],
              },
            ]}
            outerRadius={'100%'}
            innerRadius={90}
            cx='50%'
            cy='50%'
          />
        </PieChart>
        <div className='piechart-single-value-container-severity'>
          {props.header}:
          <div style={{ color: pieColors[props.color] }}>{props.value}</div>
        </div>
      </div>
    </div>
  );
};
export default PieChartSingleValue;
