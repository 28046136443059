import usePostRequest from '../../hooks/use-post-request';
import { useState, useEffect } from 'react';
import SeverityList from '../../components/SeverityList';
import CreationFormMUI from '../../components/CreationFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';
import InputMUI from '../../components/InputMUI';

const CreateAlert = () => {
  const { postSuccess, postError, postFeedback, postRequestFunc, postLoading } =
    usePostRequest();

  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [chosenGroup, setChosenGroup] = useState('N/A');
  const [tags, setTags] = useState([]);
  const [severity, setSeverity] = useState('N/A');
  const [tlp, setTlp] = useState('N/A');
  const [type, setType] = useState('N/A');

  const createNewItem = async () => {
    const subject = document.getElementById('subject').value;
    const content = document.getElementById('content').value;

    const newAlert = {
      group: chosenGroup,
      type,
      tlp,
      severity,
      subject,
      content,
      createdAt: new Date(Date.now()),
      tags,
    };

    if (chosenGroup === 'Specific Person') {
      newAlert.to = user;
      newAlert.specific_user = user;
    } else if (chosenGroup === 'Specific Organization') {
      alert.to = organization;
      alert.specific_organiztion = organization;
    }

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/alerts',
      JSON.stringify(newAlert),
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Alert'
      textInputs={[
        { label: 'Subject', id: 'subject' },
        { label: 'Content', id: 'content' },
        { label: 'Number of Users', id: 'numOfUsers', type: 'number' },
      ]}
      selectInputs={
        <>
          <FormSelectListStatic
            id='group'
            header='Group'
            onChange={(e) => {
              setChosenGroup(e.target.value);
            }}
            options={[
              'Country',
              'Industry',
              'Specific Person',
              'Specific Organization',
              'Company',
              'SOAR/SIEM',
            ]}
          />
          <SeverityList
            onChange={(e) => {
              setSeverity(e.target.value);
            }}
          />
          <FormSelectListStatic
            onChange={(e) => {
              setTlp(e.target.value);
            }}
            id='tlp'
            header='TLP'
            options={['White', 'Green', 'Amber', 'Red']}
          />
          <FormSelectListStatic
            id='tags'
            header='Tags'
            options={['Phishing', 'Exploit', 'Security']}
            onChange={(e) => {
              setTags((prev) => {
                return [...prev, e.target.value];
              });
            }}
          />
          <FormSelectListStatic
            onChange={(e) => {
              setType(e.target.value);
            }}
            id='type'
            header='Type'
            options={[
              'Intelligence',
              'Risk',
              'Vulnerability',
              'Data Leakage',
              'Crime',
              'Terror',
            ]}
          />
          {chosenGroup === 'Specific Person' && (
            <FormSelectListDynamic
              header='User'
              path='users'
              optVal='name'
              id='specific_user'
              onChange={(e) => {
                setUser(e.target.value);
              }}
            />
          )}
          {chosenGroup === 'Specific Organization' && (
            <FormSelectListDynamic
              header='Organization'
              path='organizations'
              optVal='name'
              id='specific_organiztion'
              onChange={(e) => {
                setOrganization(e.target.value);
              }}
            />
          )}
          {chosenGroup !== 'Specific Organization' &&
            chosenGroup !== 'Specific Person' && (
              <InputMUI id='to' label='To' />
            )}
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateAlert;
