// import InputSelect from '../components/InputSelect';
import CountryList from '../components/CountryList';
import usePostRequest from '../hooks/use-post-request';
import { useEffect, useState } from 'react';
import CreationFormMUI from '../components/CreationFormMUI';

const CreateMostDangerousCountry = () => {
  const { postSuccess, postError, postFeedback, postLoading, postRequestFunc } =
    usePostRequest();
  const [country, setCountry] = useState('N/A');

  const createNewItem = async () => {
    const pct = document.getElementById('pct').value;

    const newItemStr = JSON.stringify({
      country,
      pct,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/most-dangerous-countries',
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Dangerous Country'
      textInputs={[
        {
          label: 'Danger Level (1-10)',
          id: 'pct',
          type: 'number',
          min: 1,
          max: 10,
          defaultValue: 1,
        },
      ]}
      selectInputs={
        <CountryList
          id='country'
          header='Country'
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateMostDangerousCountry;
