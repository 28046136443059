import usePostRequest from '../hooks/use-post-request';
import { useEffect, useState } from 'react';
import SeverityList from '../components/SeverityList';
import CreationFormMUI from '../components/CreationFormMUI';

const CreateRuleRepositoryItem = () => {
  const { postSuccess, postError, postFeedback, postRequestFunc, postLoading } =
    usePostRequest();
  const [severity, setSeverity] = useState('N/A');

  const createNewItem = async () => {
    const rule_name = document.getElementById('rule_name').value;
    const product = document.getElementById('product').value;
    const description = document.getElementById('description').value;
    const mitre_tactic = document.getElementById('mitre_tactic').value;
    const mitre_technique = document.getElementById('mitre_technique').value;

    const newItemStr = JSON.stringify({
      rule_name,
      product,
      description,
      mitre_tactic,
      mitre_technique,
      severity,
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/rule-repository',
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Threat Actor Cyber Campaign'
      textInputs={[
        { label: 'Rule Name', id: 'rule_name' },
        { label: 'Product', id: 'product' },
        { label: 'Description', id: 'description' },
        { label: 'Mitre Tactic', id: 'mitre_tactic' },
        { label: 'Mitre Technique', id: 'mitre_technique' },
      ]}
      selectInputs={
        <SeverityList
          onChange={(e) => {
            setSeverity(e.target.value);
          }}
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateRuleRepositoryItem;
