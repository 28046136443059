import FormSelectListStatic from './FormSelectListStatic';

const EventIdentifierTypeList = (props) => {
  return (
    <FormSelectListStatic
      id='type'
      onChange={props.onChange}
      header='Type'
      options={[
        'OS Application',
        'Security',
        'Setup',
        'System',
        'Applications & Services Logs',
      ]}
    />
  );
};
export default EventIdentifierTypeList;
