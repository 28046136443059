import './EmergingThreat.css';
import { useState, useEffect } from 'react';

import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';
import { useNavigate } from 'react-router-dom';
import PieChartSingleValue from '../../components/PieChartSingleValue';
import { dateFormat } from '../../utils/dateFormat';
import useDeleteRequest from '../../hooks/use-delete-request';
import useGetRequest from '../../hooks/use-get-request';
import info from '../../assets/images/info-icon.png';

const EmergingThreat = () => {
  const threatID = sessionStorage.getItem('emerging_threat');
  const { deleteRequestFunc } = useDeleteRequest();
  const { getRequestFunc, data } = useGetRequest();

  const navigate = useNavigate();
  const [emergingThreat, setEmergingThreat] = useState();

  const getThreat = () => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/emerging-threats/${threatID}`,
      true
    );
  };

  useEffect(() => {
    getThreat();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      setEmergingThreat(data);
    }
  }, [data]);

  const handleEmergingThreatDeleted = () => {
    emergingThreat &&
      emergingThreat.recommendations &&
      emergingThreat.recommendations.length > 0 &&
      emergingThreat.recommendations.map((rec) => {
        deleteRequestFunc(
          `https://api.hackeye.io/api/v1/emerging-threat-recommendations/${rec._id}`,
          true
        );
      });
    navigate('/emerging-threats');
    sessionStorage.removeItem('emerging_threat');
  };

  return (
    emergingThreat && (
      <div className='emerging-threat-wrapper'>
        <div className='emerging-threat-details-wrapper'>
          {emergingThreat.severity && (
            <PieChartSingleValue
              color={emergingThreat.severity}
              value={emergingThreat.severity}
              header='Severity'
            />
          )}

          <div className='emerging-threat-details-container'>
            <div className='emerging-threat-detail'>
              <div>
                Vulnerability Name: {'  '}
                <span>{emergingThreat.threatname}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                ID:{'  '} <span>{emergingThreat.cveid}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                CVSS Score:{'  '} <span>{emergingThreat.cvssv3score}</span>
              </div>
            </div>
            <div className='emerging-threat-detail'>
              <div>
                Last Update:{'  '}{' '}
                <span>{dateFormat(emergingThreat.date)}</span>
              </div>
            </div>
            {emergingThreat.affectedproducts && (
              <div className='emerging-threat-detail'>
                <div>
                  Affected Products:{'  '}
                  <span>
                    {emergingThreat.affectedproducts
                      .split(',')
                      .map((product, i) => {
                        return (
                          <button
                            className='emerging-threat-affected-product'
                            key={i}
                          >
                            {product.replaceAll('&lt;', '')}
                          </button>
                        );
                      })}
                  </span>
                </div>
              </div>
            )}
            <div className='emerging-threat-detail'>
              <div>
                Description:{'  '} <span>{emergingThreat.summary}</span>
              </div>
            </div>
          </div>
          <div>
            <DeleteAndEditButtons
              id={emergingThreat.id}
              path={'emerging-threats'}
              afterDeleteFunc={handleEmergingThreatDeleted}
              navigateToEdit={`/edit-emerging-threats/${emergingThreat.id}`}
            />
          </div>
        </div>

        <div>
          <div className='bloc-tabs mb-3'>
            <button className='tabs active-tabs table-single-tab'>
              Recommendations
            </button>
          </div>
          <div>
            {emergingThreat.recommendations &&
            emergingThreat.recommendations.length > 0 ? (
              emergingThreat.recommendations.map((rec, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      textAlign: 'left',
                      margin: '7px 0',
                      padding: '10px',
                      color: '#fff',
                      background: 'rgb(37,40,71)',
                      borderRadius: '10px',
                      height: '42px',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <img
                        style={{ height: '18px', width: '18px' }}
                        src={info}
                        alt=''
                      />{' '}
                      {rec.recommendation}
                    </div>
                    <DeleteAndEditButtons
                      id={rec._id}
                      path='emerging-threat-recommendations'
                      afterDeleteFunc={() => {
                        setEmergingThreat(undefined);
                        getThreat();
                      }}
                      editFunc={() => {
                        sessionStorage.setItem(
                          'edit_emerging_threat_recommendation',
                          rec._id
                        );
                      }}
                      navigateToEdit='/edit-emerging-threat-recommendation'
                    />
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  textAlign: 'left',
                  margin: '7px 0',
                  padding: '10px',

                  color: '#fff',
                  background: 'rgb(37,40,71)',
                  borderRadius: '10px',
                  height: '42px',
                  overflow: 'hidden',
                }}
              >
                <img
                  style={{ height: '18px', width: '18px' }}
                  src={info}
                  alt=''
                />{' '}
                N/A
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default EmergingThreat;
