export const toggleDropMenu = (el) => {
  const element = document.querySelector(`.${el}`);
  element.style.display !== 'block'
    ? (element.style.display = 'block')
    : (element.style.display = 'none');

  setTimeout(() => {
    element.style.display = 'none';
  }, 5000);
};
