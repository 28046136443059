import axios from 'axios';
import { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import './AlertsMainDashboard.css';
import { useSelector } from 'react-redux';

const AlertsMainDashboard = () => {
  const { user_id } = useSelector((state) => state.appReducer);
  const token =
    sessionStorage.getItem('token') || localStorage.getItem('token');

  const [critical, setCritical] = useState(undefined);
  const [high, setHigh] = useState(undefined);
  const [medium, setMedium] = useState(undefined);
  const [low, setLow] = useState(undefined);

  const severities = { low: 0, medium: 0, high: 0, critical: 0 };

  const getAlerts = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/alerts', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { doc } = res.data.data;
          for (const alert of Object.values(doc)) {
            if (
              !alert.openStatus[user_id] ||
              alert.openStatus[user_id] === 'New'
            ) {
              if (alert.severity === 'Critical') {
                severities.critical += 1;
              } else if (alert.severity === 'High') {
                severities.high += 1;
              } else if (alert.severity === 'Medium') {
                severities.medium += 1;
              } else if (alert.severity === 'Low') {
                severities.low += 1;
              }
            }
          }
          setCritical(severities.critical);
          setHigh(severities.high);
          setMedium(severities.medium);
          setLow(severities.low);
        })
        .catch((err) => {
          console.log('failed: ' + err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user_id) {
      getAlerts();
    }
  }, [user_id]);

  return (
    <div>
      <div className='main-dash-alerts-pie-chart-container mb-3'>
        <ResponsiveContainer>
          <PieChart width={100} height={100}>
            <Pie
              stroke='none'
              dataKey='value'
              isAnimationActive={true}
              data={
                critical || high || medium || low
                  ? [
                      { value: critical, name: 'Critical', fill: '#c51d31' },
                      { value: high, name: 'High', fill: '#d14d14' },
                      { value: medium, name: 'Medium', fill: '#d99517' },
                      { value: low, name: 'Low', fill: '#2f6451' },
                    ]
                  : [{ value: 1, name: 'None', fill: '#1c2e38' }]
              }
              outerRadius={'100%'}
              innerRadius={50}
              cx='50%'
              cy='50%'
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className='main-dash-real-time-alerts-severity-wrapper'>
        <div className='main-dash-real-time-alerts-severity-number-container'>
          {' '}
          <span className='color-red'>Critical</span>
          <span className='main-dash-real-time-alerts-severity-number'>
            {critical}
          </span>
        </div>
        <div className='main-dash-real-time-alerts-severity-number-container'>
          {' '}
          <span className='color-orange'>High</span>
          <span className='main-dash-real-time-alerts-severity-number'>
            {high}
          </span>
        </div>
        <div className='main-dash-real-time-alerts-severity-number-container'>
          {' '}
          <span className='color-yellow'>Medium</span>
          <span className='main-dash-real-time-alerts-severity-number'>
            {medium}
          </span>
        </div>
        <div className='main-dash-real-time-alerts-severity-number-container'>
          {' '}
          <span className='color-green'>Low</span>
          <span className='main-dash-real-time-alerts-severity-number'>
            {low}
          </span>
        </div>
      </div>
    </div>
  );
};
export default AlertsMainDashboard;
