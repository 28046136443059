export const countryData = {
  AL: {
    name: 'Albania',
    latLng: [41.01, 20.01],
  },
  DZ: {
    name: 'Algeria',
    latLng: [28.01, 3.01],
  },
  AS: {
    name: 'American Samoa',
    latLng: [-14.33, -170.01],
  },
  AD: {
    name: 'Andorra',
    latLng: [42.5, 1.6],
  },
  AO: {
    name: 'Angola',
    latLng: [-12.5, 18.5],
  },
  AI: {
    name: 'Anguilla',
    latLng: [18.25, -63.1667],
  },
  AQ: {
    name: 'Antarctica',
    latLng: [-90, 0],
  },
  AG: {
    name: 'Antigua & Barbuda',
    latLng: [17.05, -61.8],
  },
  AR: {
    name: 'Argentina',
    latLng: [-34, -64],
  },
  AM: {
    name: 'Armenia',
    latLng: [40, 45],
  },
  AW: {
    name: 'Aruba',
    latLng: [12.5, -69.9667],
  },
  AU: {
    name: 'Australia',
    latLng: [-27, 133],
  },
  AT: {
    name: 'Austria',
    latLng: [47.3333, 13.3333],
  },
  AZ: {
    name: 'Azerbaijan',
    latLng: [40.5, 47.5],
  },
  BS: {
    name: 'Bahamas',
    latLng: [24.25, -76],
  },
  BH: {
    name: 'Bahrain',
    latLng: [26, 50.55],
  },
  BD: {
    name: 'Bangladesh',
    latLng: [24, 90],
  },
  BB: {
    name: 'Barbados',
    latLng: [13.1667, -59.5333],
  },
  BY: {
    name: 'Belarus',
    latLng: [53, 28],
  },
  BE: {
    name: 'Belgium',
    latLng: [50.8333, 4],
  },
  BZ: {
    name: 'Belize',
    latLng: [17.25, -88.75],
  },
  BJ: {
    name: 'Benin',
    latLng: [9.5, 2.25],
  },
  BM: {
    name: 'Bermuda',
    latLng: [32.3333, -64.75],
  },
  BT: {
    name: 'Bhutan',
    latLng: [27.5, 90.5],
  },
  BO: {
    name: 'Bolivia',
    latLng: [-17, -65],
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    latLng: [44, 18],
  },
  BW: {
    name: 'Botswana',
    latLng: [-22, 24],
  },
  BV: {
    name: 'Bouvet Island',
    latLng: [-54.4333, 3.4],
  },
  BR: {
    name: 'Brazil',
    latLng: [-10, -55],
  },
  IO: {
    name: 'British Indian Ocean Territory',
    latLng: [-6, 71.5],
  },
  BN: {
    name: 'Brunei Darussalam',
    latLng: [4.5, 114.6667],
  },
  BG: {
    name: 'Bulgaria',
    latLng: [43, 25],
  },
  BF: {
    name: 'Burkina Faso',
    latLng: [13, -2],
  },
  BI: {
    name: 'Burundi',
    latLng: [-3.5, 30],
  },
  KH: {
    name: 'Cambodia',
    latLng: [13, 105],
  },
  CM: {
    name: 'Cameroon',
    latLng: [6, 12],
  },
  CA: {
    name: 'Canada',
    latLng: [60, -95],
  },
  CV: {
    name: 'Cape Verde',
    latLng: [16, -24],
  },
  KY: {
    name: 'Cayman Islands',
    latLng: [19.5, -80.5],
  },
  CF: {
    name: 'Central African Republic',
    latLng: [7, 21],
  },
  TD: {
    name: 'Chad',
    latLng: [15, 19],
  },
  CL: {
    name: 'Chile',
    latLng: [-30, -71],
  },
  CN: {
    name: 'China',
    latLng: [35, 105],
  },
  CX: {
    name: 'Christmas Island',
    latLng: [-10.5, 105.6667],
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    latLng: [-12.5, 96.8333],
  },
  CO: {
    name: 'Colombia',
    latLng: [4, -72],
  },
  KM: {
    name: 'Comoros',
    latLng: [-12.1667, 44.25],
  },
  CG: {
    name: 'Congo',
    latLng: [-1, 15],
  },
  CD: {
    name: 'Congo, Democratic Republic',
    latLng: [0, 25],
  },
  CK: {
    name: 'Cook Islands',
    latLng: [-21.2333, -159.7667],
  },
  CR: {
    name: 'Costa Rica',
    latLng: [10, -84],
  },
  CI: {
    name: "Côte d'Ivoire",
    latLng: [8, -5],
  },
  HR: {
    name: 'Croatia',
    latLng: [45.1667, 15.5],
  },
  CU: {
    name: 'Cuba',
    latLng: [21.5, -80],
  },
  CY: {
    name: 'Cyprus',
    latLng: [35, 33],
  },
  CZ: {
    name: 'Czech Republic',
    latLng: [49.75, 15.5],
  },
  DK: {
    name: 'Denmark',
    latLng: [56, 10],
  },
  DJ: {
    name: 'Djibouti',
    latLng: [11.5, 43],
  },
  DM: {
    name: 'Dominica',
    latLng: [15.4167, -61.3333],
  },
  DO: {
    name: 'Dominican Republic',
    latLng: [19, -70.6667],
  },
  EC: {
    name: 'Ecuador',
    latLng: [-2, -77.5],
  },
  EG: {
    name: 'Egypt',
    latLng: [27, 30],
  },
  SV: {
    name: 'El Salvador',
    latLng: [13.8333, -88.9167],
  },
  GQ: {
    name: 'Equatorial Guinea',
    latLng: [2, 10],
  },
  ER: {
    name: 'Eritrea',
    latLng: [15, 39],
  },
  EE: {
    name: 'Estonia',
    latLng: [59, 26],
  },
  ET: {
    name: 'Ethiopia',
    latLng: [8, 38],
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    latLng: [-51.75, -59],
  },
  FO: {
    name: 'Faroe Islands',
    latLng: [62, -7],
  },
  FJ: {
    name: 'Fiji',
    latLng: [-18, 175],
  },
  FI: {
    name: 'Finland',
    latLng: [64, 26],
  },
  FR: {
    name: 'France',
    latLng: [46, 2],
  },
  GF: {
    name: 'French Guiana',
    latLng: [4, -53],
  },
  PF: {
    name: 'French Polynesia',
    latLng: [-15, -140],
  },
  TF: {
    name: 'French Southern Territories',
    latLng: [-43, 67],
  },
  GA: {
    name: 'Gabon',
    latLng: [-1, 11.75],
  },
  GM: {
    name: 'Gambia',
    latLng: [13.4667, -16.5667],
  },
  GE: {
    name: 'Georgia',
    latLng: [42, 43.5],
  },
  DE: {
    name: 'Germany',
    latLng: [51, 9],
  },
  GH: {
    name: 'Ghana',
    latLng: [8, -2],
  },
  GI: {
    name: 'Gibraltar',
    latLng: [36.1833, -5.3667],
  },
  GR: {
    name: 'Greece',
    latLng: [39, 22],
  },
  GL: {
    name: 'Greenland',
    latLng: [72, -40],
  },
  GD: {
    name: 'Grenada',
    latLng: [12.1167, -61.6667],
  },
  GP: {
    name: 'Guadeloupe',
    latLng: [16.25, -61.5833],
  },
  GU: {
    name: 'Guam',
    latLng: [13.4667, 144.7833],
  },
  GT: {
    name: 'Guatemala',
    latLng: [15.5, -90.25],
  },
  GG: {
    name: 'Guernsey',
    latLng: [49.5, -2.56],
  },
  GN: {
    name: 'Guinea',
    latLng: [11, -10],
  },
  GW: {
    name: 'Guinea-Bissau',
    latLng: [12, -15],
  },
  GY: {
    name: 'Guyana',
    latLng: [5, -59],
  },
  HT: {
    name: 'Haiti',
    latLng: [19, -72.4167],
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    latLng: [-53.1, 72.5167],
  },
  VA: {
    name: 'Vatican',
    latLng: [41.9, 12.45],
  },
  HN: {
    name: 'Honduras',
    latLng: [15, -86.5],
  },
  HK: {
    name: 'Hong Kong',
    latLng: [22.25, 114.1667],
  },
  HU: {
    name: 'Hungary',
    latLng: [47, 20],
  },
  IS: {
    name: 'Iceland',
    latLng: [65, -18],
  },
  IN: {
    name: 'India',
    latLng: [20, 77],
  },
  ID: {
    name: 'Indonesia',
    latLng: [-5, 120],
  },
  IR: {
    name: 'Iran',
    latLng: [32, 53],
  },
  IQ: {
    name: 'Iraq',
    latLng: [33, 44],
  },
  IE: {
    name: 'Ireland',
    latLng: [53, -8],
  },
  IM: {
    name: 'Isle of Man',
    latLng: [54.23, -4.55],
  },
  IL: {
    name: 'Israel',
    latLng: [31.5, 34.75],
  },
  IT: {
    name: 'Italy',
    latLng: [42.8333, 12.8333],
  },
  JM: {
    name: 'Jamaica',
    latLng: [18.25, -77.5],
  },
  JP: {
    name: 'Japan',
    latLng: [36, 138],
  },
  JE: {
    name: 'Jersey',
    latLng: [49.21, -2.13],
  },
  JO: {
    name: 'Jordan',
    latLng: [31, 36],
  },
  KZ: {
    name: 'Kazakhstan',
    latLng: [48, 68],
  },
  KE: {
    name: 'Kenya',
    latLng: [1, 38],
  },
  KI: {
    name: 'Kiribati',
    latLng: [1.4167, 173],
  },
  KR: {
    name: 'South Korea',
    latLng: [40, 127],
  },
  KP: {
    name: 'North Korea',
    latLng: [37, 127.5],
  },
  KW: {
    name: 'Kuwait',
    latLng: [29.3375, 47.6581],
  },
  KG: {
    name: 'Kyrgyzstan',
    latLng: [41, 75],
  },
  LA: {
    name: 'Laos',
    latLng: [18, 105],
  },
  LV: {
    name: 'Latvia',
    latLng: [57, 25],
  },
  LB: {
    name: 'Lebanon',
    latLng: [33.8333, 35.8333],
  },
  LS: {
    name: 'Lesotho',
    latLng: [-29.5, 28.5],
  },
  LR: {
    name: 'Liberia',
    latLng: [6.5, -9.5],
  },
  LY: {
    name: 'Libya',
    latLng: [25, 17],
  },
  LI: {
    name: 'Liechtenstein',
    latLng: [47.1667, 9.5333],
  },
  LT: {
    name: 'Lithuania',
    latLng: [56, 24],
  },
  LU: {
    name: 'Luxembourg',
    latLng: [49.75, 6.1667],
  },
  MO: {
    name: 'Macao',
    latLng: [22.1667, 113.55],
  },
  MK: {
    name: 'Macedonia',
    latLng: [41.8333, 22],
  },
  MG: {
    name: 'Madagascar',
    latLng: [-20, 47],
  },
  MW: {
    name: 'Malawi',
    latLng: [-13.5, 34],
  },
  MY: {
    name: 'Malaysia',
    latLng: [2.5, 112.5],
  },
  MV: {
    name: 'Maldives',
    latLng: [3.25, 73],
  },
  ML: {
    name: 'Mali',
    latLng: [17, -4],
  },
  MT: {
    name: 'Malta',
    latLng: [35.8333, 14.5833],
  },
  MH: {
    name: 'Marshall Islands',
    latLng: [9, 168],
  },
  MQ: {
    name: 'Martinique',
    latLng: [14.6667, -61],
  },
  MR: {
    name: 'Mauritania',
    latLng: [20, -12],
  },
  MU: {
    name: 'Mauritius',
    latLng: [-20.2833, 57.55],
  },
  YT: {
    name: 'Mayotte',
    latLng: [-12.8333, 45.1667],
  },
  MX: {
    name: 'Mexico',
    latLng: [23, -102],
  },
  FM: {
    name: 'Micronesia',
    latLng: [6.9167, 158.25],
  },
  MD: {
    name: 'Moldova',
    latLng: [47, 29],
  },
  MC: {
    name: 'Monaco',
    latLng: [43.7333, 7.4],
  },
  MN: {
    name: 'Mongolia',
    latLng: [46, 105],
  },
  ME: {
    name: 'Montenegro',
    latLng: [42, 19],
  },
  MS: {
    name: 'Montserrat',
    latLng: [16.75, -62.2],
  },
  MA: {
    name: 'Morocco',
    latLng: [32, -5],
  },
  MZ: {
    name: 'Mozambique',
    latLng: [-18.25, 35],
  },
  MM: {
    name: 'Myanmar',
    latLng: [22, 98],
  },
  NA: {
    name: 'Namibia',
    latLng: [-22, 17],
  },
  NR: {
    name: 'Nauru',
    latLng: [-0.5333, 166.9167],
  },
  NP: {
    name: 'Nepal',
    latLng: [28, 84],
  },
  NL: {
    name: 'Netherlands',
    latLng: [52.5, 5.75],
  },
  AN: {
    name: 'Netherlands Antilles',
    latLng: [12.25, -68.75],
  },
  NC: {
    name: 'New Caledonia',
    latLng: [-21.5, 165.5],
  },
  NZ: {
    name: 'New Zealand',
    latLng: [-41, 174],
  },
  NI: {
    name: 'Nicaragua',
    latLng: [13, -85],
  },
  NE: {
    name: 'Niger',
    latLng: [16, 8],
  },
  NG: {
    name: 'Nigeria',
    latLng: [10, 8],
  },
  NU: {
    name: 'Niue',
    latLng: [-19.0333, -169.8667],
  },
  NF: {
    name: 'Norfolk Island',
    latLng: [-29.0333, 167.95],
  },
  MP: {
    name: 'Northern Mariana Islands',
    latLng: [15.2, 145.75],
  },
  NO: {
    name: 'Norway',
    latLng: [62, 10],
  },
  OM: {
    name: 'Oman',
    latLng: [21, 57],
  },
  PK: {
    name: 'Pakistan',
    latLng: [30, 70],
  },
  PW: {
    name: 'Palau',
    latLng: [7.5, 134.5],
  },
  PS: {
    name: 'Palestine',
    latLng: [32, 35.25],
  },
  PA: {
    name: 'Panama',
    latLng: [9, -80],
  },
  PG: {
    name: 'Papua New Guinea',
    latLng: [-6, 147],
  },
  PY: {
    name: 'Paraguay',
    latLng: [-23, -58],
  },
  PE: {
    name: 'Peru',
    latLng: [-10, -76],
  },
  PH: {
    name: 'Philippines',
    latLng: [13, 122],
  },
  PN: {
    name: 'Pitcairn',
    latLng: [-24.7, -127.4],
  },
  PL: {
    name: 'Poland',
    latLng: [52, 20],
  },
  PT: {
    name: 'Portugal',
    latLng: [39.5, -8],
  },
  PR: {
    name: 'Puerto Rico',
    latLng: [18.25, -66.5],
  },
  QA: {
    name: 'Qatar',
    latLng: [25.5, 51.25],
  },
  RE: {
    name: 'Réunion',
    latLng: [-21.1, 55.6],
  },
  RO: {
    name: 'Romania',
    latLng: [46, 25],
  },
  RU: {
    name: 'Russia',
    latLng: [60, 100],
  },
  RW: {
    name: 'Rwanda',
    latLng: [-2, 30],
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    latLng: [-15.9333, -5.7],
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    latLng: [17.3333, -62.75],
  },
  LC: {
    name: 'Saint Lucia',
    latLng: [13.8833, -61.1333],
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    latLng: [46.8333, -56.3333],
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    latLng: [13.25, -61.2],
  },
  WS: {
    name: 'Samoa',
    latLng: [-13.5833, -172.3333],
  },
  SM: {
    name: 'San Marino',
    latLng: [43.7667, 12.4167],
  },
  ST: {
    name: 'Sao Tome and Principe',
    latLng: [1, 7],
  },
  SA: {
    name: 'Saudi Arabia',
    latLng: [25, 45],
  },
  SN: {
    name: 'Senegal',
    latLng: [14, -14],
  },
  RS: {
    name: 'Serbia',
    latLng: [44, 21],
  },
  SC: {
    name: 'Seychelles',
    latLng: [-4.5833, 55.6667],
  },
  SL: {
    name: 'Sierra Leone',
    latLng: [8.5, -11.5],
  },
  SG: {
    name: 'Singapore',
    latLng: [1.3667, 103.8],
  },
  SK: {
    name: 'Slovakia',
    latLng: [48.6667, 19.5],
  },
  SI: {
    name: 'Slovenia',
    latLng: [46, 15],
  },
  SB: {
    name: 'Solomon Islands',
    latLng: [-8, 159],
  },
  SO: {
    name: 'Somalia',
    latLng: [10, 49],
  },
  ZA: {
    name: 'South Africa',
    latLng: [-29, 24],
  },
  GS: {
    name: 'South Georgia, South Sandwich Islands',
    latLng: [-54.5, -37],
  },
  ES: {
    name: 'Spain',
    latLng: [40, -4],
  },
  LK: {
    name: 'Sri Lanka',
    latLng: [7, 81],
  },
  SD: {
    name: 'Sudan',
    latLng: [15, 30],
  },
  SR: {
    name: 'Suriname',
    latLng: [4, -56],
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    latLng: [78, 20],
  },
  SZ: {
    name: 'Swaziland',
    latLng: [-26.5, 31.5],
  },
  SE: {
    name: 'Sweden',
    latLng: [62, 15],
  },
  CH: {
    name: 'Switzerland',
    latLng: [47, 8],
  },
  SY: {
    name: 'Syria',
    latLng: [35, 38],
  },
  TW: {
    name: 'Taiwan',
    latLng: [23.5, 121],
  },
  TJ: {
    name: 'Tajikistan',
    latLng: [39, 71],
  },
  TZ: {
    name: 'Tanzania',
    latLng: [-6, 35],
  },
  TH: {
    name: 'Thailand',
    latLng: [15, 100],
  },
  TL: {
    name: 'Timor-Leste',
    latLng: [-8.55, 125.5167],
  },
  TG: {
    name: 'Togo',
    latLng: [8, 1.1667],
  },
  TK: {
    name: 'Tokelau',
    latLng: [-9, -172],
  },
  TO: {
    name: 'Tonga',
    latLng: [-20, -175],
  },
  TT: {
    name: 'Trinidad and Tobago',
    latLng: [11, -61],
  },
  TN: {
    name: 'Tunisia',
    latLng: [34, 9],
  },
  TR: {
    name: 'Turkey',
    latLng: [39, 35],
  },
  TM: {
    name: 'Turkmenistan',
    latLng: [40, 60],
  },
  TC: {
    name: 'Turks and Caicos Islands',
    latLng: [21.75, -71.5833],
  },
  TV: {
    name: 'Tuvalu',
    latLng: [-8, 178],
  },
  UG: {
    name: 'Uganda',
    latLng: [1, 32],
  },
  UA: {
    name: 'Ukraine',
    latLng: [49, 32],
  },
  AE: {
    name: 'United Arab Emirates',
    latLng: [24, 54],
  },
  GB: {
    name: 'United Kingdom',
    latLng: [54, -2],
  },
  US: {
    name: 'United States',
    latLng: [38, -97],
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    latLng: [19.2833, 166.6],
  },
  UY: {
    name: 'Uruguay',
    latLng: [-33, -56],
  },
  UZ: {
    name: 'Uzbekistan',
    latLng: [41, 64],
  },
  VU: {
    name: 'Vanuatu',
    latLng: [-16, 167],
  },
  VE: {
    name: 'Venezuela',
    latLng: [8, -66],
  },
  VN: {
    name: 'Viet Nam',
    latLng: [16, 106],
  },
  VG: {
    name: 'Virgin Islands, British',
    latLng: [18.5, -64.5],
  },
  VI: {
    name: 'Virgin Islands, U.S.',
    latLng: [18.3333, -64.8333],
  },
  WF: {
    name: 'Wallis and Futuna',
    latLng: [-13.3, -176.2],
  },
  EH: {
    name: 'Western Sahara',
    latLng: [24.5, -13],
  },
  YE: {
    name: 'Yemen',
    latLng: [15, 48],
  },
  ZM: {
    name: 'Zambia',
    latLng: [-15, 30],
  },
  ZW: {
    name: 'Zimbabwe',
    latLng: [-20, 30],
  },
  AF: {
    name: 'Afghanistan',
    latLng: [33, 65],
  },
};
