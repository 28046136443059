import { useState, useEffect } from 'react';
import useGetRequest from '../../hooks/use-get-request';
import { dateFormat } from '../../utils/dateFormat';
import Card from '../../components/Card';
import { renderCenteredCardLabelValue } from '../../utils/renderCenteredCardLabelValue';
import DeleteAndEditButtons from '../../components/DeleteAndEditButtons';

const UserDetails = () => {
  const chosen_user_id = sessionStorage.getItem('chosen_user_id');
  const {
    getError,
    getSuccess,
    setGetSuccess,
    getLoading,
    setGetLoading,
    getRequestFunc,
    data,
    setData,
  } = useGetRequest();

  const [user, setUser] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    getRequestFunc(
      `https://api.hackeye.io/api/v1/users/${chosen_user_id}`,
      true
    );
  }, []);

  useEffect(() => {
    if (data) {
      setUser(
        <Card
          content={
            <div>
              <DeleteAndEditButtons
                id={data._id}
                path='users'
                // afterDeleteFunc={() => {}}
                navigateToEdit='/edit-user'
              />
              {renderCenteredCardLabelValue('name', data.name)}
              {renderCenteredCardLabelValue('email', data.email)}
              {renderCenteredCardLabelValue('role', data.role)}
              {renderCenteredCardLabelValue('country', data.country || 'N/A')}
              {renderCenteredCardLabelValue('industry', data.industry || 'N/A')}
              {renderCenteredCardLabelValue('comapny', data.comapny || 'N/A')}
              {renderCenteredCardLabelValue('position', data.position || 'N/A')}
              {renderCenteredCardLabelValue(
                'last login',
                dateFormat(data.lastLogged)
              )}
              {renderCenteredCardLabelValue(
                'state',
                data.active ? 'active' : 'disabled'
              )}
            </div>
          }
        />
      );
    }
  }, [data]);

  return (
    <div>
      <h1>User Details</h1>
      <div>{user}</div>
    </div>
  );
};
export default UserDetails;
