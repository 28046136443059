import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card';
import threatIcon from '../../assets/images/bomb-icon-threat-library.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import Flags from '../../components/Flags';
import FiltersSelect from '../../components/FiltersSelect';
import useGetRequest from '../../hooks/use-get-request';
import FiltersBarAndData from '../../components/FiltersBarAndData';
import { dateFormat } from '../../utils/dateFormat';
import * as React from 'react';
import { renderCenteredCardLabelValue } from '../../utils/renderCenteredCardLabelValue';
// import {
//   AppBar,
//   Button,
//   // Card,
//   CardActions,
//   CardContent,
//   CardMedia,
//   CssBaseline,
//   Grid,
//   Stack,
//   Box,
//   Toolbar,
//   Typography,
//   Container,
//   Link,
// } from '@mui/material/AppBar';
// import CameraIcon from '@mui/icons-material/PhotoCamera';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

const ThreatLibraryCybersAndTerrors = (props) => {
  const {
    getError,
    getSuccess,
    setGetSuccess,
    getLoading,
    setGetLoading,
    getRequestFunc,
    data,
  } = useGetRequest();

  const { isAdmin } = useSelector((state) => state.appReducer);
  const [originalData, setOriginalData] = useState();
  const [currentData, setCurrentData] = useState();
  const [filters, setFilters] = useState();
  const navigate = useNavigate();

  const handleCurrentActor = (type, threatID) => {
    sessionStorage.setItem('threat_actor_type', type);
    sessionStorage.setItem('threatActorID', threatID);
    navigate(`/threat-actor/${threatID}`);
  };

  useEffect(() => {
    if (currentData) {
      let countries = [];
      let victims = [];
      const targetSectors = [];
      const years = [];

      currentData.map((actor) => {
        actor.country &&
          actor.country.map((t) => {
            if (t.trim() !== '' && t.toLowerCase() !== 'x') {
              if (
                !countries.some(
                  (item) => item.name.toLowerCase() === t.toLowerCase()
                )
              ) {
                countries.push({ name: t, quantity: 1 });
              } else {
                const existing = countries.find(
                  (element) => element.name.toLowerCase() === t.toLowerCase()
                );
                existing.quantity += 1;
              }
            }
          });

        props.type === 'cyber-threat-actor'
          ? actor.victims &&
            actor.victims.map((vic) => {
              if (vic.trim() !== '' && vic.toLowerCase() !== 'x') {
                if (
                  !victims.some(
                    (item) => item.name.toLowerCase() === vic.toLowerCase()
                  )
                ) {
                  victims.push({ name: vic, quantity: 1 });
                } else {
                  const existing = victims.find(
                    (element) =>
                      element.name.toLowerCase() === vic.toLowerCase()
                  );
                  existing.quantity += 1;
                }
              }
            })
          : actor.victim_country &&
            actor.victim_country.map((vic) => {
              if (vic.trim() !== '' && vic.toLowerCase() !== 'x') {
                if (
                  !victims.some(
                    (item) => item.name.toLowerCase() === vic.toLowerCase()
                  )
                ) {
                  victims.push({ name: vic, quantity: 1 });
                } else {
                  const existing = victims.find(
                    (element) =>
                      element.name.toLowerCase() === vic.toLowerCase()
                  );
                  existing.quantity += 1;
                }
              }
            });

        if (
          actor.victims_industries &&
          actor.victims_industries.trim() !== '' &&
          actor.victims_industries.toLowerCase() !== 'x'
        ) {
          if (
            !targetSectors.some(
              (item) =>
                item.name.toLowerCase() ===
                actor.victims_industries.toLowerCase()
            )
          ) {
            targetSectors.push({
              name: actor.victims_industries,
              quantity: 1,
            });
          } else {
            const existing = targetSectors.find(
              (element) =>
                element.name.toLowerCase() ===
                actor.victims_industries.toLowerCase()
            );
            existing.quantity += 1;
          }
        }
        if (actor.last_update) {
          const year = new Date(actor.last_update).getFullYear();
          const existingYear = years.find((item) => item.name === year);

          if (existingYear) {
            existingYear.quantity++;
          } else {
            years.push({ name: year, quantity: 1 });
          }
        }
      });

      setFilters([
        {
          header: 'Origin Country',
          fieldValue: 'country',
          filters: countries
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 10),
        },
        {
          header: 'Target Country',
          fieldValue:
            props.type === 'cyber-threat-actor' ? 'victims' : 'victim_country',
          filters: victims
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 10),
        },
        {
          header: 'Target Sector',
          fieldValue: 'victims_industries',
          filters: targetSectors
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 10),
        },
        {
          header: 'Year',
          fieldValue: 'last_update',
          filters: years
            .sort((a, b) => {
              return b.quantity - a.quantity;
            })
            .slice(0, 4),
        },
      ]);
    }
  }, [currentData]);

  useEffect(() => {
    if (data) {
      setOriginalData(data);
      setCurrentData(data);
    }
  }, [data]);

  const getActors = async (adminOnly = false) => {
    const url = adminOnly
      ? `https://api.hackeye.io/api/v1/${props.path}/${props.adminPath}`
      : `https://api.hackeye.io/api/v1/${props.path}`;
    await getRequestFunc(url, true);
  };

  const handleAdminOnly = (e) => {
    if (e.target.checked) {
      getActors(true);
    } else {
      getActors(false);
    }
  };

  useEffect(() => {
    getActors(false);
  }, []);

  //PRACTICE - START CHANGING THREAT LIBRARY TO MUI
  // const defaultTheme = createTheme();
  //   <ThemeProvider theme={defaultTheme}>
  //     <CssBaseline />
  //     <main>
  //       <Container
  //         sx={{
  //           py: 8,
  //         }}
  //         maxWidth='xl'
  //       >
  //         <Grid container spacing={4}>
  //           {data.map((threat, i) => (
  //             <Grid
  //               item
  //               key={i}
  //               xs={12}
  //               sm={6}
  //               md={4}
  //               onClick={() => {
  //                 handleCurrentActor(props.type, threat._id);
  //               }}
  //               style={{
  //                 border: '1px solid #212b44',
  //                 borderRadius: '15px',
  //                 flex: '1 0 21%',
  //                 margin: '10px',
  //                 padding: '0',
  //                 width: '25%',
  //               }}
  //             >
  //               <Card
  //                 sx={{
  //                   borderRadius: '15px',
  //                   border: '1px solid #212b44',
  //                   backgroundColor: '#181728',
  //                   color: '#ffffff7c',
  //                   height: '440px',
  //                   display: 'flex',
  //                   flexDirection: 'column',
  //                 }}
  //               >
  //                 <CardContent
  //                   sx={{
  //                     flexGrow: 1,
  //                     border: '5px solid #16131f',
  //                     borderRadius: '15px',
  //                     textAlign: 'left',
  //                   }}
  //                 >
  //                   <img
  //                     src={threatIcon}
  //                     alt=''
  //                     className='threat-library-threat-icon'
  //                   />
  //                   <Typography
  //                     gutterBottom
  //                     variant='h5'
  //                     component='h5'
  //                     sx={{ display: 'inline' }}
  //                   >
  //                     {threat.name} {threat.admin_only && '(ADMIN ONLY)'}
  //                   </Typography>
  //                   <Typography variant='h6' component='h6'>
  //                     Victims:
  //                   </Typography>
  //                   <Typography>
  //                     {threat.victims_industries &&
  //                     threat.victims_industries.length > 0
  //                       ? threat.victims_industries
  //                           .split(',')
  //                           .map(
  //                             (industry, i) =>
  //                               i <= 3 && industry !== '' && industry
  //                           )
  //                       : 'N/A'}
  //                   </Typography>
  //                   <Typography variant='h6' component='h6'>
  //                     Type:
  //                   </Typography>
  //                   <Typography>{threat.type || 'N/A'}</Typography>
  //                   <Typography>
  //                     {threat.tags &&
  //                       threat.tags.length > 0 &&
  //                       threat.tags
  //                         .split(',')
  //                         .map(
  //                           (industry, i) =>
  //                             i <= 3 && industry !== '' && industry
  //                         )}
  //                   </Typography>
  //                   <Typography variant='h6' component='h6'>
  //                     Last update:
  //                   </Typography>
  //                   <Typography>
  //                     {dateFormat(threat.last_update)}
  //                   </Typography>
  //                   <Typography variant='h6' component='h6'>
  //                     Description:
  //                   </Typography>
  //                   <Typography paragraph sx={{ fontSize: '14px' }}>
  //                     {threat.description || 'N/A'}
  //                   </Typography>
  //                 </CardContent>
  //               </Card>
  //             </Grid>
  //           ))}
  //         </Grid>
  //       </Container>
  //     </main>
  //   </ThemeProvider>
  // )

  const displayData = (data) => {
    return (
      data &&
      data.length > 0 && (
        <>
          <div className='threat-library-admin-only-checkbox-wrapper'>
            <label style={{ marginTop: '10px' }}>
              {data.length} Results found
            </label>
            <div style={{ display: 'flex' }}>
              <FiltersSelect
                options={[
                  'Display All',
                  'Last 3 Months',
                  'Last Month',
                  'Last Year',
                ]}
                onChange={() => {}}
              />
              <FiltersSelect options={['Relevance']} onChange={() => {}} />
              <FiltersSelect options={['Filter By']} onChange={() => {}} />
              {isAdmin && (
                <div style={{ padding: '5px', paddingTop: '8px' }}>
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      handleAdminOnly(e);
                    }}
                  />
                  <label>Admin Only</label>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data.map((threat, i) => {
              return (
                <div
                  key={threat._id}
                  style={{
                    flex: '1 0 21%',
                    margin: '10px',
                  }}
                  onClick={() => {
                    handleCurrentActor(props.type, threat._id);
                  }}
                >
                  <Card
                    className='threat-library-threat-actor-info-card-wrapper'
                    content={
                      <div className='threat-library-threat-actor-info-card-container'>
                        <div className='threat-library-threat-icon-name-container'>
                          <img
                            src={threatIcon}
                            alt=''
                            className='threat-library-threat-icon'
                          />
                          <span className='threat-library-threat-name'>
                            {threat.name} {threat.admin_only && '(ADMIN ONLY)'}
                          </span>
                        </div>
                        <div>
                          {renderCenteredCardLabelValue(
                            'Origin Country',
                            threat.country.length > 0 ? (
                              <Flags
                                countries={threat.country}
                                withName={true}
                                restrictedLength={true}
                                max={4}
                                imgContainerClassName='flags-image-seperator'
                              />
                            ) : (
                              'N/A'
                            )
                          )}
                          {props.type === 'cyber-threat-actor' &&
                            renderCenteredCardLabelValue(
                              'Top Target Countries',
                              threat.victims.length > 0 ? (
                                <Flags
                                  countries={threat.victims}
                                  withName={true}
                                  restrictedLength={true}
                                  max={4}
                                  imgContainerClassName='flags-image-seperator'
                                />
                              ) : (
                                'N/A'
                              )
                            )}
                          {props.type === 'terror-threat-actor' &&
                            renderCenteredCardLabelValue(
                              'Top Target Countries',
                              threat.victim_country.length > 0 ? (
                                <Flags
                                  countries={threat.victim_country}
                                  withName={true}
                                  restrictedLength={true}
                                  max={4}
                                  imgContainerClassName='flags-image-seperator'
                                />
                              ) : (
                                'N/A'
                              )
                            )}
                          {renderCenteredCardLabelValue(
                            'Top Target Sectors',

                            threat.victims_industries
                              .split(',')
                              .map((industry, i) => {
                                if (i <= 3) {
                                  if (industry !== '') {
                                    return (
                                      <div
                                        className='threat-library-actor-displayed-data-tag-filled-bg'
                                        key={i}
                                      >
                                        {industry}
                                      </div>
                                    );
                                  }
                                  return (
                                    <div
                                      className='threat-library-actor-displayed-data-tag-filled-bg'
                                      key={i}
                                    >
                                      N/A
                                    </div>
                                  );
                                }
                              })
                          )}
                          {renderCenteredCardLabelValue(
                            'Type',
                            threat.type || 'N/A'
                          )}

                          {renderCenteredCardLabelValue(
                            'Last Activity',
                            dateFormat(threat.last_update)
                          )}
                          {renderCenteredCardLabelValue(
                            'Description',
                            threat.description || 'N/A',
                            '92px'
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        </>
      )
    );
  };

  return originalData ? (
    <FiltersBarAndData
      filters={filters}
      originalData={originalData}
      displayData={displayData}
      searchbar={true}
      searchField='name'
    />
  ) : (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );
};
export default ThreatLibraryCybersAndTerrors;
