const Select = (props) => {
  return (
    <div className={props.wrapperClassName}>
      {props.label && <label>{props.label}</label>}
      <select
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        className={props.className}
      >
        <option value={props.defaultOption || 'Choose'}>
          {props.defaultOption || 'Choose'}
        </option>
        {props.trueOrFalseSelect
          ? props.options &&
            props.options.map((opt, i) => {
              return <option key={i}>{opt === 'no' ? 'false' : 'true'}</option>;
            })
          : props.options &&
            props.options.map((opt, i) => {
              return <option key={i}>{opt}</option>;
            })}
      </select>
    </div>
  );
};
export default Select;
