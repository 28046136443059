import usePostRequest from '../../hooks/use-post-request';
import { useEffect, useState } from 'react';
import ThreatIndustryList from '../../components/ThreatIndustryList';
import CreationFormMUI from '../../components/CreationFormMUI';
import { useSelector } from 'react-redux';

const CreateThreatOnFeed = () => {
  const { user_id } = useSelector((state) => state.appReducer);
  const [industries, setIndustries] = useState([]);

  const { postSuccess, postLoading, postFeedback, postError, postRequestFunc } =
    usePostRequest();

  const createNewItem = async () => {
    const content = document.getElementById('content').value;
    const title = document.getElementById('title').value;
    const recommendations = document.getElementById('recommendations').value;
    const tags = document.getElementById('tags').value;
    const url = document.getElementById('url').value;
    const reference = document.getElementById('reference').value;
    const date = document.getElementById('date').value;
    const newItemStr = JSON.stringify({
      title,
      content,
      admin_id: user_id,
      recommendations,
      tags,
      url,
      reference,
      industry: industries,
      date: new Date(date),
      createdAt: new Date(Date.now()),
    });

    await postRequestFunc(
      `https://api.hackeye.io/api/v1/threat-feed`,
      newItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Threat (Threat Feed)'
      textInputs={[
        { label: 'Title', id: 'title' },
        { label: 'Tags', id: 'tags' },
        { label: 'Recommendations', id: 'recommendations' },
        { label: 'Reference', id: 'reference' },
        { label: 'Url', id: 'url' },
        {
          label: 'Date',
          id: 'date',
          type: 'date',
          defaultValue: null,
        },
        { label: 'Content', id: 'content' },
      ]}
      selectInputs={
        <ThreatIndustryList
          onChange={(e) => {
            setIndustries((prev) => {
              return [...prev, e.target.value];
            });
          }}
        />
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateThreatOnFeed;
