import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import generalUserImg from '../assets/images/navbar/general-user-image.png';

export const setUserImage = (image_id, user_id) => {
  const storage = getStorage();

  getDownloadURL(ref(storage, `/profileImages/${user_id}`))
    .then((url) => {
      const img = document.getElementById(image_id);
      if (url) {
        img.setAttribute('src', url);
      }
    })
    .catch(() => {
      const img = document.getElementById(image_id);
      img.setAttribute('src', generalUserImg);
      // console.log(err);
    });
};
