import './Input.css';

const Input = (props) => {
  return (
    <div className='input-control-wrapper'>
      {props.label && (
        <label className='input-props-label'>{props.label}</label>
      )}
      <div className='input-control'>
        <input
          className={props.className}
          id={props.id}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          type={props.type || 'text'}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          accept={props.accept}
          minLength={props.min}
          maxLength={`${props.max}`}
        />
      </div>
      {props.description && <div>{props.description}</div>}
    </div>
  );
};
export default Input;
