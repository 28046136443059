import EditFormMUI from '../components/EditFormMUI';
import SeverityList from '../components/SeverityList';
import { useEffect, useState } from 'react';
import ThreatIndustryList from '../components/ThreatIndustryList';
import useGetRequest from '../hooks/use-get-request';
import { useNavigate } from 'react-router-dom';
import FormSelectListDynamic from '../components/FormSelectListDynamic';
import MalwareTypeList from '../components/MalwareTypeList';
import MalwareOperationSystemList from '../components/MalwareOperationSystemList';

const EditThreatRepoActor = () => {
  const navigate = useNavigate();
  const { getSuccess, getError, getLoading, getRequestFunc, data } =
    useGetRequest();
  const edit_malware_id = sessionStorage.getItem('edit_malware_id');
  const industries = [];
  const threatActors = [];
  const [severity, setSeverity] = useState(null);
  const [operationSystem, setOperationSystem] = useState(null);
  const [malwareTypes, setMalwareTypes] = useState([]);

  useEffect(() => {
    getRequestFunc(
      `https://api.hackeye.io/api/v1/malware-threat-actors/${edit_malware_id}`,
      true
    );
  }, []);

  const getEditedStr = async () => {
    const malware_name = document.getElementById('malware_name').value;
    const ioc = document.getElementById('ioc').value;
    const detection_name = document.getElementById('detection_name').value;
    const detections = document.getElementById('detections').value;
    const confidence_score = document.getElementById('confidence_score').value;
    const hash = document.getElementById('hash').value;
    const description = document.getElementById('description').value;
    const attack_vectors = document.getElementById('attack_vectors').value;
    const affected_platforms =
      document.getElementById('affected_platforms').value;
    const impacted_parties = document.getElementById('impacted_parties').value;
    const impact = document.getElementById('impact').value;
    const file_extention = document.getElementById('file_extention').value;
    const chainware = document.getElementById('chainware').value;
    const victomology = document.getElementById('victomology').value;
    const mitre_attack = document.getElementById('mitre_attack').value;
    const tags = document.getElementById('tags').value;

    const editedStr = JSON.stringify({
      malware_name,
      ioc,
      industry: industries.length === 0 ? data.industry : industries,
      detection_name,
      hash,
      severity: severity || data.severity,
      type: malwareTypes.length === 0 ? data.type : malwareTypes,
      description,
      attack_vectors,
      operation_system: operationSystem || data.operation_system,
      threat_actors:
        threatActors.length === 0 ? data.threatActors : threatActors,
      detections,
      confidence_score,
      affected_platforms,
      impacted_parties,
      impact,
      file_extention,
      chainware,
      victomology,
      mitre_attack,
      tags,
    });

    return editedStr;

    // await editRequestFunc(
    //   `https://api.hackeye.io/api/v1/malware-threat-actors/${edit_malware_id}`,
    //   updatedMalwareActor,
    //   true
    // );
  };

  return (
    data && (
      <EditFormMUI
        header='Threat Repository Actor'
        textInputs={[
          {
            id: 'malware_name',
            defaultValue: data.malware_name,
            label: 'malware_name',
          },
          { id: 'ioc', defaultValue: data.ioc, label: 'ioc' },
          {
            id: 'detection_name',
            defaultValue: data.detection_name,
            label: 'detection_name',
          },
          { id: 'hash', defaultValue: data.hash, label: 'hash' },

          {
            id: 'attack_vectors',
            defaultValue: data.attack_vectors,
            label: 'attack_vectors',
          },

          {
            id: 'detections',
            defaultValue: data.detections,
            label: 'detections',
          },
          {
            id: 'confidence_score',
            defaultValue: data.confidence_score,
            label: 'confidence_score',
          },
          {
            id: 'description',
            defaultValue: data.description,
            label: 'description',
          },
          {
            id: 'affected_platforms',
            defaultValue: data.affected_platforms,
            label: 'affected_platforms',
          },
          {
            id: 'impacted_parties',
            defaultValue: data.impacted_parties,
            label: 'impacted_parties',
          },
          { id: 'impact', defaultValue: data.impact, label: 'impact' },
          {
            id: 'file_extention',
            defaultValue: data.file_extention,
            label: 'file_extention',
          },
          { id: 'chainware', defaultValue: data.chainware, label: 'chainware' },
          {
            id: 'victomology',
            defaultValue: data.victomology,
            label: 'victomology',
          },
          { id: 'tags', defaultValue: data.tags, label: 'tags' },
          {
            id: 'mitre_attack',
            defaultValue: data.mitre_attack,
            label: 'mitre_attack',
          },
        ]}
        selectInputs={
          <>
            <MalwareOperationSystemList
              additionalData={`Operation System in DB: ${data.operation_system}`}
              onChange={(e) => {
                setOperationSystem(e.target.value);
              }}
            />
            <MalwareTypeList
              onChange={(e) => {
                setMalwareTypes((prev) => {
                  return [...prev, e.target.value];
                });
              }}
              additionalData={`Type in DB: ${data.type}`}
            />
            <ThreatIndustryList
              onChange={(e) => {
                industries.push(e.target.value);
              }}
              additionalData={`Industry in DB: ${data.industry}`}
            />
            <FormSelectListDynamic
              onChange={(e) => {
                threatActors.push(e.target.value);
              }}
              header='Threat Actors'
              path='cyber-threat-actors'
              optVal='name'
              id='threat_actors'
              additionalData={`Threat Actors in DB: ${data.threat_actors}`}
            />
            <SeverityList
              onChange={(e) => {
                setSeverity(e.target.value);
              }}
              additionalData={`Severity in DB: ${data.severity}`}
            />
          </>
        }
        itemToEdit={getEditedStr}
        path={`malware-threat-actors/${edit_malware_id}`}
        afterDeleteFunc={() => {
          navigate('/threat-repository');
        }}
      />
    )
  );
};
export default EditThreatRepoActor;
