import { useEffect, useState } from 'react';
import './TerrorDashboard.css';
import Card from '../components/Card';
import axios from 'axios';
import generalThreat from '../assets/images/navbar/general-user-image.png';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import Flags from '../components/Flags';
import EmergingThreatsMainDashboard from '../components/main dashboard/EmergingThreatsMainDashboard';
import { useNavigate } from 'react-router-dom';
import useGetRequest from '../hooks/use-get-request';
import DeleteAndEditButtons from '../components/DeleteAndEditButtons';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import CardWithLeftHeader from '../components/CardWithLeftHeader';

const TerrorDashboard = () => {
  const { getError, getSuccess, getLoading, getRequestFunc, data } =
    useGetRequest();

  const clipLoader = (
    <ClipLoader loading={true} size={35} className='cliploader' />
  );

  const { isAdmin } = useSelector((state) => state.appReducer);
  const storage = getStorage();
  const navigate = useNavigate();
  const [terrorActor, setTerrorActor] = useState();
  const [criminalActor, setCriminalActor] = useState();
  const [criminalImg, setCriminalImg] = useState();
  const [terrorImg, setTerrorImg] = useState(undefined);
  const [countries, setCountries] = useState(undefined);

  const token = sessionStorage.getItem('token');

  const getThreatImg = (id, type, setType) => {
    getDownloadURL(ref(storage, `/${type}_threat_actor_images/${id}`))
      .then((url) => {
        setType(url);
      })
      .catch(() => {
        setType(generalThreat);
      });
  };

  const getTerrorActor = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/terror-threat-actors', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const terrorGroup = res.data.data.doc[0];
          setTerrorActor(terrorGroup);
          getThreatImg(terrorGroup._id, 'terror', setTerrorImg);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const getCriminalActor = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/criminal-threat-actors', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const criminal = res.data.data.doc[0];
          setCriminalActor(criminal);
          getThreatImg(criminal._id, 'criminal', setCriminalImg);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTerrorActor();
    getCriminalActor();
    getRequestFunc(
      'https://api.hackeye.io/api/v1/most-dangerous-countries',
      true
    );
  }, []);

  useEffect(() => {
    if (data) {
      handleCountries();
    }
  }, [data]);

  const handleCountries = () => {
    const countryData = [];

    const sortedData = data.sort((a, b) => {
      return b.pct - a.pct;
    });

    sortedData.map((item, i) => {
      countryData.push(
        <div
          key={i}
          style={{
            display: 'flex',
            height: '50px',
            padding: '10px 5px',
            margin: '5px 0',
          }}
        >
          <div className='terror-dashboard-country-flag'>
            <Flags countries={[item.country]} withName={true} />
          </div>
          <div className='terror-dashboard-country-danger-level'>
            <div
              className='terror-dashboard-country-danger-level-percentage'
              style={{ width: `${item.pct * 10}%` }}
            />
          </div>
          {isAdmin && (
            <DeleteAndEditButtons
              id={item._id}
              path={'most-dangerous-countries'}
              afterDeleteFunc={() => {
                handleCountries();
              }}
              editFunc={() => {
                sessionStorage.setItem('edit_most_dangerous_country', item._id);
              }}
              navigateToEdit={`/edit-most-dangerous-country`}
            />
          )}
        </div>
      );
    });

    setCountries(countryData);
  };

  const handleCurrentActor = (type, threatID) => {
    sessionStorage.setItem('threat_actor_type', type);
    sessionStorage.setItem('threatActorID', threatID);
    navigate(`/threat-actor/${threatID}`);
  };

  return (
    <div className='terror-dashboard-wrapper page-wrapper-scrollable'>
      <div className='terror-dashboard-left-container'>
        <div className='terror-dashboard-top-left-container'>
          <div className='terror-dashboard-top-left-container-left'>
            <div className='terror-dashboard-top-left-container-left-card'>
              <Card
                content={
                  terrorActor ? (
                    <div className='terror-dashboard-threat-actor-information-card'>
                      {terrorImg && (
                        <img
                          className='terror-dashboard-terror-threat-img'
                          src={terrorImg}
                          alt=''
                        />
                      )}

                      <h6
                        className='terror-dashboard-terror-threat-name'
                        onClick={() => {
                          handleCurrentActor(
                            'terror-threat-actor',
                            terrorActor._id
                          );
                        }}
                      >
                        {terrorActor && terrorActor.name}
                      </h6>
                      <h6 className='terror-dashboard-terror-threat-description'>
                        {terrorActor &&
                          terrorActor.description !== '' &&
                          terrorActor.description}
                      </h6>
                    </div>
                  ) : (
                    clipLoader
                  )
                }
              />
            </div>
            <div className='terror-dashboard-top-left-container-left-card'>
              <Card
                content={
                  <div className='terror-dashboard-threat-actor-information-card'>
                    {criminalImg && (
                      <img
                        className='terror-dashboard-terror-threat-img'
                        src={criminalImg}
                        alt=''
                      />
                    )}

                    <h6
                      className='terror-dashboard-terror-threat-name'
                      onClick={() => {
                        handleCurrentActor(
                          'criminal-threat-actor',
                          criminalActor._id
                        );
                      }}
                    >
                      {criminalActor && criminalActor.name}
                    </h6>
                    <h6 className='terror-dashboard-terror-threat-description'>
                      {criminalActor &&
                        criminalActor.description !== '' &&
                        criminalActor.description}
                    </h6>
                  </div>
                }
              />
            </div>
          </div>
          <div className='terror-dashboard-top-left-container-right-card'>
            {countries ? (
              <CardWithLeftHeader
                css='terror-dashboard-top-left-container-right-card-content'
                header='Most Dangerous Countries'
                content={countries}
              />
            ) : (
              clipLoader
            )}
          </div>
        </div>

        <div className='terror-dashboard-bottom-left-container'>
          {' '}
          <div className='terror-dashboard-bottom-left-container-card'>
            <CardWithLeftHeader
              css='terror-dashboard-bottom-left-container-card-content'
              header='Telegram'
            />
          </div>
          <div className='terror-dashboard-bottom-left-container-card'>
            <CardWithLeftHeader
              css='terror-dashboard-bottom-left-container-card-content'
              header='Tiktok'
            />
          </div>{' '}
          <div className='terror-dashboard-bottom-left-container-card'>
            <CardWithLeftHeader
              css='terror-dashboard-bottom-left-container-card-content'
              header='Darknet'
            />
          </div>
        </div>
      </div>

      <div className='terror-dashboard-right-container'>
        <div className='terror-dashboard-right-top-card'>
          <CardWithLeftHeader
            css='terror-dashboard-bottom-left-container-card-content'
            header='Keywords'
            content={
              <div className='terror-dashboard-right-top-card-wrapper'>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>Terror</div>
                  <div>6,100,000</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>Bombs</div>
                  <div>5,102,000</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>
                    Dynamite bomb
                  </div>
                  <div>5,000,123</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>Murder</div>
                  <div>192,293</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>
                    Explosions
                  </div>
                  <div>100,231</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>
                    Terrorist
                  </div>
                  <div>98,132</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>Hammas</div>
                  <div>78,642</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>Jihad</div>
                  <div>65,962</div>
                </div>
                <div className='terror-dashboard-keyword'>
                  <div className='terror-dashboard-keyword-value'>
                    Propaganda
                  </div>
                  <div>54,167</div>
                </div>
              </div>
            }
          />
        </div>
        <div>
          {' '}
          <div>
            <CardWithLeftHeader
              header='Real-time Alerts'
              content={<EmergingThreatsMainDashboard />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TerrorDashboard;
