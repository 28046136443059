import rectangle from '../../assets/images/main-dashboard/rectangle-16@2x.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Flags from '../Flags';
import './PotentialThreatsMainDash.css';
import useGetRequest from '../../hooks/use-get-request';

const PotentialThreatsMainDash = () => {
  const navigate = useNavigate();
  const { getRequestFunc, data } = useGetRequest();
  const [top3, setTop3] = useState();

  useEffect(() => {
    getRequestFunc('https://api.hackeye.io/api/v1/cyber-threat-actors', true);
  }, []);

  useEffect(() => {
    const displayedTop3 = [];
    if (data) {
      const fixedData = data
        .filter((value) => {
          return value.createdAt && value.country.length > 0;
        })
        .sort((a, b) => {
          return a.createdAt - b.createdAt;
        })
        .slice(0, 3);

      fixedData.map((threat, i) => {
        displayedTop3.push(
          <div
            key={i}
            onClick={() => {
              sessionStorage.setItem('threat_actor_type', 'cyber-threat-actor');
              sessionStorage.setItem('threatActorID', threat.id);
              navigate(`/threat-actor/${threat.id}`);
            }}
          >
            <div className='potential-threats-main-dash-flag-name-container'>
              <Flags
                countries={threat.country}
                withName={false}
                displayOtherDataByFlag={threat.name}
              />
            </div>
            <img
              src={rectangle}
              alt=''
              className='main-dashboard-real-time-alerts-threat-line'
            />
          </div>
        );
      });
      setTop3(displayedTop3);
    }
  }, [data]);

  return (
    <div className='potential-threats-main-dashboar-wrapper'>
      <div className='main-dashboard-real-time-alerts-header'>
        Potential threats
      </div>
      {top3}
    </div>
  );
};
export default PotentialThreatsMainDash;
