import { useEffect } from 'react';
import './ThreatRepositoryActor.css';
import { useSelector } from 'react-redux';
import Table from '../components/Table';
import PieChartSingleValue from '../components/PieChartSingleValue';
import { dateFormat } from '../utils/dateFormat';

const TopMalware = () => {
  const { top_malware } = useSelector((state) => state.appReducer);
  let severity;
  if (top_malware.pct <= 25) {
    severity = 'Low';
  } else if (top_malware.pct > 25 && top_malware.pct <= 50) {
    severity = 'Medium';
  } else if (top_malware.pct > 50 && top_malware.pct <= 75) {
    severity = 'High';
  } else {
    severity = 'Critical';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='threat-repository-actor-top-data-container'>
        <PieChartSingleValue
          header='Severity'
          value={severity}
          color={severity}
        />
        <div className='threat-repository-actor-actor-info-container'>
          <div className='threat-repository-actor-details-container'>
            <div>
              Threat name: {'  '}
              <span>{top_malware.signature || 'N/A'}</span>
            </div>
            <div>
              MD5 Hash:{'  '} <span>{top_malware.md5_hash || 'N/A'}</span>
            </div>
          </div>
          <div className='threat-repository-actor-details-container'>
            <div>
              Last Activity:{'  '}
              <span>
                {top_malware.last_seen
                  ? dateFormat(top_malware.last_seen)
                  : 'x'}
              </span>{' '}
            </div>
            <div>
              SHA1 Hash: {'  '}
              <span>{top_malware.sha1_hash || 'N/A'}</span>
            </div>
          </div>
          <div className='threat-repository-actor-details-container'>
            <div>
              SHA256 Hash:{'  '} <span>{top_malware.sha256_hash || 'N/A'}</span>
            </div>
          </div>
        </div>
      </div>
      <Table
        receivedData={undefined}
        tabs={[
          'Indicator Of Compromise',
          'Cyber Campaigns',
          'Playbook',
          'Pseudo Code',
          'Indicator Of Attack',
          'Recommendations',
        ]}
      />
    </div>
  );
};
export default TopMalware;
