import './Login.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, adminLogin } from '../actions';
import ClipLoader from 'react-spinners/ClipLoader';
import loginLogo from '../assets/images/navbar/Logint.png';
import { setUser } from '../utils/handleLogin';
import usePatchRequest from '../hooks/use-patch-request';
import axios from 'axios';

const Login = () => {
  const { editRequestFunc } = usePatchRequest();
  const token =
    sessionStorage.getItem('token') || localStorage.getItem('token');
  const { isLoggedIn, user_id, loginTime } = useSelector(
    (state) => state.appReducer
  );
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [pressedLogin, setPressedLogin] = useState(false);

  /**************************************************************************************************************************** */
  const logUser = (user) => {
    if (user.role === 'admin') {
      dispatch(
        adminLogin(
          user.email,
          user.name,
          true,
          user.beforeLastLog,
          user.organization_id,
          user.id
        )
      );
    } else {
      dispatch(
        userLogin(
          user.email,
          user.name,
          user.beforeLastLog,
          user.organization_id,
          user.id
        )
      );
    }
  };

  useEffect(() => {
    if (token && isLoggedIn && pressedLogin) {
      editRequestFunc(
        `https://api.hackeye.io/api/v1/users/${user_id}`,
        {
          lastLogged: new Date(Date.now()),
        },
        true
      );
    }
  }, [isLoggedIn, pressedLogin]);

  const validateLogin = async () => {
    setPressedLogin(true);
    const email = document.querySelector('.email-input').value;
    const password = document.querySelector('.password-input').value;

    /**************************************************************************************************************************** */

    try {
      setLoading(true);
      setError(false);
      await axios
        .post(
          'https://api.hackeye.io/api/v1/users/login',
          {
            email,
            password,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .then(async (res) => {
          const data = res.data;
          const user = data.data.user;
          // document.cookie = `token: ${data.token}`;
          setUser(data.token);

          //IF USER IS ACTIVE + INDIVIDUAL
          if (user.active && !user.organization_id) {
            logUser(user);

            navigate('/main-dashboard');
            //IF USER IS ACTIVE + PART OF ORGANIZATION
          } else if (user.active && user.organization_id) {
            try {
              await axios
                .get(
                  `https://api.hackeye.io/api/v1/organizations/${user.organization_id}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }
                )
                .then(async (res) => {
                  const { expiration } = res.data.data.doc;
                  if (new Date(Date.now()) >= new Date(expiration)) {
                    setLoading(false);
                    setError(true); //License expired
                    setErrorMsg('License has Expired');
                  } else {
                    setError(false);
                    logUser(user);

                    navigate('/main-dashboard');
                  }
                })
                .catch((err) => {
                  setErrorMsg('Something went wrong!');
                  setError(true);
                  setLoading(false);
                });
            } catch (err) {
              setErrorMsg('Something went wrong!');
              setError(true);
              setLoading(false);
            }
          } else {
            setError(true);
            setLoading(false);
            setErrorMsg('Something went wrong!');
          }
        })
        .catch(() => {
          setError(true);
          setLoading(false);
          setErrorMsg('Something went wrong!');
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='login-page'>
      <div className='login-page-container'>
        <div className='login-wrapper'>
          <img className='login-logo' src={loginLogo} alt=''></img>
          <div className='login-form-wrapper'>
            <div className='login-form-container'>
              <h1 className='login-header'>Login</h1>
              <div className='login-sub-header'>Sign into your account</div>
              <div className='login-form'>
                <div className='login-input-control'>
                  <input
                    className='email-input'
                    type='text'
                    placeholder='Email address'
                  ></input>
                </div>
                <div className='login-input-control'>
                  <input
                    className='password-input'
                    type='password'
                    placeholder='password'
                  ></input>
                </div>
              </div>
              <button className='login-btn' onClick={validateLogin}>
                Login
              </button>
            </div>
          </div>
        </div>

        {!loading && error && <div className='login-error-msg'>{errorMsg}</div>}
        <ClipLoader loading={loading} size={35} className='cliploader' />
      </div>
    </div>
  );
};
export default Login;
