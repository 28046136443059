import { useEffect, useState } from 'react';
import usePostRequest from '../../hooks/use-post-request';
import CreationFormMUI from '../../components/CreationFormMUI';
import FormSelectListStatic from '../../components/FormSelectListStatic';
import FormSelectListDynamic from '../../components/FormSelectListDynamic';

const CreateThreatHuntingItem = () => {
  const { postFeedback, postRequestFunc, postSuccess, postError, postLoading } =
    usePostRequest();
  const [type, setType] = useState('N/A');
  const [malwareID, setMalwareID] = useState(null);

  const createNewItem = async () => {
    const name = document.getElementById('name').value;
    const date = document.getElementById('date').value;
    const description = document.getElementById('description').value;
    const program = document.getElementById('program').value;
    const legitimate_path = document.getElementById('legitimate_path').value;

    const tags =
      document.getElementById('tags').value.trim() === ''
        ? []
        : document
            .getElementById('tags')
            .value.split(',')
            .map((t) => {
              return t.trim();
            });

    const threatHuntingItemStr = JSON.stringify({
      name,
      program,
      description,
      tags,
      type,
      legitimate_path,
      malware_id: malwareID,
      createdAt: new Date(Date.now()),
      date: new Date(date),
    });

    await postRequestFunc(
      'https://api.hackeye.io/api/v1/threat-hunting',
      threatHuntingItemStr,
      true
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CreationFormMUI
      header='Threat Hunting Item'
      textInputs={[
        { label: 'Name', id: 'name' },
        { label: 'Program', id: 'program' },
        { label: 'Description', id: 'description' },
        { label: 'Tags', id: 'tags' },
        { label: 'Legitimate Path', id: 'legitimate_path' },
        { id: 'date', type: 'date', defaultValue: null },
      ]}
      selectInputs={
        <>
          <FormSelectListStatic
            id='type'
            onChange={(e) => {
              setType(e.target.value);
            }}
            header='Type'
            options={[
              'Process',
              'Service',
              'Registry Key',
              'Command',
              'DLL',
              'Daemon',
            ]}
          />
          <FormSelectListDynamic
            header='Malware'
            onChange={(e) => {
              setMalwareID(e.target.value);
            }}
            path='malware-threat-actors'
            optVal='malware_name'
            id='malware_id'
          />
        </>
      }
      onClick={() => {
        createNewItem();
      }}
      postSuccess={postSuccess}
      postError={postError}
      postFeedback={postFeedback}
      postLoading={postLoading}
    />
  );
};
export default CreateThreatHuntingItem;
