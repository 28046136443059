export const renderCenteredCardLabelValue = (
  header,
  content,
  height = '17px'
) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <div style={{ color: '#fff', opacity: '0.5' }}>{header}</div>
      <div
        style={{
          color: '#fff',
          margin: '5px 0',
          fontSize: '12px',
          overflow: 'hidden',
          // border: '1px solid red',
          height: height,
        }}
      >
        {content}
      </div>
    </div>
  );
};
