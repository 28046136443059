import './EmergingThreatsMainDashboard.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../utils/dateFormat';

const EmergingThreatsMainDashboard = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const [allthreats, setAllThreats] = useState([]);
  const threats = [];

  const getEmergingThreats = async () => {
    try {
      await axios
        .get('https://api.hackeye.io/api/v1/emerging-threats', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { doc } = res.data.data;

          const handledDoc = doc
            .sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            })
            .slice(0, 12);

          handledDoc.map((threat, i) => {
            threats.push(
              <div
                className='main-dash-emerging-threat'
                key={i}
                onClick={() => {
                  sessionStorage.setItem('emerging_threat', threat._id);
                  navigate(`/emerging-threat/${threat._id}`);
                }}
              >
                <div className='small text-truncate main-dash-emerging-threat-line'>
                  {threat.threatname.replaceAll('&lt;', '')}
                </div>
                <div className='small text-truncate main-dash-emerging-threat-date'>
                  {dateFormat(threat.date)}
                </div>
                <div
                  className={`small text-truncate main-dash-real-time-alerts-severity-level ${threat.severity.toLowerCase()}`}
                >
                  {threat.severity}
                </div>
              </div>
            );
          });
          setAllThreats(threats);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEmergingThreats();
  }, []);

  return (
    <div className='emerging-threats-main-dash-wrapper'>
      {props.withHeader && (
        <div className='main-dash-emerging-threat-header'>Emerging Threats</div>
      )}
      <div className='emerging-threats-main-dash-threat-list'>{allthreats}</div>
    </div>
  );
};
export default EmergingThreatsMainDashboard;
