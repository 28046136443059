import DeleteAndEditButtons from './DeleteAndEditButtons';
import { ListItemText, Typography, List } from '@mui/material';
const ListReadOnly = (props) => {
  return (
    <List>
      {props.data.map((item, i) => {
        return (
          <ListItemText
            key={i}
            id={item.id}
            sx={{ padding: '10px' }}
            onClick={item.onClick}
            disableTypography
            primary={item.label}
            secondary={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ color: '#FFFFFF' }}>
                  {item.value}
                </Typography>
                {item.actionBtns && (
                  <DeleteAndEditButtons
                    id={item.id}
                    path={item.path}
                    afterDeleteFunc={item.afterDeleteFunc}
                    navigateToEdit={`${item.navigateToEdit}`}
                  />
                )}
              </div>
            }
          />
        );
      })}
    </List>
  );
};
export default ListReadOnly;
